import * as React from 'react'
import { AppMessageBehavior } from '@pushly/models/lib/structs/app-messages/app-message-behavior'
import { convertSeconds } from '../../../_utils/time'
import { AppMessagePriority } from '@pushly/models/lib/enums'
import titleCase from 'title-case'

type AMBehaviorSlugProps = {
    behavior: AppMessageBehavior
    priority: AppMessagePriority
}

export const AppMessageBehaviorSlugs = ({ behavior, priority }: AMBehaviorSlugProps) => {
    const redisplay = behavior.redisplay
    const maxDisplayTime = behavior.maxDisplayTime

    let redisplaySlug
    let maxDisplayTimeSlug

    if (maxDisplayTime) {
        const displayMetric = maxDisplayTime.displayMetric
        maxDisplayTimeSlug = `Enabled: auto dismisses after ${convertSeconds(
            maxDisplayTime.intervalSeconds,
            displayMetric,
        )} ${displayMetric.toLowerCase()}`
    } else {
        maxDisplayTimeSlug = 'Disabled'
    }

    if (redisplay.enabled && redisplay.fcap) {
        const fcap = redisplay.fcap
        const metric = fcap.displayMetric
        const convertedTime = convertSeconds(fcap.intervalSeconds, metric)

        const displayMetric = convertedTime === 1 ? metric.toLowerCase().slice(0, -1) : metric.toLowerCase()

        redisplaySlug = `Enabled: redisplay after ${convertedTime} ${displayMetric.toLowerCase()}`
    } else {
        redisplaySlug = 'Disabled'
    }

    return (
        <div>
            <div>
                <span className="priority">
                    <b>Priority</b>&nbsp;
                    {titleCase(
                        Object.keys(AppMessagePriority).find(
                            (key) => typeof key === 'string' && AppMessagePriority[key] === priority,
                        )!,
                    )}
                </span>
            </div>
            <div>
                <span className="behavior-max-display-slug">
                    <b>Auto Dismiss</b> {maxDisplayTimeSlug}
                </span>
            </div>
            <div>
                <span className="behavior-redisplay-slug">
                    <b>Redisplay</b> {redisplaySlug}
                </span>
            </div>
        </div>
    )
}
