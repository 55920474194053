import * as moment from 'moment-timezone'
import { arrayContains, numberWithCommas } from './utils'
import * as React from 'react'

export class HighchartsUtils {
    public static defaultTooltipPointFormatter(): string {
        const ctx = this as any
        const ttSuffix = ctx.series.tooltipOptions.valueSuffix

        return `
<br/>
<span style="color: ${ctx.color}">●</span>
${ctx.series.name}: <b>${ctx.y}${ttSuffix ? ttSuffix : ''}</b>
`.trim()
    }

    public static commaTooltipPointFormatter() {
        const ctx = this as any
        const ttPrefix = ctx.series.tooltipOptions.valuePrefix

        return `
<br/>
<span style="color: ${ctx.color}">●</span>
${ctx.series.name}${ttPrefix ? ttPrefix : ''}: <b>${numberWithCommas(ctx.y)}</b>
`.trim()
    }

    public static currencyTooltipPointFormatter(currency: string = 'USD'): () => React.ReactNode {
        return function () {
            // @ts-ignore
            const ctx = this as any
            const currencyValue = Intl.NumberFormat('en-us', { style: 'currency', currency }).format(ctx.y ?? 0)

            return `
<br/>
<span style="color: ${ctx.color}">●</span>
${ctx.series.name}: <b>${currencyValue}</b>
`.trim()
        }
    }

    public static defaultTooltipFormatter(tooltip: any): string {
        const ctx = this as any
        let xInLocale: string = ''

        if (tooltip.chart.options.swContext) {
            const timeWindowPreset = tooltip.chart.options.swContext().timeWindowPreset
            const timeBreakdown = tooltip.chart.options.swContext().timeBreakdown

            if (!arrayContains(['today', 'yesterday'], timeWindowPreset) && timeBreakdown === 'day') {
                xInLocale = moment(ctx.x, 'MMM D').format('(dddd)')
            }
        }

        return `
<span style="font-size: 10px">${ctx.x} ${xInLocale}</span><br/>

${ctx.points
    .map((point: any) => {
        const ttOptions = point.series.tooltipOptions
        if (ttOptions.pointFormatter) {
            return ttOptions.pointFormatter.call(point)
        } else if (tooltip.options.shared && tooltip.options.pointFormatter) {
            return tooltip.options.pointFormatter.call(point)
        }

        return HighchartsUtils.defaultTooltipPointFormatter.call(point)
    })
    .join('')}
`.trim()
    }
}
