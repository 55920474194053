import React from 'react'
import { getClassNames } from '../../_utils/classnames'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Card, Modal, Result, Button, Skeleton, Descriptions } from 'antd'
import { useMountedRef } from '../../_utils/use-mounted-ref'
import { useService } from '../../hooks/use-service'
import { DomainIntegrationService } from '../../services'
import { useLoadableDataState } from '../../hooks/use-loadable-data-state'
import { simpleNotification } from '../../_utils/utils'
import { IIntegrationRow, IWordPressIntegration } from './interfaces'
import CopyToClipboard from 'react-copy-to-clipboard'
import moment from 'moment/moment'

const WordpressIntegration = (props: IIntegrationRow<IWordPressIntegration>) => {
    const { domainId, loading, integration } = props

    const runIfMounted = useMountedRef()[1]
    const [value, setValue] = useLoadableDataState({
        loading: false,
        data: integration,
    })
    const svc = useService<DomainIntegrationService>(DomainIntegrationService)

    const createIntegration = async () => {
        if (props.mode === 'create') {
            setValue({
                loading: true,
                data: {
                    ...(value.data ?? ({} as any)),
                    isActive: true,
                    data: {},
                },
            })

            Modal.destroyAll()
            const modal = Modal.success({
                className: 'completing-oauth-dialog',
                title: 'Creating WordPress API Key',
                icon: <span className="wordpress-logo-color" />,
                okText: 'Complete',
                cancelText: 'Cancel',
                footer: null,
                content: (
                    <div>
                        <Loading3QuartersOutlined spin={true} />
                    </div>
                ),
            } as any)

            const res = await svc.completeIntegrationSetup(domainId, 'wordpress', {}, 'wordpress-integration')

            runIfMounted(() => {
                setValue({
                    loading: false,
                })

                if (res.ok && res.data?.data?.token) {
                    modal.update({
                        className: 'wordpress-api-key-success-modal',
                        title: 'WordPress API Key Created',
                        content: (
                            <div className="api-key-success-modal-content">
                                <div>
                                    <span>
                                        The following API Key should be saved on the WordPress plugin Settings page in
                                        the <b>API Key</b> text box. Click the key to copy it your clipboard.
                                    </span>
                                </div>
                                <div className="api-key-viewable">
                                    <CopyToClipboard
                                        text={res.data.data.token!}
                                        onCopy={() =>
                                            simpleNotification(
                                                'success',
                                                'WordPress API Key has been copied to your clipboard',
                                            )
                                        }
                                    >
                                        <span>{res.data.data.token}</span>
                                    </CopyToClipboard>
                                </div>
                                <div>
                                    <span>
                                        Once this window is closed you will not be able to access this key again. In the
                                        event of the key being lost, a new key must be created at which point this key
                                        will no longer be valid.
                                    </span>
                                </div>
                            </div>
                        ),
                        okText: 'Close',
                        onOk: () => {
                            modal.destroy()
                            props.onAdd?.(res.data)
                        },
                    })
                } else {
                    modal.update({
                        className: 'failed-oauth-dialog',
                        title: 'Wordpress API Key Creation Failed',
                        content: (
                            <Result
                                status="warning"
                                title={false}
                                subTitle="An error occurred while attempting to create the Wordpress API Key."
                            />
                        ),
                        onOk: () => {
                            modal.destroy()
                        },
                    })
                }
            })
        }
    }

    const removeIntegration = async () => {
        if (integration || value.data) {
            setValue({
                ...value,
                loading: true,
            })

            Modal.confirm({
                className: 'remove-integration-dialog',
                title: 'Invalidate WordPress API Key',
                icon: <span className="wordpress-logo-color" />,
                okText: 'Complete',
                cancelText: 'Cancel',
                content: (
                    <div>
                        <p>
                            Performing this step will invalidate the WordPress API Key attached to this domain resulting
                            in notifications no longer being sent when articles are published.
                        </p>
                        <p>
                            In order to disable prompting visitors to subscribe to push when they visit your site you
                            will also need to navigate to your WordPress Admin and uninstall the plugin.
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const res = await svc.removeIntegration(domainId, value.data!.id!)

                    if (res.ok) {
                        simpleNotification('success', 'Wordpress API Key was successfully invalidated.')
                        props.onRemove?.(value.data!)
                    } else {
                        setValue({
                            ...value,
                            loading: false,
                        })
                    }
                },
                onCancel: async () => {
                    setValue({
                        ...value,
                        loading: false,
                    })
                },
            })
        }
    }

    const isActive = value.data?.isActive

    return (
        <Card
            size="small"
            className={getClassNames('domain-integration', 'wordpress', props.className, {
                active: isActive,
                empty: !value.data?.id,
            })}
            title={<span className="wordpress-logo-color" />}
            extra={
                <Button
                    className={getClassNames(null, 'integration-toggle', 'enabled', {
                        linked: !!integration?.id,
                    })}
                    size="small"
                    loading={loading || value.loading}
                    icon={<LegacyIcon type={integration?.id ? 'disconnect' : 'link'} />}
                    onClick={() => (integration?.id ? removeIntegration() : createIntegration())}
                >
                    <span>
                        {loading || value.loading ? 'Loading' : !integration?.id ? 'New API Key' : 'Invalidate API Key'}
                    </span>
                </Button>
            }
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false} paragraph={{ rows: 1 }}>
                {value.data?.id && (
                    <Descriptions layout="horizontal" column={1}>
                        <Descriptions.Item label="Status">Enabled</Descriptions.Item>
                        <Descriptions.Item label="Last Active">
                            {value?.data.data.tokenLastUsedAt
                                ? moment(value?.data.data.tokenLastUsedAt).format('LL')
                                : 'Never'}
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </Skeleton>
        </Card>
    )
}

export default WordpressIntegration
