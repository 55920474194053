import { Skeleton } from 'antd'
import { Well } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import * as React from 'react'

const SkeletonWrapper = (props: { loading?; children }) => (
    <Skeleton loading={props.loading} active={true} avatar={false} title={false}>
        {props.children}
    </Skeleton>
)

export const Section = (props: Well['props']) => {
    const wellProps: Well['props'] = {
        ...props,
        className: getClassNames(props?.className ?? 'default'),
        mode: 'ghost',
        hideFooter: true,
    }
    return (
        <Well {...wellProps}>
            <SkeletonWrapper loading={false}>{props.children}</SkeletonWrapper>
        </Well>
    )
}
