import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { NotificationService } from '../../services'
import { NotificationDto } from '../../features/notifications'
import { PromiseableProp } from '../../_utils/promiseable-prop'
import { ApiVersion } from '../../enums/api-version.enum'
import { NotificationTemplateService } from '../../services/notification-template'
import NotificationTemplatesLoader from '../../features/notifications/named-notification-template/notification-templates-loader'
import { CustomApiResponseErrorHandler } from '../../types/api-call-options-with-custom-error-handler'

interface INotificationsLoader {
    domainId: number
    notifId?: number
    testId?: number
    templateId?: number
    children: (notifications: PromiseableProp<NotificationDto[] | undefined>) => JSX.Element
    errorHandler?: CustomApiResponseErrorHandler
}

const NotificationsLoader = ({ domainId, notifId, testId, templateId, children, ...props }: INotificationsLoader) => {
    const notifService = Container.get(NotificationService)
    const notifTemplateSvc: NotificationTemplateService = Container.get(NotificationTemplateService)

    const loadData = async (): Promise<NotificationDto[] | undefined> => {
        const notifs: any = []

        if (notifId) {
            const notification = await notifService.fetchNotificationById(domainId, notifId, {
                query: { includeSchedules: true },
                cancellationKey: `nl-n${notifId}`,
                version: ApiVersion.V4,
                errorHandler: props.errorHandler,
            })
            if (!!notification) {
                if (!testId) {
                    delete notification.abTest

                    notifs.push(notification)
                } else {
                    const { notifications, schedules, ...test } = (await notifService.fetchTestById(
                        domainId,
                        testId,
                        undefined,
                        `nl-t${testId}`,
                    )) as any

                    notifications.forEach((n) => {
                        const notif = NotificationDto.parse(n)
                        notif.audience = notification?.audience! ?? {}
                        notif.abTest = {
                            ...(notif.abTest ?? {}),
                            ...test,
                        }

                        const schedule = schedules.find((s) => s.notificationId.toString() === n.id.toString())
                        if (schedule) {
                            notif.status = schedule.status ?? notif.status
                            notif.deliverySpec = {
                                ...schedule.deliverySpec,
                                ...notif.deliverySpec,
                            }
                        }

                        notifs.push(notif)
                    })
                }
            }
        }

        return notifs.length ? notifs : undefined
    }

    return templateId ? (
        <NotificationTemplatesLoader
            domainId={domainId}
            templateId={templateId}
            children={children}
            customErrorHandler={props.errorHandler}
        />
    ) : (
        children(loadData())
    )
}

export default React.memo(NotificationsLoader, (prevProps, nextProps) => prevProps.templateId === nextProps.templateId)
