import { Singleton } from 'typescript-ioc'
import { ApiCallOptionsWithCustomErrorHandler } from '../types/api-call-options-with-custom-error-handler'
import { AppMessage } from '@pushly/models/lib/structs/app-messages/app-message'
import { AppService } from './app'
import { Container } from 'typescript-ioc/es5'
import * as querystring from 'querystring'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import aqe from '@pushly/aqe'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import { IApiCallOptions } from '@pushly/aqe/lib/interfaces'
import { simpleNotification } from '@pushly/aqe/lib/utils'
import { AppMessageBuilderPayload, AppMessageViewable } from '../components/app-message-builder/model-extensions'
import { Constructor } from '@pushly/models/lib/types/constructor'
import { MutableStruct } from '@pushly/models/lib/structs/abstract-struct'

@Singleton
export class AppMessageService {
    private appSvc: AppService

    public constructor() {
        this.appSvc = Container.get(AppService)
    }

    public async fetchAppMessagesByDomainId(
        domainId: number,
        opts?: any,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<{ messages: AppMessage[]; meta: any; cancelled: boolean }> {
        if (showLoadingScreen) this.appSvc.setModuleLoading()

        let response: any = {
            messages: [],
            meta: {},
            cancelled: false,
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: this.buildSvcRouteFromBase(domainId, undefined, opts),
                },
                cancellationKey,
            )
            const { data: messages, ...data } = req.data

            response = {
                messages,
                meta: data,
            }
        } catch (error) {
            response.cancelled = isAxiosCancellation(error)
        }

        if (showLoadingScreen) this.appSvc.unsetModuleLoading()

        return response
    }

    public async fetchAppMessageById(
        klass: Constructor<AppMessageViewable>,
        domainId: number,
        appMsgId: number,
        opts: ApiCallOptionsWithCustomErrorHandler,
    ): Promise<IServiceApiResponse<AppMessageViewable | undefined>>
    public async fetchAppMessageById(
        klass: Constructor<AppMessageBuilderPayload>,
        domainId: number,
        appMsgId: number,
        opts: ApiCallOptionsWithCustomErrorHandler,
    ): Promise<IServiceApiResponse<AppMessageBuilderPayload | undefined>>
    public async fetchAppMessageById<T extends AppMessageViewable | AppMessageBuilderPayload>(
        klass: Constructor<T>,
        domainId: number,
        appMsgId: number,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<
        T extends AppMessageViewable
            ? IServiceApiResponse<AppMessageViewable | undefined>
            : IServiceApiResponse<AppMessageBuilderPayload | undefined>
    > {
        if (opts.showLoadingScreen) this.appSvc.setModuleLoading()

        let data: T | undefined
        let cancelled = false
        let ok = false
        let meta = {}
        try {
            const response = await axiosFetch(
                'get',
                {
                    url: this.buildSvcRouteFromBase(domainId, appMsgId.toString(), opts.query),
                },
                opts.cancellationKey,
            )

            const { data: message, ...responseMeta } = response.data
            data = (klass as MutableStruct<T>).parseJsonObject(message) as T
            ok = true
            meta = responseMeta
        } catch (error) {
            if (opts.errorHandler) {
                const defaultHandler = () => handleResponseErrorMessage(error)
                opts.errorHandler(error, defaultHandler)
            }
        }

        if (opts.showLoadingScreen) this.appSvc.unsetModuleLoading()

        return { ok, data, meta, cancelled } as T extends AppMessageViewable
            ? IServiceApiResponse<AppMessageViewable | undefined>
            : IServiceApiResponse<AppMessageBuilderPayload | undefined>
    }

    public async create(
        domainId: number,
        requestDto: AppMessageBuilderPayload,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<IServiceApiResponse<AppMessage | undefined>> {
        this.handleLoadingAnimation('show', opts)

        let data: AppMessage | undefined
        let cancelled = false
        let ok = false
        let meta = {}

        try {
            const response = await axiosFetch(
                'post',
                {
                    url: this.buildSvcRouteFromBase(domainId, ''),
                    data: requestDto.serialize(),
                },
                opts.cancellationKey,
            )

            const { data: message, ...responseMeta } = response.data
            data = AppMessage.parseJsonObject(message)
            ok = true
            meta = responseMeta

            simpleNotification('success', 'App Message successfully created.')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.handleLoadingAnimation('hide', opts)

        return { ok, data, meta, cancelled }
    }

    public async patch(
        domainId: number,
        appMsgId: number,
        requestDto: AppMessageBuilderPayload,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<IServiceApiResponse<AppMessage | undefined>> {
        this.handleLoadingAnimation('show', opts)

        let data: AppMessage | undefined
        let cancelled = false
        let ok = false
        let meta = {}

        try {
            const response = await axiosFetch(
                'patch',
                {
                    url: this.buildSvcRouteFromBase(domainId, appMsgId.toString()),
                    data: requestDto.serialize(),
                },
                opts.cancellationKey,
            )

            const { data: message, ...responseMeta } = response.data
            data = AppMessage.parseJsonObject(message)
            ok = true
            meta = responseMeta

            simpleNotification('success', 'App Message successfully updated.')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.handleLoadingAnimation('hide', opts)

        return { ok, data, meta, cancelled }
    }

    protected handleLoadingAnimation(state: 'show' | 'hide', options: IApiCallOptions = {}) {
        if (options.showLoadingScreen) {
            if (state === 'show') {
                this.appSvc.setModuleLoading()
            } else {
                this.appSvc.unsetModuleLoading()
            }
        }
    }

    // Service Utilities
    protected buildBaseSvcRoute(domainId: number): string {
        return `${aqe.defaults.publicApiDomain}/v4/domains/${domainId}/app-messages`
    }

    protected buildSvcRouteFromBase(domainId: number, route: string = '', query: any = {}): string {
        if (query) query = querystring.stringify(query || {})
        return `${this.buildBaseSvcRoute(domainId)}${route ? `/${route}` : ''}${query ? `?${query}` : ''}`
    }
}
