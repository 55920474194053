import { Constructor } from '@pushly/models/lib/types/constructor'
import { InvalidFieldException } from '@pushly/models/lib/exceptions/invalid-field-exception'
import { MissingFieldException } from '@pushly/models/lib/exceptions/missing-field-exception'
import { sentenceCase, snakeCase } from 'change-case'

type ErrorExtractionOptions<T extends Error> = {
    parentProperty?: string
    rootModel?: Constructor<{}>
    extractReason?: (error: InvalidFieldException | MissingFieldException) => string
    errorClass?: Constructor<T>
    snakeCasePropertyName?: boolean
    fieldNamesMap?: object
    startWithProperty?: boolean
}

export function extractFriendlyErrorMessage<T extends Error = Error>(
    error: InvalidFieldException | MissingFieldException,
    options?: ErrorExtractionOptions<T>,
): T {
    const errorClass = options?.errorClass ?? Error
    const target =
        options?.parentProperty ?? (options?.rootModel?.name === error.target ? null : snakeCase(error.target ?? ''))
    const targetStr = !target ? '' : ` of ${target}`
    const field = error.field
    const fieldStr = options?.snakeCasePropertyName
        ? snakeCase(field)
        : options?.fieldNamesMap
        ? field
              .split('.')
              .map((f, idx) => options.fieldNamesMap?.[f] ?? f)
              .join(' ')
        : field
    const reason = options?.extractReason ? options.extractReason(error) : error.failureReason
    return new errorClass(
        `${options?.startWithProperty ? '' : 'Property'} ${
            options?.startWithProperty ? sentenceCase(fieldStr) : fieldStr
        }${targetStr} ${reason}`,
    ) as T
}
