import { NotificationApiColumns } from '../constants/notification-api-columns'
import { PromptApiColumns } from '../constants/prompt-api-columns'
import { PubNetworkApiColumns } from '../constants/pub-network-api-columns'
import { NotificationApiOrderBlacklist } from '../constants/notification-api-order'
import { IReportConfig } from '../interfaces/report-config'
import { COLUMN_DROPDOWN_OPTIONS } from '../constants/column-dropdown-options'
import { DELIVERY_TABLE_COLUMN_DEFINITIONS } from '../constants/delivery-table-column-definitions'
import { SUBSCRIBER_TABLE_COLUMN_DEFINITIONS } from '../constants/subscriber-table-column-definitions'
import { PUB_NETWORK_TABLE_COLUMN_DEFINITIONS } from '../constants/pub-network-table-column-definitions'
import { COLUMN_TABLE_ORDER } from '../constants/column-table-order'
import { TIME_RANGE_OPTIONS } from '../constants/time-range-options'
import { SegmentApiColumns } from '../constants/segment-api-columns'
import { MONTHLY_UNIQUES_TABLE_COLUMN_DEFINITIONS } from '../constants/monthly-uniques-table-column-definitions'
import { MonthlyUniquesApiColumns } from '../constants/monthly-uniques-api-columns'
import { ColumnDropdownOption } from '../types'
import { APP_MESSAGE_TABLE_COLUMN_DEFINITIONS } from '../constants/app-message-table-column-definitions'
import { AppMessageApiColumns } from '../constants/app-message-api-columns'
import { LiveActivityApiColumns } from '../constants/live-activity-api-columns'
import { LIVE_ACTIVITY_TABLE_COLUMN_DEFINITIONS } from '../constants/live-activity-table-column-definitions'

function getColumnConfigurations(type: string) {
    let columnConfigs = {}

    switch (type) {
        case 'DELIVERY':
            columnConfigs = DELIVERY_TABLE_COLUMN_DEFINITIONS
            break

        case 'SUBSCRIBER':
            columnConfigs = SUBSCRIBER_TABLE_COLUMN_DEFINITIONS
            break

        case 'PUB_NETWORK':
            columnConfigs = PUB_NETWORK_TABLE_COLUMN_DEFINITIONS
            break

        case 'MONTHLY_UNIQUES':
            columnConfigs = MONTHLY_UNIQUES_TABLE_COLUMN_DEFINITIONS
            break

        case 'APP_MESSAGE':
            columnConfigs = APP_MESSAGE_TABLE_COLUMN_DEFINITIONS
            break

        case 'LIVE_ACTIVITY':
            columnConfigs = LIVE_ACTIVITY_TABLE_COLUMN_DEFINITIONS
            break
    }

    return columnConfigs
}

export function buildApiReportContract(reportConfig: any, domainId: number, type, level, downloadable?: boolean): any {
    const globalTable = (window as any).__SW_REPORT_TABLE__ || {}

    const contract: any = {
        date_increment: reportConfig.dateIncrement,
        order: {
            field: 'send_date',
            direction: globalTable.sortDirection || 'desc',
        },
        breakdowns: [],
        fields: [],
        filters: [
            { field: 'domain.id', operator: 'in', value: [domainId] },
            ...(reportConfig.filters || []).filter((f: any) => !!f),
        ],
    }

    if (
        contract.order.field === 'send_date' &&
        (reportConfig.type === 'SUBSCRIBER' ||
            reportConfig.type === 'APP_MESSAGE' ||
            reportConfig.type === 'LIVE_ACTIVITY')
    ) {
        contract.order.field = 'event_date'
    } else if (['send_date', 'event_date'].includes(contract.order.field) && reportConfig.type === 'PUB_NETWORK') {
        contract.order.field = 'report_date'
    } else if (contract.order.field === 'send_date' && reportConfig.type === 'MONTHLY_UNIQUES') {
        contract.order.field = 'report_month'
    }

    const hasPresetKey = !!reportConfig.dateRangePresetKey
    const hasDateRange = !!reportConfig.dateRange && !!reportConfig.dateRange.since
    if (hasPresetKey) {
        contract.date_range = getRangeFromPresetKey(reportConfig.dateRangePresetKey)
    } else if (hasDateRange) {
        contract.date_range = reportConfig.dateRange
    } else {
        contract.date_preset = 'lifetime'
    }

    if (reportConfig.type === 'DELIVERY') {
        contract.entity = 'notifications'

        attachContractLevelImplicitBreakdown(contract, reportConfig.level)

        if (reportConfig.level === 'CAMPAIGN') {
            contract.entity = 'campaigns'
            contract.order.field = 'event_date'
        }

        attachContractBreakdown(contract, reportConfig.breakdown)

        if (reportConfig.level === 'SEGMENT') {
            contract.entity = 'segments'

            attachContractFields(
                contract,
                reportConfig.columns,
                SegmentApiColumns,
                reportConfig,
                type,
                level,
                downloadable,
            )
        } else {
            attachContractFields(
                contract,
                reportConfig.columns,
                NotificationApiColumns,
                reportConfig,
                type,
                level,
                downloadable,
            )
        }

        if (reportConfig.level === 'NOTIFICATION') {
            contract.fields.push('notification.source')
        }

        if (reportConfig.level === 'KEYWORD') {
            attachContractFields(
                contract,
                reportConfig.columns,
                NotificationApiColumns,
                reportConfig,
                type,
                level,
                downloadable,
            )
        }
    } else if (reportConfig.type === 'SUBSCRIBER') {
        contract.entity = 'prompts'

        attachContractLevelImplicitBreakdown(contract, reportConfig.level)
        attachContractBreakdown(contract, reportConfig.breakdown)
        attachContractFields(contract, reportConfig.columns, PromptApiColumns, reportConfig, type, level, downloadable)

        if (reportConfig.level === 'PROMPT') {
            if (
                contract.filters.find((f) => f.field.toUpperCase() === 'TEST_ID') &&
                !contract.fields.includes('test.id')
            ) {
                contract.fields.push('test.id')
            }
        }
    } else if (reportConfig.type === 'PUB_NETWORK') {
        contract.entity = 'pub_network'

        attachContractLevelImplicitBreakdown(contract, reportConfig.level)
        attachContractBreakdown(contract, reportConfig.breakdown)
        attachContractFields(
            contract,
            reportConfig.columns,
            PubNetworkApiColumns,
            reportConfig,
            type,
            level,
            downloadable,
        )
    } else if (reportConfig.type === 'MONTHLY_UNIQUES') {
        contract.entity = 'monthly_uniques'

        attachContractLevelImplicitBreakdown(contract, reportConfig.level)
        attachContractBreakdown(contract, reportConfig.breakdown)
        attachContractFields(
            contract,
            reportConfig.columns,
            MonthlyUniquesApiColumns,
            reportConfig,
            type,
            level,
            downloadable,
        )
    } else if (reportConfig.type === 'APP_MESSAGE') {
        contract.entity = 'app_messages'

        attachContractLevelImplicitBreakdown(contract, reportConfig.level)
        attachContractBreakdown(contract, reportConfig.breakdown)
        attachContractFields(
            contract,
            reportConfig.columns,
            AppMessageApiColumns,
            reportConfig,
            type,
            level,
            downloadable,
        )
    } else if (reportConfig.type === 'LIVE_ACTIVITY') {
        contract.entity = 'live_activities'

        attachContractLevelImplicitBreakdown(contract, reportConfig.level)
        attachContractBreakdown(contract, reportConfig.breakdown)
        attachContractFields(
            contract,
            reportConfig.columns,
            LiveActivityApiColumns,
            reportConfig,
            type,
            level,
            downloadable,
        )
    }

    if (!!globalTable.sortBy) {
        if (!(globalTable.sortBy in NotificationApiOrderBlacklist)) {
            const sortField = globalTable.sortBy

            if (contract.fields.indexOf(sortField) !== -1) {
                contract.order.field = sortField

                if (!!globalTable.sortDirection) {
                    contract.order.direction = globalTable.sortDirection
                }
            }
        }
    }

    if (reportConfig.dateIncrement === 'LIFETIME') {
        if (
            contract.order.field === 'send_date' ||
            contract.order.field === 'event_date' ||
            contract.order.field === 'report_date' ||
            contract.order.field === 'report_month'
        ) {
            delete contract.order
        }
    }

    return contract
}

function attachContractLevelImplicitBreakdown(contract: any, level: string): void {
    contract.breakdowns = [level.toLowerCase()]
}

function attachContractBreakdown(contract: any, breakdown: string): void {
    if (breakdown !== 'NONE') {
        let breakdowns: string[] = Array.isArray(breakdown) ? breakdown : breakdown.split(',')
        breakdowns = breakdowns.map((bd: string) => bd.trim().toLowerCase())

        contract.breakdowns = [...contract.breakdowns, ...breakdowns]
    }
}

function attachContractFields(
    contract: any,
    columns: string[],
    columnMap: any,
    reportConfig: IReportConfig,
    type,
    level,
    downloadable?: boolean,
): void {
    const unorderedFields = [...contract.fields, ...columns.map((col: string) => columnMap[col])]

    // reorder fields by column defs
    const orderedFields: string[] = []
    const fieldOptionsKey = `${reportConfig.type}_${reportConfig.level}`
    const fieldOptions = COLUMN_DROPDOWN_OPTIONS[fieldOptionsKey]

    const levelExplicitFields: string[] = []
    switch (reportConfig.level) {
        case 'NOTIFICATION':
            levelExplicitFields.push('notification.id')
            break
        case 'CAMPAIGN':
            levelExplicitFields.push('campaign.id')
            break
        case 'PROMPT':
            levelExplicitFields.push('prompt.id')
            levelExplicitFields.push('group.id')
            break
        case 'DOMAIN':
            levelExplicitFields.push('domain.id')
            break
        case 'TEST':
            levelExplicitFields.push('test.id')
            break
        case 'SEGMENT':
            levelExplicitFields.push('segment.id')
            break
        case 'KEYWORD':
            levelExplicitFields.push('keyword')
            break
        case 'APP_MESSAGE':
            levelExplicitFields.push('app_message.id')
            break
        case 'ACTIVITY':
            levelExplicitFields.push('activity.id')
            break
    }

    const breakdownExplicitFields = reportConfig
        .breakdown!.split(',')
        .filter((bd) => bd !== 'NONE')
        .map((bd) => bd.toLowerCase())

    if (fieldOptions) {
        const dims: ColumnDropdownOption[] =
            (fieldOptions.find((opts: any) => opts.label.toUpperCase() === 'DIMENSIONS') || ({} as any)).options || []
        const measures: ColumnDropdownOption[] =
            (fieldOptions.find((opts: any) => opts.label.toUpperCase() === 'MEASURES') || ({} as any)).options || []

        const unnestedOptions = [...dims, ...measures]

        unnestedOptions.forEach((opt) => {
            const optKey = columnMap[opt.value]

            if (unorderedFields.indexOf(optKey) !== -1) {
                orderedFields.push(optKey)
            }
        })
    }

    const fields = orderedFields.length > 0 ? orderedFields : unorderedFields

    const scheduledTimeIndex = fields.indexOf('notification.scheduled_time')
    if (scheduledTimeIndex !== -1) {
        fields.splice(scheduledTimeIndex + 1, 0, 'notification.scheduled_time_zone')
    }

    let addtRequiredFields: string[] = []
    const columnConfigs = getColumnConfigurations(type)
    if (!downloadable) {
        fields.forEach((field) => {
            const colKey = field.toUpperCase()
            const config = columnConfigs[colKey]
            if (Array.isArray(config?.requiredColumnKeys) && config.requiredColumnKeys.length) {
                addtRequiredFields = addtRequiredFields.concat(
                    config.requiredColumnKeys.filter((key) => !fields.includes(key)),
                )
            }
        })

        if (
            reportConfig.level === 'NOTIFICATION' ||
            (reportConfig.level === 'PROMPT' &&
                !fields.includes('test.id') &&
                contract.filters.find((filter) => filter.field === 'TEST_ID'))
        ) {
            addtRequiredFields.push('test.id')
        }

        contract.addtRequiredFields = addtRequiredFields
    }

    contract.fields = orderRequestedFields(
        Array.from(new Set([...levelExplicitFields, ...breakdownExplicitFields, ...addtRequiredFields, ...fields])),
        type,
        level,
    )
}

function orderRequestedFields(requestedFields: any[], type, level): string[] {
    const fields: string[] = []
    type = type.toUpperCase()
    level = level.toUpperCase()

    const columnDefKey = `${type}_${level}`
    const columnOrder: string[] = COLUMN_TABLE_ORDER[columnDefKey] || []

    requestedFields = requestedFields.filter((col) => !!col).map((col) => col.toUpperCase())

    columnOrder.forEach((col) => {
        if (requestedFields.indexOf(col) !== -1) {
            fields.push(col.toLowerCase())
        }
    })

    return fields
}

function getRangeFromPresetKey(presetKey: string): { since: string; through: string } {
    const rangeKeys = Object.keys(TIME_RANGE_OPTIONS)
    const timeRangeKey = rangeKeys.find((key) => TIME_RANGE_OPTIONS[key]()!.includes(presetKey))
    const range = TIME_RANGE_OPTIONS[timeRangeKey!]()!

    return {
        since: range[0]?.format('YYYY-MM-DD') ?? undefined!,
        through: range[1]?.format('YYYY-MM-DD') ?? undefined!,
    }
}
