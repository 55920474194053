import { EcommItemType } from '../campaign-builder/enums'
import { MacroInputTrigger, MacroOptions } from './types'

export const MACRO_MGR_BASE_CLASSNAME = 'macro-manager'

export const DEFAULT_MACRO_TRIGGER: MacroInputTrigger = '{{.}}'

export const BaseMacroOptions: MacroOptions = {
    app_message: {
        label: 'App Message',
        options: [
            {
                label: 'App Message ID',
                value: 'app_message_id',
            },
        ],
    },
    domain: {
        label: 'Domain',
        options: [
            {
                label: 'Domain ID',
                value: 'domain_id',
            },
            {
                label: 'Domain URL',
                value: 'domain_url',
            },
        ],
    },
    device: {
        label: 'Device',
        options: [
            {
                label: 'Type',
                value: 'profile.placement',
            },
        ],
    },
    notification: {
        label: 'Notification',
        options: [
            {
                label: 'Notification ID',
                value: 'notification_id',
            },
            {
                label: 'Notification Title',
                value: 'notification_title',
            },
            {
                label: 'Notification Body',
                value: 'notification_body',
            },
            {
                label: 'Segment IDs',
                value: 'segment_ids',
            },
            {
                label: 'Segment Names',
                value: 'segment_names',
            },
            {
                label: 'Keywords',
                value: 'keywords',
            },
            {
                label: 'Notification Channel',
                value: 'notification_channel',
            },
            {
                label: 'Notification Source (Manual, Feed)',
                value: 'notification_source',
            },
            {
                label: 'Delivery Type (Immediate, Scheduled)',
                value: 'delivery_type',
            },
            {
                label: 'Feed Name',
                value: 'feed_name',
            },
        ],
    },
    location: {
        label: 'Location',
        options: [
            {
                label: 'City',
                value: 'profile.city',
            },
            {
                label: 'Province',
                value: 'profile.province',
            },
            {
                label: 'Country Code',
                value: 'profile.country_code',
            },
        ],
    },
    ecomm: {
        label: 'E-Commerce Item',
        options: [
            {
                label: 'ID',
                value: 'item.item_id',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Name',
                value: 'item.name',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Description',
                value: 'item.description',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Landing URL',
                value: 'item.url',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Image URL',
                value: 'item.image',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Price',
                value: 'item.price',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },

            // PRODUCT
            {
                label: 'Brand Name',
                value: 'item.brand_name',
                option_types: [EcommItemType.PRODUCT],
            },

            // EVENT
            {
                label: 'Event Start Date',
                value: 'item.date_start',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Event End Date',
                value: 'item.date_end',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Genre: Major',
                value: 'item.genre_major',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Genre: Minor',
                value: 'item.genre_minor',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Performer Name',
                value: 'item.performer_name',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Performer URL',
                value: 'item.performer_url',
                option_types: [EcommItemType.EVENT],
            },

            {
                label: 'Performer Image URL',
                value: 'item.performer_image',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue ID',
                value: 'item.location_id',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue Name',
                value: 'item.location_name',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue City',
                value: 'item.location_city',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue Region',
                value: 'item.location_region',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue Country',
                value: 'item.location_country',
                option_types: [EcommItemType.EVENT],
            },
        ],
    },
    custom: {
        label: 'Custom',
        options: [],
    },
}
