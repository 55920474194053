import React from 'react'
import * as clone from 'clone'
import { getClassNames } from '../../../_utils/classnames'
import { NotificationActionBuilder } from '../../notification-action-builder/notification-action-builder'
import { noop } from '../../../_utils/utils'
import { DomainDto } from '../../../dtos/domain'
import { PromiseableProp, resolvePromiseableProp } from '../../../_utils/promiseable-prop'
import { INotificationAction } from '../../../interfaces/notification-action'
import { NotificationActionModel } from '../../../models/notification/notification-action.model'
import { parseDefaultNotificationAction } from '../../../_utils/domain'
import randomstring from 'randomstring'
import { useDebouncer } from '../../../hooks/use-debouncer'
import { MacroOptionType } from '../../macro-manager/types'
import { NotificationDefaultContentModel } from '../../../models/notification/notification-default-content.model'

const getCompiledActions = (
    domain: DomainDto | null,
    actions: NotificationActionModel[] | undefined,
    reset?: boolean,
): NotificationActionModel[] => {
    const defaultPrimaryAction = parseDefaultNotificationAction(domain, 0)
    const defaultSecondaryAction = parseDefaultNotificationAction(domain, 1)

    const compiled = reset ? [] : clone(actions ?? [])
    compiled.forEach((a) => {
        if (!a.getDisplayMeta() || !a.getDisplayMeta().eid) {
            a.setDisplayMeta({
                ...a.getDisplayMeta(),
                eid: randomstring.generate(),
            })
        }
    })

    if (reset || actions === undefined) {
        const primaryDefined = compiled.find((a) => a.getOrdinal() === 0)
        if (!primaryDefined) {
            compiled.push(NotificationActionModel.build(defaultPrimaryAction!))
        }

        const secondaryDefined = compiled.find((a) => a.getOrdinal() === 1)
        if (!secondaryDefined && !!defaultSecondaryAction) {
            compiled.push(NotificationActionModel.build(defaultSecondaryAction))
        }
    }

    return compiled
}

interface INotificationActionsBuilder {
    domain: DomainDto | null
    value: NotificationDefaultContentModel
    onChange: (value: NotificationDefaultContentModel) => any
    loading?: boolean
    customMacroOptions?: PromiseableProp<any>
    validMacroOptionTypes?: MacroOptionType[]
}

const NotificationActionsBuilder = (props: INotificationActionsBuilder) => {
    const { loading, value, onChange, domain } = props

    const [debounce] = useDebouncer(4)

    const workingModel = value.clone()
    const workingActions = getCompiledActions(domain, workingModel.getActions() ?? undefined)
    const configs = workingActions.map((a) => {
        const config = a.serialize()

        // remove serialized model keys
        delete config.landing_url
        delete config.use_primary_landing_url
        delete config.display_meta

        // add action builder config keys
        config.landingUrl = a.getLandingUrl()
        config.usePrimaryLandingUrl = a.getUsePrimaryLandingUrl()
        config.displayMeta = a.getDisplayMeta()

        return config
    })

    const totalActions = workingActions.length
    const primaryLandingUrlInUse = !!workingActions.find((a) => a.getUsePrimaryLandingUrl())

    const handleActionUpdate = (v: INotificationAction) => {
        const actionIdx = workingActions.findIndex((a) => a.getOrdinal() === v.ordinal)
        if (actionIdx !== -1) {
            const update = NotificationActionModel.build(v)
            workingActions.splice(actionIdx, 1, update)
            workingModel.setActions(workingActions)
        }

        debounce(() => onChange(workingModel))
    }

    const handleActionAdd = (v: INotificationAction) => {
        if (totalActions < 2) {
            const newAction = NotificationActionModel.build(v)
            newAction.setOrdinal(totalActions === 0 ? 0 : 1)

            if (primaryLandingUrlInUse) {
                newAction.setUsePrimaryLandingUrl(false)
                newAction.setLandingUrl(undefined)
            } else {
                newAction.setUsePrimaryLandingUrl(true)
                newAction.setLandingUrl(workingModel.getLandingUrl())
            }

            workingActions.push(newAction)
            workingModel.setActions(workingActions)

            onChange(workingModel)
        }
    }

    const handleActionRemove = (v: INotificationAction) => {
        if (totalActions > 1) {
            const actionIdx = workingActions.findIndex((a) => a.getOrdinal() === v.ordinal)
            if (actionIdx !== -1) {
                workingActions.splice(actionIdx, 1)
                workingActions[0].setOrdinal(0)
                workingModel.setActions(workingActions)

                onChange(workingModel)
            }
        }
    }

    return (
        <div className={getClassNames('notification-builder-buttons')}>
            <div className={getClassNames('notif-action-builder-collection')}>
                {configs.map((actionConfig, idx) => (
                    <NotificationActionBuilder
                        key={`action-${idx}`}
                        enabled={true}
                        title={`Button ${idx + 1}`}
                        primaryLandingUrl={value.getLandingUrl()}
                        config={actionConfig}
                        onChange={handleActionUpdate}
                        onRemove={handleActionRemove}
                        rootActions={configs}
                        macroTypes={
                            ['domain', 'device', 'location', 'ecomm', 'notification', 'custom'] ?? [
                                'domain',
                                'device',
                                'location',
                                'notification',
                                'custom',
                            ]
                        }
                        customMacroOptions={resolvePromiseableProp(props.customMacroOptions)}
                        validMacroOptionTypes={props.validMacroOptionTypes}
                    />
                ))}
                {workingActions.length < 2 && (
                    <NotificationActionBuilder
                        enabled={false}
                        title={`Button Placeholder`}
                        primaryLandingUrl={value.getLandingUrl()}
                        config={{} as any}
                        onChange={noop}
                        onAdd={handleActionAdd}
                    />
                )}
            </div>
        </div>
    )
}

export default NotificationActionsBuilder
