import * as React from 'react'
import { NotificationTemplateListContext } from './notification-template-list-context'
import { Well } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { Modal, Table, TablePaginationConfig } from 'antd'
import { AntdTableEmptyPlaceholder } from '../../../components/aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { FormOutlined, LoadingOutlined } from '@ant-design/icons'
import { useLoadableDataState } from '../../../hooks/use-loadable-data-state'
import { getTablePaginationConfig } from '../../../components/segment-list/helpers'
import { stripUndefined } from '../../../_utils/strip-undefined'
import { NotificationNamedTemplate } from '@pushly/models/lib/structs'
import { useService } from '@pushly/aqe/lib/hooks'
import { NotificationTemplateService } from '../../../services/notification-template'
import { AppService } from '../../../services'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { NNTDataTableControlOptions, FilterKey, NotificationTemplateListProps } from './interfaces'
import { NotificationTemplateListHeader } from './notification-named-template-list-header'
import '../../../components/notification-list/notification-list.scss'
import SwSimpleLinkPagination from '../../../components/sw-simple-link-pagination/sw-simple-link-pagination'
import { Key, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { TableRowEntityDisplay } from '../../../components/table-row-entity-display/table-row-entity-display'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { WebBadge } from '../../../components/badges/web-badge'
import { NativeIosBadge } from '../../../components/badges/native-ios-badge'
import { NativeAndroidBadge } from '../../../components/badges/native-android-badge'
import { NotificationImageBadge } from '../../../components/badges/notification-image-badge'
import { NotificationSilentBadge } from '../../../components/badges/notification-silent-badge'
import structuredClone from '@ungap/structured-clone'

const NotificationTemplateList = (props: NotificationTemplateListProps) => {
    const appSvc = useService(AppService)
    const notifTemplateSvc = useService(NotificationTemplateService)
    const [dataSource, setDataSource] = useLoadableDataState<NotificationNamedTemplate[]>({ loading: true, data: [] })
    const [tableOptions, setTableOptions] = React.useState<NNTDataTableControlOptions>({
        paginationConfig: getTablePaginationConfig({
            pageSize: props.pageSize ?? 15,
        }),
        refreshing: false,
        filters: {
            search: props.defaultFilters?.search,
        },
    })

    React.useEffect(() => {
        resetList()
    }, [])

    React.useEffect(() => {
        setTableOptions((prev) => ({
            ...prev,
            refreshing: true,
            paginationConfig: {
                ...prev.paginationConfig,
                current: 1,
            },
        }))
        setDataSource({ loading: true })

        fetchNotificationTemplates()
    }, [...Object.values(tableOptions.filters)])

    const resetList = () => {
        setDataSource({ loading: true })
        setTableOptions({
            refreshing: true,
            previousRowIds: [],
            paginationConfig: {
                ...tableOptions.paginationConfig,
                current: 1,
            },
            filters: {
                search: props.defaultFilters?.search,
            },
        })

        fetchNotificationTemplates()
    }

    const fetchNotificationTemplates = async () => {
        const { filters } = tableOptions

        const apiOptions = {
            showLoadingScreen: false,
            cancellationKey: 'notif-template-list.fetch',
            query: stripUndefined({
                page: tableOptions.paginationConfig.current,
                limit: tableOptions.paginationConfig.pageSize,
                search: filters.search,
            }),
        }

        // DTO won't work for this table, either create new or use model from @pushly/models
        const currDataSource: NotificationNamedTemplate[] = dataSource.data ?? []

        setTableOptions((prev) => ({
            ...prev,
            previousRowIds: currDataSource?.map((data) => {
                return data.id
            }),
        }))

        let nextDataSource: NotificationNamedTemplate[] = []
        const tableControlChanges = {
            ...tableOptions,
            refreshing: false,
        }

        const response = await notifTemplateSvc.fetchNamedNotificationTemplatesByDomainId(props.domainId, apiOptions)
        if (!response.cancelled) {
            if (response.ok && response.data) {
                response.data.forEach((datum) => {
                    nextDataSource.push(datum)
                })

                tableControlChanges.paginationLinks = response.meta.links ?? tableControlChanges.paginationLinks
            } else if (response.error) {
                console.warn(response.error)
            }

            setTableOptions(tableControlChanges)
            setDataSource({ loading: false, data: nextDataSource })
        }
    }

    const renderTemplateName = (template: NotificationNamedTemplate) => {
        const name = template.name
        const containsImage = template.templateContent.default.imageUrl
        const isSilent = template.templateContent.default.isSilent
        const channels = template.templateContent.default.channels

        return (
            <TableRowEntityDisplay
                title={name}
                badges={
                    <>
                        {containsImage && <NotificationImageBadge />}
                        {isSilent && <NotificationSilentBadge />}
                        {channels?.includes(DeliveryChannel.WEB) && <WebBadge />}
                        {channels?.includes(DeliveryChannel.NATIVE_IOS) && <NativeIosBadge />}
                        {channels?.includes(DeliveryChannel.NATIVE_ANDROID) && <NativeAndroidBadge />}
                    </>
                }
            />
        )
    }

    const renderRowActions = (template: NotificationNamedTemplate) => {
        const actions: any[] = [
            {
                text: 'Edit',
                icon: 'edit',
                onClick: () => handleTemplateEdit(template),
                template,
                altHref: buildEditUrl(template),
            },
            {
                text: 'Duplicate',
                icon: 'copy',
                onClick: () => handleTemplateDuplicate(template),
                template,
                altHref: buildDuplicateUrl(template),
            },
            {
                text: 'Archive',
                icon: 'folder',
                onClick: () => handleTemplateArchive(template),
                template,
            },
        ]

        return (
            <AsyncButton
                onClick={() => appSvc.route(buildNewNotifUrl(template))}
                size="small"
                altHref={buildNewNotifUrl(template)}
                actions={actions}
            >
                <span>New Notification</span>
            </AsyncButton>
        )
    }

    const buildNewNotifUrl = (data: NotificationNamedTemplate): string => {
        const templateId = data.id
        const newRoute = `/notifications/new?template_id=${templateId}`

        return appSvc.routeWithin(props.level, newRoute, true)
    }

    const handleTemplateEdit = (template: NotificationNamedTemplate) => {
        appSvc.route(buildEditUrl(template))
    }

    const buildEditUrl = (data: NotificationNamedTemplate) => {
        let templateId = data.id

        return appSvc.routeWithin(props.level, `/notifications/templates/edit/${templateId}`, true)
    }

    const handleTemplateDuplicate = (template: NotificationNamedTemplate) => {
        appSvc.route(buildDuplicateUrl(template))
    }

    const buildDuplicateUrl = (data: NotificationNamedTemplate): string => {
        let templateId = data.id

        return appSvc.routeWithin(props.level, `notifications/templates/new?template_id=${templateId}`, true)
    }

    const handleTemplateArchive = (template: NotificationNamedTemplate) => {
        const finalize = async () => {
            await notifTemplateSvc.destroyNamedTemplate(props.domainId!, template.id, {
                showLoadingScreen: true,
            })

            return resetList()
        }

        return new Promise((res) => {
            Modal.confirm({
                title: 'Archive Notification Template',
                content: (
                    <div>
                        <p>
                            This template will no longer be shown in the Notification Templates List page. Are you sure
                            you want to archive this template?
                        </p>
                    </div>
                ),
                okText: 'Yes, Archive Template',
                onOk: () => res(finalize()),
                cancelText: 'Cancel',
                onCancel: () => res(false),
            })
        })
    }

    const handleSearchClick = async (value: string) => {
        const { search: prevValue } = tableOptions.filters
        const emptyValue = !value.trim() && prevValue?.trim()

        if (!emptyValue || value.length > 2) {
            setTableOptions((prev) => ({
                ...prev,
                paginationConfig: {
                    ...prev.paginationConfig,
                    current: 1,
                },
                filters: {
                    ...prev.filters,
                    search: value,
                },
            }))
            await fetchNotificationTemplates()
        }
    }

    const handleFilterChange = async <FilterValue extends NNTDataTableControlOptions['filters'][FilterKey]>(
        key: FilterKey,
        value: FilterValue,
    ): Promise<void> => {
        const prevFilters = structuredClone(tableOptions.filters)

        const newFilters = {
            ...prevFilters,
            [key]: value,
        }

        setTableOptions((prev) => ({
            ...prev,
            filters: newFilters,
        }))
    }

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        _filters: Record<string, Key[] | null>,
        _sorter: SorterResult<NotificationNamedTemplate> | SorterResult<NotificationNamedTemplate>[],
        _extra: TableCurrentDataSource<NotificationNamedTemplate>,
    ) => {
        setTableOptions((prev) => ({
            ...prev,
            paginationConfig: {
                ...prev.paginationConfig,
                current: pagination.current,
            },
        }))
        await fetchNotificationTemplates()
    }

    const showTableLoadingAnimation = dataSource.loading && tableOptions.refreshing

    let filtersHidden: FilterKey[] = []
    if (props.hideFilters === true) {
        filtersHidden = ['search']
    } else if (Array.isArray(props.hideFilters)) {
        filtersHidden = props.hideFilters
    } else if (props.hideFilters) {
        filtersHidden = [props.hideFilters]
    }

    return (
        <NotificationTemplateListContext.Provider
            value={{
                ...dataSource,
                ...tableOptions,
                onSearchClick: handleSearchClick,
                onFilterChange: handleFilterChange,
            }}
        >
            <Well
                className={getClassNames('notification-template-list', 'table-well', 'nested')}
                header={
                    <NotificationTemplateListHeader
                        title={props.title}
                        filterSize={props.filterSize}
                        hideFilters={filtersHidden}
                    />
                }
                hideFooter={true}
            >
                <div className={getClassNames('notification-template-list-content')}>
                    <Table
                        className={getClassNames('notification-template-list-table', {
                            ['hide-title']: !props.title,
                            ['hide-pagination']: props.hidePagination,
                            // ['hide-actions']: !currentUser
                        })}
                        rowClassName="notification-template-list-row"
                        size="small"
                        dataSource={dataSource.data}
                        rowKey={(r) => r.id}
                        pagination={false}
                        footer={(currentTableData) => (
                            <SwSimpleLinkPagination
                                currentTableData={currentTableData}
                                links={tableOptions.paginationLinks}
                                onPrev={async (_ev, _link, config) => {
                                    setTableOptions((prev) => ({
                                        ...prev,
                                        paginationConfig: {
                                            ...prev.paginationConfig,
                                            current: config.current,
                                        },
                                    }))
                                    await fetchNotificationTemplates()
                                }}
                                onNext={async (_ev, _link, config) => {
                                    setTableOptions((prev) => ({
                                        ...prev,
                                        paginationConfig: {
                                            ...prev.paginationConfig,
                                            current: config.current,
                                        },
                                    }))
                                    await fetchNotificationTemplates()
                                }}
                            />
                        )}
                        onChange={handleTableChange}
                        locale={{
                            emptyText: <AntdTableEmptyPlaceholder text="No Templates" icon={<FormOutlined />} />,
                        }}
                        loading={
                            showTableLoadingAnimation && {
                                indicator: <LoadingOutlined spin={true} />,
                            }
                        }
                    >
                        <Table.Column key="name" className="name" title="Name" render={renderTemplateName} />
                        <Table.Column
                            key="description"
                            className="description"
                            dataIndex={['description']}
                            title="Description"
                            render={(description) => {
                                return <span>{description}</span>
                            }}
                        />
                        <Table.Column
                            key="actions"
                            className="ntl-actions"
                            title="Actions"
                            align="right"
                            render={renderRowActions}
                        />
                    </Table>
                </div>
            </Well>
        </NotificationTemplateListContext.Provider>
    )
}

export default NotificationTemplateList
