import * as React from 'react'
import clone from 'clone'
import { BetterComponent } from '../../../components/better-component/better-component'
import { BetterSelect } from '../../../components/better-select/better-select'
import { IFilterBuilderProps, IFilterBuilderState } from '../interfaces/filter-builder'
import * as deepEqual from 'react-fast-compare'
import { getClassNames } from '../../../_utils/classnames'
import { AppMessageTemplateStyle } from '@pushly/models/lib/enums/app-messages/app-message-template-style'
import titleCase from 'title-case'

export class AppMessageStyleFilterBuilder extends BetterComponent<IFilterBuilderProps, IFilterBuilderState> {
    public readonly defaultSelection = [
        AppMessageTemplateStyle.BANNER,
        AppMessageTemplateStyle.FULLSCREEN,
        AppMessageTemplateStyle.MODAL,
    ]

    public state: IFilterBuilderState = {
        operator: 'IN',
        value: this.defaultSelection,
    }

    public render(): React.ReactNode {
        return (
            <div className={getClassNames('filter', 'nsource-filter')}>
                <div className={getClassNames('filter-wrapper')}>
                    <div className={getClassNames('filter-display')}>
                        <span>
                            <span className="filter-op">{this.currentOperatorDisplay}</span>
                            <span> </span>
                            <span className="filter-val">{this.currentValueDisplay}</span>
                        </span>
                    </div>
                    <div className={getClassNames('filter-editor')}>
                        <div className="filter-op">
                            <BetterSelect
                                size="small"
                                disableSearch={true}
                                dropdownClassName="filter-op-dropdown"
                                options={[
                                    { value: 'IN', label: 'is' },
                                    { value: 'NOT_IN', label: 'is not' },
                                ]}
                                defaultValue={['IN']}
                                value={this.currentValue.operator}
                                onChange={(operator: string) => {
                                    this.handleChange({ operator })
                                }}
                            />
                        </div>
                        <div className="filter-val">
                            <BetterSelect
                                size="small"
                                mode="multiple"
                                dropdownClassName="filter-val-dropdown"
                                placeholder="Select..."
                                disableSearch={true}
                                selectAllLabel="All Styles"
                                options={[
                                    { value: AppMessageTemplateStyle.BANNER, label: 'Banner' },
                                    { value: AppMessageTemplateStyle.FULLSCREEN, label: 'Fullscreen' },
                                    { value: AppMessageTemplateStyle.MODAL, label: 'Modal' },
                                ]}
                                value={this.currentValue.value || this.defaultSelection}
                                onChange={this.handleValueChange}
                                onClose={this.handleValueChangeComplete}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected get currentValue(): any {
        const propsValue: any = this.props.value || {}
        return {
            field: 'APP_MESSAGE.STYLE',
            operator: propsValue.operator || 'IN',
            value: this.state.value || propsValue.value,
        }
    }

    protected get currentOperatorDisplay(): string {
        return this.currentValue.operator === 'IN' ? 'is' : 'is not'
    }

    protected get currentValueDisplay(): string {
        let display: string = ''

        if (!this.currentValue.value || this.currentValue.value.length === 3) {
            display = 'All Styles'
        } else {
            const currentValue = clone(this.currentValue.value || []).map((v) => titleCase(v))

            if (currentValue.length <= 2) {
                display = currentValue.join(' or ')
            } else {
                const lastValue = currentValue.pop()
                display = currentValue.join(', ') + `, or ${lastValue}`
            }
        }

        return display
    }

    protected handleValueChange = async (value: any) => {
        if (Array.isArray(value) && value.length === 0) {
            value = undefined
        }

        await this.setState({ value })

        return value
    }

    protected handleValueChangeComplete = async (value: any) => {
        value = await this.handleValueChange(value)

        if (!deepEqual(value, (this.props.value || {}).value)) {
            this.handleChange({ value })
        }
    }

    protected handleChange(config: any): any {
        if (config) {
            this.props.onChange({
                field: 'APP_MESSAGE.STYLE',
                operator: this.currentValue.operator,
                ...this.props.value,
                ...config,
            })
        } else {
            this.props.onChange()
        }

        this.setState({ value: undefined })
    }
}
