import React from 'react'
import './styles/notification-ecomm-options.scss'
import { Well } from '@pushly/aqe/lib/components'
import { Tooltip, Select, Skeleton } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { EcommItemPickStrategy } from '../../../enums/ecomm-item-pick-strategy'
import { INotificationBuilderState } from '../interfaces/notification-builder-state.interface'
import { getClassNames } from '../../../_utils/classnames'
import { NotificationDispatchActionPack } from '../types'

interface INotificationEcommOptionsBuilderProps {
    className?: string
    builder: INotificationBuilderState
    setBuilder: (action: NotificationDispatchActionPack) => void
    loading?: boolean
    disabled?: boolean
    showPreviouslyChosenItemOption?: boolean
}

const NotificationEcommOptions = (props: INotificationEcommOptionsBuilderProps) => {
    const { loading, builder, setBuilder, disabled, showPreviouslyChosenItemOption } = props

    return (
        <Well
            className={getClassNames('notification-ecomm-options-wrapper', {})}
            title={
                <>
                    <span>Item Selection</span>
                    <Tooltip
                        title={
                            <span>
                                Determines how the item that will be included within the notification will be chosen.
                            </span>
                        }
                    >
                        <QuestionCircleOutlined className="info-icon" />
                    </Tooltip>
                </>
            }
            mode="ghost"
            hideFooter={true}
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false}>
                <Select
                    className="ecomm-item-pick-strategy-select"
                    dropdownClassName="ecomm-item-pick-strategy-dropdown"
                    placeholder="Select a strategy"
                    value={builder?.ecommItemPickStrategy}
                    onChange={(strategy) => {
                        setBuilder({ type: 'patch', entity: 'root', data: { ecommItemPickStrategy: strategy } })
                    }}
                    disabled={disabled}
                >
                    {showPreviouslyChosenItemOption && (
                        <Select.Option value={EcommItemPickStrategy.PREVIOUSLY_SELECTED}>
                            Previously Chosen Item
                        </Select.Option>
                    )}
                    <Select.Option value={EcommItemPickStrategy.RANDOM}>Random</Select.Option>
                    <Select.Option value={EcommItemPickStrategy.HIGHEST_PRICE}>Highest Price</Select.Option>
                    <Select.Option value={EcommItemPickStrategy.LOWEST_PRICE}>Lowest Price</Select.Option>
                    <Select.Option value={EcommItemPickStrategy.MOST_RECENT}>Most Recent</Select.Option>
                </Select>
            </Skeleton>
        </Well>
    )
}

export default NotificationEcommOptions
