import * as React from 'react'
import './dasboard-widget.scss'
import './overview.scss'
import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { Button, DatePicker, Divider, Form, Select, Tooltip } from 'antd'
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { Flags } from '@pushly/aquarium-utils/lib/enums/flags'
import { AppService, DomainService, NotificationService } from '../../../services'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Well } from '../../../components/well/well'
import { AppState } from '../../../stores/app'
import { backfillStats } from '../../../_utils/utils'
import { DomainDto } from '../../../dtos/domain'
import { StatusType } from '../../../enums/status-type'
import { observe } from 'mobx'
import { DomainDeliveryClickReport } from '../domain-delivery-click-report/domain-delivery-click-report'
import { DomainSubscriberReport } from '../domain-subscriber-report/domain-subscriber-report'
import { SegmentService } from '../../../services/segment'
import { SHORT_DATE_FORMAT } from '../../../constants'
import { InsightsService } from '../../../services/insights'
import { PageHeader } from '../../../components/page-header/page-header'
import { getClassNames } from '../../../_utils/classnames'
import { DomainAdDeliveryClickReport } from '../domain-ad-delivery-click-report/domain-ad-delivery-click-report'
import SubscribersWidget from './subscribers.widget'
import ClicksWidget from './clicks.widget'
import RevenueWidget from './revenue.widget'
import type { RangeValueWithPreset } from '../../../types/range-value'
import { RangeValueRecordWithPreset } from '../../../types/range-value'
import NotificationList from '../../../components/notification-list/notification-list'
import { addVisibilityAwareIntervalRunner } from '../../../_utils/visibility-api'
import { NotificationListTypes } from '../../../components/notification-list/enums'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { ReachByChannel } from '../../../components/notification-builder/elements/notification-builder-reach'

type TimeBreakdown = 'day' | 'week' | 'month'

interface IProps {}

interface IState {
    domain: DomainDto
    loadingSubscriberEstimate: boolean
    loadingYesterdaysStats: boolean
    loadingStats: boolean
    loadingAttrition: boolean

    timeWindowPreset: string
    timeBreakdown: TimeBreakdown
    timeRangeSince: any
    timeRangeUntil: any

    stats: {
        subscriberStats: any[]
        deliveryStats: any[]
        adStats: any[]
    }

    attrition?: any
    subscriberEstimate?: any
    subscribersToday?: any
    subscribersYesterday?: any
    deliveryYesterday?: any
}

@observer
export class Overview extends BetterComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService
    private domainService: DomainService
    private segmentService: SegmentService
    private notificationService: NotificationService
    private insightsService: InsightsService

    private disposeObservers: any[]

    private dateInterval: number = 3600000
    private dateCheck

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)
        this.segmentService = Container.get(SegmentService)
        this.notificationService = Container.get(NotificationService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            domain: this.appState.currentDomain!,
            loadingSubscriberEstimate: true,
            loadingYesterdaysStats: true,
            loadingStats: true,
            loadingAttrition: true,
            timeWindowPreset: 'Last 7 Days',
            timeBreakdown: 'day',
            timeRangeSince: this.defaultReportTimeRange[0],
            timeRangeUntil: this.defaultReportTimeRange[1],
            stats: {
                subscriberStats: [],
                deliveryStats: [],
                adStats: [],
            },
        }
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.updateDateRange().then(() => {
            this.dateCheck = setInterval(this.updateDateRange, this.dateInterval)
        })

        this.setDomainState()
    }

    public componentWillUnmount(): void {
        this.unmounting = true
        this.disposeObservers.forEach((fn: any) => fn())
        clearInterval(this.dateCheck)
    }

    public render() {
        const domain = this.appState.currentDomain!
        const pubNetFlag = Flags.domain.FEAT_DOMAIN_INTEGRATIONS_PUB_NETWORK
        const domainHasPubNetFlag = domain.flags?.includes(pubNetFlag.key) ?? false
        const availableChannels = getEnabledDeliveryChannels(domain, true)

        return (
            <div className="overview page">
                <PageHeader
                    title="Dashboard"
                    append={
                        <span>
                            {domain?.displayName} | {domain?.name}
                        </span>
                    }
                    loading={!domain}
                />

                {this.renderWidgets()}

                <Well
                    loading={this.state.loadingStats}
                    className="performance-overview-well"
                    title="Performance Overview"
                    action={this.renderStatsActions()}
                    showFooter={false}
                >
                    <div className="delivery-reports">
                        <DomainDeliveryClickReport
                            key={`${domain.id}-ddcr-widget`}
                            stats={this.state.stats.deliveryStats}
                            isSingleDay={this.isSingleDayBreakdown}
                            isLifetime={this.isLifetimeBreakdown}
                            timeBreakdown={this.state.timeBreakdown}
                        />
                        {domainHasPubNetFlag && (
                            <DomainAdDeliveryClickReport
                                key={`${domain.id}-dadcr-widget`}
                                stats={this.state.stats.adStats}
                                isSingleDay={this.isSingleDayBreakdown}
                                isLifetime={this.isLifetimeBreakdown}
                                timeBreakdown={this.state.timeBreakdown}
                            />
                        )}
                    </div>

                    <DomainSubscriberReport
                        key={`${domain.id}-dsr-widget`}
                        stats={this.state.stats.subscriberStats}
                        isSingleDay={this.isSingleDayBreakdown}
                        isLifetime={this.isLifetimeBreakdown}
                        timeBreakdown={this.state.timeBreakdown}
                        availableChannels={availableChannels}
                    />
                </Well>

                <NotificationList
                    listType={NotificationListTypes.NOTIFICATION}
                    level="domain"
                    domainId={domain.id}
                    title="Recent Notifications"
                    pageSize={5}
                    hidePagination={true}
                    hideRowActions={true}
                    hideAutoRefreshOptions={true}
                    hideFilters={['search', 'source']}
                    filterSize="small"
                    filtersAddonBefore={
                        <AsyncButton size="small" onClick={this.goToNotifications} altHref={this.notificationsListUrl}>
                            <span>View All</span>
                        </AsyncButton>
                    }
                    defaultFilters={{
                        status: [StatusType.COMPLETED.name, StatusType.DELIVERING.name, StatusType.SCHEDULED.name],
                    }}
                />
            </div>
        )
    }

    private renderWidgets(): React.ReactNode {
        const now = moment()
        const dtYesterdayStr = now.clone().subtract(1, 'day').format('YYYY-MM-DD')

        const domain = this.appState.currentDomain!
        const pubNetFlag = Flags.domain.FEAT_DOMAIN_INTEGRATIONS_PUB_NETWORK
        const ecommFlag = Flags.domain.FEAT_DOMAIN_INTEGRATIONS_ECOMM
        const domainHasPubNetFlag = domain.flags?.includes(pubNetFlag.key) ?? false
        const domainHasEcommFlag = domain.flags?.includes(ecommFlag.key) ?? false
        const showWidgetType = domainHasPubNetFlag

        const attrition = this.state.attrition === 0 ? '--' : this.state.attrition
        const deliveryYesterday = this.state.deliveryYesterday || {}
        const clicks = deliveryYesterday.clicks
        const ctrDecimal = deliveryYesterday.ctr_decimal || 0
        const yesterdayAdStats = this.state.stats.adStats.find((s) => s.report_date === dtYesterdayStr) ?? {}

        return (
            <div className={getClassNames('dashboard-overview-widgets')}>
                <div className={getClassNames('dashboard-overview-widgets-wrapper')}>
                    <SubscribersWidget
                        key={`${domain.id}-subs-widget`}
                        loading={this.state.loadingSubscriberEstimate}
                        total={this.state.subscriberEstimate?.total ?? 0}
                        audSizeByChannel={this.state.subscriberEstimate}
                        availableChannels={getEnabledDeliveryChannels(domain, true)}
                        yesterday={{
                            new: this.state.subscribersYesterday,
                            attrition,
                        }}
                    />

                    <ClicksWidget
                        key={`${domain.id}-clicks-widget`}
                        type="Content"
                        showType={showWidgetType}
                        loading={this.state.loadingSubscriberEstimate}
                        total={this.state.subscriberEstimate?.total ?? 0}
                        yesterday={{
                            clicks,
                            ctr: ctrDecimal,
                        }}
                    />

                    {domainHasEcommFlag && (
                        <RevenueWidget
                            key={`${domain.id}-rev-widget`}
                            type="Content"
                            showType={showWidgetType}
                            loading={this.state.loadingSubscriberEstimate}
                            currency={domain.currencyCode}
                            amount={deliveryYesterday.purchase_amount ?? 0}
                        />
                    )}

                    {domainHasPubNetFlag && (
                        <ClicksWidget
                            key={`${domain.id}-ad-clicks-widget`}
                            type="Ad"
                            showType={showWidgetType}
                            loading={this.state.loadingSubscriberEstimate}
                            yesterday={{
                                clicks: yesterdayAdStats.paid_clicks ?? 0,
                                ctr: yesterdayAdStats.paid_ctr_decimal ?? 0,
                            }}
                        />
                    )}

                    {domainHasPubNetFlag && (
                        <RevenueWidget
                            key={`${domain.id}-ad-rev-widget`}
                            type="Ad"
                            showType={showWidgetType}
                            loading={this.state.loadingSubscriberEstimate}
                            currency={domain.currencyCode}
                            amount={yesterdayAdStats.revenue ?? 0}
                        />
                    )}
                </div>
            </div>
        )
    }

    private async setDomainState(): Promise<void> {
        const state: any = {
            domain: this.appState.currentDomain,
        }

        if (state.domain) {
            this.updateGenStats(state.domain)
            this.updatePerformanceStats(state.domain)
        }

        this.setState(state)
    }

    @autobind
    private async setTimeBreakdownState(value: TimeBreakdown) {
        await this.setState({ timeBreakdown: value })
        this.updatePerformanceStats()
    }

    @autobind
    private async setTimeWindowPresetState(value: string) {
        await this.setState({ timeWindowPreset: value })
        if (!!this.state.timeRangeUntil) {
            this.updatePerformanceStats()
        }
    }

    private updateDateRange = async () => {
        const now = moment(new Date())

        if (this.state.timeWindowPreset !== 'CUSTOM') {
            if (!this.state.timeRangeUntil.isSame(now)) {
                await this.setDateRangeState(this.reportTimeRanges[this.state.timeWindowPreset]())
            }
        }
    }

    private get reportTimeRanges(): RangeValueRecordWithPreset<Moment> {
        const now = new Date()

        return {
            ['Last 7 Days']: () => [moment(now).subtract(6, 'd'), moment(now), 'Last 7 Days'],
            ['Last 30 Days']: () => [moment(now).subtract(29, 'd'), moment(now), 'Last 30 Days'],
            ['Last 90 Days']: () => [moment(now).subtract(89, 'd'), moment(now), 'Last 90 Days'],
            ['This Month']: () => [moment(now).startOf('month'), moment(now), 'This Month'],
            ['All Time']: () => [null, null, 'All Time'],
        }
    }

    private get defaultReportTimeRange() {
        return this.reportTimeRanges['Last 7 Days']()
    }

    private get isSingleDayBreakdown(): boolean {
        if (!this.state.timeRangeSince) {
            return false
        } else {
            return this.state.timeRangeUntil?.diff(this.state.timeRangeSince, 'd') === 0
        }
    }

    private get isLifetimeBreakdown(): boolean {
        return !this.state.timeRangeSince
    }

    private renderStatsActions(): React.ReactNode {
        let dateGroupingLabel: any = 'Date Grouping'

        if (this.isLifetimeBreakdown) {
            dateGroupingLabel = (
                <div>
                    <Tooltip title='Only "Monthly" date grouping is supported when selected timeframe is "All Time".'>
                        Date Grouping
                        <InfoCircleOutlined className="info-icon" />
                    </Tooltip>
                </div>
            )
        }

        return (
            <React.Fragment>
                <Form>
                    <Form.Item label={dateGroupingLabel}>
                        <Select
                            size="small"
                            value={this.state.timeBreakdown}
                            onChange={this.setTimeBreakdownState}
                            disabled={this.isSingleDayBreakdown || this.isLifetimeBreakdown}
                        >
                            <Select.Option value="day">Daily</Select.Option>
                            <Select.Option value="week">Weekly</Select.Option>
                            <Select.Option value="month">Monthly</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Timeframe">
                        <DatePicker.RangePicker
                            size="small"
                            className="date-picker"
                            dropdownClassName="reporting-date-range"
                            getPopupContainer={this.appService.getAppContainer}
                            format={SHORT_DATE_FORMAT}
                            ranges={this.reportTimeRanges as any}
                            value={
                                this.state.timeRangeSince
                                    ? [this.state.timeRangeSince, this.state.timeRangeUntil]
                                    : [null, null]
                            }
                            onCalendarChange={this.setDateRangeState}
                        />
                    </Form.Item>

                    <Divider type="vertical" orientation="center" className="header-divider" />

                    <Form.Item>
                        <Tooltip title="Refresh">
                            <Button className="refresh-report" size="small" shape="round" onClick={this.refreshStats}>
                                <ReloadOutlined spin={this.state.loadingStats} />
                            </Button>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </React.Fragment>
        )
    }

    private setDateRangeState = async (value: RangeValueWithPreset<Moment>) => {
        await this.setState({
            timeRangeSince: value?.[0],
            timeRangeUntil: value?.[1],
        })
        const preset = value?.[2] ? value?.[2] : 'CUSTOM'

        await this.setTimeWindowPresetState(preset).then(() => {
            if (!!this.state.timeRangeUntil || this.state.timeWindowPreset === 'All Time') {
                this.updatePerformanceStats()
            }
        })
    }

    private refreshStats = async () => {
        await this.updatePerformanceStats()
    }

    private async updateGenStats(domain: DomainDto): Promise<void> {
        this.updateAttrition(domain)
        this.updateTotalSubs(domain)
        this.updateYesterdaysSubs(domain)
        this.updateYesterdaysDelivery(domain)

        const [cleanup] = addVisibilityAwareIntervalRunner(
            'overview-stats',
            async () => {
                return Promise.all([this.updateTotalSubs(domain), this.updateYesterdaysSubs(domain)])
            },
            300000,
            true,
        )

        this.disposeObservers.push(cleanup)
    }

    private async updateAttrition(domain: DomainDto): Promise<any> {
        await this.setState({ loadingAttrition: true })

        const attritionPct = await this.domainService.fetchAttritionDomainId(domain.id)

        if (attritionPct !== undefined && attritionPct !== null) {
            this.setState({
                loadingAttrition: false,
                attrition: attritionPct / 100,
            })
        } else {
            await this.setState({ loadingAttrition: false })
        }
    }

    private async updateTotalSubs(domain: DomainDto): Promise<any> {
        await this.setState({ loadingSubscriberEstimate: true })

        if (!domain.isDemoDomain) {
            const subscriberEstimate = await this.domainService.fetchActiveSubscriberEstimateForDomainId(
                domain.id,
                'count_by_channel',
                { showLoadingScreen: true },
            )

            if (subscriberEstimate !== undefined && subscriberEstimate !== null) {
                const estimateByChannel = {
                    total: 0,
                    [DeliveryChannel.WEB]: 0,
                    [DeliveryChannel.NATIVE_IOS]: 0,
                    [DeliveryChannel.NATIVE_ANDROID]: 0,
                }

                for (const channel in subscriberEstimate.reach) {
                    if (channel) {
                        estimateByChannel.total += subscriberEstimate.reach[channel]
                        estimateByChannel[channel.toUpperCase()] += subscriberEstimate.reach[channel]
                    }
                }

                this.setState({
                    loadingSubscriberEstimate: false,
                    subscriberEstimate: estimateByChannel,
                })
            } else {
                await this.setState({ loadingSubscriberEstimate: false })
            }
        } else {
            const subscriberEstimate = await this.domainService.fetchActiveSubscriberEstimateForDomainId(
                domain.id,
                'count',
                { showLoadingScreen: true },
            )

            if (subscriberEstimate !== undefined && subscriberEstimate !== null) {
                this.setState({
                    loadingSubscriberEstimate: false,
                    subscriberEstimate: { total: subscriberEstimate.reach },
                })
            } else {
                await this.setState({ loadingSubscriberEstimate: false })
            }
        }
    }

    private async updateYesterdaysSubs(domain: DomainDto): Promise<any> {
        await this.setState({ loadingYesterdaysStats: true })
        const yesterdayDate = moment().subtract(1, 'd').format('YYYY-MM-DD')
        const todayDate = moment().format('YYYY-MM-DD')

        const stats = await this.insightsService.fetch(
            {
                entity: 'prompts',
                date_increment: 'day',
                date_range: {
                    since: yesterdayDate,
                    through: todayDate,
                },
                breakdowns: ['domain'],
                fields: ['subscriptions'],
                filters: [{ field: 'domain.id', operator: 'eq', value: domain.id }],
                order: {
                    field: 'event_date',
                    direction: 'asc',
                },
            },
            false,
        )

        let subsT = 0
        let subsY = 0

        if (stats && stats.length > 0) {
            const today = stats.find((s: any) => s.event_date === todayDate)
            const yesterday = stats.find((s: any) => s.event_date === yesterdayDate)

            if (today) {
                subsT = today.subscriptions
            }

            if (yesterday) {
                subsY = yesterday.subscriptions
            }
        }

        this.setState({
            loadingYesterdaysStats: false,
            subscribersToday: subsT,
            subscribersYesterday: subsY,
        })
    }

    private async updateYesterdaysDelivery(domain: DomainDto): Promise<any> {
        await this.setState({ loadingYesterdaysStats: true })
        const yesterdayDate = moment().subtract(1, 'd').format('YYYY-MM-DD')
        const todayDate = moment().format('YYYY-MM-DD')

        const stats = await this.insightsService.fetch(
            {
                entity: 'notifications',
                date_increment: 'day',
                date_range: {
                    since: yesterdayDate,
                    through: todayDate,
                },
                breakdowns: ['domain'],
                fields: [
                    'deliveries',
                    'impressions',
                    'clicks',
                    'ctr_decimal',
                    'delivery_rate_decimal',
                    'purchase_amount',
                ],
                filters: [{ field: 'domain.id', operator: 'eq', value: domain.id }],
                order: {
                    field: 'send_date',
                    direction: 'asc',
                },
            },
            false,
        )

        let yesterdayStats: any = {}
        if (stats && stats.length > 0) {
            const yesterday = stats.find((s: any) => s.send_date === yesterdayDate)

            if (yesterday) {
                yesterdayStats = yesterday
            }
        }

        this.setState({
            loadingYesterdaysStats: false,
            deliveryYesterday: yesterdayStats,
        })
    }

    private async updatePerformanceStats(domain?: DomainDto): Promise<void> {
        await this.setState({ loadingStats: true })

        const requests = [
            this.updateSubscriberStats(domain || this.state.domain),
            this.updateDeliveryStats(domain || this.state.domain),
            this.updateAdStats(domain || this.state.domain),
        ]

        const res = await Promise.all(requests)

        const subscriberStats = res[0]
        const deliveryStats = res[1]
        const adStats = res[2]

        this.setState({
            loadingStats: false,
            stats: {
                subscriberStats,
                deliveryStats,
                adStats,
            },
        })
    }

    private async updateAdStats(domain: DomainDto): Promise<any> {
        let stats: any[] = []

        const contract: any = {
            entity: 'pub_network',
            date_increment: 'day',
            breakdowns: ['domain'],
            fields: ['impressions', 'paid_clicks', 'paid_ctr', 'paid_ctr_decimal', 'revenue', 'currency_code'],
            filters: [{ field: 'domain.id', operator: 'eq', value: domain.id }],
            order: [
                {
                    field: 'report_date',
                    direction: 'asc',
                },
            ],
        }

        if (this.isLifetimeBreakdown) {
            contract.date_preset = 'lifetime'
            contract.date_increment = 'month'
        } else {
            if (this.isSingleDayBreakdown) {
                contract.date_increment = 'day'
            }

            contract.date_range = {
                since: this.state.timeRangeSince.format('YYYY-MM-DD'),
                through: this.state.timeRangeUntil.format('YYYY-MM-DD'),
            }
        }

        if (domain.flags?.includes(Flags.domain.FEAT_DOMAIN_INTEGRATIONS_PUB_NETWORK.key)) {
            stats = await this.insightsService.fetch(contract, false)
        }

        const backfilledStats = backfillStats(
            stats,
            contract.date_increment,
            {
                impressions: 0,
                paid_clicks: 0,
                paid_ctr: 0,
                paid_ctr_decimal: 0,
                revenue: 0,
                currency_code: domain.currencyCode ?? 'usd',
            },
            // dashboard does not support all channels yet
            [],
            {
                dateCol: 'report_date',
                startDate: this.state.timeRangeSince,
                endDate: this.state.timeRangeUntil,
            },
        )

        return backfilledStats
    }

    private async updateSubscriberStats(domain: DomainDto): Promise<any> {
        const contract: any = {
            entity: 'prompts',
            date_increment: this.state.timeBreakdown,
            breakdowns: ['domain', 'channel'],
            fields: ['subscriptions', 'channel'],
            filters: [{ field: 'domain.id', operator: 'eq', value: domain.id }],
            order: {
                field: 'event_date',
                direction: 'asc',
            },
        }

        if (this.isLifetimeBreakdown) {
            contract.date_preset = 'lifetime'
            contract.date_increment = 'month'
        } else {
            if (this.isSingleDayBreakdown) {
                contract.date_increment = 'day'
            }

            contract.date_range = {
                since: this.state.timeRangeSince.format('YYYY-MM-DD'),
                through: this.state.timeRangeUntil.format('YYYY-MM-DD'),
            }
        }

        const stats = await this.insightsService.fetch(contract, false)
        const backfilledStats = backfillStats(
            stats,
            contract.date_increment,
            {
                subscriptions: 0,
            },
            getEnabledDeliveryChannels(domain, true),
            {
                dateCol: 'event_date',
                startDate: this.state.timeRangeSince,
                endDate: this.state.timeRangeUntil,
            },
        )

        return backfilledStats
    }

    private async updateDeliveryStats(domain: DomainDto): Promise<any> {
        const contract: any = {
            entity: 'notifications',
            date_increment: this.state.timeBreakdown,
            breakdowns: ['domain'],
            fields: ['deliveries', 'impressions', 'clicks', 'delivery_rate', 'ctr', 'purchase_amount'],
            filters: [{ field: 'domain.id', operator: 'eq', value: domain.id }],
            order: {
                field: 'send_date',
                direction: 'asc',
            },
        }

        if (this.isLifetimeBreakdown) {
            contract.date_preset = 'lifetime'
            contract.date_increment = 'month'
        } else {
            if (this.isSingleDayBreakdown) {
                contract.date_increment = 'day'
            }

            contract.date_range = {
                since: this.state.timeRangeSince.format('YYYY-MM-DD'),
                through: this.state.timeRangeUntil.format('YYYY-MM-DD'),
            }
        }

        const stats = (await this.insightsService.fetch(contract, false)) ?? []

        const backfilledStats = backfillStats(
            stats,
            contract.date_increment,
            {
                deliveries: 0,
                impressions: 0,
                clicks: 0,
                delivery_rate: 0,
                ctr: 0,
                purchase_amount: 0,
            },
            // dashboard does not support all channels yet
            [],
            {
                dateCol: 'send_date',
                startDate: this.state.timeRangeSince,
                endDate: this.state.timeRangeUntil,
            },
        )

        return backfilledStats
    }

    private get notificationsListUrl(): string {
        return this.appService.routeWithinDomain('/notifications', true)
    }

    private goToNotifications = () => {
        this.appService.route(this.notificationsListUrl)
    }
}
