import { AppMessageBuilderState } from './interfaces/app-message-builder-state.interface'
import { AudienceConfig } from '@pushly/models/lib/structs/audience-config'
import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import { MutableAppMessageTemplateButtonConfig } from '@pushly/models/lib/structs/app-messages/app-message-template-button-config'
import { AppMessageConditions } from '@pushly/models/lib/structs/app-messages/app-message-conditions'
import { AppMessageBehavior } from '@pushly/models/lib/structs/app-messages/app-message-behavior'
import { AppMessage } from '@pushly/models/lib/structs/app-messages/app-message'
import { DomainDto } from '../../dtos/domain'
import { SegmentDto } from '../../dtos/segment'
import { Dispatch } from 'react'
import { PromiseableProp } from '@pushly/aqe/lib/utils'
import { AppMessageBuilderPayload, AppMessageBuilderSchedulePayload } from './model-extensions'
import { DisplayAnimationBehavior } from '@pushly/models/lib/structs/animation-behavior'

export enum AnimationEffect {
    Enter = 'enter',
    Exit = 'exit',
}

export enum CtaButtonType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

export type CtaGroupTuple = [CtaButtonType, MutableAppMessageTemplateButtonConfig]

export enum ConditionDuration {
    SESSION = 'SESSION',
    LIFETIME = 'LIFETIME',
}

export enum SessionTriggerTypes {
    SESSION_START = 'SESSION_START',
    TIME_IN_APP = 'TIME_IN_APP',
    CUSTOM = 'CUSTOM',
}

// AppMessageBuilderState Context/Reducer types
export type ActionDispatcher = (action: AppMessageDispatchActionPack) => void

export type AppMessageBuilderProps = {
    domain: DomainDto
    mode: 'create' | 'edit'
    type: 'message' | 'template'
    messages?: PromiseableProp<AppMessage[] | undefined>
    defaultSelectedId?: number
}

export type AppMessageReducerElements = [
    context: AppMessageBuilderState,
    dispatch: Dispatch<AppMessageDispatchActionPack>,
]

export type OverrideBuilderStateActionPack = {
    type: 'put'
    entity: 'root'
    data: AppMessageBuilderState
}

export type PatchBuilderStateActionPack = {
    type: 'patch'
    entity: 'root'
    data: Partial<AppMessageBuilderState>
}

export type BaseActionPack = {
    type: 'patch'
    entity: 'base'
    data: AppMessageBuilderPayload
}

export type DetailsActionPack = {
    type: 'patch'
    entity: 'details'
    data: AppMessageDetailsReducerRequest
}

export type NamedTemplateActionPack = {
    type: 'patch'
    entity: 'named-template'
    data: NamedTemplateReducerRequest
}

export type AudienceActionPack = {
    type: 'patch'
    entity: 'audience'
    data: AudienceConfig
}

export type PatchAvailableSegmentsActionPack = {
    type: 'patch'
    entity: 'available-segments'
    data: SegmentDto[]
}

export type ReachEstimateActionPack = {
    type: 'patch'
    entity: 'reach-estimate'
    data: number
}

export type TemplateActionPack = {
    type: 'patch'
    entity: 'template'
    data: Partial<AppMessageUiTemplate>
}

export type ConditionActionPack = {
    type: 'patch'
    entity: 'conditions'
    data: AppMessageConditions
}

export type AppMessageDetails = {
    name?: string
    description?: string
}

export type BehaviorActionPack = {
    type: 'patch'
    entity: 'behavior'
    data: AppMessageBehavior
}

export type AnimationActionPack = {
    type: 'patch'
    entity: 'animation'
    data: DisplayAnimationBehavior
}

export type ScheduleActionPack = {
    type: 'patch'
    entity: 'schedule'
    data: AppMessageBuilderSchedulePayload
}

export type AppMessageDispatchActionPack =
    | OverrideBuilderStateActionPack
    | PatchBuilderStateActionPack
    | BaseActionPack
    | DetailsActionPack
    | NamedTemplateActionPack
    | AudienceActionPack
    | PatchAvailableSegmentsActionPack
    | ReachEstimateActionPack
    | TemplateActionPack
    | ConditionActionPack
    | BehaviorActionPack
    | AnimationActionPack
    | ScheduleActionPack

export type AppMessageDetailsReducerRequest = {
    name?: string
    description?: string | null
    templateId?: number
    usingTemplate?: boolean
    domain: DomainDto
}

export type NamedTemplateReducerRequest = {
    templateId?: number
    usingTemplate?: boolean
}
