import { secondsToDays, secondsToHours, secondsToMinutes } from '@pushly/aqe/lib/utils'
import { daysToSeconds, hoursToSeconds, minutesToSeconds } from './utils'
import { RelativeDateDisplayMetric } from '@pushly/models/lib/enums/relative-date-display-metric'

export function convertSeconds(seconds: number, toMetric: RelativeDateDisplayMetric) {
    switch (toMetric) {
        case RelativeDateDisplayMetric.SECONDS:
            return seconds
        case RelativeDateDisplayMetric.MINUTES:
            return secondsToMinutes(seconds)
        case RelativeDateDisplayMetric.HOURS:
            return secondsToHours(seconds)
        case RelativeDateDisplayMetric.DAYS:
            return secondsToDays(seconds)
        default:
            return 0
    }
}

export function convertToSeconds(seconds: number, toMetric: RelativeDateDisplayMetric) {
    switch (toMetric) {
        case RelativeDateDisplayMetric.SECONDS:
            return seconds
        case RelativeDateDisplayMetric.MINUTES:
            return minutesToSeconds(seconds)
        case RelativeDateDisplayMetric.HOURS:
            return hoursToSeconds(seconds)
        case RelativeDateDisplayMetric.DAYS:
            return daysToSeconds(seconds)
        default:
            return 0
    }
}
