const BASE_DATE_INCREMENT_OPTIONS = [
    { value: 'DAY', label: 'Day' },
    { value: 'WEEK', label: 'Week' },
    { value: 'MONTH', label: 'Month' },
    { value: 'YEAR', label: 'Year' },
    { value: 'LIFETIME', label: 'None' },
]

export const DATE_INCREMENT_DROPDOWN_OPTIONS = {
    NONE: [...BASE_DATE_INCREMENT_OPTIONS],
    DELIVERY: [...BASE_DATE_INCREMENT_OPTIONS],
    SUBSCRIBER: [...BASE_DATE_INCREMENT_OPTIONS],
    PUB_NETWORK: [...BASE_DATE_INCREMENT_OPTIONS],
    ATTRITION: [...BASE_DATE_INCREMENT_OPTIONS],
    MONTHLY_UNIQUES: [{ value: 'MONTH', label: 'Month' }],
    APP_MESSAGE: [...BASE_DATE_INCREMENT_OPTIONS],
    LIVE_ACTIVITY: [...BASE_DATE_INCREMENT_OPTIONS],
}
