import { DEFAULT_HIGHCHARTS_CONFIG, HIGHCHARTS_CHANNEL_COLOR_MAP } from '../../constants'
import { numberWithCommas, titleCase } from '../../_utils/utils'
import { HighchartsUtils } from '../../_utils/highcharts-utils'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { mapDataKeyFromObjectList } from './helpers'
import { reduceStatTotalsByChannel } from '../../features/notifications/notification-details/helpers'
import { Divider, Popover, Select } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import * as React from 'react'
import { DeliveryChannelSelector } from '@pushly/aqe/lib/components/delivery-channel-selector/delivery-channel-selector'
import { Well } from '@pushly/aqe/lib/components'
import './channel-breakdown-stats-chart.scss'
import moment from 'moment-timezone'
import { ChannelBreakdownStatsChartProps, ChartType, StatsType } from './types'

export const ChannelBreakdownStatsChart = (props: ChannelBreakdownStatsChartProps) => {
    const {
        stats,
        channelOptions,
        selectedChannels,
        statsType,
        loading,
        xAxisDateProp,
        onStatsTypeChange,
        onSelectionChange,
    } = props

    const buildSeries = (chartType: ChartType) => {
        const series: any[] = [{ keys: ['name', 'y'], visible: false, showInLegend: false }]
        const colorType = chartType === 'impressions' ? 'primary' : 'secondary'

        if (statsType === 'lifetime') {
            series.push({
                data: selectedChannels.map((channel) => {
                    const foundStat = stats.find((stat) => stat.channel === channel)
                    return {
                        name: DeliveryChannel.getLongName(channel),
                        color: HIGHCHARTS_CHANNEL_COLOR_MAP[channel][colorType],
                        y: foundStat?.[chartType] ?? 0,
                    }
                }),
            })
        } else {
            series.push(
                ...selectedChannels.map((channel) => ({
                    name: DeliveryChannel.getLongName(channel),
                    color: HIGHCHARTS_CHANNEL_COLOR_MAP[channel][colorType],
                    data: mapDataKeyFromObjectList(chartType, stats, channel),
                })),
            )
        }

        return series
    }

    const xAxisDateDataSet = new Set(stats.map((stat) => moment.tz(stat[xAxisDateProp], 'UTC').format('MMM D')))

    const xAxisChannelDataSet = new Set((channelOptions ?? []).map((channel) => DeliveryChannel.getLongName(channel)))

    const options = (chartType: ChartType) => ({
        ...DEFAULT_HIGHCHARTS_CONFIG,
        chart: {
            type: 'column',
            marginLeft: 70,
            marginRight: 50,
            spacingTop: 20,
        },
        title: {
            text: titleCase(chartType),
        },
        xAxis: {
            // handler toggle statsType
            categories: statsType !== 'lifetime' ? [...xAxisDateDataSet] : [...xAxisChannelDataSet],
        },
        legend: {
            enabled: false,
        },
        yAxis: [
            {
                title: { text: '' },
                allowDecimals: false,
                startOnTick: 0,
                minRange: 0,
            },
        ],
        tooltip: {
            pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
        },
        // plotOpt
        series: buildSeries(chartType),
    })

    let impressionsTotals = [
        <div key="impressions" className="ant-popover-title">
            Impressions:
        </div>,
    ]
    let clicksTotals = [
        <div key="clicks" className="ant-popover-title">
            Clicks:
        </div>,
    ]
    const totalStatsByChannel = reduceStatTotalsByChannel(stats)

    Object.keys(totalStatsByChannel).map((key) => {
        impressionsTotals.push(
            <div key={`${key}-impressions`} className="channel-totals-row">
                <span className="channel-totals-label">{DeliveryChannel.getShortName(DeliveryChannel[key])}:</span>
                <span className="channel-totals-value">{numberWithCommas(totalStatsByChannel[key].impressions)}</span>
            </div>,
        )

        clicksTotals.push(
            <div key={`${key}-clicks`} className="reach-breakdown-row">
                <span className="reach-breakdown-label">{DeliveryChannel.getShortName(DeliveryChannel[key])}:</span>
                <span className="reach-breakdown-value">{numberWithCommas(totalStatsByChannel[key].clicks)}</span>
            </div>,
        )
    })

    return (
        <Well className="thin primary-stats-well" hideHeader={true} hideFooter={true} loading={loading}>
            <div className="chart-header">
                <div className="chart-title">Performance Breakdown by Channel</div>
                <div className="chart-actions">
                    <Select<StatsType>
                        className="stats-type-select"
                        size="small"
                        value={statsType}
                        onChange={(val) => {
                            onStatsTypeChange(val)
                        }}
                    >
                        <Select.Option value="day">Daily</Select.Option>
                        <Select.Option value="week">Weekly</Select.Option>
                        <Select.Option value="month">Monthly</Select.Option>
                        <Select.Option value="lifetime">All Time</Select.Option>
                    </Select>
                    <div className="channel-selectors">
                        <DeliveryChannelSelector
                            type="multiple"
                            nested={true}
                            onChange={(change: DeliveryChannel[]) => {
                                const channelUpdate = Array.from(change)
                                onSelectionChange(channelUpdate)
                            }}
                            value={selectedChannels}
                            visibleChannels={channelOptions}
                            allowEmptySelection={true}
                        />
                    </div>
                </div>
            </div>
            <Popover
                key={`popover-details`}
                overlayClassName="domain-notif-channel-select-details-popover"
                content={
                    <div>
                        <div className="impressions-breakdown-stats">{impressionsTotals}</div>

                        <div className="clicks-breakdown-stats">{clicksTotals}</div>
                    </div>
                }
                placement="left"
            >
                <HighchartsReact
                    className="impressions-chart"
                    highcharts={Highcharts}
                    options={options('impressions')}
                />

                <Divider type="horizontal" />

                <HighchartsReact highcharts={Highcharts} options={options('clicks')} />
            </Popover>
        </Well>
    )
}
