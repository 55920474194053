import SimpleList from '../structs/simple-list'
import { AccessRoleId } from '../enums/access-role.enum'

export class AccessRole<RoleId extends AccessRoleId> {
    public constructor(
        public readonly id: RoleId,
        public readonly name: string,
        public readonly label: string,
    ) {}
}

class AccessRoleList implements SimpleList<AccessRole<AccessRoleId>> {
    public static from(roles: ArrayLike<AccessRole<any>>) {
        return new AccessRoleList(Array.from(roles))
    }

    private roleMap: { [RoleId in AccessRoleId | number]?: AccessRole<RoleId> } = {}

    protected constructor(roles: AccessRole<any>[]) {
        for (const role of roles) {
            if (!this.roleMap[role.id]) {
                this.roleMap[role.id] = role
            }
        }
    }

    public get<RoleId extends AccessRoleId>(roleId: RoleId): AccessRole<AccessRoleId> | undefined {
        return this.roleMap[roleId]
    }

    public set<RoleId extends AccessRoleId>(roleId: RoleId, role: AccessRole<RoleId>): void {
        this.roleMap[roleId] = role
    }

    public delete<RoleId extends AccessRoleId>(roleId: RoleId): void {
        if (this.roleMap[roleId]) {
            delete this.roleMap[roleId]
        }
    }

    public toArray(): AccessRole<AccessRoleId>[] {
        let roles: AccessRole<AccessRoleId>[] = []

        for (const role of Object.values(this.roleMap)) {
            if (role) {
                roles.push(role)
            }
        }

        return roles
    }

    public [Symbol.iterator]() {
        let index = -1
        const data = Object.values(this.roleMap)

        return {
            next: () => ({
                value: data[++index],
                done: !data[index],
            }),
        }
    }
}

/**
 * currently sourced from existing DB values
 */

export const AccessRoleMap = AccessRoleList.from([
    new AccessRole(1, 'internal_no_access', 'No Access'),
    new AccessRole(2, 'external_no_access', 'No Access'),
    new AccessRole(3, 'internal_developer', 'Developer'),
    new AccessRole(4, 'internal_account_manager_lead', 'Lead Account Manager'),
    new AccessRole(5, 'internal_account_manager', 'Account Manager'),
    new AccessRole(6, 'internal_sales_associate', 'Sales Associate'),
    new AccessRole(7, 'internal_api_user', 'API User'),
    new AccessRole(8, 'internal_read_only', 'Read-Only'),
    new AccessRole(9, 'internal_user', 'User'),
    new AccessRole(10, 'external_admin', 'Admin'),
    new AccessRole(11, 'external_user', 'User'),
    new AccessRole(12, 'external_api_user', 'API User'),
    new AccessRole(13, 'external_read_only', 'Read-Only'),
    new AccessRole(14, 'external_cms_api_user', 'CMS API User'),
    new AccessRole(15, 'external_domain_api_user', 'Domain API User'),
])
