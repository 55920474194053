import { MutableRefObject, useState } from 'react'
import * as React from 'react'
import { useListenerEvent } from '../../hooks/use-listener-event'
import { Popover } from 'antd'
import { getClassNames } from '../../_utils/classnames'
import { SmileOutlined } from '@ant-design/icons'
import './emoji-manager.scss'
import { preventAll } from '@pushly/aqe/lib/utils/prevent'

const BASE_CLASSNAME = 'emoji-popover'

type EmojiPopover = {
    // uniquely identify this popover component for click events
    componentId: MutableRefObject<string>
    // allow custom value handling
    onChange: (...args: any[]) => void
}

/**
 * Allows use of EmojiPicker as a icon/button with popover not as a controlled input component
 */
export const EmojiPopover = (props: EmojiPopover) => {
    const { componentId, onChange } = props

    const [showEmojiOptions, setShowEmojiOptions] = useState(false)

    /**
     * Determines whether click is blur or focus.
     * Uses component unique id to prevent collisions.
     *
     */
    const handleDocumentClick = (ev: any) => {
        const toEl = ev.target
        if (toEl) {
            // determine if clicked element is inside
            // the current macro manager scope
            // only true external clicks should close
            const masterEl = toEl.closest(`#${componentId.current}`)
            const overlayEl = toEl.closest(`.${componentId.current}`)
            const shouldClose = !masterEl && !overlayEl

            // computed blur events should clear
            // input state and hide macro options
            if (shouldClose) {
                setShowEmojiOptions(false)
            }
        }
    }

    const handleToggleClick = React.useCallback(
        (ev: React.MouseEvent<HTMLSpanElement>) => {
            preventAll(ev)

            const visible = !showEmojiOptions
            setShowEmojiOptions(visible)

            if (visible) {
                addPickerListener()
            }
        },
        [componentId.current, showEmojiOptions],
    )

    const clickCallback = React.useCallback(
        (ev: any) => {
            handleDocumentClick(ev)
        },
        [componentId.current],
    )

    const handlePickerClick = React.useCallback(
        (ev: any) => {
            if (ev.detail) {
                onChange(ev, { emoji: ev.detail.unicode, annotation: ev.detail.emoji.annotation })
            }
        },
        [componentId.current],
    )

    const addPickerListener = React.useCallback(() => {
        setTimeout(() => {
            const picker = document.querySelector(`emoji-picker.${componentId.current}`)
            picker?.addEventListener('emoji-click', handlePickerClick)
        }, 10)
    }, [componentId.current])

    const removePickerListener = React.useCallback(() => {
        const picker = document.querySelector(`emoji-picker.${componentId.current}`)
        picker?.removeEventListener('emoji-click', handlePickerClick)
    }, [componentId.current])

    useListenerEvent('click', clickCallback, document.body)

    return (
        <Popover
            overlayClassName={getClassNames(`${BASE_CLASSNAME}-emoji-overlay`, componentId.current)}
            trigger="click"
            visible={showEmojiOptions}
            afterVisibleChange={(v) => {
                if (!v) {
                    removePickerListener()
                }
            }}
            placement="bottomLeft"
            content={
                <div className={getClassNames(`${BASE_CLASSNAME}-emoji-picker-react`)}>
                    <emoji-picker class={`light ${componentId.current}`} />
                </div>
            }
        >
            <span className={getClassNames(`${BASE_CLASSNAME}-emoji-toggle`, componentId.current)}>
                <SmileOutlined onClick={handleToggleClick} />
            </span>
        </Popover>
    )
}
