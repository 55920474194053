import * as React from 'react'
import { observer } from 'mobx-react'
import '@ant-design/compatible/assets/index.css'
import { Container } from 'typescript-ioc/es5'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Well } from '../../../components/well/well'
import { AppState } from '../../../stores/app'
import { DomainDto } from '../../../dtos/domain'
import { AppService, DomainService } from '../../../services'
import autobind from 'autobind-decorator'
import * as moment from 'moment-timezone'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { DEFAULT_HIGHCHARTS_CONFIG } from '../../../constants'
import { HighchartsUtils } from '../../../_utils/highcharts-utils'
import './domain-subscriber-report.scss'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { arrayUniqueObjects } from '../../../_utils/utils'
import { buildColumnSeriesForChannel, buildSeriesByChannel } from '../helpers'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'

interface IProps {
    stats: any[]
    isSingleDay: boolean
    isLifetime: boolean
    timeBreakdown: string
    availableChannels: DeliveryChannel[]
}

interface IState {
    loading: boolean
    domain: DomainDto
}

@observer
export class DomainSubscriberReport extends BetterComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService
    private domainService: DomainService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        this.state = {
            loading: true,
            domain: this.appState.currentDomain!,
        }
    }

    public render(): React.ReactNode {
        return (
            <Well
                className="domain-sub-report"
                title="Subscribers"
                // action={this.renderStatsActions()}
                ghost={true}
                showFooter={false}
            >
                {this.renderHighChart()}
            </Well>
        )
    }

    @autobind
    private renderHighChart(): React.ReactNode {
        const { stats } = this.props
        if (!stats) {
            return
        }

        const xAxisData = arrayUniqueObjects(stats, 'event_date').map(this.formatStatDate)

        const options: Highcharts.Options & { swContext: () => any } = {
            ...DEFAULT_HIGHCHARTS_CONFIG,
            swContext: (): any => this.props,
            title: { text: '' },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                height: 300,
            },
            xAxis: {
                categories: [...xAxisData],
                tickInterval: 1,
                lineWidth: 0,
                tickWidth: 0,
                margin: 0,
                minPadding: 0.065,
                maxPadding: 0.065,
            },
            plotOptions: {
                column: {
                    groupPadding: 0.1275,
                    stacking: 'normal',
                },
            },
            yAxis: [
                {
                    title: { text: '' },
                    allowDecimals: false,
                    minRange: 0,
                },
            ],
            tooltip: {
                pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                formatter: HighchartsUtils.defaultTooltipFormatter,
                shared: true,
            },
            series: [
                ...buildSeriesByChannel(
                    stats,
                    getEnabledDeliveryChannels(this.state.domain, true),
                    buildColumnSeriesForChannel,
                    { key: 'subscriptions', type: 'column' },
                ),
            ],
        }

        return <HighchartsReact highcharts={Highcharts} options={options} />
    }

    @autobind
    private formatStatDate(stat: any): string {
        const date = moment.tz(`${stat.event_date}`, 'UTC')

        if (this.props.timeBreakdown === 'month' || this.isLifetimeBreakdown) {
            return date.format('MMM')
        }

        return date.format('MMM D')
    }

    private get isSingleDayBreakdown(): boolean {
        return this.props.isSingleDay
    }

    private get isLifetimeBreakdown(): boolean {
        return this.props.isLifetime
    }
}
