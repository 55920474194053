import { persist, create } from 'mobx-persist'
import { Platform } from '@pushly/cuttlefish'
import { PreviewState } from '../components/notification-builder/elements/notification-builder-preview'
import { useEffect, useState } from 'react'
import { computed, observable } from 'mobx'
import { useService } from '@pushly/aqe/lib/hooks'
import { Singleton } from 'typescript-ioc'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { DeliveryChannelPlatforms } from '../types/delivery-channel-platforms'
import { coalesce } from '../_utils/coalesce'

const STORAGE_KEY = 'pufferfish.nprs'

type NotificationPreviewLevel = 'org' | 'domain'

type NotificationPreviewStateCache = Record<'o' | 'd', Record<number, { p?: Platform; s?: PreviewState }>>
const emptyCache: NotificationPreviewStateCache = { o: {}, d: {} }

export class NotificationPreviewConfig {
    public constructor(
        protected level: NotificationPreviewLevel,
        protected levelId: number,
        protected data: { p?: Platform; s?: PreviewState },
        protected updateCompletedCallback: (next: { p?: Platform; s?: PreviewState }) => void,
    ) {}

    public get platform(): Platform | undefined {
        return this.data.p && Object.values(Platform).includes(this.data.p) ? this.data.p : undefined
    }

    public set platform(value: Platform | undefined) {
        this.data.p = value
        this.updateCompletedCallback(this.data)
    }

    public get state(): PreviewState | undefined {
        return this.data.s && Object.values(PreviewState).includes(this.data.s) ? this.data.s : undefined
    }

    public set state(value: PreviewState | undefined) {
        this.data.s = value
        this.updateCompletedCallback(this.data)
    }

    public resolvePlatformFromAvailableChannels(channels: DeliveryChannel[] = [], defaultValue = Platform.ANDROID) {
        try {
            let initialPlatform = coalesce(this.platform, defaultValue)
            if (channels.length && !channels.some((ch) => DeliveryChannelPlatforms[ch].includes(initialPlatform!))) {
                initialPlatform = DeliveryChannelPlatforms[channels[0]][0]
            }
            return coalesce(initialPlatform, defaultValue)
        } catch (err) {
            console.warn(err)
            return defaultValue
        }
    }
}

@Singleton
export class NotificationPreviewState {
    protected rehydrator: Promise<any> | undefined

    @observable
    @persist
    protected cachedValue: string | undefined

    @computed
    protected get cache(): NotificationPreviewStateCache {
        return this.cachedValue ? JSON.parse(this.cachedValue) : emptyCache
    }

    public constructor() {
        this.rehydrator = new Promise<void>(async (res, rej) => {
            try {
                const hydrate = create({
                    storage: localStorage,
                    jsonify: true,
                })

                await hydrate(STORAGE_KEY, this)

                res()
            } catch (error) {
                rej(error)
            }
        })
    }

    public get isReady(): Promise<this> {
        return Promise.resolve(this.rehydrator)
            .catch((err) => console.warn(err))
            .then(() => this)
    }

    public getCache(level: 'org' | 'domain', levelId: number): NotificationPreviewConfig {
        const cache = this.cache
        const levelKey = level[0] as 'o' | 'd'
        cache[levelKey][levelId] ??= {}

        return new NotificationPreviewConfig(level, levelId, cache[levelKey][levelId], (next) => {
            cache[levelKey][levelId] = next
            this.cachedValue = JSON.stringify(cache)
        })
    }
}

type PersistedNotificationPreviewState = {
    readonly isLoaded: boolean
    readonly value: NotificationPreviewState
}

export const usePersistedNotificationPreviewState = (storageKey?: string): PersistedNotificationPreviewState => {
    const [isLoaded, setIsLoaded] = useState(false)
    const state = useService(NotificationPreviewState)

    useEffect(() => {
        state.isReady.then(() => setIsLoaded(true))
    }, [])

    return {
        isLoaded,
        value: state,
    }
}
