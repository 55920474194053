import React, { useEffect } from 'react'
import './macro-manager.scss'
import { useService } from '../../hooks/use-service'
import { AppState } from '../../stores/app'
import { Popover, Tooltip } from 'antd'
import { getClassNames } from '../../_utils/classnames'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { MacroSelectOptionsListToggle } from './macro-select-options-list-toggle'
import { preventAll } from '@pushly/aqe/lib/utils/prevent'
import { useCallback, useState } from 'react'
import { MacroPopoverProps, MacroSelectOptionsListProps } from './types'
import { MacroSelectOptionsList } from './macro-select-options-list'

const BASE_CLASSNAME = 'macro-popover'

export const MacroPopover = ({ title, visible, id, ...props }: MacroPopoverProps) => {
    const appState = useService(AppState)

    const [showMacroOptions, _setShowMacroOptions] = useState(visible ?? false)
    const setShowMacroOptions = useCallback(
        (show: boolean) => {
            _setShowMacroOptions(show)
            props.onVisibleChange?.(show)
        },
        [showMacroOptions],
    )

    /**
     * Determines whether click is blur or focus.
     * Uses component unique id to prevent collisions.
     *
     */
    const handleDocumentClick = useCallback(
        (ev: any) => {
            const toEl = ev.target
            if (toEl) {
                const update: any = {}

                // determine if clicked element is inside
                // the current macro manager scope
                // only true external clicks should close
                const masterEl = toEl.closest(`#${id}`)
                const overlayEl = toEl.closest(`.${id}`)
                const shouldClose = !masterEl && !overlayEl

                // computed blur evenst should clear
                // input state and hide macro options
                if (shouldClose) {
                    update.showMacroOptions = false
                    update.inputContext = undefined
                }

                if (props.onDocClick) {
                    // when props.onDocClick() is provided
                    // the consumer is responsible for determining
                    // the visibility change behavior
                    props.onDocClick(update, ev)
                } else {
                    setShowMacroOptions(update.showMacroOptions)
                }
            }
        },
        [!!props.onDocClick],
    )

    const handleToggle = useCallback(
        (ev: any) => {
            preventAll(ev)
            setShowMacroOptions(!showMacroOptions)
        },
        [showMacroOptions],
    )

    const handleItemSelect: MacroSelectOptionsListProps['onItemSelect'] = useCallback((macro) => {
        props.onItemSelect(macro)
        setShowMacroOptions(false)
    }, [])

    const handleVisibilityChange: (typeof props)['onVisibleChange'] = useCallback(
        (v) => {
            props.onVisibleChange?.(v)
        },
        [!!props.onVisibleChange],
    )

    useEffect(() => {
        document.body.addEventListener('click', handleDocumentClick)

        return () => document.body.removeEventListener('click', handleDocumentClick)
    })

    title ??= 'Macros'

    return (
        <Popover
            key={`${id}-macro-list`}
            {...props}
            className={getClassNames(`${BASE_CLASSNAME}-control`, props.className, id, {})}
            visible={visible ?? showMacroOptions}
            onVisibleChange={handleVisibilityChange}
            title={
                <div className={getClassNames(`${BASE_CLASSNAME}-overlay-title`)}>
                    <span className={getClassNames(`${BASE_CLASSNAME}-title`)}>{title}</span>
                    <span className={getClassNames(`${BASE_CLASSNAME}-learn-more`)}>
                        <Tooltip title="Learn More">
                            <a href={`${appState.documentationLink}/platform/notifications/macros`} target="_blank">
                                <QuestionCircleOutlined className="info-icon" />
                            </a>
                        </Tooltip>
                    </span>
                </div>
            }
            placement="bottom"
            overlayClassName={getClassNames(`${BASE_CLASSNAME}-overlay`, props?.overlayClassName, id, {})}
            content={
                <MacroSelectOptionsList
                    loading={props.loading}
                    context={props.inputContext}
                    macros={props.macros}
                    onItemSelect={handleItemSelect}
                />
            }
            trigger="click"
        >
            <MacroSelectOptionsListToggle loading={props.loading} onClick={handleToggle} hideToggle={false} />
        </Popover>
    )
}
