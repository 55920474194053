import { Container, Singleton } from 'typescript-ioc/es5'
import { AppState } from '../stores/app'
import { AppService } from './app'
import IApiCallOptions from '../interfaces/api-call-options'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import aqe from '@pushly/aqe'
import { ApiVersion } from '../enums/api-version.enum'
import { simpleNotification } from '../_utils/utils'
import { AppMessageScheduleWithFullMessage } from '@pushly/models/lib/structs/app-messages/app-message-schedule-with-full-message'
import { HttpMethod } from '@pushly/aqe/lib/enums'
import * as querystring from 'query-string'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { AppMessageScheduleViewable } from '../components/app-message-builder/model-extensions'

@Singleton
export class AppMessageScheduleService {
    private readonly appState: AppState
    private readonly appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public async fetchAll(
        domainId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<AppMessageScheduleViewable[]>> {
        let res: IServiceApiResponse<AppMessageScheduleViewable[]> = {
            ok: false,
            data: [],
            meta: {},
        }

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const options = querystring.stringify(opts?.query ?? {})

            const { data, ...meta } = await axiosFetch(
                HttpMethod.GET,
                {
                    url: `${aqe.defaults.publicApiDomain}/${ApiVersion.V4}domains/${domainId}/app-message-schedules?${options}&include_app_message=1&include_segments=1`,
                },
                opts.cancellationKey,
            )

            res.ok = true
            res.meta = { links: data.links, ...meta }
            res.data = data.data.map((schedule) => AppMessageScheduleViewable.parseJsonObject(schedule))
        } catch (e) {
            console.error(e)
            if (isAxiosCancellation(e)) {
                res.cancelled = true
            }
            res.error = e
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return res
    }

    public async archiveAppMessageSchedule(domainId: number, appMessageScheduleId: number): Promise<boolean> {
        this.appService.setModuleLoading()

        try {
            await axiosFetch(HttpMethod.DELETE, {
                url: `${aqe.defaults.publicApiDomain}/${ApiVersion.V4}domains/${domainId}/app-message-schedules/${appMessageScheduleId}`,
            })

            simpleNotification('success', `App Message successfully archived.`)
            this.appService.unsetModuleLoading()

            return false
        } catch (error) {
            handleResponseErrorMessage(error, {
                fallbackMessage: 'App Message could not be archived at this time.',
            })

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async updateAppMessageSchedule(
        domainId: number,
        appMessageScheduleId: number,
        requestDto: Partial<AppMessageScheduleWithFullMessage>,
        opts: IApiCallOptions = {},
    ): Promise<string | boolean> {
        this.appService.setModuleLoading()

        try {
            const res = await axiosFetch(
                HttpMethod.PATCH,
                {
                    url: `${aqe.defaults.publicApiDomain}/${ApiVersion.V4}domains/${domainId}/app-message-schedules/${appMessageScheduleId}`,
                    data: requestDto,
                },
                opts.cancellationKey,
            )
            const { data } = res.data

            simpleNotification('success', `App Message Schedule successfully updated.`)
            this.appService.unsetModuleLoading()

            return data.id
        } catch (error) {
            handleResponseErrorMessage(error, {
                fallbackMessage: 'App Message Schedule could not be updated at this time.',
            })

            this.appService.unsetModuleLoading()
            return false
        }
    }
}
