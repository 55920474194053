import * as React from 'react'
import { getClassNames } from '../../../_utils/classnames'

type BuilderMain = {
    className?: string
}

export const AppMessageBuilderMain: React.FC<BuilderMain> = (props) => {
    const { className, children } = props

    return <div className={getClassNames('app-message-builder-main', className)}>{children}</div>
}
