import React from 'react'
import { getClassNames } from '../../_utils/classnames'
import { MACRO_MGR_BASE_CLASSNAME } from './constants'
import Icon from '@ant-design/icons'
import { IconMacro } from '../svg/icon-macro'
import { MacroSelectOptionsListToggleProps } from './types'

export const MacroSelectOptionsListToggle = ({ onClick, hideToggle }: MacroSelectOptionsListToggleProps) => {
    return (
        <span
            className={getClassNames(`${MACRO_MGR_BASE_CLASSNAME}-macro-toggle`, {
                ['hidden']: hideToggle,
            })}
        >
            <Icon component={IconMacro} onClick={onClick} />
        </span>
    )
}
