import * as React from 'react'
import './notification-test-details.scss'
import * as deepEqual from 'react-fast-compare'
import { observe } from 'mobx'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import * as moment from 'moment-timezone'
import * as numeral from 'numeral'
import * as randomstring from 'randomstring'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import autobind from 'autobind-decorator'
import { ClockCircleFilled, ExclamationCircleOutlined, RetweetOutlined } from '@ant-design/icons'
import { Button, Skeleton, Tag, Tooltip } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import {
    BASE_TIME_FORMAT,
    BASE_TIME_FORMAT_WITHOUT_TZ,
    DEFAULT_HIGHCHARTS_CONFIG,
    INSIGHTS_ANDROID_COLOR,
    INSIGHTS_IOS_COLOR,
    INSIGHTS_WEB_COLOR,
} from '../../../constants'
import { arrayContains, preventBubbling, titleCase } from '../../../_utils/utils'
import { DomainDto } from '../../../dtos/domain'
import { StatusType } from '../../../enums/status-type'
import { AppService } from '../../../services/app'
import { Well } from '../../../components/well/well'
import { NotificationService } from '../../../services/notification'
import { HighchartsUtils } from '../../../_utils/highcharts-utils'
import { AppState } from '../../../stores/app'
import { NotificationDto } from '../'
import { NotificationScheduleDto } from '../dtos/notification-schedule-dto'
import { SegmentDto } from '../../../dtos/segment'
import { NotificationSummaryCard } from '@pushly/aqe/lib/components'
import { BaseNotificationDetails, IBaseNotificationDetailsState } from './base-notification-details-component'
import { InsightsService } from '../../../services/insights'
import * as clone from 'clone'
import { NotificationAbWinnerBadge } from '../../../components/badges/notification-ab-winner-badge'
import { INotificationTreatment } from '../../../interfaces/notification-treatment'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'
import { generateShortID } from '../../../components/campaign-builder/helpers/uid'
import { NotificationDeliveryType } from '../../../enums/notification-delivery-type'
import { extractSummaryNotification } from '../../../_utils/notifications'
import { WebBadge } from '../../../components/badges/web-badge'
import { NativeIosBadge } from '../../../components/badges/native-ios-badge'
import { NativeAndroidBadge } from '../../../components/badges/native-android-badge'
import { NoTranslate } from '../../../components/no-translate/no-translate'
import { NotificationHoldOutBadge } from '../../../components/badges/notification-hold-out-badge'
import { NotificationAbBadge } from '../../../components/badges/notification-ab-badge'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { DeliveryChannelSelector } from '@pushly/aqe/lib/components/delivery-channel-selector/delivery-channel-selector'
import { NotificationPreviewState } from '../../../stores/notification-preview-state'
import { DeliveryChannelPlatforms } from '../../../types/delivery-channel-platforms'

Highcharts.setOptions({ lang: { thousandsSep: ',' } })

interface IHeaderProps {
    domain: DomainDto
    test?: any
}

class TestDetailsHeader extends React.Component<IHeaderProps, {}> {
    protected appState: AppState
    protected appService: AppService

    public constructor(props: IHeaderProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render(): React.ReactNode {
        return (
            <div className="details-header">
                <Skeleton title={false} paragraph={{ rows: 5 }} loading={!this.test} active={true}>
                    <div className="notif-title">
                        <h2>{this.test.name}</h2>
                        <div className="append">
                            <div className="notif-id">
                                <span>
                                    #{this.test.id}
                                    <span>
                                        {' '}
                                        | Created by <NoTranslate>{this.test.createdByUserName}</NoTranslate>
                                    </span>
                                </span>

                                {this.buildOptions()}
                            </div>

                            <div className="test-types">{this.buildTestType()}</div>

                            <div className="notif-dates">{this.buildFlightDates()}</div>

                            <div className="notif-audience">{this.buildSegments()}</div>
                        </div>
                    </div>
                </Skeleton>
            </div>
        )
    }

    protected get test(): any {
        return this.props.test!
    }

    protected get sample(): NotificationDto {
        return this.test.schedules[0]
    }

    protected buildTestType() {
        let testType = 'Content & Delivery'
        const test = this.test
        const variants = test.schedules
        const deliverySpecs = variants.map((v) => v.deliverySpec)
        const allSpecsMatch = deliverySpecs.every((v, i, arr) => deepEqual(v, arr[0]))

        if (test.testTypes?.length === 1) {
            if (test[0] === 'content') {
                testType = 'Content'
            } else {
                testType = 'Delivery'
            }
        } else if (allSpecsMatch) {
            testType = 'Content'
        }

        return (
            <span>
                <b>Test Type</b> {testType}
            </span>
        )
    }

    protected buildFlightDates(): React.ReactNode {
        const isSTZ = this.sample.isTzDelivery()
        const sendDateFormat = isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT

        let startDeliverySpec
        let endDeliverySpec

        if (this.test && this.test.isTimeVariant) {
            const sortedDeliverySpecs = this.test.schedules
                .map((s) => s.deliverySpec)
                .sort((a, b) => {
                    const aMoment = moment(a.sendDateUtc!)
                    const bMoment = moment(b.sendDateUtc!)

                    if (aMoment.isAfter(bMoment)) {
                        return 1
                    } else if (aMoment.isBefore(bMoment)) {
                        return -1
                    }

                    return 0
                })

            startDeliverySpec = sortedDeliverySpecs[0]
            endDeliverySpec = sortedDeliverySpecs[sortedDeliverySpecs.length - 1]
        } else {
            startDeliverySpec = endDeliverySpec = this.sample.deliverySpec
        }

        const startDate = moment.tz(startDeliverySpec.sendDateUtc, startDeliverySpec.timezone)
        const expiryDate = moment
            .tz(endDeliverySpec.sendDateUtc, endDeliverySpec.timezone)
            .add(endDeliverySpec.ttlSeconds, 'seconds')

        const flightDateStart = startDate.format(isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT)
        const flightDateEnd = expiryDate.format(sendDateFormat)

        return (
            <span>
                <b>Delivery Date Range</b> {flightDateStart} through {flightDateEnd}
            </span>
        )
    }

    protected buildSegments(): React.ReactNode {
        let subscribers: React.ReactNode
        let excludedSubscribers: React.ReactNode
        const allSubscriberTag: React.ReactNode = (
            <a key="all-subs">
                <Tag className="no-click">All Subscribers</Tag>
            </a>
        )

        if (this.sample.audience.segments) {
            let subscriberTags: React.ReactNode[] = [allSubscriberTag]
            const isAllSubscribers = this.sample.audience.segments.find((s: SegmentDto) => s.isDefault)

            if (!isAllSubscribers) {
                subscriberTags = this.sample.audience.segments
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((segment: any, idx: number) => (
                        <a
                            key={`i-${idx}`}
                            onClick={this.jumpToSegment.bind(this, segment)}
                            href={this.buildSegmentUrl(segment)}
                        >
                            <Tag>{segment.name}</Tag>
                        </a>
                    ))
            }

            subscribers = subscriberTags
        } else if (this.sample.audience.externalSubscriberIds) {
            subscribers = <Tag>Subscriber IDs</Tag>
        }

        if (this.sample.audience.excludedSegments && this.sample.audience.excludedSegments.length > 0) {
            excludedSubscribers = this.sample.audience.excludedSegments
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                .map((segment: any, idx: number) => (
                    <a
                        key={`x-${idx}`}
                        onClick={this.jumpToSegment.bind(this, segment)}
                        href={this.buildSegmentUrl(segment)}
                    >
                        <Tag>{segment.name}</Tag>
                    </a>
                ))
        }

        return (
            <>
                <div className="audience-section">
                    <div>
                        <b>Included Segments</b>
                    </div>
                    <div>{subscribers}</div>
                </div>
                {!!excludedSubscribers ? (
                    <div className="audience-section">
                        <div>
                            <b>Excluded Segments</b>
                        </div>
                        <div>{excludedSubscribers}</div>
                    </div>
                ) : (
                    ''
                )}
            </>
        )
    }

    protected buildOptions(): React.ReactNode {
        const options: any[] = []
        const hasIsSilent = this.test.notifications.find((n) => !!n.defaultTemplate.isSilent)

        if (hasIsSilent) {
            options.push(
                <span key="silent" className="option">
                    <Tooltip title="Silent">
                        <i className="icon-muted" />
                    </Tooltip>
                </span>,
            )
        }

        if (this.sample.isTzDelivery()) {
            options.push(
                <span key="stz" className="option">
                    <Tooltip title="Delivered in Subscriber's Time Zone">
                        <ClockCircleFilled />
                    </Tooltip>
                </span>,
            )
        }

        if (this.test.notifications.find((n) => n.abTest)) {
            options.push(
                <span className="option">
                    <NotificationAbBadge />
                </span>,
            )
        }

        if (this.test.notifications.find((n) => n.deliverySpec)) {
            this.test.notifications.find(
                (n) =>
                    n.deliverySpec.window === NotificationDeliveryWindow.HOLD_OUT &&
                    options.push(
                        <span className="option">
                            <NotificationHoldOutBadge />
                        </span>,
                    ),
            )
        }

        if (this.test.notifications.find((n) => n.channels)) {
            this.test.notifications.find((n) => n.channels.includes(DeliveryChannel.WEB)) &&
                options.push(
                    <span className="option">
                        <WebBadge />
                    </span>,
                )
            this.test.notifications.find((n) => n.channels?.includes(DeliveryChannel.NATIVE_IOS)) &&
                options.push(
                    <span className="option">
                        <NativeIosBadge />
                    </span>,
                )
            this.test.notifications.find((n) => n.channels?.includes(DeliveryChannel.NATIVE_ANDROID)) &&
                options.push(
                    <span className="option">
                        <NativeAndroidBadge />
                    </span>,
                )
        }

        if (options.length > 0) options.unshift(<span key={generateShortID()}>|</span>)

        return options
    }

    private buildSegmentUrl(segment: any): string {
        return this.appService.routeWithinDomain(`/segments/${segment.id}/summary`, true)
    }

    @autobind
    private jumpToSegment(segment: any, ev?: any): void {
        if (!ev.metaKey) {
            preventBubbling(ev)
            this.appService.route(this.buildSegmentUrl(segment))
        }
    }
}

interface IProps {}

interface IState extends IBaseNotificationDetailsState {
    domain: DomainDto
    preloading: boolean
    statsType: 'hour' | 'day'
    loadingGenStats: boolean
    loadingBreakdownStats: boolean
    test?: any
    isDelivered: boolean
    testStats: {
        composite: any
        compositeDaily: any
        schedules: any[]
        rawStats: any[]
    }
    treatments: INotificationTreatment[]
    winningTreatmentIndex: number
    performanceChannel: DeliveryChannel[]
}

@observer
export class TestDetails extends BaseNotificationDetails<IProps, IState> {
    protected appService: AppService
    protected readonly notificationPreviewState: NotificationPreviewState
    private appState: AppState
    private notificationService: NotificationService
    private insightsService: InsightsService

    private disposeObservers: any[]

    private get testParamId(): number | undefined {
        try {
            return parseInt(this.injectedProps.match.params.testId, 10)
        } catch {
            return
        }
    }

    public constructor(props: IProps & FormComponentProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.notificationService = Container.get(NotificationService)
        this.insightsService = Container.get(InsightsService)
        this.notificationPreviewState = Container.get(NotificationPreviewState)

        this.state = {
            domain: this.globalDomain!,
            preloading: true,
            statsType: 'day',
            loadingGenStats: true,
            loadingBreakdownStats: true,
            isDelivered: false,
            treatments: [],
            winningTreatmentIndex: 0,
            testStats: {
                composite: {},
                compositeDaily: {},
                schedules: [],
                rawStats: [],
            },
            performanceChannel: [],
        } as any
    }

    public async UNSAFE_componentWillMount() {
        await this.preloadRequiredDataStates()

        const tabs = [
            {
                name: 'summary',
                label: 'Summary',
                render: this.renderSummaryTab,
            },
        ]

        const activeTab = this.determineActiveTab(tabs)

        this.setState({
            tabs,
            activeTab,
        })
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        this.disposeObservers.forEach((fn) => fn())
    }

    public render() {
        return (
            <div className="module test-details">
                {!this.state.preloading && (
                    <React.Fragment>
                        <TestDetailsHeader test={this.state.test} domain={this.localDomain!} />

                        {this.renderTabs()}
                    </React.Fragment>
                )}
            </div>
        )
    }

    private get isDisabled(): boolean {
        const completedStatuses = [StatusType.COMPLETED.name, StatusType.COMPLETED_WITH_FAILURES.name]
        const isDelivered = arrayContains(completedStatuses, this.state.test!.schedules[0].status)
        const isDeliverying = this.state.test!.schedules[0].status === StatusType.DELIVERING.name
        const isCancelled =
            this.state.test!.schedules[0].status === StatusType.CANCELLED.name ||
            this.state.test!.schedules[0].status === StatusType.CANCELLING.name
        const isTzPush = this.state.test!.schedules[0].deliverySpec.window === NotificationDeliveryWindow.TIMEZONE

        return !isDelivered && !(isTzPush && (isDeliverying || isCancelled))
    }

    private renderSummaryTab(): React.ReactNode {
        const { treatments } = this.state
        const sortedTreatments = this.sortById(treatments)

        return (
            <React.Fragment>
                <div className="outer-well-buttons">
                    <Button className="refresh-list" size="small" onClick={this.refreshStats}>
                        <RetweetOutlined />
                        <span>Refresh</span>
                    </Button>
                </div>

                {this.renderGenericStats()}
                <br />

                {this.renderGenPerformanceChart()}
                <br />

                <div className="test-result-cards">
                    {sortedTreatments.map((treatment, index: number) => {
                        const name: string = ['A', 'B', 'C', 'D', 'E'][index]
                        return this.buildTestResult(`Variant ${name}`, treatment, index)
                    })}
                </div>
            </React.Fragment>
        )
    }

    private renderGenPerformanceChart(): React.ReactNode {
        const { testStats } = this.state

        if (!testStats) return ''

        const channelOptions = Array.from(new Set(testStats.rawStats.map((stat) => stat?.channel) ?? []))
        const channelsSelection: any = {}
        channelOptions.forEach((ch) => {
            channelsSelection[ch.toUpperCase()] = this.state.performanceChannel.includes(ch)
        })
        const mapDataKeyFromObject = (key: string, obj: any, channel: DeliveryChannel) => {
            return obj?.filter((val) => val.channel === channel)?.map((filtered) => filtered[key])
        }

        const buildSeries = (chartType: 'impressions' | 'clicks') => {
            const series: any[] = []

            if (this.state.performanceChannel.includes(DeliveryChannel.WEB)) {
                series.push({
                    name: `Web`,
                    color: INSIGHTS_WEB_COLOR,
                    data: mapDataKeyFromObject(chartType, testStats.rawStats, DeliveryChannel.WEB),
                })
            }

            if (this.state.performanceChannel.includes(DeliveryChannel.NATIVE_IOS)) {
                series.push({
                    name: `iOS`,
                    chartType,
                    color: INSIGHTS_IOS_COLOR,
                    data: mapDataKeyFromObject(chartType, testStats.rawStats, DeliveryChannel.NATIVE_IOS),
                })
            }

            if (this.state.performanceChannel.includes(DeliveryChannel.NATIVE_ANDROID)) {
                series.push({
                    name: `Android`,
                    chartType,
                    color: INSIGHTS_ANDROID_COLOR,
                    data: mapDataKeyFromObject(chartType, testStats.rawStats, DeliveryChannel.NATIVE_ANDROID),
                })
            }

            return series
        }

        const options = (chartType: 'impressions' | 'clicks') => ({
            ...DEFAULT_HIGHCHARTS_CONFIG,
            chart: {
                type: 'column',
            },
            title: {
                text: undefined,
            },
            yAxis: [
                {
                    type: 'logarithmic',
                    title: { text: '' },
                    startOnTick: 0,
                    minRange: 0,
                },
            ],
            xAxis: {
                categories: [titleCase(chartType)],
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: false,
                },
            },
            tooltip: {
                pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                formatter: HighchartsUtils.defaultTooltipFormatter,
                shared: true,
            },
            series: buildSeries(chartType),
        })

        return (
            <Well
                className="thin"
                showHeader={false}
                showFooter={false}
                loading={this.state.loadingBreakdownStats}
                disabled={!this.state.test!.notifications}
            >
                <div className="chart-header">
                    <div className="chart-title">Performance Summary by Channel</div>
                    <div className="chart-actions">
                        <DeliveryChannelSelector
                            type="multiple"
                            nested={true}
                            onChange={(change: DeliveryChannel[]) => {
                                this.setState({ performanceChannel: change })
                            }}
                            visibleChannels={channelOptions}
                            allowEmptySelection={true}
                        />
                    </div>
                </div>

                <div className="chart-body">
                    <HighchartsReact highcharts={Highcharts} options={options('impressions')} />

                    <HighchartsReact highcharts={Highcharts} options={options('clicks')} />
                </div>
            </Well>
        )
    }

    private renderGenericStats(): React.ReactNode {
        return (
            <Well
                className="thin gen-stats-well nested"
                loading={this.state.loadingGenStats}
                showHeader={false}
                showFooter={false}
            >
                <Well ghost={true} showHeader={false} showFooter={false}>
                    <div className="chart-title">Status</div>
                    <div className="stat stat-status">{this.renderStatus()}</div>
                </Well>
                <Well ghost={true} showHeader={false} showFooter={false} disabled={this.isDisabled}>
                    <div className="chart-title">Impressions</div>
                    <div className="stat stat-impressions">{this.renderImpressions()}</div>
                </Well>
                <Well ghost={true} showHeader={false} showFooter={false} disabled={this.isDisabled}>
                    <div className="chart-title">Total Clicks (CTR)</div>
                    <div className="stat stat-clicks">
                        {this.renderClicks()}
                        <div className="stat stat-ctr sub-stat">({this.renderCTR()})</div>
                    </div>
                </Well>
            </Well>
        )
    }

    @autobind
    private renderStatus(): React.ReactNode {
        const test = this.state.test
        if (!test) return 'Pending'
        const hasStarted = test.schedules.some(
            (s) => !!s.startedDateUtc || s.deliverySpec.type === NotificationDeliveryType.IMMEDIATE,
        )
        const hasFullyCompleted = !test.schedules.some((s) => !s.completedDateUtc)
        const hasFullyFailed = !test.schedules.some((s) => s.status !== StatusType.FAILED.name)
        const isPartiallyDelivered = test.schedules.some((s) => s.status === StatusType.COMPLETED_WITH_FAILURES.name)

        let statusStr = 'scheduled'
        if (hasFullyFailed) {
            statusStr = 'failed'
        } else if (hasFullyCompleted) {
            statusStr = 'delivered'
        } else if (hasStarted) {
            statusStr = 'delivering'
        }

        // Delivered
        let color: string = 'green'

        switch (statusStr) {
            case 'scheduled':
                color = 'purple'
                break
            case 'delivering':
                color = 'orange'
                break
            case 'failed':
                color = 'red'
                break
        }

        return (
            <div className={`color-${color}`}>
                {titleCase(statusStr || 'unknown')}
                {isPartiallyDelivered && (
                    <>
                        <Tooltip title="A portion of this notification's audience failed to deliver.">
                            <ExclamationCircleOutlined className="info-icon error-icon" />
                        </Tooltip>
                    </>
                )}
            </div>
        )
    }

    private renderImpressions(stats?: any): React.ReactNode {
        stats = stats || this.state.testStats.composite
        if (this.isDisabled) {
            return '--'
        }
        if (!stats) return ''
        return numeral(stats.impressions).format('O,O')
    }

    private renderClicks(): React.ReactNode {
        const stats: any = this.state.testStats.composite
        if (this.isDisabled) {
            return '--'
        }
        if (!stats) return ''
        return numeral(stats.clicks).format('O,O')
    }

    private renderCTR(stats?: any): React.ReactNode {
        stats = stats || this.state.testStats.composite
        if (!stats || !stats.clicks) return '--'
        return numeral(stats.clicks / stats.impressions).format('0.00%')
    }

    private async preloadRequiredDataStates(): Promise<void> {
        const state: Partial<IState> = {}

        if (this.globalDomain && this.testParamId) {
            const test: any = await this.notificationService.fetchTestById(this.globalDomain.id, this.testParamId, true)

            if (test) {
                state.preloading = false
                state.test = test
                state.treatments = test.notifications.map(this.extractNotificationTreatment)

                test.schedules.forEach((schedule: any) => {
                    const notif = test.notifications.find(
                        (n: any) => n.id.toString() === schedule.notificationId.toString(),
                    )

                    if (notif) {
                        schedule.template = notif.template
                    }
                })

                const sample: NotificationDto = test.schedules[0]
                if (sample && sample.status === 'COMPLETED') {
                    state.isDelivered = true
                }

                await this.setState(state as any)
                this.getNotificationGenStats(test.notifications).catch((err) => console.warn(err))
                this.getNotificationBreakdownStats(test.schedules).catch((err) => console.warn(err))
            }
        }
    }

    private reduceStat(key: string, stats: any[]): any[] {
        return stats.reduce((n: number, stat: any) => (n += stat[key]), 0)
    }

    private async getNotificationGenStats(notifications?: NotificationDto[]): Promise<void> {
        notifications = notifications! || this.state.test!.notifications
        const notificationIds = notifications.map((n) => n.id)

        await this.setState({ loadingGenStats: true })

        const stats = await this.insightsService.fetch(
            {
                entity: 'notifications',
                date_preset: 'lifetime',
                date_increment: 'lifetime',
                action_attribution: 'send_time',
                breakdowns: ['channel', 'notification'],
                fields: ['notification.id', 'deliveries', 'impressions', 'clicks', 'channel'],
                filters: [
                    {
                        field: 'domain.id',
                        operator: 'eq',
                        value: this.appState.currentDomain!.id,
                    },
                    {
                        field: 'notification.id',
                        operator: 'in',
                        value: notificationIds,
                    },
                    {
                        field: 'channel',
                        operator: 'in',
                        value: DeliveryChannel.getAllChannels(),
                    },
                ],
            },
            false,
            'ntd.getNotifGenStats',
        )

        let compositeStats

        if (stats) {
            compositeStats = {
                clicks: this.reduceStat('clicks', stats),
                deliveries: this.reduceStat('deliveries', stats),
                impressions: this.reduceStat('impressions', stats),
            }
        }

        this.setState(({ testStats }) => ({
            loadingGenStats: false,
            testStats: {
                ...testStats,
                composite: compositeStats ?? {},
                schedules: stats,
            },
        }))
    }

    @autobind
    private async refreshStats(): Promise<void> {
        this.preloadRequiredDataStates()
        this.getNotificationGenStats(this.state.test!.notifications)
        this.getNotificationBreakdownStats(this.state.test!.schedules)
    }

    private async getNotificationBreakdownStats(schedules?: NotificationScheduleDto[]): Promise<void> {
        const { test } = this.state
        const data: NotificationScheduleDto[] = schedules || test.schedules
        const notifIds = data.map((s) => s.notificationId)
        const compositeBreakdownStats = {}

        await this.setState({ loadingBreakdownStats: true })

        const rawStats = await this.insightsService.fetch(
            {
                entity: 'notifications',
                action_attribution: 'event_time',
                date_increment: 'lifetime', // statsType,
                date_preset: 'lifetime',
                breakdowns: ['domain', 'channel'],
                fields: ['deliveries', 'impressions', 'clicks', 'channel'],
                filters: [
                    { field: 'domain.id', operator: 'eq', value: this.state.domain.id },
                    { field: 'notification.id', operator: 'in', value: notifIds },
                    { field: 'channel', operator: 'in', value: DeliveryChannel.getAllChannels() },
                ],
            },
            false,
        )

        this.setState({ performanceChannel: Array.from(new Set(rawStats?.map((stat) => stat?.channel) ?? [])) })
        this.setState(({ testStats }) => ({
            loadingBreakdownStats: false,
            testStats: {
                ...testStats,
                compositeDaily: compositeBreakdownStats,
                rawStats,
            },
        }))
    }

    private async setDomainState(): Promise<void> {
        const globalDomainIsSet = !!this.globalDomain
        const localDomainIsUnset = globalDomainIsSet && !this.localDomain
        const localDomainIsMismatch =
            globalDomainIsSet && !!this.localDomain && this.localDomain.id !== this.globalDomain!.id

        if (localDomainIsMismatch) {
            this.appService.routeWithinDomain('/notifications')
        }

        if (localDomainIsUnset) {
            await this.setState({ domain: this.globalDomain! })
        }
    }

    private sortById<T>(arr: T[]): T[] {
        const result = clone(arr)
        result.sort((a: any, b: any) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))

        return result
    }

    private buildTestResult(name: string, _treatment: any, index: number): JSX.Element {
        const { testStats } = this.state
        const notification: any = this.sortById(this.state.test.notifications)[index]
        const schedule: any = this.sortById(this.state.test.schedules)[index]
        const stats = testStats.schedules.find(
            (s) => (s.notification || {}).id.toString() === notification.id.toString(),
        )
        const isWinner = notification.abTest.winner || false
        const isResult = !!notification.abTest.winnerOriginId

        const backgroundColor = {
            'Variant A': 'test-a-color',
            'Variant B': 'test-b-color',
            'Variant C': 'test-c-color',
            'Variant D': 'test-d-color',
            'Variant E': 'test-e-color',
        }[name]

        const buildScheduleDetailsUrl = (notificationId: string): string => {
            if (!notificationId) {
                return this.appService.routeWithin('domain', `/notifications`, true)
            }

            return this.appService.routeWithin('domain', `/notifications/${notificationId}`, true)
        }

        const handleSummaryTitleClick = (ev: React.MouseEvent<HTMLAnchorElement>, notificationId: string) => {
            this.stopPropagation(ev.nativeEvent)

            if (ev.metaKey) {
                window.open(buildScheduleDetailsUrl(notificationId))
                return
            }

            this.appService.route(buildScheduleDetailsUrl(notificationId))
        }

        const channelOptions = (notification.channels ?? notification.defaultTemplate.channels) as DeliveryChannel[]
        const npsCache = this.notificationPreviewState.getCache('domain', this.state.domain.id)
        const initialPlatform = npsCache.resolvePlatformFromAvailableChannels(channelOptions)
        const previewState = npsCache.state

        return (
            <NotificationSummaryCard
                key={index}
                mode="dark"
                className={backgroundColor}
                loading={this.state.loadingGenStats}
                title={
                    isWinner ? (
                        <>
                            <span>{name}</span>
                            <NotificationAbWinnerBadge />
                        </>
                    ) : isResult ? (
                        'Winning Notification'
                    ) : (
                        name
                    )
                }
                titleUrl={buildScheduleDetailsUrl(notification.id)}
                onTitleClick={(ev) => handleSummaryTitleClick(ev, notification.id)}
                showWeight={true}
                domain={this.state.domain}
                notification={{
                    ...extractSummaryNotification(notification),
                    abTest: notification.abTest,
                    statusId: StatusType[schedule.status].id,
                    schedule,
                }}
                channels={notification.channels ?? notification.defaultTemplate.channels}
                initialPlatform={initialPlatform}
                previewState={previewState}
                showScheduleDetails={this.state.test.isTimeVariant}
                stats={stats}
                hideFooter={true}
            />
        )
    }

    private extractNotificationTreatment(notification: NotificationDto): INotificationTreatment {
        return {
            id: (notification.id as any) || randomstring.generate(5),
            title: notification.defaultTemplate.title || undefined,
            body: notification.defaultTemplate.body || undefined,
            image: notification.defaultTemplate.imageUrl || undefined,
            icon: notification.defaultTemplate.iconUrl || undefined,
            badge: notification.defaultTemplate.badgeUrl || undefined,
            landingUrl: notification.defaultTemplate.landingUrl || undefined,
            landingUrlDisabled: !notification.defaultTemplate.landingUrl,
            isSilent: notification.defaultTemplate.isSilent,
            requireInteraction: notification.defaultTemplate.requireInteraction,
            keywords: notification.keywords,
        } as any
    }

    private stopPropagation(ev: Event): void {
        ev.preventDefault()
        ev.stopPropagation()
    }
}
