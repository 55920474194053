import * as React from 'react'
import { Cell as FDCell } from 'fixed-data-table-2'
import { numberWithCommas } from '../../../_utils/utils'
import moment from 'moment-timezone'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { SHORT_DATE_FORMAT } from '../../../constants'

export const LIVE_ACTIVITY_TABLE_COLUMN_DEFINITIONS = {
    ['DATE']: {
        selectable: false,
        className: 'r-record-date',
        width: 140,
        columnKey: 'event_date',
        title: (reportConfig: any, colConfig: any) => {
            return (
                <>
                    <span>Event Date</span>
                    <Tooltip title={<>The date the app message event took place in the domain's time zone.</>}>
                        <InfoCircleOutlined className="info-icon" />
                    </Tooltip>
                </>
            )
        },
        align: 'center',
        sortable: true,
        render(data: any, dataSet: any[], config: any) {
            const columnKey = 'event_date'
            let value = moment(data[columnKey]).format(SHORT_DATE_FORMAT)
            if (config.dateIncrement === 'MONTH') {
                value = moment(data[columnKey]).format('MMM, YYYY')
            } else if (config.dateIncrement === 'YEAR') {
                value = moment(data[columnKey]).format('YYYY')
            }
            return value
        },
    },
    ['ACTIVITY.ID']: {
        className: 'r-activity-id',
        width: 240,
        columnKey: 'activity.id',
        title: 'Activity ID',
        sortable: true,
        sortType: 'string',
    },
    ['STARTS']: {
        className: 'r-starts',
        width: 120,
        columnKey: 'starts',
        title: 'Starts',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.starts)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.starts).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['UPDATES_SENT']: {
        className: 'r-updates-sent',
        width: 120,
        columnKey: 'updates_sent',
        title: 'Updates Sent',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.updates_sent)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.updates_sent).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
}
