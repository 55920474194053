import React from 'react'
import './styles/distribution-manager.scss'
import { getClassNames } from '../../../_utils/classnames'
import { DatePicker, Form, Input, InputNumber, Popover, Radio, Select, Switch } from 'antd'
import { DistributionSlider } from '../../distribution-slider/distribution-slider'
import { NotificationTestConfigurationModel } from '../../../models/notification/notification-test-configuration.model'
import { getDefaultElapsedSchedule, getDefaultSpecificSchedule, getNormalizedDist } from '../helpers'
import { NdfTestType } from '../../notification-data-form/enums/ndf-test-type.enum'
import { DomainDto } from '../../../dtos/domain'
import { tryParseInt } from '../../../_utils/try-parse'
import { parseFloatTo } from '../../../_utils/math'
import { NdfTestScheduleType } from '../../notification-data-form/enums/ndf-test-schedule-type.enum'
import moment from 'moment-timezone'
import { Moment } from 'moment'
import { getSupportedTimeZonesWithAbbrs, parseDateTimeInTz } from '../../../_utils/moment'
import { RadioChangeEvent } from 'antd/lib/radio'
import { NotificationDeliveryModel } from '../../../models/notification/notification-delivery.model'
import { NotificationTestEvaluationScheduleModel } from '../../../models/notification/notification-test-evaluation-schedule.model'
import { InfoCircleOutlined } from '@ant-design/icons'
import { INotificationBuilderState } from '../interfaces/notification-builder-state.interface'
import { BetterTimePicker } from '@pushly/aqe/lib/components/better-timepicker/better-timepicker'

const applyTz = (model: NotificationTestEvaluationScheduleModel, tz: string) => {
    model.setEvaluationDateTimeZone(tz)

    const newDate = parseDateTimeInTz(model.getEvaluationDateUtc(), tz, true)
    if (newDate) {
        model.setEvaluationDateUtc(newDate.toISOString())
    }

    return model
}

const applyTime = (model: NotificationTestEvaluationScheduleModel, time: Moment | undefined, currTz: string) => {
    const now = moment()
    time = (!time ? now : time) as Moment

    const oldDate = parseDateTimeInTz(model.getEvaluationDateUtc() ?? now.toISOString(), currTz)
    if (oldDate) {
        oldDate.hour(time.hour())
        oldDate.minute(time.minute())
        model.setEvaluationDateUtc(oldDate.toISOString())
    }

    return model
}

interface ITestDistributionManager {
    className?: string
    domain: DomainDto
    loading?: boolean
    builder: INotificationBuilderState
    disabled?: boolean
    layout?: 'horizontal' | 'vertical' | 'compact'
    hideTitle?: boolean
    hideTypeSelection?: boolean
    disableSampling?: boolean
    disableSamplingTooltip?: string
    value: NotificationTestConfigurationModel
    delivery: NotificationDeliveryModel
    onChange?: (value: NotificationTestConfigurationModel) => any
}

const DEFAULT_CTR_THRESHOLD = 0.03

const DistributionManager = (props: ITestDistributionManager) => {
    const {
        className,
        domain,
        loading,
        builder,
        disabled,
        disableSampling,
        disableSamplingTooltip,
        hideTitle,
        hideTypeSelection,
        value,
        delivery,
        onChange,
    } = props

    const distMap = value.getDistributionMap()
    const totalDistributions = distMap?.length ?? 1
    const layout = props.layout === 'horizontal' ? 'h' : props.layout === 'compact' ? 'c' : 'v'
    const isSampleDistribution = value.getDistributionType()?.toLowerCase() === 'sample'
    const sampleSchedule = value.getEvaluationSchedule()
    const sampleScheduleType = sampleSchedule?.getType()
    const isElapsedSampleWinner = sampleScheduleType === NdfTestScheduleType.ELAPSED
    const isSpecificSampleWinner = sampleScheduleType === NdfTestScheduleType.SPECIFIC

    let workingCtrThreshold = value.getCtrThreshold()
    if (!!workingCtrThreshold && !isNaN(workingCtrThreshold)) {
        workingCtrThreshold = workingCtrThreshold * 100
    }
    const isNullOrUndefinedThreshold = workingCtrThreshold === null || workingCtrThreshold === undefined

    const handleCtrThresholdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const update = value.clone()

        const v = ev.target.value.replace(/[^0-9]/gi, '').trim()
        const threshold: any = !v ? v : parseFloatTo(tryParseInt(v) / 100, 2)

        update.setCtrThreshold(threshold)
        onChange?.(update)
    }

    const handleCtrThresholdToggle = (enabled) => {
        const update = value.clone()
        update.setCtrThreshold(enabled ? DEFAULT_CTR_THRESHOLD : undefined)
        onChange?.(update)
    }

    const SampleWrapper = disableSampling && !!disableSamplingTooltip ? Popover : ({ children }: any) => children

    const rcSelectProps: any = {
        dropdownAlign: {
            points: ['tr', 'br'],
        },
    }

    const currSampleTz = sampleSchedule?.getEvaluationDateTimeZone() ?? delivery.getTimeZone() ?? domain.timezone
    let currSendDateObj: Moment | null | undefined
    if (delivery.getSendDate()) {
        const currSendDateTz = delivery.getTimeZone() ?? domain.timezone
        currSendDateObj = parseDateTimeInTz(delivery.getSendDate(), currSendDateTz)
    }
    let currSampleDateObj: Moment | null | undefined
    if (sampleSchedule?.getEvaluationDateUtc()) {
        currSampleDateObj = parseDateTimeInTz(sampleSchedule!.getEvaluationDateUtc(), currSampleTz)
    }

    const defaultElapsedValue =
        sampleSchedule?.getElapsedSeconds() === undefined ? 1 : sampleSchedule!.getElapsedSeconds()! / 60 / 60

    return (
        <div className={getClassNames('distribution-manager', `layout-${layout}`, {})}>
            {!hideTitle && (
                <div className="distribution-manager-title">
                    <label>
                        <span>Distribution</span>
                        <Popover
                            overlayClassName={getClassNames('notification-builder-popover', 'distribtion-popover')}
                            content={
                                <>
                                    <p>
                                        <b>Split Delivery:</b> Each variation will be sent to a specified percentage of
                                        your audience.
                                    </p>
                                    <p>
                                        <b>Sample:</b> All variations in the test will be sent to a sample percentage of
                                        your audience with the remaining audience receiving the winning variation once
                                        it has been chosen.
                                    </p>
                                </>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </label>

                    {layout === 'c' && !hideTypeSelection && (
                        <div className="distribution-type-selection">
                            <Radio.Group
                                size="small"
                                buttonStyle="solid"
                                disabled={disabled}
                                value={value.getDistributionType()?.toUpperCase()}
                                onChange={(ev) => {
                                    const update = value.clone()
                                    const type = ev.target.value.toLowerCase()
                                    const isSplit = type === 'split'

                                    update.setDistributionType(type)
                                    update.setDistributionMap(getNormalizedDist(totalDistributions, isSplit ? 100 : 24))
                                    update.setCtrThreshold(undefined)

                                    if (isSplit) {
                                        update.setEvaluationSchedule(undefined)
                                    } else {
                                        update.setEvaluationSchedule(getDefaultElapsedSchedule())
                                    }

                                    onChange?.(update)
                                }}
                            >
                                <Radio.Button value={NdfTestType.SPLIT}>Split Delivery</Radio.Button>
                                <SampleWrapper
                                    overlayClassName={getClassNames(
                                        'notification-builder-popover',
                                        'sample-disabled-popover',
                                    )}
                                    content={disableSamplingTooltip}
                                >
                                    <Radio.Button value={NdfTestType.SAMPLE} disabled={disableSampling}>
                                        Sample
                                    </Radio.Button>
                                </SampleWrapper>
                            </Radio.Group>
                        </div>
                    )}
                </div>
            )}

            <div className="distribution-manager-content">
                {layout !== 'c' && !hideTypeSelection && (
                    <div className="distribution-type-selection">
                        <Radio.Group
                            size="small"
                            buttonStyle="solid"
                            disabled={disabled}
                            value={value.getDistributionType()?.toUpperCase()}
                            onChange={(ev) => {
                                const update = value.clone()
                                const type = ev.target.value.toLowerCase()
                                const isSplit = type === 'split'

                                update.setDistributionType(type)
                                update.setDistributionMap(getNormalizedDist(totalDistributions, isSplit ? 100 : 24))
                                update.setCtrThreshold(undefined)

                                if (isSplit) {
                                    update.setEvaluationSchedule(undefined)
                                } else {
                                    update.setEvaluationSchedule(getDefaultElapsedSchedule())
                                }

                                onChange?.(update)
                            }}
                        >
                            <Radio.Button value={NdfTestType.SPLIT}>Split Delivery</Radio.Button>
                            <SampleWrapper
                                overlayClassName={getClassNames(
                                    'notification-builder-popover',
                                    'sample-disabled-popover',
                                )}
                                content={disableSamplingTooltip}
                            >
                                <Radio.Button value={NdfTestType.SAMPLE} disabled={disableSampling}>
                                    Sample
                                </Radio.Button>
                            </SampleWrapper>
                        </Radio.Group>
                    </div>
                )}

                <DistributionSlider
                    type={(value.getDistributionType() as any) ?? 'split'}
                    totalDistributions={totalDistributions}
                    disabled={disabled}
                    legendLabel={<span>Distribution</span>}
                    value={distMap}
                    onChange={(v) => {
                        const update = value.clone()
                        update.setDistributionMap(v)

                        onChange?.(update)
                    }}
                    stepSize={1}
                    hideLegend={true}
                    numericLabels={true}
                    distributionStyles={['variant-a', 'variant-b', 'variant-c', 'variant-d']}
                />

                {isSampleDistribution && (
                    <div className="addt-sample-settings">
                        <div className={getClassNames('sample-schedule-builder')}>
                            <div className="switch-row sample-schedule-header">
                                <div className="switch-left">
                                    <span>Winner Strategy</span>
                                    <Popover
                                        overlayClassName={getClassNames(
                                            'notification-builder-popover',
                                            'elapsed-value-popover',
                                        )}
                                        content={
                                            <>
                                                <p>
                                                    <b>Elapsed:</b> The variation with the highest CTR will be sent to
                                                    the rest of the audience once the chosen number of hours has
                                                    elapsed.
                                                </p>
                                                <p>
                                                    <b>Specific:</b> The variation with the highest CTR will be sent to
                                                    the rest of the audience at the chosen date and time.
                                                </p>
                                            </>
                                        }
                                    >
                                        <InfoCircleOutlined className="info-icon" />
                                    </Popover>
                                </div>
                                <div className="switch-right">
                                    <Radio.Group
                                        size="small"
                                        buttonStyle="solid"
                                        disabled={disabled}
                                        onChange={(ev: RadioChangeEvent) => {
                                            const v = ev.target.value
                                            const update = value.clone()

                                            update.setEvaluationSchedule({
                                                ...(sampleSchedule ?? {}),
                                                type: v,
                                            } as any)

                                            if (v === NdfTestScheduleType.ELAPSED) {
                                                update.setEvaluationSchedule(getDefaultElapsedSchedule())
                                            } else {
                                                update.setEvaluationSchedule(
                                                    getDefaultSpecificSchedule(delivery, domain),
                                                )
                                            }

                                            onChange?.(update)
                                        }}
                                        value={sampleScheduleType}
                                    >
                                        <Radio.Button value={NdfTestScheduleType.ELAPSED}>Elapsed Time</Radio.Button>
                                        <Radio.Button value={NdfTestScheduleType.SPECIFIC}>Specific Time</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>

                            {isElapsedSampleWinner ? (
                                <Form.Item className="elapsed-value" label="After">
                                    <Input.Group size="small">
                                        <InputNumber
                                            size="small"
                                            type="number"
                                            disabled={disabled}
                                            value={defaultElapsedValue}
                                            onChange={(v: number) => {
                                                const update = value.clone()

                                                update?.setEvaluationSchedule({
                                                    ...(sampleSchedule ?? {}),
                                                    elapsed_seconds: (v ?? 0) * 60 * 60,
                                                } as any)

                                                onChange?.(update)
                                            }}
                                            max={100}
                                            min={1}
                                        />
                                        <span className="ant-input-group-addon">Hour(s)</span>
                                    </Input.Group>
                                </Form.Item>
                            ) : (
                                <div className="schedule-details">
                                    <DatePicker
                                        className="sample-schedule-date-picker"
                                        size="small"
                                        format="ddd, MMM DD, YYYY"
                                        placeholder="Send Date"
                                        disabled={disabled}
                                        disabledDate={(d) => {
                                            return (
                                                !!d &&
                                                d <= (currSendDateObj?.clone() ?? moment()).add(1, 'day').startOf('day')
                                            )
                                        }}
                                        // @ts-ignore
                                        value={currSampleDateObj}
                                        onChange={(v) => {
                                            const update = value.clone()
                                            const es = update.getEvaluationSchedule()!
                                            const tz = es.getEvaluationDateTimeZone() ?? domain.timezone

                                            const now = moment()
                                            v = v ?? now.clone().add(1, 'day')
                                            const oldDate = parseDateTimeInTz(currSampleDateObj?.toISOString(), tz) ?? v

                                            v!.set({
                                                hour: !currSampleDateObj ? now.hour() : oldDate.hour(),
                                                minute: !currSampleDateObj ? now.minute() : oldDate.minute(),
                                            })
                                            es.setEvaluationDateUtc(v!.toISOString())

                                            update.setEvaluationSchedule(es)

                                            onChange?.(update)
                                        }}
                                    />

                                    <BetterTimePicker
                                        className="sample-schedule-time-picker"
                                        size="small"
                                        format="h:mm a"
                                        use12Hours={true}
                                        placeholder="Time"
                                        disabled={disabled}
                                        // @ts-ignore
                                        value={currSampleDateObj}
                                        onTimeChange={(v) => {
                                            const update = value.clone()
                                            const es = update.getEvaluationSchedule()!

                                            applyTime(es, v ?? undefined, currSampleTz)
                                            update.setEvaluationSchedule(es)

                                            onChange?.(update)
                                        }}
                                    />

                                    <Select<string>
                                        {...rcSelectProps}
                                        className="sample-schedule-tz-picker"
                                        dropdownClassName={getClassNames('sample-schedule-tz-picker-overlay')}
                                        size="small"
                                        disabled={disabled}
                                        showSearch={true}
                                        placeholder="Select a time zone"
                                        value={currSampleTz}
                                        optionLabelProp="title"
                                        onChange={(tz) => {
                                            const update = value.clone()
                                            const es = update.getEvaluationSchedule()!

                                            applyTz(es, tz)
                                            update.setEvaluationSchedule(es)

                                            onChange?.(update)
                                        }}
                                    >
                                        {getSupportedTimeZonesWithAbbrs().map((tz) => (
                                            <Select.Option key={tz.zone} value={tz.zone} title={tz.abbr}>
                                                {tz.zone}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            )}
                        </div>

                        <div className="switch-row">
                            <div className="switch-left">
                                <Switch
                                    size="small"
                                    checked={!isNullOrUndefinedThreshold}
                                    onChange={handleCtrThresholdToggle}
                                />
                            </div>
                            <div className="switch-right">
                                <div className="switch-row ctr-threshold-input-wrapper">
                                    <div className="switch-left">
                                        <span>CTR Minimum </span>
                                        <Popover
                                            overlayClassName={getClassNames(
                                                'notification-builder-popover',
                                                'ctr-constraint-popover',
                                            )}
                                            content={
                                                <span>
                                                    When a Sample Test has a performance constraint the winning variant
                                                    will only be sent if it meets or exceeds the specified CTR.
                                                </span>
                                            }
                                        >
                                            <InfoCircleOutlined className="info-icon" />
                                        </Popover>
                                    </div>
                                    <div className="switch-right">
                                        {!isNullOrUndefinedThreshold && (
                                            <Input
                                                className="ctr-threshold-input"
                                                size="small"
                                                addonAfter="%"
                                                value={workingCtrThreshold}
                                                onChange={handleCtrThresholdChange}
                                                maxLength={2}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DistributionManager
