import { AppMessageConditionType } from '@pushly/models/lib/enums/app-messages/app-message-condition-type'
import { convertSeconds } from '../../../_utils/time'
import { AppMessageConditions } from '@pushly/models/lib/structs/app-messages/app-message-conditions'
import * as React from 'react'

type AMDeliveryConditionSlugProps = {
    conditions: AppMessageConditions
}

export const AppMessageDisplayConditionSlug = (props: AMDeliveryConditionSlugProps) => {
    const conditions = props.conditions

    const type = conditions.type
    let slug
    if (type === AppMessageConditionType.SESSION_START) {
        slug = 'On session start'
    } else if (type === AppMessageConditionType.SESSION_TIME_IN_APP) {
        const { intervalSeconds, displayMetric: metric } = conditions.timeInApp!
        const convertedTime = convertSeconds(intervalSeconds, metric)
        const displayMetric = convertedTime === 1 ? metric.toLowerCase().slice(0, -1) : metric.toLowerCase()
        slug = `After ${convertedTime} ${displayMetric} in session`
    } else if (type === AppMessageConditionType.LIFETIME_TIME_IN_APP) {
        const { intervalSeconds, displayMetric: metric } = conditions.timeInApp!
        const convertedTime = convertSeconds(intervalSeconds, metric)
        const displayMetric = convertedTime === 1 ? metric.toLowerCase().slice(0, -1) : metric.toLowerCase()
        slug = `After ${convertedTime} ${displayMetric} in app lifetime`
    } else {
        const customTrigger = conditions.triggers
        slug = `After custom trigger has been met: '${customTrigger.key} = ${customTrigger.value}'`
    }

    return (
        <div>
            <span className="display-conditions-slug">
                <b>Display</b> {slug}
            </span>
        </div>
    )
}
