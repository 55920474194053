import * as React from 'react'
import { useCallback, useRef } from 'react'
import { useActiveDomainChangeEffect } from '../../../hooks/use-active-domain-change-effect'
import { useHistory, useParams } from 'react-router-dom'
import { parseQueryStringParams } from '../../../_utils/query-string'
import { AppState } from '../../../stores/app'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService } from '../../../services'
import titleCase from 'title-case'
import { AppMessageBuilderContext } from '../context'
import { useAppMessageReducer } from '../../../components/app-message-builder/reducer'
import '../../../components/app-message-builder/styles/app-message-builder.scss'
import { getClassNames } from '../../../_utils/classnames'
import { simpleNotification } from '@pushly/aqe/lib/utils'
import { AppMessageTemplateService } from '../../../services/app-message-template.service'
import { onResponseError403 } from '../../../_utils/on-response-error-403'
import { Alert, Skeleton } from 'antd'
import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import { getAMPreviewConfig, cleanAndValidateMessage, cleanAndValidateTemplate } from './helpers'
import { DeliveryChannelSelector } from '@pushly/aqe/lib/components/delivery-channel-selector/delivery-channel-selector'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { AppMessagePreview } from '../../../components/app-message-builder/elements/app-message-preview'
import { tryParseInt } from '../../../_utils/try-parse'
import { PageHeader, Well } from '@pushly/aqe/lib/components'
import { AppMessageBuilderAside } from '../../../components/app-message-builder/elements/app-message-builder-aside'
import { AppMessageBuilderMain } from '../../../components/app-message-builder/elements/app-message-builder-main'
import { AppMessagesLoader } from '../../../components/app-message-builder/app-messages-loader'
import { AppMessageDetailsBuilder } from '../../../components/app-message-builder/elements/app-message-details-builder'
import { AppMessageContentBuilder } from '../../../components/app-message-builder/elements/app-message-content-builder'
import { AppMessageConditionsBuilder } from '../../../components/app-message-builder/elements/app-message-conditions-builder'
import { AppMessageBehaviorBuilder } from '../../../components/app-message-builder/elements/app-message-behavior-builder'
import { AppMessageScheduleBuilder } from '../../../components/app-message-builder/elements/app-message-schedule-builder'
import { AppMessageAudienceBuilder } from '../../../components/app-message-builder/elements/app-message-audience-builder'
import { AppMessageService } from '../../../services/app-message.service'
import { AppMessageBuilderPayload } from '../../../components/app-message-builder/model-extensions'
import { AppMessageBuilderReach } from '../../../components/app-message-builder/elements/app-message-builder-reach'
import { InvalidFieldException } from '@pushly/models/lib/exceptions/invalid-field-exception'
import { MissingFieldException } from '@pushly/models/lib/exceptions/missing-field-exception'
import { extractFriendlyErrorMessage } from '../../../exceptions/helpers'
import { AppMessageBuilderFieldNamesMap } from './constants'
import { EditorActionRef } from '../../../components/custom-quill-editor/types'
import { Position } from '@pushly/models/lib/enums/position'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import randomstring from 'randomstring'

export const BuildAppMessage = () => {
    const appState = useService(AppState)
    const appSvc = useService(AppService)
    const appMsgSvc = useService(AppMessageService)
    const appMsgTemplateSvc = useService(AppMessageTemplateService)
    const domain = appState.currentDomain!

    // Component uses url pathname to determine what view and mode we are in
    const pathCrumbs = location.pathname.split('/app-messages/')[1]
    const type = pathCrumbs.includes('templates') ? 'template' : 'message'
    let mode: 'create' | 'edit' = pathCrumbs.includes('new') ? 'create' : 'edit'
    const viewRoute = `/app-messages${type === 'template' ? '/templates' : ''}`

    const { push: updateRoute } = useHistory()
    const qs = parseQueryStringParams()

    let requestedAppMsgId
    let requestedTemplateId

    // create should attempt to parse ids to load if duplicate
    if (mode === 'create') {
        requestedAppMsgId = tryParseInt(qs.get('app_msg_id') ?? '') || undefined
        requestedTemplateId = tryParseInt(qs.get('template_id') ?? '') || undefined
    } else {
        const { appMessageId } = useParams<{ appMessageId: string }>()
        requestedAppMsgId = tryParseInt(appMessageId) || undefined

        const { appMessageTemplateId } = useParams<{ appMessageTemplateId: string }>()
        requestedTemplateId = tryParseInt(appMessageTemplateId) || undefined
    }

    const bodyRef = useRef<EditorActionRef>(null)

    const [state, dispatch] = useAppMessageReducer({ domain, mode, type, defaultSelectedId: requestedTemplateId })
    const selectedVariant = state.variants[state.selectedVariantIdx]
    const channels = selectedVariant.template!.channels

    useActiveDomainChangeEffect((activeDomain, appService) => {
        appService.routeWithinDomain(viewRoute)
    })

    const goBackToAppMessages = () => {
        updateRoute(`/domains/${domain.id}${viewRoute}`)
    }

    const goBackToPreviousTab = () => {
        appSvc.routeBack()
    }

    const enabledChannels = getEnabledDeliveryChannels(domain).filter((ch) => ch !== DeliveryChannel.WEB)
    let channelTargetDisabled: DeliveryChannel | undefined
    if (
        selectedVariant.template!.channels.length === 1 &&
        !enabledChannels.includes(selectedVariant.template!.channels[0])
    ) {
        channelTargetDisabled = selectedVariant.template!.channels[0]
    }

    /**
     * ***
     * all internal user roles are prohibited from sending on non-demo|internal domains
     * ***
     */
    const submitAllowed =
        !domain?.isPreviewDomain &&
        (!appState.currentUser?.isInternalUser || domain?.isDemoDomain || domain?.isInternal)

    const submitText = state.type === 'template' ? (state.mode === 'create' ? 'Create' : 'Update Template') : 'Schedule'

    const submitProps: Well['props'] = {
        hideFooter: false,
        disableSubmit: !submitAllowed,
        onSubmit: async () => {
            let saved: boolean = false
            try {
                if (state.type === 'template') {
                    let valid: AppMessageUiTemplate | undefined
                    valid = await cleanAndValidateTemplate(state, selectedVariant.template!, bodyRef)

                    if (valid) {
                        let response
                        if (mode === 'create') {
                            response = await appMsgTemplateSvc.create(domain.id, valid, {
                                cancellationKey: `am-template-post`,
                            })
                        } else {
                            response = await appMsgTemplateSvc.put(domain.id, requestedTemplateId, valid, {
                                cancellationKey: `am-template-put${requestedTemplateId}`,
                            })
                        }

                        saved = response.ok
                    }
                } else {
                    let valid: AppMessageBuilderPayload = await cleanAndValidateMessage(state, selectedVariant, bodyRef)

                    if (valid) {
                        let response
                        if (mode === 'create') {
                            response = await appMsgSvc.create(domain.id, valid, {
                                cancellationKey: `am-post`,
                            })
                        } else {
                            response = await appMsgSvc.patch(domain.id, requestedAppMsgId, valid, {
                                cancellationKey: `am-patch`,
                            })
                        }

                        saved = response.ok
                    }
                }
            } catch (error) {
                if (error instanceof InvalidFieldException || error instanceof MissingFieldException) {
                    error = extractFriendlyErrorMessage(error, {
                        rootModel: error.context.constructor,
                        fieldNamesMap: AppMessageBuilderFieldNamesMap,
                        startWithProperty: true,
                    })
                }

                simpleNotification('error', error.message.replace('[object Object]', ''))
            }

            if (saved) {
                goBackToAppMessages()
            }
        },
        onCancel() {
            goBackToPreviousTab()
        },
        submitText,
    }

    const previewConfig: AppMessageUiTemplate = getAMPreviewConfig(selectedVariant.template!)

    const handleSendIntegrationLinkClick = useCallback(() => {
        const historyState = {
            containerId: 'send-integrations',
            returnTo: window.location.pathname,
        }

        appSvc.routeWithinDomain('#settings', false, historyState)
    }, [])

    return (
        <>
            <PageHeader
                title={`${titleCase(mode)} App Message${type === 'template' ? ' Template' : ''}`}
                onTitleSet={appSvc.customizeTabTitle}
            />
            <div className={getClassNames('app-message-builder')}>
                <AppMessageBuilderContext.Provider
                    value={{ state: { ...state, disabled: !!channelTargetDisabled }, dispatch }}
                >
                    <AppMessagesLoader
                        domainId={domain.id}
                        appMsgId={requestedAppMsgId}
                        channels={channels}
                        errorHandler={onResponseError403(() => {
                            goBackToAppMessages()
                        })}
                    />
                    <AppMessageBuilderAside>
                        {state.type === 'message' && <AppMessageBuilderReach domain={domain} />}
                        <AppMessagePreview
                            template={previewConfig}
                            loading={state.loading ? <Loading3QuartersOutlined spin={true} /> : false}
                        />
                    </AppMessageBuilderAside>

                    {state.loading ? (
                        <Skeleton active={true} loading={true} />
                    ) : (
                        <AppMessageBuilderMain>
                            {channelTargetDisabled && (
                                <Alert
                                    className="app-message-builder-alert"
                                    type="warning"
                                    showIcon={true}
                                    message=""
                                    description={
                                        <span>
                                            This App Message is configured to target the Native&nbsp;
                                            {DeliveryChannel.getShortName(channelTargetDisabled)} Send Integration which
                                            is currently not enabled. Please enable the&nbsp;
                                            <a onClick={handleSendIntegrationLinkClick}>Send Integration</a> before
                                            attempting to edit.
                                        </span>
                                    }
                                />
                            )}
                            <DeliveryChannelSelector
                                type="multiple"
                                disabled={state.disabled || !!channelTargetDisabled}
                                onChange={(ch) =>
                                    dispatch({
                                        type: 'patch',
                                        entity: 'template',
                                        data: selectedVariant.template!.clone({
                                            channels: ch,
                                        }),
                                    })
                                }
                                visibleChannels={getEnabledDeliveryChannels(domain).filter(
                                    (ch) => ch !== DeliveryChannel.WEB,
                                )}
                                value={selectedVariant.template!.channels}
                            />
                            <AppMessageDetailsBuilder domain={domain} />
                            {state.type === 'message' && <AppMessageAudienceBuilder domain={domain} />}
                            <AppMessageContentBuilder
                                domain={domain}
                                submitProps={state.type === 'template' ? { ...submitProps } : {}}
                                ref={bodyRef}
                            />

                            {state.type === 'message' && (
                                <>
                                    <AppMessageConditionsBuilder />
                                    <AppMessageBehaviorBuilder />
                                    <AppMessageScheduleBuilder
                                        submitProps={state.type === 'message' ? { ...submitProps } : {}}
                                    />
                                </>
                            )}
                        </AppMessageBuilderMain>
                    )}
                </AppMessageBuilderContext.Provider>
            </div>
        </>
    )
}
