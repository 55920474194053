import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { AppMessageTemplateStyle } from '@pushly/models/lib/enums/app-messages/app-message-template-style'
import { Card, Popover, Radio, Skeleton, Switch } from 'antd'
import titleCase from 'title-case'
import { getClassNames } from '../../../_utils/classnames'
import { BgColorsOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import TemplateMediaField from '../../notification-builder/elements/template-fields/media-field'
import { CtaButtonType } from '../types'
import { Position } from '@pushly/models/lib/enums/position'
import { AppMessageImageSize } from '@pushly/models/lib/enums/app-messages/app-message-image-size'
import 'react-quill/dist/quill.snow.css'
import { TemplatizedTextFieldWithColor } from '../../templatized-text-field-with-color/templatized-text-field-with-color'
import { AppMessageTemplateImageConfig } from '@pushly/models/lib/structs/app-messages/app-message-template-image-config'
import { AppMessageTemplateButtonConfig } from '@pushly/models/lib/structs/app-messages/app-message-template-button-config'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { getCustomMacroFields } from '../../../_utils/domain'
import { LayoutOrientation } from '@pushly/models/lib/enums/layout-orientation'
import { AppMessageCtasBuilder } from './app-message-ctas-builder'
import './styles/app-message-content-builder.scss'
import { useAppMessageBuilderContext } from '../../../features/app-messages/context'
import { DomainDto } from '../../../dtos/domain'
import { CustomQuillEditor } from '../../custom-quill-editor/custom-quill-editor'
import { MutableAppMessageTemplateBannerPosition, MutableTogglableTextField } from '@pushly/models/lib/structs'
import { InputFocusRef } from '../../templatized-text-field/templatized-text-field'
import { EditorActionRef } from '../../custom-quill-editor/types'
import { enumAsSelectOptions } from '../../../_utils/antd'
import { headerPlaceholder, titlePlaceholder } from '../../../features/app-messages/build-app-message/constants'
import { DEFAULT_TOGGLEABLE_FG_COLOR } from '../constants'
import { Section } from './wrappers'
import { AppMessageActionStyle } from '@pushly/models/lib/enums/app-messages/app-message-action-style'
import { ConditionalTooltip } from '../../conditional-popover/conditional-popover'

type AppMessageContentBuilderProps = {
    domain: DomainDto
    submitProps: Well['props']
    onValidate?: () => void
}

export const AppMessageContentBuilder = forwardRef<EditorActionRef, AppMessageContentBuilderProps>((props, ref) => {
    const { domain } = props

    const [context, dispatch] = useAppMessageBuilderContext()
    const { variants, selectedVariantIdx, availableTemplates, selectedTemplateId } = context

    const titleRef = useRef<InputFocusRef>(null)
    const headerRef = useRef<InputFocusRef>(null)
    const bodyRef = ref ?? useRef<EditorActionRef>(null)

    // internal working value state ensures clean render of children
    const workingValue = variants[selectedVariantIdx].template!

    const [selectedStyle, setSelectedStyle] = useState<AppMessageTemplateStyle>(
        workingValue.style ?? AppMessageTemplateStyle.MODAL,
    )

    // dirty fields track original state versus when a user has manually interacted via controlled form field
    // necessary for fields that can be removed from view/form via style options
    const [dirtyFieldsEnabled, setDirtyFieldsEnabled] = useState<{ [key: string]: boolean }>({
        title: workingValue.title.enabled,
        header: workingValue.header.enabled,
        ctaPrimary: workingValue.ctaPrimary.enabled,
        ctaSecondary: workingValue.ctaSecondary.enabled,
    })

    useEffect(() => {
        setSelectedStyle(workingValue.style ?? AppMessageTemplateStyle.MODAL)
    }, [workingValue.style])

    const [customMacros, setCustomMacros] = React.useState()

    useEffect(() => {
        const getMacros = async () => {
            const customMacroItems = await getCustomMacroFields(props.domain.id)
            setCustomMacros(customMacroItems)
        }

        getMacros()
    }, [])

    // -------------------------- Value Handlers -------------------------- \\
    const onChange = (change: Partial<AppMessageUiTemplate>) => {
        dispatch({ type: 'patch', entity: 'template', data: change })
    }

    const handleTemplateImageUpdate = (change: Partial<AppMessageTemplateImageConfig>) => {
        const image = workingValue.image.clone({ ...change })
        onChange({ image })
    }

    const handleTemplateTextFieldUpdate = (
        change: Partial<MutableTogglableTextField>,
        property: 'title' | 'header' | 'body',
    ) => {
        // when enabling a field from duplicate/edit fgColor may not be seeded
        if (change.enabled && !workingValue[property].enabled) {
            change.fgColor = workingValue[property].fgColor ?? DEFAULT_TOGGLEABLE_FG_COLOR
        }
        const update = workingValue[property!].clone({ ...change })
        onChange({ [property as string]: update })
    }

    const handleCtaFieldUpdate = (change: Partial<AppMessageTemplateButtonConfig>, action: CtaButtonType) => {
        const ctaKey = action === CtaButtonType.PRIMARY ? 'ctaPrimary' : 'ctaSecondary'

        const cta = workingValue[ctaKey].clone(change)
        onChange({ [ctaKey]: cta })
    }

    return (
        <Well
            className={getClassNames('app-message-content-builder', 'nested')}
            title="Appearance"
            hideFooter={true}
            loading={context.loading}
            {...props.submitProps}
        >
            <Section title="Style">
                <div
                    className={getClassNames('app-message-style-options', {
                        disabled: context.disabled,
                    })}
                >
                    {Object.values(AppMessageTemplateStyle)
                        .filter((s) => s !== AppMessageTemplateStyle.CUSTOM)
                        .map((style) => {
                            return (
                                <div
                                    key={style}
                                    onClick={() => {
                                        if (context.disabled) {
                                            return
                                        }
                                        setSelectedStyle(style)
                                        workingValue.style = style

                                        // always reset close action to false to ensure no accidental save and display
                                        // on a different type in SDK
                                        workingValue.showCloseAction = false

                                        const image = workingValue.image.toMutable()
                                        const ctaPrimary = workingValue.ctaPrimary.toMutable()
                                        const ctaSecondary = workingValue.ctaSecondary.toMutable()
                                        const title = workingValue.title.toMutable()
                                        const header = workingValue.header.toMutable()
                                        if (workingValue.style !== AppMessageTemplateStyle.BANNER) {
                                            workingValue.bannerPosition = null
                                            image.position = Position.TOP

                                            title.enabled = dirtyFieldsEnabled.title
                                            header.enabled = dirtyFieldsEnabled.header
                                            ctaPrimary.enabled = dirtyFieldsEnabled.ctaPrimary
                                            ctaSecondary.enabled = dirtyFieldsEnabled.ctaSecondary
                                        } else {
                                            workingValue.bannerPosition = new MutableAppMessageTemplateBannerPosition()
                                            workingValue.bannerPosition.mobile = Position.TOP
                                            workingValue.bannerPosition.tablet = Position.TOP

                                            title.enabled = false
                                            header.enabled = false
                                            image.position = Position.LEFT
                                            ctaPrimary.enabled = dirtyFieldsEnabled.ctaPrimary
                                            ctaPrimary.style = AppMessageActionStyle.TAB
                                            ctaSecondary.enabled = false
                                        }

                                        onChange({ ...workingValue, image, ctaPrimary, ctaSecondary, title, header })
                                    }}
                                >
                                    <Card
                                        className={getClassNames(null, `app-message-style-${style.toLowerCase()}`, {
                                            selected: style === selectedStyle,
                                            disabled: context.disabled,
                                        })}
                                    >
                                        <Card.Meta title={titleCase(style)} />
                                    </Card>
                                </div>
                            )
                        })}
                </div>
            </Section>

            <Section title="General">
                <TemplatizedTextFieldWithColor
                    disabled={context.disabled}
                    className={getClassNames(null, 'app-message-bg', 'color-field')}
                    label="Background Color"
                    placeholder="Background Color"
                    onColorChange={(color) => onChange({ bgColor: color })}
                    colorValue={workingValue.bgColor ?? '#FFFFFF'}
                    colorIcon={<BgColorsOutlined />}
                    colorTitle={'Background Color'}
                    hideMacros={true}
                    hideEmojis={true}
                />

                {workingValue.style === AppMessageTemplateStyle.FULLSCREEN && (
                    <div className="switch-row">
                        <label>
                            <Switch
                                size="small"
                                disabled={context.disabled}
                                checked={workingValue.showCloseAction}
                                onChange={(enabled) => onChange({ showCloseAction: enabled })}
                            />
                            <span className="label-with-component-l">Close Button</span>
                        </label>
                        <Popover
                            overlayClassName={getClassNames('app-message-builder-popover', 'close-btn-popover')}
                            content={
                                <span>
                                    When enabled, a dedicated close button will be added in the top right corner of the
                                    app message.
                                </span>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </div>
                )}

                {workingValue.style === AppMessageTemplateStyle.BANNER && (
                    <div className="theme-banner-position">
                        <label>
                            <div className="theme-banner-position-label">
                                <span>Position</span>
                            </div>
                            <Radio.Group
                                disabled={context.disabled}
                                buttonStyle="solid"
                                className="banner-position-group"
                                onChange={(ev) => {
                                    onChange({
                                        bannerPosition: workingValue.bannerPosition?.clone({
                                            mobile: ev.target.value,
                                            tablet: ev.target.value,
                                        }),
                                    })
                                }}
                                size="small"
                                value={workingValue.bannerPosition?.mobile ?? Position.TOP}
                            >
                                {[Position.TOP, Position.BOTTOM].map((p) => (
                                    <Radio.Button key={p} value={Position[p]}>
                                        {titleCase(p)}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </label>
                    </div>
                )}
            </Section>

            <Section title="Content">
                <div className="image-fields">
                    <TemplateMediaField
                        key={workingValue.image.enabled ? 'enabled' : 'disabled'}
                        disabled={!workingValue.image?.enabled || context.disabled}
                        label={
                            <div className="switch-row">
                                <label>
                                    <Switch
                                        disabled={context.disabled}
                                        size="small"
                                        onChange={(enabled) => {
                                            const image = workingValue.image.toMutable()
                                            image.enabled = enabled

                                            // set default properties via form control
                                            image.position =
                                                workingValue.style === AppMessageTemplateStyle.BANNER
                                                    ? Position.LEFT
                                                    : Position.TOP
                                            image.size = AppMessageImageSize.COVER

                                            onChange({ image })
                                        }}
                                        checked={workingValue.image.enabled}
                                    />
                                    <span className="label-with-component-l">Image</span>
                                    <Popover
                                        overlayClassName={getClassNames(
                                            'notification-builder-popover',
                                            'image-popover',
                                        )}
                                        content={
                                            <>
                                                <p>
                                                    This image will be displayed in the specified position and fit
                                                    selected within the boundaries of the app message.
                                                </p>
                                            </>
                                        }
                                    >
                                        <InfoCircleOutlined className="info-icon" />
                                    </Popover>
                                </label>
                            </div>
                        }
                        className="image-upload"
                        domainId={domain.id}
                        defaultDomainMediaUrl={domain.defaultIconUrl ?? undefined}
                        accountId={domain.accountId}
                        value={workingValue.image.source}
                        onChange={(v) => {
                            handleTemplateImageUpdate({ source: v ?? undefined })
                        }}
                        quality={70}
                        pjpg={true}
                        forceCrop={workingValue.style === AppMessageTemplateStyle.BANNER}
                        cropRatio={workingValue.style === AppMessageTemplateStyle.BANNER ? 1 : null}
                        size="large"
                        hideMacroToggle={true}
                    />

                    <div className="image-properties">
                        <div className="image-property-position">
                            <label>
                                <div className="image-property-label">
                                    <span>Position</span>
                                </div>
                                <ConditionalTooltip
                                    title="Banner app message images are locked to a left-aligned position when used with the primary button."
                                    condition={
                                        workingValue.image.enabled &&
                                        selectedStyle === AppMessageTemplateStyle.BANNER &&
                                        workingValue.ctaPrimary.enabled
                                    }
                                >
                                    <Radio.Group
                                        buttonStyle="solid"
                                        className="position-group"
                                        disabled={
                                            !workingValue.image.enabled ||
                                            context.disabled ||
                                            (selectedStyle === AppMessageTemplateStyle.BANNER &&
                                                workingValue.ctaPrimary.enabled)
                                        }
                                        onChange={(ev) => {
                                            handleTemplateImageUpdate({ position: ev.target.value })
                                        }}
                                        size="small"
                                        value={workingValue.image.position ?? Position.TOP}
                                    >
                                        {enumAsSelectOptions(Position)
                                            .filter((opt) => {
                                                return workingValue.style === AppMessageTemplateStyle.BANNER
                                                    ? opt.value !== Position.TOP &&
                                                          opt.value !== Position.BOTTOM &&
                                                          opt.value !== Position.BACKGROUND
                                                    : opt.value !== Position.LEFT &&
                                                          opt.value !== Position.RIGHT &&
                                                          opt.value !== Position.BACKGROUND
                                            })
                                            .map((p) => (
                                                <Radio.Button key={p.value} value={p.value}>
                                                    {titleCase(p.label)}
                                                </Radio.Button>
                                            ))}
                                    </Radio.Group>
                                </ConditionalTooltip>
                            </label>
                        </div>

                        <div className="image-property-fit">
                            <label>
                                <div>
                                    <span>Fit</span>
                                </div>
                                <Radio.Group
                                    buttonStyle="solid"
                                    disabled={!workingValue.image.enabled || context.disabled}
                                    onChange={(ev) => {
                                        handleTemplateImageUpdate({ size: ev.target.value })
                                    }}
                                    size="small"
                                    value={workingValue.image.size ?? AppMessageImageSize.COVER}
                                >
                                    {Object.keys(AppMessageImageSize).map((size) => (
                                        <Radio.Button key={size} value={AppMessageImageSize[size]}>
                                            {titleCase(size)}
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            </label>
                        </div>
                    </div>
                </div>

                {selectedStyle !== AppMessageTemplateStyle.BANNER && (
                    <>
                        <div className="switch-row">
                            <label>
                                <Switch
                                    disabled={context.disabled}
                                    size="small"
                                    onChange={(enabled) => {
                                        handleTemplateTextFieldUpdate({ enabled }, 'header')
                                        setDirtyFieldsEnabled((currentState) => ({ ...currentState, header: enabled }))
                                        if (headerRef.current) {
                                            headerRef.current.focusInput()
                                        }
                                    }}
                                    checked={workingValue.header.enabled}
                                />
                                <span className="label-with-component-l">Header</span>
                            </label>
                        </div>

                        <TemplatizedTextFieldWithColor
                            ref={headerRef}
                            className="app-message-header"
                            disabled={!workingValue.header.enabled || context.disabled}
                            placeholder={headerPlaceholder}
                            limits={{ max: 55, maxConstraint: true, messageOverride: '' }}
                            colorValue={workingValue.header.fgColor ?? DEFAULT_TOGGLEABLE_FG_COLOR}
                            fieldValue={workingValue.header.value}
                            onColorChange={(color) => {
                                handleTemplateTextFieldUpdate({ fgColor: color }, 'header')
                            }}
                            onFieldChange={(field) => {
                                handleTemplateTextFieldUpdate({ value: field }, 'header')
                            }}
                        />

                        <div className="switch-row">
                            <label>
                                <Switch
                                    disabled={context.disabled}
                                    size="small"
                                    checked={workingValue.title.enabled}
                                    onChange={(enabled) => {
                                        handleTemplateTextFieldUpdate({ enabled }, 'title')
                                        setDirtyFieldsEnabled((currentState) => ({ ...currentState, title: enabled }))
                                        if (titleRef.current) {
                                            titleRef.current.focusInput()
                                        }
                                    }}
                                />
                                <span className="label-with-component-l">Title</span>
                            </label>
                        </div>
                        <TemplatizedTextFieldWithColor
                            ref={titleRef}
                            className="app-message-title"
                            disabled={!workingValue.title.enabled || context.disabled}
                            placeholder={titlePlaceholder}
                            limits={{ max: 65, maxConstraint: true, messageOverride: '' }}
                            colorValue={workingValue.title.fgColor ?? DEFAULT_TOGGLEABLE_FG_COLOR}
                            fieldValue={workingValue.title.value}
                            onColorChange={(color) => {
                                handleTemplateTextFieldUpdate({ fgColor: color }, 'title')
                            }}
                            onFieldChange={(field) => {
                                handleTemplateTextFieldUpdate({ value: field }, 'title')
                            }}
                        />
                    </>
                )}

                <div className="app-message-body">
                    <div className="switch-row">
                        <label>
                            <Switch
                                disabled={context.disabled}
                                size="small"
                                onChange={(enabled) => {
                                    handleTemplateTextFieldUpdate({ enabled }, 'body')
                                    if (ref && (ref as any).current) {
                                        ;(ref as any).current.focusEditor?.()
                                    }
                                }}
                                checked={workingValue.body.enabled}
                            />
                            <span className="label-with-component-l">Body</span>
                        </label>
                    </div>
                    <CustomQuillEditor
                        ref={bodyRef}
                        key={context.selectedTemplateId ?? 'q-editor'}
                        value={workingValue.body.value}
                        disabled={!workingValue.body.enabled || context.disabled}
                        onChange={(change) => handleTemplateTextFieldUpdate({ value: change }, 'body')}
                        formats={[
                            'bold',
                            'italic',
                            'strike',
                            'list',
                            'bullet',
                            'indent',
                            'align',
                            'direction',
                            'color',
                            'emojis',
                        ]}
                        macros={customMacros}
                        contentEditableProps={{
                            backgroundColor: workingValue.bgColor,
                        }}
                    />
                </div>
            </Section>

            <Section title="Buttons" className="app-message-buttons">
                {selectedStyle !== AppMessageTemplateStyle.BANNER && (
                    <div className="buttons-layout">
                        <label>
                            <span className="label-with-component-r">Button Layout</span>
                            <Radio.Group
                                disabled={context.disabled}
                                buttonStyle="solid"
                                className="button-layout"
                                onChange={(ev) => {
                                    onChange({ ctaLayout: ev.target.value })
                                }}
                                size="small"
                                defaultValue={workingValue.ctaLayout ?? LayoutOrientation.VERTICAL}
                            >
                                {Object.values(LayoutOrientation).map((o) => (
                                    <Radio.Button key={o} value={LayoutOrientation[o]}>
                                        {titleCase(o)}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </label>
                    </div>
                )}

                <AppMessageCtasBuilder
                    value={workingValue}
                    onChange={(change, action) => {
                        handleCtaFieldUpdate(change, action)

                        const field = action === 'PRIMARY' ? 'ctaPrimary' : 'ctaSecondary'
                        if ('enabled' in change) {
                            setDirtyFieldsEnabled((current) => ({
                                ...current,
                                [field]: change.enabled!,
                            }))
                        }
                    }}
                    disabled={context.disabled}
                />
            </Section>
        </Well>
    )
})
