import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { Skeleton } from 'antd'
import TemplatizedTextField from '@pushly/aqe/lib/components/templatized-text-field/templatized-text-field'
import { TemplateDetails } from '../../../components/notification-builder/interfaces/notification-builder-state.interface'

// tslint:disable-next-line:interface-name
interface TemplateDetailsBuilderProps {
    loading: boolean
    value: TemplateDetails
    onChange: (change: TemplateDetails) => void
}

export const TemplateDetailsBuilder = (props) => {
    const { loading, value, onChange } = props

    const name = value.name
    const description = value.description

    return (
        <Well className={getClassNames('template-details-builder')} title="Details" hideFooter={true}>
            <Skeleton loading={loading} active={true} avatar={false} title={false}>
                {loading ? (
                    <></>
                ) : (
                    <>
                        <TemplatizedTextField
                            size="middle"
                            hideMacros={true}
                            label="Name"
                            placeholder="Template Name"
                            value={name}
                            onChange={(nameVal) => {
                                let newVal: string | undefined = nameVal.trim()

                                if (newVal === '') {
                                    newVal = undefined
                                }

                                onChange({ ...value, name: newVal })
                            }}
                        />

                        <TemplatizedTextField
                            size="middle"
                            hideMacros={true}
                            label="Description"
                            placeholder="Description"
                            value={description}
                            onChange={(descVal) => {
                                const newVal = descVal.trim()

                                onChange({ ...value, description: newVal })
                            }}
                        />
                    </>
                )}
            </Skeleton>
        </Well>
    )
}
