import { Container, Singleton } from 'typescript-ioc'
import IApiCallOptions from '../interfaces/api-call-options'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import { AppService } from './app'
import * as querystring from 'querystring'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import aqe from '@pushly/aqe'
import { simpleNotification } from '@pushly/aqe/lib/utils'
import { hydrateNotifNamedTemplate } from '../_utils/models-hydration'
import { NotificationNamedTemplate } from '@pushly/models/lib/structs'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { titleCase } from '../_utils/utils'
import { ApiCallOptionsWithCustomErrorHandler } from '../types/api-call-options-with-custom-error-handler'

@Singleton
export class NotificationTemplateService {
    private appSvc: AppService

    public constructor() {
        this.appSvc = Container.get(AppService)
    }
    public async fetchNamedNotificationTemplatesByDomainId(
        domainId: number,
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<NotificationNamedTemplate[]>> {
        if (opts?.showLoadingScreen) this.appSvc.setModuleLoading()

        let res: IServiceApiResponse<NotificationNamedTemplate[]> = {
            data: [],
            cancelled: false,
            ok: false,
            meta: {},
        }

        try {
            const response = await axiosFetch(
                'get',
                {
                    url: this.buildSvcRouteFromBase(domainId, '', opts?.query),
                },
                opts?.cancellationKey ?? 'notif-temp-domain.id',
            )
            const { data: temps, ...meta } = response.data
            const templates = temps.map(hydrateNotifNamedTemplate)

            res = {
                data: templates,
                meta,
                ok: true,
            }
        } catch (error) {
            if (!isAxiosCancellation(error)) {
                simpleNotification('error', 'Notification Templates could not be fetched.')
            } else {
                res.cancelled = true
            }
        }

        if (opts?.showLoadingScreen) this.appSvc.unsetModuleLoading()

        return res
    }

    public async fetchNamedNotificationTemplateById(
        domainId: number,
        templateId: number,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<IServiceApiResponse<NotificationNamedTemplate>> {
        if (opts?.showLoadingScreen) this.appSvc.setModuleLoading()

        let data: any
        let cancelled = false
        let ok = false
        let meta = {}

        try {
            const response = await axiosFetch(
                'get',
                {
                    url: this.buildSvcRouteFromBase(domainId, templateId.toString()),
                },
                opts?.cancellationKey ?? `fetch-n-template.${templateId}`,
            )

            const { data: dto, ...metaData } = response.data
            const template = hydrateNotifNamedTemplate(dto)

            data = template
            meta = metaData
            ok = true
        } catch (error) {
            const defaultHandler = () =>
                handleResponseErrorMessage(error, {
                    onCancelled: () => (cancelled = true),
                    fallbackMessage: 'An error has occurred',
                })

            if (opts?.errorHandler) {
                opts?.errorHandler(error, defaultHandler)
            } else {
                defaultHandler()
            }
        }

        if (opts?.showLoadingScreen) this.appSvc.unsetModuleLoading()

        return { data, meta, ok, cancelled }
    }

    public async createNamedTemplate(domainId: number, requestDto: any, opts?: IApiCallOptions): Promise<boolean> {
        this.appSvc.setModuleLoading()

        try {
            const response = await axiosFetch(
                'post',
                {
                    url: this.buildSvcRouteFromBase(domainId),
                    data: requestDto,
                },
                opts?.cancellationKey,
            )

            try {
                const template = hydrateNotifNamedTemplate(response.data.data)
            } catch {}

            simpleNotification('success', 'Notification Template has been successfully created')
            this.appSvc.unsetModuleLoading()
            return true
        } catch (error) {
            this.handleErrorNotificationResponse(error)
            this.appSvc.unsetModuleLoading()
            return false
        }
    }

    // TODO: PUT should eventually use PUT model to validate all fields exist
    public async putNamedTemplate(
        domainId: number,
        templateId: number,
        requestDto: any,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<NotificationNamedTemplate>> {
        if (opts.showLoadingScreen) this.appSvc.setModuleLoading()

        let res: IServiceApiResponse<NotificationNamedTemplate> = {
            data: {} as NotificationNamedTemplate, // initialization isn't great here
            cancelled: false,
            ok: false,
            meta: {},
        }

        try {
            const response = await axiosFetch(
                'put',
                {
                    url: this.buildSvcRouteFromBase(domainId, `${templateId}`),
                    data: requestDto,
                },
                opts.cancellationKey,
            )

            const { data, ...meta } = response.data
            let template
            try {
                template = hydrateNotifNamedTemplate(data)
            } catch {}

            res.data = template
            res.ok = true
            res.meta = meta
            simpleNotification('success', 'Notification Template has been successfully updated')
        } catch (error) {
            this.handleErrorNotificationResponse(error)
        }

        if (opts.showLoadingScreen) this.appSvc.unsetModuleLoading()
        return res
    }

    public async patchNamedTemplate(
        domainId: number,
        templateId: number,
        requestDto: any,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<NotificationNamedTemplate>> {
        if (opts.showLoadingScreen) this.appSvc.setModuleLoading()

        let res: IServiceApiResponse<NotificationNamedTemplate> = {
            data: {} as NotificationNamedTemplate, // initialization isn't great here
            cancelled: false,
            ok: false,
            meta: {},
        }

        try {
            const response = await axiosFetch(
                'patch',
                {
                    url: this.buildSvcRouteFromBase(domainId, `${templateId}`),
                    data: requestDto,
                },
                opts.cancellationKey,
            )

            const { data, ...meta } = response.data
            let template
            try {
                template = hydrateNotifNamedTemplate(data)
            } catch {}

            res.data = template
            res.ok = true
            res.meta = meta
            simpleNotification('success', 'Notification Template has been successfully updated')
        } catch (error) {
            this.handleErrorNotificationResponse(error)
        }

        if (opts.showLoadingScreen) this.appSvc.unsetModuleLoading()
        return res
    }

    public async destroyNamedTemplate(
        domainId: number,
        templateId: number,
        opts: IApiCallOptions = {},
    ): Promise<boolean> {
        if (opts.showLoadingScreen) this.appSvc.setModuleLoading()

        try {
            const response = await axiosFetch(
                'delete',
                {
                    url: this.buildSvcRouteFromBase(domainId, `${templateId}`),
                },
                opts?.cancellationKey,
            )
        } catch (error) {
            simpleNotification('error', error.message)
            return false
        }

        if (opts.showLoadingScreen) this.appSvc.unsetModuleLoading()

        return true
    }

    // Service Utilities
    protected handleErrorNotificationResponse(error: any) {
        const errMessage = error.response?.data?.message

        if (!!errMessage) {
            const message = errMessage.replace(/(?:template\.channels\.default\.)([^\s]+)/gi, (...args: any[]) => {
                if (args.length > 1) {
                    return titleCase(args[1])
                } else {
                    return args[0]
                }
            })

            simpleNotification('error', message)
        } else {
            simpleNotification('error', `Notification could not be updated at this time.`)
        }
    }

    protected buildBaseSvcRoute(domainId: number): string {
        return `${aqe.defaults.publicApiDomain}/v4/domains/${domainId}/notification-templates`
    }

    protected buildSvcRouteFromBase(domainId: number, route: string = '', query: any = {}): string {
        if (query) query = querystring.stringify(query || {})
        return `${this.buildBaseSvcRoute(domainId)}${route ? `/${route}` : ''}${query ? `?${query}` : ''}`
    }
}
