import { NoTranslate } from '../no-translate/no-translate'
import { PageHeader } from '@pushly/aqe/lib/components'
import * as React from 'react'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService } from '../../services'
import { ExposeUserObject } from '../app-message-builder/model-extensions'

// Page Header
export type DetailsViewPageHeaderProps<T> = {
    title: string
    resource: T & { id?: number | string; creator?: ExposeUserObject }
    buildSubOptions: () => React.ReactNode
    addOnAfter?: () => React.ReactNode
    className?: string
}

export const DetailsViewPageHeader = <T extends {} = any>(props: DetailsViewPageHeaderProps<T>) => {
    const appSvc = useService(AppService)
    return (
        <div className={props.className}>
            <PageHeader
                title={<NoTranslate>{props.title}</NoTranslate>}
                onTitleSet={appSvc.customizeTabTitle}
                append={
                    <div className="header-append">
                        <span>
                            #{props.resource?.id ?? 0}
                            <span>
                                {' '}
                                | Created by <NoTranslate>{props.resource.creator?.name ?? 'Unknown User'}</NoTranslate>
                                &nbsp;
                            </span>
                        </span>
                        {props.buildSubOptions()}
                    </div>
                }
            />
            {props.addOnAfter && props.addOnAfter()}
        </div>
    )
}
