import { useState, Dispatch, SetStateAction } from 'react'

export interface ILoadableDataState<T> {
    data?: T
    loading: boolean
}
export const useLoadableDataState = <T extends any>(
    initialState?: Partial<ILoadableDataState<T>>,
): [ILoadableDataState<T>, Dispatch<SetStateAction<ILoadableDataState<T>>>] => {
    return useState({
        loading: false,
        ...(initialState ?? {}),
    })
}
