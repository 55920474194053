import * as React from 'react'
import { AppService, UserService } from '../../services'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { Button, Drawer, Well } from '@pushly/aqe/lib/components'
import { Input, Skeleton } from 'antd'
import { UserDto } from '../../dtos/user'
import { getClassNames } from '../../_utils/classnames'
import { Form } from '@ant-design/compatible'
import { EditOutlined } from '@ant-design/icons'
import randomstring from 'randomstring'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { validateFields } from '../../_utils/antd'
import { simpleFormErrorNotification, simpleNotification } from '../../_utils/utils'
import { PasswordForm } from '../../components/password-form/password-form'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../constants'
interface IUserCredsEditor extends FormComponentProps {
    user: UserDto
    visible: boolean
    onClose: (e: any) => void
    onUpdated: (user: Partial<UserDto>) => void
}

export const UserCredentialsEditor = Form.create<IUserCredsEditor>()(
    class extends React.Component<IUserCredsEditor> {
        protected appState: AppState
        protected appService: AppService
        protected userService: UserService

        public constructor(props: IUserCredsEditor) {
            super(props)

            this.appState = Container.get(AppState)
            this.appService = Container.get(AppService)
            this.userService = Container.get(UserService)
        }

        public render() {
            const { user } = this.props
            const platformUser = this.appState.currentUser
            const userIsPlatformUser = platformUser?.id === user.id

            const currentValues = this.getCurrentValues()

            return (
                <Drawer
                    getContainer={this.appService.getAppContainer}
                    className={getClassNames('user-details-editor')}
                    title="Edit Credentials"
                    placement="right"
                    closable={true}
                    onSubmit={this.handleSubmit}
                    disableSubmit={Object.keys(currentValues).length === 0}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                >
                    {userIsPlatformUser ? (
                        <Form {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}>
                            <Form.Item label="Current Password">
                                <div>
                                    {this.props.form.getFieldDecorator('currentPassword', {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'You must confirm your current password to make changes to your account.',
                                            },
                                        ],
                                    })(<Input.Password placeholder="Current Password" />)}
                                </div>
                            </Form.Item>
                            <PasswordForm drawer={true} form={this.props.form} user={this.props.user} />
                        </Form>
                    ) : null}
                </Drawer>
            )
        }

        protected get user(): UserDto {
            return this.props.user
        }

        protected getCurrentValues(): Partial<UserDto> {
            const formValues = this.props.form.getFieldsValue()
            const values: Partial<UserDto & { currentPassword: string; password: string }> = {}

            const pwd = formValues.password

            const pwdVerify = formValues.passwordVerify
            if (pwd && pwdVerify && pwdVerify === pwd) {
                values.password = pwd.trim()
            }

            if (formValues.currentPassword) {
                values.currentPassword = formValues.currentPassword.trim()
            }

            return values
        }

        protected handleSubmit = async () => {
            try {
                await validateFields(this.props.form)
                const values = this.getCurrentValues() as any

                values.confirmationRequired = true

                const { ok, data, meta } = await this.userService.updateById(this.props.user.id, values, {
                    showLoadingScreen: true,
                })

                if (ok) {
                    this.props.onUpdated(data!)

                    this.props.onClose(null)
                    simpleNotification('success', `User, ${this.props.user.name}, has been successfully updated.`)
                }
            } catch (err) {
                simpleFormErrorNotification(err)
            }
        }
    },
)

interface IUserCreds {
    user: UserDto | any
    loading: boolean
    onUpdated: (account: Partial<UserDto>) => void
    emptyUserState?: React.ReactNode
}

interface IUserCredsState {
    showDrawer: boolean
    drawerKey?: string
}

export class UserCredentialsWell extends React.Component<IUserCreds, IUserCredsState> {
    protected appService: AppService
    protected appState: AppState
    protected userService: UserService

    public constructor(props: IUserCreds) {
        super(props)

        this.appService = Container.get(AppService)
        this.appState = Container.get(AppState)
        this.userService = Container.get(UserService)

        this.state = {
            showDrawer: false,
        }
    }

    public render() {
        const user = this.props.user
        const platformUser = this.appState.currentUser
        const userIsPlatformUser = user && platformUser?.id === user.id
        return (
            <Well className="nested" title="Security" hideFooter={true} actions={this.buildActions()}>
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading &&
                        (!this.props.user && this.props.emptyUserState ? (
                            this.props.emptyUserState
                        ) : userIsPlatformUser ? (
                            <>
                                {this.renderCredentialDetails()}

                                <UserCredentialsEditor
                                    key={this.state.drawerKey}
                                    visible={this.state.showDrawer}
                                    user={this.props.user}
                                    onClose={this.handleDrawerClose}
                                    onUpdated={this.props.onUpdated}
                                />
                            </>
                        ) : null)}
                </Skeleton>
            </Well>
        )
    }

    protected renderCredentialDetails = () => {
        const user = this.props.user
        const platformUser = this.appState.currentUser
        const userIsPlatformUser = platformUser?.id === user.id

        return (
            <Well title="Credentials" mode="ghost" hideFooter={true}>
                {userIsPlatformUser && (
                    <div className={getClassNames('form-layout-row')}>
                        <div className={getClassNames('form-layout-row-label')}>Password</div>
                        <div className={getClassNames('form-layout-row-value')}>*****</div>
                    </div>
                )}
            </Well>
        )
    }

    protected buildActions(): React.ReactNode {
        const actions: any[] = []

        actions.push(
            <span key="edit">
                <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                    <EditOutlined />
                    <span>Change Password</span>
                </Button>
            </span>,
        )

        return actions
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = async () => {
        return this.setState({ showDrawer: false })
    }
}
