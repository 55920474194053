import React from 'react'
import './subscribers.widget.scss'
import { numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import { Tag, Descriptions, Tooltip, Popover } from 'antd'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { PartitionOutlined } from '@ant-design/icons'

const PropsFragment = (props: any) => <>{props.children}</>

const SubscribersWidget = ({ total, audSizeByChannel, availableChannels, yesterday }: any) => {
    const HoverWrapper = total <= 9999 ? PropsFragment : Tooltip

    return (
        <div className="dashboard-widget subs-widget vsplit">
            <div className="dashboard-widget-content">
                <div className="widget-title">
                    <label>Subscribers</label>
                </div>
                <div className="widget-body today">
                    <div className="widget-sub-title">
                        <label>
                            <span className="label-with-component-r">Total</span>
                        </label>
                    </div>
                    <div className="widget-body-main">
                        <HoverWrapper title={numberWithCommas(total)}>
                            <span className="total-subs label-with-component-r">
                                {!!total && <span className="indicator">~</span>}
                                <span>
                                    {!total ? '--' : numberWithCommas(total, total > 9999 ? '0.0a' : undefined)}
                                </span>
                            </span>
                        </HoverWrapper>
                        {availableChannels?.length > 1 && (
                            <Popover
                                title="Audience Size by Channel"
                                overlayClassName="subscriber-channel-breakdown-popover"
                                content={availableChannels.map((ch) => {
                                    return (
                                        <div key={ch} className="reach-breakdown-row">
                                            <span className="reach-breakdown-label">
                                                {DeliveryChannel.getLongName(DeliveryChannel.fromString(ch)!)}:&nbsp;
                                            </span>
                                            <span className="reach-breakdown-value">
                                                {audSizeByChannel &&
                                                    numberWithCommas(audSizeByChannel?.[ch.toUpperCase()] ?? 0)}
                                            </span>
                                        </div>
                                    )
                                })}
                                placement="left"
                            >
                                <PartitionOutlined className="channel-breakdown-icon" />
                            </Popover>
                        )}
                    </div>
                </div>
                <div className="widget-body yesterday">
                    <div className="widget-sub-title">
                        <label>Yesterday</label>
                    </div>
                    <Descriptions layout="horizontal" column={1}>
                        <Descriptions.Item label="New" className="new">
                            <Tag>
                                {yesterday.new === undefined
                                    ? '--'
                                    : numberWithCommas(yesterday.new, yesterday.new > 9999 ? '0.0a' : undefined)}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Attrition" className="attrition">
                            <Tag>{yesterday.attrition === '--' ? '--' : numberWithPercent(yesterday.attrition)}</Tag>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>
        </div>
    )
}

export default SubscribersWidget
