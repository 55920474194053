import { BreakdownDropdownOption } from '../types'

export const DEFAULT_BREAKDOWN = {
    value: 'NONE',
    label: 'None',
}

export const GENERIC_BREAKDOWNS = [
    { value: 'BROWSER', label: 'Browser' },
    { value: 'BROWSER,PLACEMENT', label: 'Browser & Device Type' },
    { value: 'BROWSER,PLACEMENT,OPERATING_SYSTEM', label: 'Browser, Device Type, & OS' },
    { value: 'BROWSER,OPERATING_SYSTEM', label: 'Browser & OS' },
    { value: 'OPERATING_SYSTEM', label: 'OS' },
    { value: 'PLACEMENT', label: 'Device Type' },
    { value: 'PLACEMENT,OPERATING_SYSTEM', label: 'Device Type & OS' },
    { value: 'CHANNEL', label: 'Delivery Channel' },
]

export const TIMEZONE_BREAKDOWNS = [{ value: 'TIMEZONE', label: 'Time Zone' }]

export const BUTTON_BREAKDOWNS = [{ value: 'BUTTON', label: 'Button' }]

export const BREAKDOWN_DROPDOWN_OPTIONS: Record<string, BreakdownDropdownOption[]> = {
    DELIVERY_DOMAIN: [DEFAULT_BREAKDOWN, ...BUTTON_BREAKDOWNS, ...GENERIC_BREAKDOWNS],
    DELIVERY_NOTIFICATION: [DEFAULT_BREAKDOWN, ...BUTTON_BREAKDOWNS, ...GENERIC_BREAKDOWNS],
    DELIVERY_TEST: [DEFAULT_BREAKDOWN, { value: 'CHANNEL', label: 'Delivery Channel' }],
    DELIVERY_CAMPAIGN: [DEFAULT_BREAKDOWN],
    DELIVERY_SEGMENT: [DEFAULT_BREAKDOWN],
    DELIVERY_KEYWORD: [DEFAULT_BREAKDOWN, { value: 'CHANNEL', label: 'Delivery Channel' }],
    SUBSCRIBER_DOMAIN: [DEFAULT_BREAKDOWN, ...GENERIC_BREAKDOWNS],
    SUBSCRIBER_PROMPT: [DEFAULT_BREAKDOWN, ...GENERIC_BREAKDOWNS],
    SUBSCRIBER_TEST: [DEFAULT_BREAKDOWN],
    PUB_NETWORK_DOMAIN: [DEFAULT_BREAKDOWN],
    ATTRITION_DOMAIN: [
        DEFAULT_BREAKDOWN,
        { value: 'SUSCRIBER_AGE', label: 'Subscriber Age' },
        { value: 'IMPRESSIONS', label: 'Impressions Received' },
        { value: 'CLICKS', label: 'Times Clicked' },
    ],
    MONTHLY_UNIQUES_DOMAIN: [
        DEFAULT_BREAKDOWN,
        { value: 'PLACEMENT', label: 'Device Type' },
        { value: 'CHANNEL', label: 'Delivery Channel' },
        { value: 'PLACEMENT,CHANNEL', label: 'Delivery Type & Channel' },
    ],
    APP_MESSAGE_DOMAIN: [
        DEFAULT_BREAKDOWN,
        { value: 'CHANNEL', label: 'Delivery Channel' },
        { value: 'PLACEMENT', label: 'Device Type' },
    ],
    APP_MESSAGE_APP_MESSAGE: [
        DEFAULT_BREAKDOWN,
        { value: 'CHANNEL', label: 'Delivery Channel' },
        { value: 'PLACEMENT', label: 'Device Type' },
    ],
    LIVE_ACTIVITY_DOMAIN: [DEFAULT_BREAKDOWN],
    LIVE_ACTIVITY_ACTIVITY: [DEFAULT_BREAKDOWN],
}
