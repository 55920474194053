import * as React from 'react'
import { Route, Switch } from '../../components/router'
import { Redirect, Route as ReactRoute } from 'react-router-dom'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { PlatformMessageBar } from '../../components/platform-message-bar/platform-message-bar'
import { GAHelper } from '../../components/ga-helper/ga-helper'
import { Home } from '../home/home'
import './mobile-root.scss'
import { Header } from '../header/header'
import { Content } from '../content/content'
import { Footer } from '../footer/footer'
import { CreateNotification } from '../create-notification/create-notification'
import { EditNotification } from '../edit-notification/edit-notification'
import { AccessDenied403 } from '../../components/403/403'
import { RegisterPreviewDevice } from '../../features/register-preview-device/register-preview-device'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { MobileSendNotification } from '../notifications/mobile-send-notification'
import { MobileEditNotification } from '../notifications/mobile-edit-notification'

interface IMobileViewProps {}

export class MobileRoot extends BetterComponent<IMobileViewProps, any> {
    public readonly defaultClassName: string = 'sw-mv-root'

    protected appState: AppState
    protected appService: AppService

    public constructor(props: IMobileViewProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.appService.attachHistoryContainer(this.injectedProps.history)
    }

    public componentDidMount(): void {
        // HACK
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement!.style.setProperty('--sw-vh', `${vh}px`)
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('upper')}>
                    <div ref={this.setContentContainer} className={this.buildClassName('upper-wrapper')}>
                        <Header {...this.props} />

                        <Content {...this.props}>
                            <PlatformMessageBar />
                            {this.renderGaHelper()}

                            <Switch>
                                <Route path="/register-preview-device" exact={true} component={RegisterPreviewDevice} />

                                <Route path="/domains/:domainId([\d]+)/dashboard" component={Home} />

                                <Route
                                    path="/domains/:domainId([\d]+)/notifications/new"
                                    component={this.notForReadOnly(MobileSendNotification, AbilityAction.CREATE)}
                                />

                                <Route
                                    path="/domains/:domainId([\d]+)/notifications/edit/:notifId([\d]+)"
                                    component={this.notForReadOnly(MobileEditNotification, AbilityAction.UPDATE)}
                                />

                                {/*
                                When Authenticated - Handle Default Route
                                - Causes redirect loop if user is not authenticated
                            */}
                                {this.appState.isAuthenticated && (
                                    <Redirect from="/" exact={true} to={this.currentDomainDashboardUrl} />
                                )}
                            </Switch>
                        </Content>
                    </div>
                </div>
                <div className={this.buildClassName('lower')}>
                    <div className={this.buildClassName('lower-wrapper')}>
                        <Footer {...this.props} />
                    </div>
                </div>
            </div>
        )
    }

    protected renderGaHelper(): React.ReactNode {
        let helper: any = ''

        if (this.appState.isAuthenticated) {
            helper = <ReactRoute path="/" component={() => GAHelper(this.appState)} />
        }

        return helper
    }

    protected notForReadOnly(component: any, action: AbilityAction): any {
        return this.appState.abilityStore.can(
            action,
            this.appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.NOTIFICATION),
        )
            ? component
            : AccessDenied403
    }

    private get currentDomainDashboardUrl(): string {
        return this.appService.routeWithinDomain('/dashboard', true)
    }

    protected setContentContainer = (el: any): void => {
        this.appService.contentContainerRef = el
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
