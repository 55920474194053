import React, { useState } from 'react'
import { AppMessage } from '@pushly/models/lib/structs/app-messages/app-message'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import PreviewSDK from '@pushly/cuttlefish'
import { Well } from '@pushly/aqe/lib/components'
import { Radio } from 'antd'
import './styles/app-message-summary-card.scss'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { AppMessageViewable } from '../../../components/app-message-builder/model-extensions'

type AppMessageSummaryCardProps = {
    appMessage: AppMessageViewable
    loading: boolean
    hideFooter?: boolean
}

export const AppMessageSummaryCard = (props: AppMessageSummaryCardProps) => {
    const template = props.appMessage.template!
    const [channel, setChannel] = useState<DeliveryChannel>(template.channels[0])
    const [theme, setTheme] = useState<'light' | 'dark'>('light')

    return (
        <Well className="app-message-summary-card mode-dark" hideHeader={true} hideFooter={true}>
            <div className="preview-type-group-options">
                <Radio.Group
                    buttonStyle={'outline'}
                    className="preview-type-group"
                    size="small"
                    value={channel}
                    onChange={(ev) => setChannel(ev.target.value)}
                >
                    {template.channels.map((ch) => (
                        <Radio.Button key={ch} value={ch}>
                            {DeliveryChannel.getShortName(ch)}
                        </Radio.Button>
                    ))}
                </Radio.Group>
                <Radio.Group
                    buttonStyle={'outline'}
                    className="preview-type-group"
                    size="small"
                    value={theme}
                    onChange={(ev) => setTheme(ev.target.value)}
                >
                    <Radio.Button value="light">Light</Radio.Button>
                    <Radio.Button value="dark">Dark</Radio.Button>
                </Radio.Group>
            </div>
            <div className="cuttlefish-preview-wrapper">
                <PreviewSDK.AppMessagePreview
                    systemMode={theme}
                    template={props.appMessage.template!}
                    channel={channel}
                    loading={props.loading ? <Loading3QuartersOutlined spin={true} /> : false}
                />
            </div>
        </Well>
    )
}
