import React from 'react'
import './domain-notification-settings.scss'
import classnames from 'classnames'
import DomainSettingsTab, {
    IDomainSettingsTab,
    IDomainSettingsTabState,
} from '../abstract-domain-settings-tab/abstract-domain-settings-tab'
import FallbackNotificationWell from './fallback-notification-well'
import { Flags } from '@pushly/aquarium-utils/lib/enums/flags'
import { DomainNotificationSettings as DomainNotificationSettingsView } from '@pushly/aqe/lib/components'
import { getCustomMacroFields } from '../../_utils/domain'
import { DomainService } from '../../services'
import { Container } from 'typescript-ioc'
import { hydrateDomainModel } from '../../_utils/models-hydration'
import { simpleNotification } from '@pushly/aqe/lib/utils'
import { InvalidFieldException } from '@pushly/models/lib/exceptions/invalid-field-exception'
import { MissingFieldException } from '@pushly/models/lib/exceptions/missing-field-exception'
import { extractFriendlyErrorMessage, extractMinMaxFriendlyError } from '@pushly/aqe/lib/utils/exception'
import { DomainNotificationSettingsProps } from '@pushly/aqe/lib/components/domain-notification-settings/domain-notification-settings'
import { Domain } from '@pushly/models/lib/structs/domain'

interface IDomainNotificationSettings extends IDomainSettingsTab {}

interface IState extends IDomainSettingsTabState {}

class DomainNotificationSettings extends DomainSettingsTab<IDomainNotificationSettings, IState> {
    protected domainService: DomainService

    public constructor(props) {
        super(props)

        this.domainService = Container.get(DomainService)
    }
    public render() {
        const { loading, domain } = this.state

        let hasFallbackFlag = false
        if (domain?.flags?.includes(Flags.domain.FEAT_DOMAIN_FALLBACK_NOTIFICATION.key)) {
            hasFallbackFlag = true
        }

        let hasNotifFieldSuggestionsFlag = false
        if (domain?.flags?.includes(Flags.domain.FEAT_NOTIF_FIELD_SUGGESTIONS.key)) {
            hasNotifFieldSuggestionsFlag = true
        }

        let domainModel
        if (domain) {
            domainModel = hydrateDomainModel(domain)
        }

        return (
            <div className={classnames('domain-settings-tab', 'domain-notification-settings')}>
                {domainModel && (
                    <DomainNotificationSettingsView
                        loading={loading}
                        domain={domainModel}
                        showInternalEditorAccessFields={this.appUserIsInternalDomainEditor}
                        showGeneralSettingsWell={true}
                        showNotificationAutomationWell={hasNotifFieldSuggestionsFlag}
                        fetchCustomMacroFields={getCustomMacroFields}
                        getAppContainer={this.appService.getAppContainer}
                        onEditorSubmit={this.onGeneralDomainEditSubmit}
                        wellStyle="pufferfish"
                    />
                )}

                {hasFallbackFlag && (
                    <FallbackNotificationWell
                        loading={loading}
                        domain={domain!}
                        onDomainUpdated={this.handleDomainUpdated}
                    />
                )}
            </div>
        )
    }

    public onGeneralDomainEditSubmit = async (
        builder: Parameters<DomainNotificationSettingsProps['onEditorSubmit']>[0],
    ) => {
        let resOk = builder.isEmpty

        if (!builder.isEmpty) {
            try {
                const values = builder.buildPartial() as Partial<Domain>

                const { ok, data } = await this.domainService.patchDomainById(this.props.domainId, values)
                if (ok) {
                    await this.handleDomainUpdated(data)
                    resOk = true
                }
            } catch (error) {
                if (error instanceof InvalidFieldException || error instanceof MissingFieldException) {
                    if (
                        error.field === 'deliveryThrottleMaxSeconds' &&
                        (error.constraint === 'min' || error.constraint === 'max')
                    ) {
                        error = extractMinMaxFriendlyError(error, [0, 15], 'minutes')
                    } else {
                        error = extractFriendlyErrorMessage(error)
                    }
                }

                simpleNotification('error', error.message)
            }
        }

        return resOk
    }

    protected get appUserIsInternalDomainEditor() {
        return (
            !!this.state.domain &&
            this.appState.abilityStore.acs.isInternalEditorUserScopeForDomain(this.state.domain.id)
        )
    }
}

export default DomainNotificationSettings
