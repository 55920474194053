import { BetterSelect } from '@pushly/aqe/lib/components/better-select/better-select'
import { getClassNames } from '../../_utils/classnames'
import * as React from 'react'
import { DomainDto } from '../../dtos/domain'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Well } from '@pushly/aqe/lib/components'
import { Switch } from 'antd'
import { NamedTemplateReducerRequest } from '../app-message-builder/types'
import { AppMessageBuilderState } from '../app-message-builder/interfaces/app-message-builder-state.interface'

type TemplateSelectProps = {
    config: Pick<AppMessageBuilderState, 'usingTemplate' | 'availableTemplates' | 'loading'>
    domain: DomainDto
    loading?: boolean
    value?: number
    disabled: boolean
    wellMode?: Well['props']['mode']
    onChange: (data: NamedTemplateReducerRequest) => void
}

export const TemplateSelector = (props: TemplateSelectProps) => {
    const { disabled, config, domain, value, wellMode, onChange, loading } = props

    const wellProps = { title: 'Template', hideHeader: true, hideFooter: true, mode: props.wellMode }

    const selectOptions = config.availableTemplates.map((t) => ({
        value: t.id,
        label: t.name,
    }))

    return (
        <Well {...wellProps}>
            <div>
                <div style={{ marginBottom: '6px' }}>
                    <label>
                        <Switch
                            disabled={disabled}
                            style={{ marginRight: '6px' }}
                            checked={config.usingTemplate}
                            size="small"
                            title="Use saved template"
                            onChange={() => {
                                onChange({ usingTemplate: !config.usingTemplate })
                            }}
                        />
                        <span>Use saved template</span>
                    </label>
                </div>
                <div className={getClassNames('template-selector')}>
                    <BetterSelect
                        mode="single"
                        prefix="Template"
                        placeholder={
                            loading || !config.availableTemplates.length ? (
                                <Loading3QuartersOutlined spin={true} />
                            ) : (
                                'Select a template'
                            )
                        }
                        disabled={!config.usingTemplate}
                        disableSearch={true}
                        loading={loading}
                        options={selectOptions}
                        onChange={(val) =>
                            onChange({
                                templateId: val as number,
                                usingTemplate: !!val,
                            })
                        }
                        value={value ? [value] : []}
                    />
                </div>
            </div>
        </Well>
    )
}
