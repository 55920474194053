import * as React from 'react'
import './macro-manager.scss'
import {
    MacroInputTrigger,
    MacroOptions,
    MacroOptionType,
    MacroType,
    MacroManagerOnSelect,
    MacroInputElement,
    MacroOption,
} from './types'
import { Input } from 'antd'
import { PopoverProps } from 'antd/lib/popover'
import { getClassNames } from '../../_utils/classnames'
import { generateShortID } from '../campaign-builder/helpers/uid'
import { escapeRegExpString as esc } from '../../_utils/regexp'
import { InputProps } from 'antd/lib/input'
import { BetterInput } from '../better-input/better-input'
import { PromiseableProp, resolvePromiseableProp } from '../../_utils/promiseable-prop'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { MacroPopover } from './macro-popover'
import { fireNativeInputChangeEvent, getInputContext, getMacroOptions } from './helpers'
import { InputContext } from '../custom-quill-editor/types'
import { MACRO_MGR_BASE_CLASSNAME, DEFAULT_MACRO_TRIGGER } from './constants'

interface IMacroManager extends Partial<PopoverProps> {
    types?: MacroType[]
    options?: PromiseableProp<MacroOptions>
    customOptions?: PromiseableProp<MacroOption[]>
    inputTrigger?: MacroInputTrigger
    padInputTrigger?: boolean
    onSelect?: MacroManagerOnSelect
    hideToggle?: boolean
    children: MacroInputElement
    disabled?: boolean
    validOptionTypes?: MacroOptionType[]
}

interface IState {
    showMacroOptions?: boolean
    inputContext?: InputContext

    loading: boolean
    options?: MacroOptions
    customOptions?: MacroOption[]
}

export class MacroManager extends React.Component<IMacroManager, IState> {
    public state: IState = {
        showMacroOptions: false,
        loading: true,
        options: {} as any,
        customOptions: [],
    }

    public id = `mm-${generateShortID()}`
    public inputComponentRef: any
    protected unmounting: boolean = false

    private readonly appState: AppState

    public constructor(props: IMacroManager) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public UNSAFE_componentWillMount() {
        this.configureInputElement()
        this.resolveOptions()
    }

    public componentDidMount() {
        this.wireComponentEvents()
    }

    public componentDidUpdate(prev: IMacroManager) {
        if ((!prev.options && !!this.props.options) || (!prev.customOptions && !!this.props.customOptions)) {
            this.resolveOptions()
        }
    }

    public componentWillUnmount() {
        this.unmounting = true
        this.unwireComponentEvents()
    }

    public render() {
        const {
            types,
            options,
            customOptions,
            inputTrigger,
            onSelect,
            hideToggle,
            children,
            disabled,
            validOptionTypes,
            ...props
        } = this.props
        const InputComponent = React.Children.only(children)

        const visible = this.state.showMacroOptions ?? props.visible
        const macroOptions = getMacroOptions({
            types,
            options: this.state.options,
            customOptions: this.state.customOptions,
            validOptionTypes,
        })

        const input = this.getInputElement()
        const inputProps = this.getInputProps()
        const inputContext = this.state.inputContext
        const inputRect = !input ? undefined : this.getInputElement().getBoundingClientRect()

        // calculate popover Y adjustment based on element height
        const offsetY =
            inputProps?.size === 'small'
                ? (inputRect?.height ?? 24) + 8
                : inputProps?.size === 'large'
                ? (inputRect?.height ?? 40) + 2
                : (inputRect?.height ?? 32) + 4

        const overlayStyle = !inputRect
            ? props.overlayStyle
            : {
                  ...props.overlayStyle,
                  width: `${inputRect.width}px`,
              }

        const popoverAlign = {
            // adjust for icon location and height
            points: ['tr', 'tr'],
            offset: [8, offsetY],
        }

        return (
            <div
                key={this.id}
                id={this.id}
                className={getClassNames(MACRO_MGR_BASE_CLASSNAME, this.id, {
                    ['overlay-visible']: visible,
                    ['hide-toggle']: hideToggle,
                    disabled,
                })}
            >
                <div className={getClassNames(`${MACRO_MGR_BASE_CLASSNAME}-input-wrapper`)}>
                    <InputComponent.type
                        {...InputComponent.props}
                        ref={(el) => {
                            // call initial ref method
                            const { ref } = InputComponent as any
                            if (ref && typeof ref === 'function') {
                                ref(el)
                            }

                            // set internal ref
                            this.inputComponentRef = el
                        }}
                        className={getClassNames(`${MACRO_MGR_BASE_CLASSNAME}-input`, InputComponent.props.className)}
                    />
                    <MacroPopover
                        key={`${this.id}-macro-list`}
                        {...props}
                        align={popoverAlign}
                        id={this.id}
                        visible={visible}
                        onVisibleChange={(showMacroOptions) => this.setState({ showMacroOptions })}
                        overlayClassName={props.overlayClassName}
                        overlayStyle={overlayStyle}
                        onItemSelect={this.handleSelect}
                        macros={macroOptions}
                        inputContext={inputContext}
                    />
                </div>
            </div>
        )
    }

    /**
     * Validates passed children to ensure single
     * element matching one of the following types:
     *   antd <Input />
     *   sw   <BetterInput />
     *   html <input />
     *
     */
    protected configureInputElement() {
        const child = React.Children.only(this.props.children)
        const isProperType = child.type === Input || child.type === BetterInput || child.type === 'input'
        if (!isProperType) {
            throw new Error('MacroManger child component must be type input or Input')
        }
    }

    protected getInputProps(): InputProps | undefined {
        return this.inputComponentRef?.props
    }

    /**
     * Returns the actual html input element
     * whether nested or direct
     *
     */
    protected getInputElement(): HTMLInputElement {
        const inputConstructor = this.inputComponentRef?.constructor
        return inputConstructor === Input || inputConstructor === BetterInput
            ? this.inputComponentRef.input
            : this.inputComponentRef
    }

    protected wireComponentEvents() {
        const el = this.getInputElement()
        if (el) {
            el.addEventListener('focus', this.handleInputInteraction)
            el.addEventListener('keyup', this.handleInputInteraction)
            el.addEventListener('click', this.handleInputInteraction)
        }
    }

    protected unwireComponentEvents() {
        const el = this.getInputElement()
        if (el) {
            el.removeEventListener('focus', this.handleInputInteraction)
            el.removeEventListener('keyup', this.handleInputInteraction)
            el.removeEventListener('click', this.handleInputInteraction)
        }
    }

    /**
     * Handles focus, keyup, and click events for the
     * inputComponent.
     *
     * Gathers current inputContext and updates state
     *
     */
    protected handleInputInteraction = (ev: any) => {
        ev.stopPropagation?.()
        ev.stopImmediatePropagation?.()

        let showMacroOptions = false
        let inputContext: InputContext

        try {
            const evType = ev.type
            const input = ev.target
            inputContext = getInputContext(
                input.value,
                input.selectionStart,
                this.props.inputTrigger ?? DEFAULT_MACRO_TRIGGER,
            )

            // a focus event while already open should ignore inTrigger state
            showMacroOptions = evType === 'focus' && this.state.showMacroOptions ? true : inputContext.cursor.inTrigger
        } catch (err) {
            console.warn('Error computing input state', err)
        }

        this.setState(() => ({
            showMacroOptions,
            inputContext,
        }))
    }

    /**
     * Handles interaction with the
     * <MacroSelectOption /> element
     *
     * If toggling off the active macro state is cleared
     *
     */
    protected handleSelect = async (macro: MacroOption) => {
        const el = this.getInputElement()
        const [tStart, tEnd] = (this.props.trigger?.toString() ?? DEFAULT_MACRO_TRIGGER).split('.')
        const padInputTrigger = this.props.padInputTrigger ?? false

        // calculate current positions based on
        // input context and activeMacro
        const inputContext = this.state.inputContext
        const startPos = !!inputContext?.macro ? inputContext.macro.startPos : el.selectionStart ?? 0
        const endPos = !!inputContext?.macro
            ? inputContext.macro.endPos ?? inputContext.macro.startPos
            : el.selectionStart ?? 0

        // using calculated positions the wrapped
        // macro value is injected into the current
        // value prop
        const trimStartSeq = new RegExp(`${esc(tStart)}$`)
        const trimEndSeq = new RegExp(`^${esc(tEnd)}`)

        const newValueStart =
            el.value.slice(0, startPos).replace(trimStartSeq, '') +
            `${tStart}${padInputTrigger ? ' ' : ''}${macro.value}${padInputTrigger ? ' ' : ''}${tEnd}`
        const newValueEnd = el.value
            .slice(endPos)
            .replace(trimEndSeq, '')
            // workaround for trailing open tokens
            .replace(trimStartSeq, '')

        const newValue = newValueStart + newValueEnd

        // set value on component using
        // simulated native event
        fireNativeInputChangeEvent(el, newValue)

        // reset cursor position
        el.selectionStart = newValueStart.length
        el.selectionEnd = newValueStart.length
        el.focus()

        // fire any listening method
        this.props.onSelect?.(macro)
    }

    /**
     * Ensures options are set from static
     * or async sources and adjusts loading
     * state accordingly
     *
     */
    protected async resolveOptions() {
        const { options, customOptions } = this.props

        const resolvedOptions = !options ? undefined : await resolvePromiseableProp(options)
        const resolvedCustomOptions = !customOptions ? [] : await resolvePromiseableProp(customOptions)

        if (!this.unmounting) {
            this.setState({
                loading: false,
                options: resolvedOptions,
                customOptions: resolvedCustomOptions,
            })
        }
    }
}
