export const AppMessageApiColumns = {
    'APP_MESSAGE.NAME': 'app_message.name',
    'APP_MESSAGE.STYLE': 'app_message.style',
    'APP_MESSAGE.RUN_DATE_START': 'app_message.run_date_start',
    'APP_MESSAGE.RUN_DATE_END': 'app_message.run_date_end',
    'APP_MESSAGE.RUN_DATE_TIME_ZONE': 'app_message.run_date_time_zone',
    'APP_MESSAGE.AUDIENCES': 'app_message.audiences',
    PLACEMENT: 'placement',
    CHANNEL: 'channel',
    IMPRESSIONS: 'impressions',
    CLICKS: 'clicks',
    CTR: 'ctr_decimal',
}
