import * as React from 'react'
import { useEffect, useState } from 'react'
import { AppMessage } from '@pushly/models/lib/structs/app-messages/app-message'
import { useService } from '@pushly/aqe/lib/hooks'
import { StatsWell } from '../../../components/details-view/stats-well'
import { StatusName } from '@pushly/aquarium-utils/lib/enums'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { ChannelBreakdownStatsChart } from '../../../components/channel-breakdown-stats-chart/channel-breakdown-stats-chart'
import { DomainDto } from '../../../dtos/domain'
import { Col, Row } from 'antd'
import { AppMessageSummaryCard } from './app-message-summary-card'
import { backfillStats } from '../../../_utils/utils'
import { StatsType } from '../../../components/channel-breakdown-stats-chart/types'
import * as moment from 'moment-timezone'
import { InsightsService } from '../../../services/insights'
import { Moment } from 'moment'
import { AppMessageViewable } from '../../../components/app-message-builder/model-extensions'

export type AppMessageDetailsProps = {
    appMessage: AppMessageViewable
    domain: DomainDto
}

export const AppMessageDetails = ({ appMessage, domain }: AppMessageDetailsProps) => {
    const insightsSvc = useService(InsightsService)

    const [genStats, setGenStats] = useState<{ clicks?: number; impressions?: number; ctr_decimal?: number }>({})
    const [genStatsLoading, setGenStatsLoading] = useState(true)
    const [breakdownStats, setBreakdownStats] = useState<
        { clicks?: number; impressions?: number; ctr_decimal?: number }[]
    >([])
    const [breakdownStatsLoading, setBreakdownStatsLoading] = useState(true)

    // --------------------------- Breakdown Chart State --------------------------- \\
    const channelOptions = Array.from(appMessage.template!.channels)
    const [selectedChannels, setSelectedChannels] = useState<DeliveryChannel[]>(channelOptions)

    // ----------------- Defaulting StatsType based on schedule config ----------------- \\
    const schedule = appMessage.schedules![0]
    const startDate: Moment = moment.tz(moment.utc(schedule.runDateStartUtc), schedule.runDateTimeZone)
    const today = moment()

    const scheduleTimeFrame = today.diff(startDate, 'days')
    let defaultStatsType: StatsType = 'month'
    switch (true) {
        case scheduleTimeFrame >= 90:
            defaultStatsType = 'month'
            break
        case scheduleTimeFrame >= 30:
            defaultStatsType = 'week'
            break
        case scheduleTimeFrame >= 0:
            defaultStatsType = 'day'
            break
    }

    const [statsType, setStatsType] = useState<StatsType>(defaultStatsType)

    const fetchGenAMStats = async () => {
        setGenStatsLoading(true)
        const insightsCalls: any[] = []
        insightsCalls.push(
            insightsSvc.fetch({
                entity: 'app_messages',
                date_preset: 'lifetime',
                date_increment: 'lifetime',
                breakdowns: ['app_message'],
                fields: ['app_message_id', 'impressions', 'clicks', 'ctr_decimal'],
                filters: [
                    {
                        field: 'domain.id',
                        operator: 'in',
                        value: domain.id,
                    },
                    {
                        field: 'app_message.id',
                        operator: 'in',
                        value: appMessage.id,
                    },
                ],
            }),
        )

        const stats = await Promise.all(insightsCalls)

        if (stats && stats[0]) {
            setGenStats(stats[0][0])
        }

        setGenStatsLoading(false)
    }

    const fetchBreakdownAMStats = async () => {
        setBreakdownStatsLoading(true)

        const data = await insightsSvc.fetch({
            entity: 'app_messages',
            date_preset: 'lifetime',
            date_increment: statsType,
            action_attribution: 'event_time',
            breakdowns: ['app_message', 'channel'],
            fields: ['app_message_id', 'impressions', 'clicks', 'ctr_decimal', 'channel'],
            filters: [
                {
                    field: 'domain.id',
                    operator: 'in',
                    value: domain.id,
                },
                {
                    field: 'app_message_id',
                    operator: 'in',
                    value: appMessage.id,
                },
                {
                    field: 'channel',
                    operator: 'in',
                    value: appMessage.template?.channels,
                },
            ],
        })

        if (data) {
            let backfilledStats: any[] = []
            const visualEndDate = schedule.runDateEndUtc
                ? today.isBefore(schedule.runDateEndUtc)
                    ? moment().add(1, statsType)
                    : moment.tz(moment.utc(schedule.runDateEndUtc), schedule.runDateTimeZone).add(1, statsType)
                : moment().add(1, statsType)
            if (statsType !== 'lifetime') {
                backfilledStats = backfillStats(
                    data,
                    statsType,
                    {
                        clicks: 0,
                        impressions: 0,
                    },
                    appMessage.template!.channels,
                    {
                        dateCol: 'event_date',
                        startDate: moment.tz(moment.utc(schedule.runDateStartUtc), schedule.runDateTimeZone),
                        endDate: visualEndDate,
                    },
                )
            } else {
                backfilledStats = data
            }

            setBreakdownStats(backfilledStats)
        }

        setBreakdownStatsLoading(false)
    }

    // --------------- Initial Loading ---------------- \\
    useEffect(() => {
        fetchGenAMStats()
        fetchBreakdownAMStats()
    }, [])

    // ----------- Breakdown Stats Refresh ------------ \\
    useEffect(() => {
        fetchBreakdownAMStats()
    }, [appMessage.id, statsType])

    let amStatusId = schedule.statusId
    let amStatus = schedule.status
    if (schedule.computedStatusId !== undefined) {
        amStatusId = schedule.computedStatusId
        amStatus = schedule.computedStatus!
    }

    return (
        <>
            <StatsWell
                loading={genStatsLoading}
                status={{
                    id: amStatusId,
                    name: StatusName[amStatus],
                }}
                impressions={genStats?.impressions}
                clicks={genStats?.clicks}
                ctrDecimal={genStats?.ctr_decimal}
            />
            <br />
            <Row gutter={24} className="details-lower-row">
                <Col span={14}>
                    <ChannelBreakdownStatsChart
                        loading={breakdownStatsLoading}
                        stats={breakdownStats}
                        statsType={statsType}
                        onStatsTypeChange={(type) => setStatsType(type)}
                        channelOptions={channelOptions}
                        selectedChannels={selectedChannels}
                        onSelectionChange={(channels) => setSelectedChannels(channels)}
                        xAxisDateProp="event_date"
                    />
                </Col>
                <Col span={10}>
                    <AppMessageSummaryCard appMessage={appMessage} loading={genStatsLoading} />
                </Col>
            </Row>
        </>
    )
}
