import * as React from 'react'
import { AppState } from '../../../../stores/app'
import { AppService } from '../../../../services'
import { FormInstance } from 'antd/lib/form'
import { Container } from 'typescript-ioc'
import { Radio, Select, Tooltip } from 'antd'
import {
    ALL_SCHEDULE_DAYS,
    EveryNSchedule,
    EveryNScheduleType,
    IEveryNScheduleValue,
    DayPartSelect,
    IDayPartValue,
} from '@pushly/aqe/lib/components'
import { CampaignRecurrenceType, DayOrdinal } from '../../../../models/campaign/recurrence-schedule'
import { SRScheduleContext } from '../saved-report-schedule-context'
import { RadioChangeEvent } from 'antd/lib/radio'
import { numRange } from '../../../../_utils/range'
import { getClassNames } from '../../../../_utils/classnames'
import { WarningOutlined } from '@ant-design/icons'
import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { BetterTimePicker } from '@pushly/aqe/lib/components/better-timepicker/better-timepicker'

export class SavedReportScheduleBuilder extends React.Component<any, any> {
    public static contextType = SRScheduleContext
    public context!: React.ContextType<typeof SRScheduleContext>
    protected readonly appState: AppState
    protected readonly appService: AppService

    protected formRef = React.createRef<FormInstance>()

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const savedReportSchedule = this.props.schedule ?? this.context.savedReportSchedule
        const config = savedReportSchedule.getConfiguration()

        const schedule = config.getSchedule()!
        const scheduleType = schedule?.getType()
        const scheduleSuperType = scheduleType?.replace(/_relative|_specific/, '')

        const timezone = this.appState?.currentDomain?.timezone ?? 'UTC' // forced UTC for working with date strings only
        schedule.setTimeZone(timezone)
        const today = moment().startOf('day').add(7, 'hours')

        const defaultTime = (schedule.getSpecificTime() && moment(schedule.getSpecificTime(), 'HH:mm')) || today
        const timeValue =
            schedule.getSpecificTime() !== undefined ? moment(schedule.getSpecificTime(), 'HH:mm') : defaultTime

        return (
            <div className="schedule-well">
                <div className="schedule-row">
                    <div className="schedule-label">
                        <span>Schedule Type: </span>
                    </div>
                    <div className="schedule-value">
                        <Select
                            size="small"
                            value={scheduleSuperType}
                            onChange={this.handleScheduleTypeChange}
                            dropdownClassName="schedule-type-dropdown"
                        >
                            <Select.Option value="daily">Daily</Select.Option>
                            <Select.Option value="weekly">Weekly</Select.Option>
                            <Select.Option value="monthly">Monthly</Select.Option>
                        </Select>

                        <div className="monthly-type-wrapper">
                            {/^monthly/.test(scheduleType) && (
                                <Radio.Group
                                    size="small"
                                    className="campaign-trigger-editor-rec-schedule-monthly-type-select"
                                    value={schedule.getRelativeType() ?? scheduleType}
                                    onChange={this.handleMonthlyTypeChange}
                                >
                                    <Tooltip title="The schedule will deliver once a month on the chosen day number of the month.">
                                        <Radio.Button value="monthly_specific">Specific Day</Radio.Button>
                                    </Tooltip>
                                    <Tooltip title="The schedule will deliver once a month on the first day of the month.">
                                        <Radio.Button value="first_day">First Day</Radio.Button>
                                    </Tooltip>
                                    <Tooltip title="The schedule will deliver once a month on the last day of the month.">
                                        <Radio.Button value="last_day">Last Day</Radio.Button>
                                    </Tooltip>
                                    <Tooltip title="The schedule will deliver once a month on the chosen relative date of the month.">
                                        <Radio.Button value="relative_day">Relative Day</Radio.Button>
                                    </Tooltip>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </div>

                {scheduleSuperType !== 'monthly' && (
                    <div className="schedule-row">
                        <div className="schedule-label">
                            <span>{scheduleType === 'daily' ? 'Daily' : 'Weekly'} Schedule: </span>
                        </div>
                        <div className="schedule-value">
                            <EveryNSchedule
                                key={scheduleType}
                                type={scheduleType as EveryNScheduleType}
                                defaultValue={this.context.mode > 0 ? schedule?.serialize() : undefined}
                                value={schedule.serialize()}
                                onChange={this.handleMonthlyEveryNChange}
                            />
                        </div>
                    </div>
                )}

                {scheduleType === 'monthly_specific' && (
                    <div className="schedule-row">
                        <div className="schedule-label">
                            <span>Specific Day: </span>
                        </div>
                        <div className="schedule-value">
                            <Select
                                className="specific-day-select"
                                size="small"
                                defaultValue={1}
                                value={schedule.getSpecificDay() ?? 1}
                                onChange={this.handleMonthlySpecificDayChange}
                            >
                                {numRange(1, 31).map((day) => (
                                    <Select.Option key={day} value={day}>
                                        {day}
                                    </Select.Option>
                                ))}
                            </Select>
                            {(schedule.getSpecificDay() ?? 1) > 28 && (
                                <div className={getClassNames('form-layout-row', 'specific-day-warning')}>
                                    <div className={getClassNames('form-layout-row-sub')}>
                                        <WarningOutlined />
                                        <span>
                                            This scheduled delivery will not deliver in months that do not have the
                                            chosen number of days. To always target the last day of the month use the
                                            “Relative Day” option.
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {schedule.getRelativeType() === 'relative_day' && (
                    <div className="schedule-row">
                        <div className="schedule-label">
                            <span>Relative Day: </span>
                        </div>
                        <div className="relative-day-wrapper schedule-value">
                            <Select
                                className="relative-day-ordinal-select"
                                dropdownClassName="relative-day-dropdown"
                                size="small"
                                value={schedule.getRelativeDayOrdinal()}
                                onChange={this.handleRelativeDayOrdinalChange}
                            >
                                <Select.Option value="first">First</Select.Option>
                                <Select.Option value="second">Second</Select.Option>
                                <Select.Option value="third">Third</Select.Option>
                                <Select.Option value="fourth">Fourth</Select.Option>
                                <Select.Option value="fifth">Fifth</Select.Option>
                            </Select>

                            <DayPartSelect
                                className="relative-day-select"
                                size="small"
                                mode="single"
                                daysOnly={true}
                                dayFormat={'ddd'}
                                weekBeginsOnSunday={true}
                                defaultValue={this.buildAllowedDayMap([schedule.getRelativeDay()!])}
                                value={this.buildAllowedDayMap([schedule.getRelativeDay()!])}
                                onChange={this.handleRelativeDayValueChange}
                            />
                        </div>
                    </div>
                )}

                <div className="schedule-row">
                    <div className="schedule-label">
                        <span>Delivery Time: </span>
                    </div>
                    <div className="schedule-value">
                        <div className="time-picker">
                            <BetterTimePicker
                                size="small"
                                placeholder="Start time"
                                use12Hours={true}
                                format="h A"
                                defaultValue={defaultTime}
                                value={timeValue}
                                onTimeChange={this.handleScheduleTimeChange}
                                showNow={false}
                            />
                        </div>
                        <span className="schedule-timezone ant-input-group-addon">{timezone}</span>
                    </div>
                </div>
            </div>
        )
    }

    protected buildAllowedDayMap(days: string[]) {
        const map: any = {}
        days.filter((d) => !!d).forEach((d) => (map[d] = true))

        return map
    }

    protected handleScheduleTypeChange = (type: string) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()
        const config = savedReportSchedule.getConfiguration()
        const schedule = config.getSchedule()!
        const scheduleType = schedule?.getType()
        const scheduleSuperType = scheduleType?.replace(/_relative|_specific/, '')

        if (type !== 'monthly') {
            if (scheduleSuperType === 'monthly') {
                schedule.setFrequency(1)
            }
            schedule.setAllowedDays(type === 'daily' ? ALL_SCHEDULE_DAYS : ['monday'])
        } else {
            // Set default monthly type
            type = 'monthly_relative'
            schedule.setRelativeDay('monday')
            schedule.setRelativeType('relative_day')
            schedule.setRelativeDayOrdinal(DayOrdinal.FIRST)
        }

        schedule.setType(type as CampaignRecurrenceType)

        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleMonthlyTypeChange = (ev: RadioChangeEvent) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()
        const config = savedReportSchedule.getConfiguration()
        const schedule = config.getSchedule()!

        let type = ev.target.value
        if (type === 'monthly_specific') {
            schedule.setSpecificDay(1)
            schedule.setRelativeType(undefined)
        } else {
            schedule.setRelativeType(type)
            if (type === 'relative_day') {
                schedule.setRelativeDayOrdinal(DayOrdinal.FIRST)
            }

            type = 'monthly_relative'
        }

        schedule.setType(type)
        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleMonthlyEveryNChange = (value: IEveryNScheduleValue | undefined) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()
        const config = savedReportSchedule.getConfiguration()
        const schedule = config.getSchedule()!

        schedule.setFrequency(value?.frequency)
        schedule.setAllowedDays(value?.allowed_days)

        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleMonthlySpecificDayChange = (day: number) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()
        const config = savedReportSchedule.getConfiguration()
        const schedule = config.getSchedule()!

        schedule.setSpecificDay(day)

        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleRelativeDayOrdinalChange = (ordinal: any) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()
        const config = savedReportSchedule.getConfiguration()
        const schedule = config.getSchedule()!

        schedule.setRelativeDayOrdinal(ordinal)

        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleRelativeDayValueChange = async (days: IDayPartValue) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()
        const config = savedReportSchedule.getConfiguration()
        const schedule = config.getSchedule()!

        const dayKeys = Object.keys(days).filter((d) => !!days[d])
        schedule.setRelativeDay(dayKeys.length > 0 ? dayKeys[0] : undefined)

        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleScheduleTimeChange = (dtpValue: any) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()
        const config = savedReportSchedule.getConfiguration()
        const schedule = config.getSchedule()!

        schedule.setSpecificTime(dtpValue?.format('HH:mm'))

        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleStartDateChange = (date: Moment) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()

        const startDate = !date ? undefined : date.format('YYYY-MM-DD')
        savedReportSchedule.setDateStart(startDate)

        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleEndDateChange = (date: Moment) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()

        const endDate = !date ? undefined : date.format('YYYY-MM-DD')
        savedReportSchedule.setDateEnd(endDate)

        this.context.setSavedReportSchedule(savedReportSchedule)
    }

    protected handleEndDateToggle = (endDateEnabled: boolean) => {
        const savedReportSchedule = this.context.savedReportSchedule.clone()

        if (!endDateEnabled) {
            savedReportSchedule.setDateEnd(undefined)
        }

        this.setState({ endDateEnabled: !this.state.endDateEnabled })
    }
}
