import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NotificationNativeIosContentModel } from './notification-native-ios-content.model'
import { NotificationNativeAndroidContentModel } from './notification-native-android-content.model'
import { NotificationDefaultContentModel } from './notification-default-content.model'
import { NotificationWebContentModel } from './notification-web-content.model'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { SharedContent } from '../../interfaces/notification-shared-content'

export class NotificationContentModel {
    public static build(props: NotificationContentModel | any): NotificationContentModel {
        const data = props instanceof NotificationContentModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationContentModel()
        model.setDeliveryChannels(data.deliveryChannels ?? scData.delivery_channels ?? model.getDeliveryChannels())
        model.setDefaultContent(data.default)
        model.setWebContent(data.web)
        model.setNativeIosContent(data.nativeIos ?? scData.native_ios)
        model.setNativeAndroidContent(data.nativeAndroid ?? scData.native_android)

        return model
    }

    private deliveryChannels: DeliveryChannel[]
    private default: NotificationDefaultContentModel
    private web: NotificationWebContentModel
    private nativeIos: NotificationNativeIosContentModel
    private nativeAndroid: NotificationNativeAndroidContentModel

    public clone(): NotificationContentModel {
        return NotificationContentModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            delivery_channels: this.getDeliveryChannels(),
            default: this.getDefaultContent(),
            web: this.getWebContent(),
            native_ios: this.getNativeIosContent(),
            native_android: this.getNativeAndroidContent(),
        })
    }
    public getDeliveryChannels(): DeliveryChannel[] {
        return this.deliveryChannels
    }
    public setDeliveryChannels(deliveryChannels: DeliveryChannel[]): void {
        this.deliveryChannels = deliveryChannels
    }

    public getDefaultContent(): NotificationDefaultContentModel {
        return this.default
    }
    public setDefaultContent(content: NotificationDefaultContentModel): void {
        this.default = NotificationDefaultContentModel.build(content ?? {})
    }

    public getWebContent(): NotificationWebContentModel {
        return this.web
    }
    public setWebContent(content: NotificationWebContentModel): void {
        this.web = NotificationWebContentModel.build(content ?? {})
    }

    public getNativeIosContent(): NotificationNativeIosContentModel {
        return this.nativeIos
    }
    public setNativeIosContent(content: NotificationNativeIosContentModel | undefined): void {
        this.nativeIos = NotificationNativeIosContentModel.build(content ?? {})
    }

    public getNativeAndroidContent(): NotificationNativeAndroidContentModel {
        return this.nativeAndroid
    }
    public setNativeAndroidContent(content: NotificationNativeAndroidContentModel | undefined): void {
        this.nativeAndroid = NotificationNativeAndroidContentModel.build(content ?? {})
    }

    public setChannelContent(
        content:
            | NotificationDefaultContentModel
            | NotificationWebContentModel
            | NotificationNativeIosContentModel
            | NotificationNativeAndroidContentModel
            | SharedContent,
    ): void {
        if (content instanceof NotificationDefaultContentModel) {
            this.setDefaultContent(content)
        } else if (content instanceof NotificationWebContentModel) {
            this.setWebContent(content)
        } else if (content instanceof NotificationNativeIosContentModel) {
            this.setNativeIosContent(content)
        } else if (content instanceof NotificationNativeAndroidContentModel) {
            this.setNativeAndroidContent(content)
        }
    }
}
