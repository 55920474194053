import * as React from 'react'
import { getClassNames } from '../../../_utils/classnames'
import { INotificationBuilderState } from '../interfaces/notification-builder-state.interface'
import { getVariantTtlConfig, isAllFeatTest, isContentTest, isDeliveryTest } from '../helpers'
import NotificationContentBuilder from './notification-content-builder'
import NotificationDeliveryBuilder from './notification-delivery-builder'
import { Well } from '@pushly/aqe/lib/components'
import { DomainDto } from '../../../dtos/domain'
import { PromiseableProp } from '../../../_utils/promiseable-prop'
import { NotificationVariantModel } from '../../../models/notification/notification-variant.model'
import { NotificationContentModel } from '../../../models/notification/notification-content.model'
import { useService } from '../../../hooks/use-service'
import { AppState } from '../../../stores/app'
import { NotificationBuilderLevel, NotificationBuilderTheme } from '../enums'
import { AccountDto } from '../../../dtos/account.dto'
import { NotificationDeliveryType } from '../../../enums/notification-delivery-type'

interface INotificationVariantBuilder {
    className?: string
    domain: DomainDto
    account?: AccountDto
    builder: INotificationBuilderState
    id: number
    variant: NotificationVariantModel
    manager?: JSX.Element
    selected?: boolean
    onChange: (variant: NotificationVariantModel, id: number) => void
    onSubmit: (isDraft?: boolean) => void
    onCancel?: () => void
    keywordOptions?: PromiseableProp<any>
    customMacroOptions?: PromiseableProp<any>
    hideImageMacroToggle?: boolean
    hideDelivery?: boolean
    hideTtlSettings?: boolean
    isDraft?: boolean
    isTemplate?: boolean
}

const NotificationVariantBuilder = (props: INotificationVariantBuilder) => {
    const {
        className,
        account,
        domain,
        builder,
        id,
        variant,
        manager,
        onChange,
        onSubmit,
        onCancel,
        keywordOptions,
        customMacroOptions,
        hideImageMacroToggle,
        hideDelivery,
        hideTtlSettings,
        isDraft,
        isTemplate,
    } = props

    const { currentUser, currentDomain } = useService<AppState>(AppState)
    const level = builder.level
    const isOrgLevel = level === NotificationBuilderLevel.ORG
    const isDomainLevel = level === NotificationBuilderLevel.DOMAIN
    const isCampaignLevel = level === NotificationBuilderLevel.CAMPAIGN
    const levelClassName = isCampaignLevel ? 'campaign' : isOrgLevel ? 'org' : 'domain'

    const testTypes = builder.test?.getTestTypes()
    const showEcommSection = isCampaignLevel && !!builder.showEcommStrategy

    const meta = variant.getDisplayMeta()

    /**
     * ***
     * all internal user roles are prohibited from sending on non-demo|internal domains
     * ***
     */
    const submitAllowed =
        !currentDomain?.isPreviewDomain &&
        (!currentUser?.isInternalUser || currentDomain?.isDemoDomain || currentDomain?.isInternal)

    let submitText = variant.getDelivery().getType() === NotificationDeliveryType.IMMEDIATE ? 'Send Now' : 'Schedule'
    if (isTemplate) {
        if (builder.mode === 'create') {
            submitText = 'Create Template'
        } else {
            submitText = 'Update Template'
        }
    }

    const isMobileTheme = builder.theme === NotificationBuilderTheme.MOBILE
    const isNewNotification = !Object.keys(meta).length
    const isScheduledNotification = !isNewNotification && !meta.isDraft && !meta.notEditable

    let submitActions
    if (!isMobileTheme && !isTemplate && (isNewNotification || !isScheduledNotification) && isDomainLevel) {
        submitActions = [
            {
                text: meta.isDraft ? 'Update Draft ' : 'Save as Draft',
                onClick: () => {
                    onSubmit(true)
                },
            },
        ]
    }

    const submitProps: Well['props'] = {
        hideFooter: false,
        disableSubmit: !submitAllowed,
        onSubmit,
        onCancel,
        submitText,
        submitActions,
    }

    return (
        <div
            className={getClassNames('variant-wrapper', levelClassName, className, {
                'selected-variant': id === builder.selectedVariantIdx,
                conjoined: (testTypes?.length ?? 0) === 2,
            })}
        >
            <Well
                className={getClassNames('variant-content-wrapper', 'nested')}
                title={isAllFeatTest(testTypes) ? 'Content & Delivery' : 'Content'}
                headerExtension={isContentTest(testTypes) ? manager : undefined}
                hideFooter={true}
                {...(hideDelivery && !isCampaignLevel ? submitProps : {})}
            >
                <NotificationContentBuilder
                    level={level}
                    theme={builder.theme}
                    loading={builder.loading}
                    domain={domain}
                    disabled={meta.notEditable}
                    experienceDisabled={meta.notEditable || meta.delivering}
                    value={variant.getContent()}
                    channels={builder.channels}
                    onChange={(v) => {
                        const update = variant.clone()
                        update.setContent(v)
                        onChange(update, id)
                    }}
                    onClear={() => {
                        const update = variant.clone(false)
                        update.setContent(NotificationContentModel.build({}))

                        // reset default TTL
                        const expOpt = update.getContent().getDefaultContent().getExperienceOptions()
                        const ttlSet = getVariantTtlConfig(domain, expOpt)
                        expOpt.setTtlSeconds(ttlSet.seconds)
                        expOpt.setTtlMetric(ttlSet.metric)

                        onChange(update, id)
                    }}
                    keywordOptions={keywordOptions}
                    customMacroOptions={customMacroOptions}
                    hideMacroToggle={hideImageMacroToggle}
                    hideTtlSettings={hideTtlSettings}
                />
            </Well>

            {!hideDelivery && (
                <Well
                    className={getClassNames('variant-delivery-wrapper', 'nested', {
                        // 'with-distribution-footer': isDeliveryTest(testTypes),
                    })}
                    title="Delivery"
                    headerExtension={isDeliveryTest(testTypes) && !isAllFeatTest(testTypes) ? manager : undefined}
                    hideFooter={true}
                    {...submitProps}
                >
                    <NotificationDeliveryBuilder
                        domain={domain}
                        account={account}
                        loading={builder.loading}
                        builder={builder}
                        hideTypeLabel={isAllFeatTest(testTypes)}
                        defaultTimeZone={account?.timezone ?? domain.timezone}
                        disabled={meta.notEditable || meta.delivering}
                        value={variant.getDelivery()}
                        onChange={(v) => {
                            const update = variant.clone()
                            update.setDelivery(v)
                            onChange(update, id)
                        }}
                    />
                </Well>
            )}
        </div>
    )
}

export default NotificationVariantBuilder
