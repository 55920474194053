import * as React from 'react'
import { useHistory } from 'react-router-dom'
import * as queryString from 'query-string'
import { Container } from 'typescript-ioc/es5'
import { AppService, NotificationService } from '../../services'
import { AppState } from '../../stores/app'
import { PageHeader } from '../../components/page-header/page-header'
import { tryParseInt } from '../../_utils/try-parse'
import { useRefEffect } from '../../hooks/use-ref-effect'
import { getCustomMacroFields, getDomainKeywords, getEnabledDeliveryChannels } from '../../_utils/domain'
import NotificationsLoader from '../../components/notification-builder/notifications-loader'
import NotificationBuilder from '../../components/notification-builder/notification-builder'
import NotificationBuilderMain from '../../components/notification-builder/elements/notification-builder-main'
import NotificationTestBuilder from '../../components/notification-builder/elements/notification-test-builder'
import NotificationAudienceBuilder from '../../components/notification-builder/elements/notification-audience-builder'
import NotificationVariantBuilder from '../../components/notification-builder/elements/notification-variant-builder'
import { useActiveDomainChangeEffect } from '../../hooks/use-active-domain-change-effect'
import {
    buildPreviewOptions,
    buildSubmittedNotificationPackages,
    validateSaveDraft,
} from '../../components/notification-builder/helpers'
import { ApiVersion } from '../../enums/api-version.enum'
import { DeliveryChannelSelector } from '@pushly/aqe/lib/components/delivery-channel-selector/delivery-channel-selector'
import { NotificationTemplateSelector } from '../../components/notification-template-selector/notification-template-selector'
import { Skeleton } from 'antd'
import { onResponseError403 } from '../../_utils/on-response-error-403'
import { NotificationBuilderTheme } from '../../components/notification-builder/enums'

export const MobileSendNotification = () => {
    useActiveDomainChangeEffect((activeDomain, appSvc) => {
        appSvc.routeWithinDomain('/notifications')
    })

    const { push: updateRoute } = useHistory()
    const qs = queryString.parse(location.search)
    const appState: AppState = Container.get(AppState)
    const appService: AppService = Container.get(AppService)
    const notifService: NotificationService = Container.get(NotificationService)

    const domain = appState.currentDomain!
    const requestedNotifId = tryParseInt(qs.notification_id?.toString() ?? '') || undefined
    const requestedTestId = tryParseInt(qs.test?.toString() ?? '') || undefined
    const requestTemplateId = tryParseInt(qs.template_id?.toString() ?? '') || undefined
    const [keywordOptions] = useRefEffect(() => getDomainKeywords(domain.id), [domain.id])
    const [customMacroOptions] = useRefEffect(() => getCustomMacroFields(domain.id), [domain.id])

    const [templateId, setTemplateId] = React.useState<number | undefined>(requestTemplateId)
    React.useEffect(() => {
        setTemplateId(tryParseInt(qs.template_id?.toString() ?? '') || undefined)
    }, [])

    const goBackToNotifs = (isDraft?: boolean) => {
        updateRoute(isDraft ? `/domains/${domain.id}/notifications/drafts` : `/domains/${domain.id}/dashboard`)
    }

    const goBackToPreviousNotifsTab = () => {
        appService.routeBack()
    }

    const activeDomainChannels = getEnabledDeliveryChannels(domain, true)
    return (
        <>
            <PageHeader title="Create Notification" />

            <NotificationsLoader
                domainId={domain.id}
                notifId={requestedNotifId}
                testId={requestedTestId}
                templateId={templateId}
                errorHandler={onResponseError403(() => {
                    goBackToNotifs()
                })}
            >
                {(notifications) => {
                    return (
                        <NotificationBuilder
                            mode="create"
                            theme={NotificationBuilderTheme.MOBILE}
                            domain={domain}
                            notifications={notifications}
                            defaultSelectedNotifId={requestedNotifId}
                            defaultSelectedTemplateId={templateId}
                        >
                            {(props) => {
                                const {
                                    builder,
                                    dispatchChanges,
                                    validateSubmit,
                                    onChannelChange,
                                    onAudienceChange,
                                    variants,
                                    onVariantChange,
                                    variantManager,
                                    onSelectedTemplateChange,
                                } = props
                                const activeVariant = variants[builder.selectedVariantIdx]

                                return builder.loading ? (
                                    <Skeleton active={true} loading={true} />
                                ) : (
                                    <NotificationBuilderMain>
                                        {builder.availableTemplates.length > 0 && !requestedNotifId && (
                                            <NotificationTemplateSelector
                                                builder={builder}
                                                domain={domain}
                                                onChange={onSelectedTemplateChange}
                                                value={builder.selectedTemplate}
                                            />
                                        )}
                                        {activeDomainChannels.length > 1 && (
                                            <DeliveryChannelSelector
                                                type="multiple"
                                                loading={builder.loading}
                                                value={builder.channels}
                                                onChange={onChannelChange}
                                                visibleChannels={activeDomainChannels}
                                                compact={true}
                                            />
                                        )}

                                        <NotificationAudienceBuilder
                                            loading={builder.loading}
                                            domain={domain}
                                            setBuilder={dispatchChanges}
                                            value={activeVariant.getAudience()}
                                            onChange={onAudienceChange}
                                            channels={builder.channels}
                                        />

                                        <NotificationVariantBuilder
                                            key={activeVariant.getId()}
                                            id={builder.selectedVariantIdx}
                                            domain={domain}
                                            builder={builder}
                                            variant={activeVariant}
                                            manager={variantManager}
                                            hideImageMacroToggle={true}
                                            onChange={onVariantChange}
                                            onCancel={goBackToPreviousNotifsTab}
                                            onSubmit={async (isDraft?: boolean) => {
                                                let sent = false
                                                let saved = false
                                                if (isDraft) {
                                                    const valid = await validateSaveDraft(builder)

                                                    if (valid) {
                                                        const builds = buildSubmittedNotificationPackages(
                                                            domain,
                                                            builder,
                                                            appState.flags,
                                                        )

                                                        if (builds.length === 1) {
                                                            saved = await notifService.saveDraft(domain.id, builds[0], {
                                                                cancellationKey: `notif-save-draft`,
                                                                version: ApiVersion.V4,
                                                            })
                                                        } else {
                                                            saved = await notifService.createTest(
                                                                domain.id,
                                                                builds[0].abTest,
                                                                builds,
                                                                `notif-send`,
                                                                true,
                                                            )
                                                        }
                                                    }
                                                } else {
                                                    const valid = await validateSubmit(builder, domain, appState.flags)

                                                    if (valid) {
                                                        const builds = buildSubmittedNotificationPackages(
                                                            domain,
                                                            builder,
                                                            appState.flags,
                                                            undefined,
                                                            null,
                                                            true,
                                                        )

                                                        if (builds.length === 1) {
                                                            sent = await notifService.sendNotification(
                                                                domain.id,
                                                                builds[0],
                                                                {
                                                                    cancellationKey: `notif-send`,
                                                                    version: ApiVersion.V4,
                                                                },
                                                            )
                                                        } else {
                                                            sent = await notifService.createTest(
                                                                domain.id,
                                                                builds[0].abTest,
                                                                builds,
                                                                `notif-send`,
                                                            )
                                                        }
                                                    }
                                                }

                                                if (sent || saved) {
                                                    goBackToNotifs(saved)
                                                }
                                            }}
                                            keywordOptions={keywordOptions.current}
                                            customMacroOptions={customMacroOptions.current}
                                        />
                                    </NotificationBuilderMain>
                                )
                            }}
                        </NotificationBuilder>
                    )
                }}
            </NotificationsLoader>
        </>
    )
}
