import React from 'react'
import { getClassNames } from '../../_utils/classnames'
import { MACRO_MGR_BASE_CLASSNAME } from './constants'
import { MacroSelectOption } from './macro-select-option'
import { MacroSelectOptionGroupProps } from './types'

export const MacroSelectOptionGroup = ({ group, onItemSelect, ...props }: MacroSelectOptionGroupProps) => {
    return (
        <li className={getClassNames(`${MACRO_MGR_BASE_CLASSNAME}-group`)}>
            <span className="heading">{group.label}</span>

            <ul>
                {group.options.map((macro, idx) => (
                    <MacroSelectOption key={idx} macro={macro} onClick={onItemSelect} {...props} />
                ))}
            </ul>
        </li>
    )
}
