export const AppMessageBuilderFieldNamesMap = {
    template: '',
    source: '',
    value: '',
    fgColor: 'font color',
    bgColor: 'background color',
    ctaPrimary: 'primary button',
    ctaSecondary: 'secondary button',
    bannerPosition: 'banner position',
    remoteContentUrl: 'remote content url',
    showCloseAction: 'show close action',
}

export const titlePlaceholder = 'Sample Title'
export const headerPlaceholder = 'Sample Header'
export const bannerBodyPlaceholder = `
<p style="text-align: center">
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
</p>
`
export const modalBodyPlaceholder = `
<p style="text-align: center">
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
</p>
`
export const fullScreenBodyPlaceholder = `
<p style="text-align: center">
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
<br />
<br />
Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
</p>
`
export const ctaLabelPlaceholder = 'Sample Button'

export const imagePlaceholderSource = '/assets/image-placeholder.png'

export const overridePlaceholderFontColor = '#dbdcdc'
