import React, { useEffect } from 'react'
import { ReachWell } from '@pushly/aqe/lib/components'
import { Loading3QuartersOutlined, PartitionOutlined } from '@ant-design/icons'
import { useAppMessageBuilderContext } from '../../../features/app-messages/context'
import { ReachByChannel } from '../../notification-builder/elements/notification-builder-reach'
import { numberWithCommas } from '../../../_utils/numeral'
import { Popover } from 'antd'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { getClassNames } from '../../../_utils/classnames'
import { DomainService } from '../../../services'
import { BatchService } from '../../../services/batch'
import { DomainDto } from '../../../dtos/domain'
import { useMountedRef } from '../../../_utils/use-mounted-ref'
import { Container } from 'typescript-ioc'
import './styles/app-message-builder-reach.scss'

type AMBRProps = {
    domain: DomainDto
}

export const AppMessageBuilderReach = (props: AMBRProps) => {
    const { domain } = props
    const [state, dispatch] = useAppMessageBuilderContext()

    const [_mounted, runIfMounted] = useMountedRef()
    const [currReach, setCurrReach] = React.useState<ReachByChannel | undefined>()
    const [loadingReach, setLoadingReach] = React.useState(true)

    const audience = state.variants[state.selectedVariantIdx].schedules[0].audience
    const targetedChannels = state.variants[state.selectedVariantIdx].template!.channels
    let reachByChannel: ReachByChannel = {
        total: 0,
        [DeliveryChannel.WEB]: 0,
        [DeliveryChannel.NATIVE_IOS]: 0,
        [DeliveryChannel.NATIVE_ANDROID]: 0,
    }

    useEffect(() => {
        if (!state.availableSegmentsLoaded) {
            return
        }

        const fetchReach = async () => {
            setLoadingReach(true)

            const domainSvc: DomainService = Container.get(DomainService)
            const domainsToCheck = Array.isArray(domain) ? domain : [domain]

            let res

            if (targetedChannels.length > 1) {
                res = await domainSvc.estimateSegmentReach(
                    domainsToCheck[0].id,
                    audience.segmentIds ?? [],
                    audience.excludedSegmentIds ?? [],
                    targetedChannels,
                    'count_by_channel',
                    ['APP_MESSAGE'],
                    {
                        cancellationKey: 'amba-fetch-reach',
                    },
                )

                for (const channel in res.data) {
                    if (channel) {
                        reachByChannel.total += res.data[channel]
                        reachByChannel[channel.toUpperCase()] = res.data[channel]
                    }
                }
            } else {
                res = await domainSvc.estimateSegmentReach(
                    domainsToCheck[0].id,
                    audience.segmentIds ?? [],
                    audience.excludedSegmentIds ?? [],
                    targetedChannels,
                    'count',
                    ['APP_MESSAGE'],
                    {
                        cancellationKey: 'amba-fetch-reach',
                    },
                )

                reachByChannel = { total: res.data }
            }

            if (!res.cancelled) {
                runIfMounted(() => {
                    setCurrReach(reachByChannel)
                    setLoadingReach(false)

                    dispatch({ type: 'patch', entity: 'reach-estimate', data: reachByChannel.total })
                })
            }
        }

        fetchReach()
    }, [
        JSON.stringify(audience.serialize()),
        state.availableSegmentsLoaded,
        state.availableSegments?.length,
        JSON.stringify(targetedChannels),
    ])

    const hasReachEstimate = currReach !== undefined
    return (
        <ReachWell
            className={getClassNames('app-message-builder-reach-estimate', 'nested', {})}
            actions={
                state.loading || loadingReach ? (
                    <Loading3QuartersOutlined spin={true} />
                ) : !hasReachEstimate ? (
                    <span>--</span>
                ) : (
                    <>
                        <span>{` ~ ${numberWithCommas(currReach?.total!)}`}</span>
                        {targetedChannels.length > 1 && (
                            <Popover
                                title="Reach by Channel"
                                overlayClassName="audience-channel-breakdown-popover"
                                content={targetedChannels.map((ch) => {
                                    return (
                                        <div key={ch} className="reach-breakdown-row">
                                            <span className="reach-breakdown-label">
                                                {DeliveryChannel.getLongName(ch)}:
                                            </span>
                                            <span className="reach-breakdown-value">
                                                {currReach && numberWithCommas(currReach?.[ch.toUpperCase()] ?? 0)}
                                            </span>
                                        </div>
                                    )
                                })}
                                placement="left"
                            >
                                <PartitionOutlined />
                            </Popover>
                        )}
                    </>
                )
            }
        >
            <div></div>
            <div></div>
        </ReachWell>
    )
}
