import React from 'react'
import { getClassNames } from '../../_utils/classnames'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { MacroSelectOptionProps } from './types'
import { MACRO_MGR_BASE_CLASSNAME } from './constants'

export const MacroSelectOption = ({ macro, onClick, context }: MacroSelectOptionProps) => {
    let isActive = context?.macro?.value?.trim() === macro.value.trim()

    return (
        <li
            className={getClassNames(`${MACRO_MGR_BASE_CLASSNAME}-item`, {
                ['active']: isActive,
            })}
            onClick={(e) => {
                e.preventDefault()
                onClick(macro)
            }}
        >
            <span className="label">{macro.label}</span>
            {isActive && (
                <span className="active-indicator">
                    <LegacyIcon type={'check'} />
                </span>
            )}
        </li>
    )
}
