import * as React from 'react'
import { AsyncButton } from '../../components/async-button/async-button.component'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService, NotificationService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { RecentNotificationCard } from './recent-notification-card'
import { NotificationScheduleService } from '../../services/notification-schedule'
import { NotificationScheduleDto } from '../../features/notifications/dtos/notification-schedule-dto'
import { InsightsService } from '../../services/insights'
import './recent-notifications.scss'
import { observe } from 'mobx'
import autobind from 'autobind-decorator'
import { NoNotificationCard } from './no-notification-card'
import { ApiVersion } from '../../enums/api-version.enum'
import { HOLD_OUT_COMPUTED_STATUS, StatusType } from '../../enums/status-type'
import { BetterSelect } from '../../components/better-select/better-select'
import { Tooltip } from 'antd'
import * as deepEqual from 'react-fast-compare'
import structuredClone from '@ungap/structured-clone'

const statusFilters = [
    { value: StatusType.COMPLETED.name, label: 'Delivered' },
    { value: StatusType.SCHEDULED.name, label: 'Scheduled' },
    { value: StatusType.DELIVERING.name, label: 'Delivering' },
    { value: HOLD_OUT_COMPUTED_STATUS, label: 'Hold Out' },
    { value: StatusType.CANCELLED.name, label: 'Cancelled' },
    { value: StatusType.FAILED.name, label: 'Failed' },
]

interface IRecentNotifsProps {
    className?: string
    limit?: number
}

interface IRecentNotifsState {
    reloading: boolean
    schedules: NotificationScheduleDto[]
    page: number
    selectedStatuses: string[]
    tmpSelectedStatuses?: string[]
}

export class RecentNotifications extends BetterComponent<IRecentNotifsProps, IRecentNotifsState> {
    public static readonly defaultNotificationLimit: number = 3

    public readonly defaultClassName: string = 'sw-mv-recent-notifications'

    protected appState: AppState
    protected appService: AppService
    protected notifService: NotificationService
    protected scheduleService: NotificationScheduleService
    protected insightsService: InsightsService

    protected disposeObservers: any[] = []

    public constructor(props: IRecentNotifsProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.notifService = Container.get(NotificationService)
        this.scheduleService = Container.get(NotificationScheduleService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            reloading: false,
            schedules: [],
            page: 1,
            selectedStatuses: statusFilters.map((f) => f.value),
        }
    }

    public UNSAFE_componentWillMount() {
        this.disposeObservers.push(observe(this.appState, 'currentDomainJsonData', () => this.fetchNotifications(true)))

        this.fetchNotifications()
    }

    public componentDidMount(): void {}

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.disposeObservers.forEach((fn) => fn())
    }

    public render(): React.ReactNode {
        const selectedStatuses = this.state.tmpSelectedStatuses
            ? [...this.state.tmpSelectedStatuses]
            : [...this.state.selectedStatuses]

        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    {this.state.schedules.length === 0 ? (
                        <NoNotificationCard loading={this.state.reloading} />
                    ) : (
                        <>
                            <div className={this.buildClassName('status-filter')}>
                                <BetterSelect
                                    className="status-filter-select"
                                    size="default"
                                    mode="multiple"
                                    prefix="Status"
                                    placeholder="Select Statuses"
                                    defaultValue={[...statusFilters]}
                                    value={selectedStatuses}
                                    options={statusFilters}
                                    onChange={(value: string[]) => {
                                        this.setState({ tmpSelectedStatuses: value })
                                    }}
                                    onClose={(value: string[]) => {
                                        const next = structuredClone(value)
                                        const curr = structuredClone(this.state.selectedStatuses)

                                        next.sort(Intl.Collator().compare)
                                        curr.sort(Intl.Collator().compare)

                                        this.setState({ selectedStatuses: next })
                                        if (!deepEqual(next, curr)) {
                                            this.fetchNotifications(true, next)
                                        }
                                    }}
                                    selectAllLabel="All Statuses"
                                    disableSearch={true}
                                    closeOnEscape={true}
                                    maxDisplayCount={2}
                                    maxDisplayFormatter={(options: any[]) => {
                                        const multi = options.length > 1
                                        return (
                                            <Tooltip title={options.map((o) => o.label).join(', ')}>
                                                {options.length} {multi ? 'Statuses' : 'Status'}
                                            </Tooltip>
                                        )
                                    }}
                                />
                            </div>
                            <div className={this.buildClassName('list')}>
                                {this.state.schedules.map((schedule) => (
                                    <RecentNotificationCard
                                        key={schedule.id}
                                        schedule={schedule}
                                        loading={this.state.reloading}
                                        onChange={this.handleNotificationChange}
                                    />
                                ))}
                            </div>
                            <div className={this.buildClassName('load-more')}>
                                <AsyncButton onClick={this.handleLoadMore} size="small">
                                    <span>Load more</span>
                                </AsyncButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    protected get notificationLimit(): number {
        let limit = this.props.limit || RecentNotifications.defaultNotificationLimit

        if (limit >= 5) limit = 5
        if (limit <= 0) limit = RecentNotifications.defaultNotificationLimit

        return limit
    }

    @autobind
    protected async handleNotificationChange(schedule: NotificationScheduleDto): Promise<void> {
        this.fetchNotifications(true)
    }

    @autobind
    protected async handleLoadMore(): Promise<void> {
        await this.setState(({ page }) => ({ page: page + 1 }))

        return this.fetchNotifications()
    }

    @autobind
    protected async fetchNotifications(reloadReplace: boolean = false, statusOverrides?: string[]): Promise<void> {
        if (!this.appState.currentDomain) {
            setTimeout(() => this.fetchNotifications(), 100)
            return
        }

        if (reloadReplace) {
            this.setState({
                reloading: true,
            })
        }

        let statuses = statusOverrides ?? this.state.selectedStatuses
        if (!statuses.length) {
            statuses = statusFilters.map((f) => f.value)
        }

        const opts = {
            limit: this.notificationLimit * this.state.page,
            source: 'manual,trigger,feed',
            includeSegments: true,
            status: statuses.join(','),
            page: 1,
        }

        const res = await this.scheduleService.fetchAllByDomainId(this.appState.currentDomain.id, {
            query: opts,
            cancellationKey: 'mv.rnl.fetch',
            showLoadingScreen: false,
            version: ApiVersion.V4,
        })

        if (res.ok) {
            // We are acutally pulling individual schedules in this scenario
            const schedules: NotificationScheduleDto[] = !!res.data ? res.data : []

            // Save and start render of data without stats
            this.setState({
                schedules,
                reloading: false,
            })
        }
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
