import React from 'react'
import { AppMessageColumnViewProps, AppMessageDataRecord } from './types'
import { ScheduleWindow } from '@pushly/models/lib/enums/schedule-window'
import { BASE_TIME_FORMAT_WITHOUT_TZ, SHORT_DATE_FORMAT, TZ_PLATFORM_DEFAULT } from '../../constants'
import moment from 'moment-timezone'
import { useDataViewContext } from '@pushly/aqe/lib/components/data-view/context'
import { Tooltip } from 'antd'
import { AppMessageScheduleViewable } from '../app-message-builder/model-extensions'
import { DomainDto } from '../../dtos/domain'

export const AppMessageRunDatesColumnView = (props: AppMessageColumnViewProps<AppMessageScheduleViewable>) => {
    const [state] = useDataViewContext<AppMessageDataRecord>()
    const { data, domain } = props

    const isStz = data.runDateWindow === ScheduleWindow.TIMEZONE

    let displayTz = TZ_PLATFORM_DEFAULT
    if (domain?.timezone) {
        displayTz = domain.timezone
    }

    const sendDateTz = data.runDateTimeZone ?? displayTz
    const startDate = moment.tz(data.runDateStartUtc, sendDateTz)
    const endDate = moment.tz(data.runDateEndUtc, sendDateTz)

    // stz sends should keep original send/time
    if (!isStz) {
        startDate.tz(displayTz)
    }

    return (
        <div className="composite-stack">
            <Tooltip title={`${startDate.format(BASE_TIME_FORMAT_WITHOUT_TZ)} ${isStz ? 'STZ' : ''}`}>
                <span>{startDate.format(SHORT_DATE_FORMAT)}</span>
            </Tooltip>
            {!data.runDateEndUtc ? (
                <span>Ongoing</span>
            ) : (
                <span>
                    <span className="lower">through&nbsp;</span>

                    <Tooltip title={startDate.format(BASE_TIME_FORMAT_WITHOUT_TZ)}>
                        <span>{endDate.format(SHORT_DATE_FORMAT)}</span>
                    </Tooltip>
                </span>
            )}
        </div>
    )
}
