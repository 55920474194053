import { Container, Singleton } from 'typescript-ioc/es5'
import { AbstractEntityService } from '@pushly/aqe/lib/services/index'
import { AppService } from './app'
import IApiCallOptions from '../interfaces/api-call-options'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import { ApiTokenDataRecord } from '../components/api-tokens/types'
import * as querystring from 'query-string'
import aqe from '@pushly/aqe'
import { axiosFetch } from '../config/axios-setup'
import { ApiToken } from '@pushly/models/lib/structs/api-tokens/api-token'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { NewApiTokenRequest } from '@pushly/models/lib/structs/api-tokens/new-api-token-request'
import { simpleNotification } from '../_utils/utils'
import { IApiResponse } from '@pushly/aqe/lib/interfaces/index'

@Singleton
export class ApiKeyService extends AbstractEntityService {
    private appService: AppService

    public constructor() {
        super()

        this.appService = Container.get(AppService)
    }

    public async fetchApiKeys(
        level: 'account' | 'domain',
        id: number,
        opts: IApiCallOptions = { showLoadingScreen: true },
    ): Promise<IServiceApiResponse<ApiTokenDataRecord[]>> {
        const options = querystring.stringify(opts?.query ?? {})
        let response: IServiceApiResponse<ApiTokenDataRecord[]> = {
            ok: false,
            data: [],
        }

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceUrl = this.serviceUrlForEntity(level, id, options)

        try {
            const req = await axiosFetch('get', {
                url: serviceUrl,
            })

            const { data: apiTokens, ...reqMeta } = req.data
            response.ok = true
            response.data = apiTokens.map((token) => ApiToken.parseJsonObject(token))
            response.meta = reqMeta
        } catch (error) {
            response.error = error
            handleResponseErrorMessage(error, {
                onCancelled: () => (response.cancelled = true),
            })
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return response
    }

    public async createApiKey(
        level: 'account' | 'domain',
        id: number,
        opts?: IApiCallOptions,
        createDto?: NewApiTokenRequest,
    ): Promise<IServiceApiResponse<ApiTokenDataRecord | null>> {
        let response: IServiceApiResponse<ApiTokenDataRecord | null> = {
            ok: false,
            data: null,
        }

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const serviceUrl = this.serviceUrlForEntity(level, id)

        try {
            const req = await axiosFetch('post', {
                url: serviceUrl,
                data: createDto,
                config: { timeout: 15000 },
            })

            const { data: apiToken, ...reqMeta } = req.data

            response.ok = true
            response.data = apiToken
            response.meta = reqMeta
        } catch (error) {
            // Axios uses the ECONNABORTED code for request timeouts
            // In later versions (starting in 0.21.2), ETIMEDOUT can be used, but requires
            // a transitional object set on request config object as documented here:
            // https://github.com/axios/axios/issues/1543#issuecomment-1478866117
            if (error.code === 'ECONNABORTED') {
                simpleNotification(
                    'error',
                    'An error occurred when attempting to generate the API Token. Please try again or contact your account manager for help.',
                )
            } else {
                response.error = error
                handleResponseErrorMessage(error, {
                    onCancelled: () => (response.cancelled = true),
                })
            }
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return response
    }

    public async revokeApiKey(
        level: 'account' | 'domain',
        id: number,
        userId: number,
        opts?: IApiCallOptions,
    ): Promise<IApiResponse<boolean>> {
        let ok = false

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const serviceUrl = `${aqe.defaults.publicApiDomain}/v3/${level}s/${id}/api-keys/${userId}`

        try {
            const req = await axiosFetch(
                'delete',
                {
                    url: serviceUrl,
                    config: { timeout: 15000 },
                },
                opts?.cancellationKey,
            )

            ok = true
        } catch (error) {
            // Axios uses the ECONNABORTED code for request timeouts
            // In later versions (starting in 0.21.2), ETIMEDOUT can be used, but requires
            // a transitional object set on request config object as documented here:
            // https://github.com/axios/axios/issues/1543#issuecomment-1478866117
            if (error.code === 'ECONNABORTED') {
                simpleNotification(
                    'error',
                    'An error occurred when attempting to revoke the API Token. Please try again or contact your account manager for help.',
                )
            } else {
                handleResponseErrorMessage(error)
            }
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok }
    }

    public async regenerateApiKey(
        accountId: number,
        apiKeyId: number,
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<ApiTokenDataRecord | null>> {
        let response: IServiceApiResponse<ApiTokenDataRecord | null> = {
            ok: false,
            data: null,
        }

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const serviceUrl = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/api-keys/${apiKeyId}/regenerate`

        try {
            const req = await axiosFetch('post', {
                url: serviceUrl,
            })

            const { data: apiToken, ...reqMeta } = req.data

            response.ok = true
            response.data = apiToken
            response.meta = reqMeta
        } catch (error) {
            response.error = error
            handleResponseErrorMessage(error, {
                onCancelled: () => (response.cancelled = true),
            })
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return response
    }

    protected serviceUrlForEntity(level: 'account' | 'domain', levelId: number, options?: string) {
        return `${aqe.defaults.publicApiDomain}/v3/${level}s/${levelId}/api-keys?${options}`
    }
}
