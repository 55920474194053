import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { TeamOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import { NotificationDto } from '../../features/notifications/index'
import { NotificationScheduleDto } from '../../features/notifications/dtos/notification-schedule-dto'
import { SwLink } from '../sw-link/sw-link'
import './audience-badge.scss'
import { Segment } from '@pushly/models/lib/structs/segment'
import { SegmentDto } from '../../dtos/segment'
import { SegmentType } from '@pushly/models/lib/enums/segment-type'
import { AppMessageScheduleViewable } from '../app-message-builder/model-extensions'

interface IProps extends IBadgeProps {
    source: NotificationDto | NotificationScheduleDto | AppMessageScheduleViewable
}

export class AudienceBadge extends React.PureComponent<IProps> {
    protected appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render() {
        const { source } = this.props

        const allDisplay = (segment: Segment | SegmentDto) => {
            // account for old 'All' segment
            const name = segment.name === 'All' ? 'All Subscribers' : segment.name
            return (
                <span className="included-badge">
                    <span className="included-icon">
                        <TeamOutlined />
                    </span>
                    <SwLink href={this.appService.routeWithinDomain(`/segments/${segment.id}/summary`, true)}>
                        {name}
                    </SwLink>
                </span>
            )
        }

        const isUsingSubscriberIds =
            Array.isArray(source.audience.subscriberIds) || Array.isArray(source.audience.externalSubscriberIds)
        let allPushSubsSegment: (Segment | SegmentDto) | undefined
        let allAppUsersSegment: (Segment | SegmentDto) | undefined
        let legacyDefaultAllSegment: (Segment | SegmentDto) | undefined

        if (source.audience.segments!.length === 1) {
            if (!isUsingSubscriberIds) {
                if (source.audience.segments![0].type === SegmentType.ALL_PUSH_SUBSCRIBERS) {
                    allPushSubsSegment = source.audience.segments![0]
                } else if (source.audience.segments![0].type === SegmentType.ALL_APP_USERS) {
                    allAppUsersSegment = source.audience.segments![0]
                } else if (source.audience.segments![0].isDefault) {
                    legacyDefaultAllSegment = source.audience.segments![0]
                }
            }
        }
        const isUsingExcludedSegments =
            !isUsingSubscriberIds && !!source.audience.excludedSegments && source.audience.excludedSegments.length > 0

        let badgeSet: React.ReactNode = allDisplay

        if (isUsingSubscriberIds) {
            badgeSet = (
                <span className="included-badge">
                    <span className="included-icon">
                        <TeamOutlined />
                    </span>
                    <span>Subscriber IDs</span>
                </span>
            )
        } else {
            const segments: (Segment | SegmentDto)[] = (source.audience.segments || []).slice()
            const excludedSegments = (source.audience.excludedSegments || []).slice()
            const nonDefaultSegments =
                allAppUsersSegment || allPushSubsSegment || legacyDefaultAllSegment
                    ? segments
                    : segments.filter((s) => !s.isDefault)
            const allDisplaySegment = allAppUsersSegment || allPushSubsSegment || legacyDefaultAllSegment

            let includedBadge: any
            let excludedBadge: any

            if (nonDefaultSegments.length > 0) {
                includedBadge =
                    allAppUsersSegment || allPushSubsSegment || legacyDefaultAllSegment ? (
                        allDisplay(allDisplaySegment!)
                    ) : (
                        <span className="included-badge">
                            <span className="included-icon">
                                <TeamOutlined />
                            </span>
                            <span>{nonDefaultSegments.length}</span>
                            <span>Included</span>
                        </span>
                    )
            }

            if (excludedSegments.length > 0) {
                excludedBadge = (
                    <span className="excluded-badge">
                        <span className="excluded-icon">
                            <TeamOutlined />
                        </span>
                        <span>{excludedSegments.length}</span>
                        <span>Excluded</span>
                    </span>
                )
            }

            badgeSet = (
                <>
                    {!!includedBadge &&
                        (allAppUsersSegment ||
                        allPushSubsSegment ||
                        legacyDefaultAllSegment ||
                        this.props.hideTooltip ? (
                            includedBadge
                        ) : (
                            <Popover
                                getPopupContainer={() => this.appService.getAppContainer()}
                                overlayClassName="nd-excluded-popover"
                                title="Included Segments"
                                content={nonDefaultSegments.map((s) => (
                                    <div key={s.id}>
                                        <SwLink
                                            href={this.appService.routeWithinDomain(`/segments/${s.id}/summary`, true)}
                                        >
                                            {/^all$/i.test(s.name) ? 'All Subscribers' : s.name}
                                        </SwLink>
                                    </div>
                                ))}
                            >
                                {includedBadge}
                            </Popover>
                        ))}
                    {!!excludedBadge && (
                        <>
                            <span className="details-divider">|</span>

                            {this.props.hideTooltip ? (
                                excludedBadge
                            ) : (
                                <Popover
                                    getPopupContainer={() => this.appService.getAppContainer()}
                                    overlayClassName="nd-excluded-popover"
                                    title="Excluded Segments"
                                    content={excludedSegments.map((s) => (
                                        <div key={s.id}>
                                            <SwLink
                                                href={this.appService.routeWithinDomain(
                                                    `/segments/${s.id}/summary`,
                                                    true,
                                                )}
                                            >
                                                {s.name}
                                            </SwLink>
                                        </div>
                                    ))}
                                >
                                    {excludedBadge}
                                </Popover>
                            )}
                        </>
                    )}
                </>
            )
        }

        return <span className="audience-badge">{badgeSet}</span>
    }
}
