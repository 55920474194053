import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const reduceStatTotalsByChannel = (breakdownStats: any[]) =>
    breakdownStats?.reduce((prev, curr) => {
        const channel = curr.channel
        if (channel) {
            if (prev[channel]) {
                prev[channel].impressions += curr.impressions
                prev[channel].clicks += curr.clicks
            } else {
                prev[channel] = {
                    impressions: curr.impressions,
                    clicks: curr.clicks,
                }
            }
        }
        return prev
    }, {})
