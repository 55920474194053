import { NotificationVariantModel } from '../../../models/notification/notification-variant.model'
import { NotificationActionModel } from '../../../models/notification/notification-action.model'
import { BadgeBehaviorModel } from '../../../models/notification/notification-native-ios-content.model'

/**
 * Parse the returned org notification
 * response into a standard notification
 * response for use in building a
 * NotificationVariantModel.
 */
export const parseNotificationVariantFromApiResponse = (data: any) => {
    const templateByChannelWrapper: any = data.template.channels || {}

    if (templateByChannelWrapper.nativeIos || templateByChannelWrapper.nativeAndroid) {
        templateByChannelWrapper.default.altNativeUrl =
            templateByChannelWrapper.nativeIos?.landingUrl ??
            templateByChannelWrapper.nativeAndroid?.landingUrl ??
            undefined
    }
    const defaultContent = templateByChannelWrapper.default
    const webContent = templateByChannelWrapper.web ?? {}
    const nativeIosContent = templateByChannelWrapper.nativeIos ?? {}
    const nativeAndroidContent = templateByChannelWrapper.nativeAndroid ?? {}
    const deliverySpec = data.deliverySpec ?? {}

    return NotificationVariantModel.build({
        audience: {
            domainIds: data.notifications?.map((n) => n.domainId) ?? [],
        },
        content: {
            default: {
                title: defaultContent.title,
                body: defaultContent.body,
                keywords: data.template.keywords,
                landingUrl: defaultContent.landingUrl,
                altNativeUrl: defaultContent.altNativeUrl,
                imageUrl: defaultContent.imageUrl,
                iconUrl: defaultContent.iconUrl,
                isUsingDomainDefaultIcon: defaultContent.isUsingDomainDefaultIcon,
                badgeUrl: defaultContent.badgeUrl,
                isUsingDomainDefaultBadge: defaultContent.isUsingDomainDefaultBadge,
                actions: defaultContent.actions?.map(NotificationActionModel.build),
                _custom_actions_enabled: defaultContent.overrideDefaultActions,
                contentWebhookUrl: defaultContent.contentWebhookUrl,
                overrideFallbackTemplate: defaultContent.overrideFallbackTemplate,
                autoSuggestedFieldsResults: defaultContent.autoSuggestedFieldsResults,
                experience_options: {
                    isSilent: defaultContent.isSilent,
                    requireInteraction: defaultContent.requireInteraction,
                    ttlSeconds: data.deliverySpec.ttlSeconds,
                    ttlMetric: data.deliverySpec.ttlMetric,
                },
                channels: data.channels ?? defaultContent.channels,
            },
            web: {
                title: webContent.title,
                body: webContent.body,
                keywords: data.keywords,
                landingUrl: webContent.landingUrl,
                imageUrl: webContent.imageUrl,
                iconUrl: webContent.iconUrl,
            },
            nativeIos: {
                title: nativeIosContent.title,
                body: nativeIosContent.body,
                imageUrl: nativeIosContent.imageUrl,
                iconUrl: nativeIosContent.iconUrl,
                landingUrl: nativeIosContent.landingUrl,
                badgeBehavior: BadgeBehaviorModel.build({
                    action: nativeIosContent.badgeBehavior,
                    value: nativeIosContent.badgeCount,
                }),
                interruptionLevel: nativeIosContent.interruptionLevel,
                relevanceScore: nativeIosContent.relevanceScore,
                sound: nativeIosContent.sound,
                subtitle: nativeIosContent.subtitle,
                targetContentId: nativeIosContent.targetContentId,
            },
        },
        delivery: {
            type: deliverySpec.type,
            window: deliverySpec.window,
            sendDateUtc: deliverySpec.send_date_utc ?? deliverySpec.sendDateUtc,
            timezone: deliverySpec.timezone,
        },
        channels: data.channels ?? defaultContent.channels,
    })
}
