import React from 'react'
import { IComponentTab, ITab, TabbedView, TabbedViewPathStyle } from '../tabbed-view/tabbed-view'

export type GenericComponentTab = IComponentTab<any>

interface IWrappedTabConfig {
    tabName: string | null
    tabLabel: string
}

function wrapComponentTab(Component: any, tabConfig: IWrappedTabConfig, dependencies: any[] = []) {
    return React.useMemo(
        () =>
            class extends React.Component<any, any> {
                protected static tabName = tabConfig.tabName
                protected static tabLabel = tabConfig.tabLabel

                public render() {
                    return <Component {...this.props} />
                }
            },
        [tabConfig.tabName, tabConfig.tabLabel, ...dependencies],
    )
}

interface ISimpleTabbedViewProps {
    pathStyle?: TabbedViewPathStyle
    animated?: boolean
    tabs: Array<ITab | GenericComponentTab>
}

class SimpleTabbedView extends TabbedView<ISimpleTabbedViewProps, any> {
    public static readonly wrapComponentTab = wrapComponentTab

    public state: any = { tabs: [] }
    protected readonly animated: boolean = false
    protected readonly pathStyle: TabbedViewPathStyle = 'hash'

    public constructor(props: ISimpleTabbedViewProps) {
        super(props)

        this.animated = this.props.animated ?? this.animated
        this.pathStyle = this.props.pathStyle ?? this.pathStyle
    }

    public async componentDidMount() {
        return this.loadTabsState()
    }

    public render() {
        return this.renderTabs()
    }

    protected async loadTabsState(): Promise<void> {
        const tabs = this.props.tabs
        const activeTab = this.determineActiveTab(tabs)
        return this.setState({ tabs, activeTab })
    }

    protected getCurrentTab(): ITab | IComponentTab<any> {
        return this.determineActiveTab(this.props.tabs) ?? this.state.activeTab
    }
}

export default SimpleTabbedView
