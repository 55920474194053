import * as React from 'react'
import { AsyncButton } from '../../components/async-button/async-button.component'
import { AppService } from '../../services'
import { PageHeader } from '../../components/page-header/page-header'
import { getPathEntityId } from '../../_utils/get-path-entity-id'
import { useService } from '@pushly/aqe/lib/hooks'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { AbilityAction } from '../../enums/ability-action.enum'
import { asCaslSubject, CurrentUserCan } from '../../stores/app-ability'
import SimpleTabbedView from '../../components/simple-tabbed-view/simple-tabbed-view'
import { AppMessageDataView } from '../../components/app-message-data-view/app-message-data-view'
import { AppMessageListType } from '../../components/app-message-data-view/enums'
import { getEnabledDeliveryChannels } from '../../_utils/domain'
import { AppState } from '../../stores/app'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface IAppMessageProps {
    // we do not support account level for app messages yet
    level: 'account' | 'domain'
    domainId: number
}

const AppMessages = (props: IAppMessageProps) => {
    const appService = useService(AppService)
    const appState = useService(AppState)
    const pathDomainId = getPathEntityId('domain')
    /**
     * Ensure injected props are passed down
     * for history container access
     */
    const listProps: any = { ...props }
    let permissionEntity: SubjectEntity = SubjectEntity.APP_MESSAGE
    let permissionConstraints: any = {}
    listProps.level = 'domain'
    listProps.domainId = pathDomainId
    permissionConstraints.domainId = pathDomainId

    const newAppMessagePath = appService.routeWithin(listProps.level, '/app-messages/new', true)
    const newTemplatePath = appService.routeWithin(listProps.level, '/app-messages/templates/new', true)

    const nativeChannelsEnabled = getEnabledDeliveryChannels(appState.currentDomain!, true).filter(
        (ch) => ch !== DeliveryChannel.WEB,
    )
    const nativeDisabled = nativeChannelsEnabled.length < 1

    const appMessageListTabs = [
        {
            tabClassName: 'app-message-list-tab',
            component: SimpleTabbedView.wrapComponentTab(AppMessageDataView, {
                tabName: null,
                tabLabel: 'App Messages',
            }),
            props: () => {
                return {
                    ...listProps,
                    type: AppMessageListType.APP_MESSAGE,
                    hideAutoRefreshOptions: true,
                    nativeDisabled,
                }
            },
        },
    ]

    appMessageListTabs.push({
        tabClassName: 'template-list-tab',
        component: SimpleTabbedView.wrapComponentTab(AppMessageDataView, {
            tabName: 'templates',
            tabLabel: 'Templates',
        }),
        props: () => {
            return {
                ...listProps,
                type: AppMessageListType.TEMPLATE,
                hideAutoRefreshOptions: true,
                nativeDisabled,
            }
        },
    })

    const actions = [
        {
            disabled: nativeDisabled,
            text: 'Create Template',
            onClick: () => {
                appService.route(newTemplatePath)
            },
        },
    ]

    return (
        <div className="app-messages-list">
            <PageHeader
                title={'App Messages'}
                action={
                    <CurrentUserCan
                        do={AbilityAction.CREATE}
                        on={asCaslSubject(permissionEntity, permissionConstraints)}
                    >
                        <AsyncButton
                            disabled={nativeDisabled}
                            className="new-app-message-button"
                            type="primary"
                            onClick={() => appService.route(newAppMessagePath)}
                            altHref={newAppMessagePath}
                            actions={actions}
                        >
                            <span>Schedule App Message</span>
                        </AsyncButton>
                    </CurrentUserCan>
                }
            />
            <SimpleTabbedView pathStyle="slash" animated={false} tabs={appMessageListTabs} />
        </div>
    )
}

export default AppMessages
