import React, { useCallback, useState } from 'react'
import { useDataViewReducer } from '@pushly/aqe/lib/components/data-view/reducer'
import { DataView } from '@pushly/aqe/lib/components/data-view/data-view'
import { DataViewContext } from '@pushly/aqe/lib/components/data-view/context'
import { DataSourceResponse, DataViewProps } from '@pushly/aqe/lib/components/data-view/types'
import { Alert, Button, Table } from 'antd'
import { ApiVersion } from '../../enums/api-version.enum'
import { useService } from '@pushly/aqe/lib/hooks'
import Icon from '@ant-design/icons'
import AppMessageFilled from '../icons/app-message-filled'
import { CreateApiTokenModal, RevokeApiTokenModal } from '@pushly/aqe/lib/components/index'
import { ApiTokenActionColumnView } from '@pushly/aqe/lib/components/api-token-modals/api-token-action-column-view'
import { useForm } from 'antd/lib/form/Form'
import { ApiToken, MutableApiToken } from '@pushly/models/lib/structs/api-tokens/api-token'
import { SHORT_DATE_FORMAT } from '../../constants'
import moment from 'moment'
import './styles/api-tokens.scss'
import { ApiTokenDataRecord } from './types'
import { RenderApiTokenCreatedModal } from '@pushly/aqe/lib/components/api-token-modals/render-api-token-created-modal'
import {
    NewApiTokenRequest,
    MutableNewApiTokenRequest,
} from '@pushly/models/lib/structs/api-tokens/new-api-token-request'
import { simpleNotification } from '../../_utils/utils'
import { ApiKeyService } from '../../services/api-key.service'

export const ApiTokensDataView = (props: { level: 'account' | 'domain'; id: number }) => {
    const { level, id } = props
    const apiKeySvc = useService(ApiKeyService)

    const [context, dispatch, requestDataSourceReload] = useDataViewReducer<ApiTokenDataRecord>({
        id: `api-tokens`,
    })

    const [showCreateTokenModal, setShowCreateTokenModal] = useState(false)
    const [showRevokeTokenModal, setShowRevokeTokenModal] = useState(false)
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false)
    const [form] = useForm()
    const [workingModel, setWorkingModel] = useState<NewApiTokenRequest>(() => {
        return new MutableNewApiTokenRequest()
    })

    const [selectedToken, setSelectedToken] = useState<ApiToken>(() => {
        return new MutableApiToken()
    })

    const fetchApiTokens: DataViewProps<ApiTokenDataRecord>['dataSourceLoader'] = async (filters, tableState) => {
        const apiOptions = {
            showLoadingScreen: true,
            cancellationKey: 'api-token-list.fetch',
            version: ApiVersion.V4,
        }

        const res = await apiKeySvc.fetchApiKeys(level, id, apiOptions)
        let dataViewResponse: DataSourceResponse = {
            meta: {},
            cancelled: false,
            ...res,
            data: [],
        }

        if (!res.cancelled) {
            if (res.ok && res.data) {
                res.data.length >= 5 ? setCreateButtonDisabled(true) : setCreateButtonDisabled(false)

                dataViewResponse.data = res.data.map((data) => ({ data }))
            } else if (res.error) {
                console.warn(res.error)
            }
        }

        return dataViewResponse
    }

    const handleNewTokenClick = () => {
        setShowCreateTokenModal(true)
    }

    const handleNewTokenCreate = useCallback(async () => {
        if (!workingModel.name?.trim()) {
            simpleNotification('error', 'Token Label is required')

            return
        }

        const { data: newToken } = await apiKeySvc.createApiKey(level, id, { showLoadingScreen: true }, workingModel)

        if (newToken) {
            setWorkingModel(new MutableNewApiTokenRequest())
            setShowCreateTokenModal(false)
            return handleKeyGenerateSuccess(newToken as any)
        }
    }, [workingModel.name])

    const handleRevokeTokenClick = useCallback(
        (apiToken: ApiToken) => {
            setSelectedToken(apiToken)
            setShowRevokeTokenModal(true)
        },
        [selectedToken.id],
    )

    const handleTokenRevoke = useCallback(async () => {
        const ok = await apiKeySvc.revokeApiKey(level, id, selectedToken.id!, { showLoadingScreen: true })
        if (ok) {
            setSelectedToken(new MutableApiToken())
            setShowRevokeTokenModal(false)
            dispatch({ type: 'SET_AUTO_REFRESH_REQUESTED' })
        }
    }, [selectedToken])

    const handleKeyGenerateSuccess = async (newToken: ApiTokenDataRecord['data']) => {
        RenderApiTokenCreatedModal({
            newToken: newToken.token!,
            onCancel: () => {
                dispatch({ type: 'SET_AUTO_REFRESH_REQUESTED' })
            },
        })
    }

    return (
        <>
            {createButtonDisabled && (
                <Alert
                    className="maximum-api-token-alert"
                    type="warning"
                    message={`The maximum number of API Tokens have been created for your ${
                        level === 'account' ? 'organization' : 'domain'
                    }. Revoke an existing token to create a new one.`}
                ></Alert>
            )}
            <DataViewContext.Provider value={{ state: context, dispatch }}>
                <DataView
                    dataSourceLoader={fetchApiTokens}
                    hideRefresh={true}
                    hideAutoRefreshOptions={true}
                    actions={
                        <Button
                            type="primary"
                            size="middle"
                            onClick={handleNewTokenClick}
                            disabled={createButtonDisabled}
                        >
                            Create Token
                        </Button>
                    }
                    title="API Tokens"
                    className="type-2"
                    table={{
                        rowKey: (row) => row.data.id!,
                        noData: {
                            text: 'No API Tokens',
                            icon: <Icon component={AppMessageFilled} />,
                        },
                        hideFooter: true,
                        rowClassName: 'data-view-row-collapsed',
                    }}
                >
                    <Table.Column key="label" className="label" title="Label" dataIndex={['data', 'name']} />
                    <Table.Column
                        align="center"
                        key="create-date"
                        className="create-date"
                        title="Create Date"
                        width={160}
                        dataIndex={['data', 'createdAt']}
                        render={(value) => {
                            return <span>{moment(value).format(SHORT_DATE_FORMAT)}</span>
                        }}
                    />
                    <Table.Column
                        align="center"
                        key="last-used-date"
                        className="last-used-date"
                        title="Last Used Date"
                        width={160}
                        dataIndex={['data', 'lastUsedAt']}
                        render={(value) => {
                            return <span>{value ? moment(value).format(SHORT_DATE_FORMAT) : null}</span>
                        }}
                    />
                    <Table.Column
                        align="right"
                        className="api-token-actions"
                        key="actions"
                        title="Actions"
                        width={100}
                        dataIndex="data"
                        render={(value) => {
                            return <ApiTokenActionColumnView onRevokeClick={() => handleRevokeTokenClick(value)} />
                        }}
                    />
                </DataView>

                <CreateApiTokenModal
                    visible={showCreateTokenModal}
                    onOk={handleNewTokenCreate}
                    onCancel={() => {
                        setWorkingModel(new MutableNewApiTokenRequest())
                        setShowCreateTokenModal(false)
                    }}
                    form={form}
                    workingModel={workingModel}
                    setWorkingModel={setWorkingModel}
                />

                <RevokeApiTokenModal
                    visible={showRevokeTokenModal}
                    onOk={handleTokenRevoke}
                    onCancel={() => {
                        setSelectedToken(new MutableApiToken())
                        setShowRevokeTokenModal(false)
                    }}
                    tokenName={selectedToken.name}
                />
            </DataViewContext.Provider>
        </>
    )
}
