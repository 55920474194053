import * as React from 'react'
import { IFilterBuilderProps } from '../interfaces/filter-builder'
import { getClassNames } from '../../../_utils/classnames'
import { NotificationIdFilterBuilder } from './notification-id.filter-builder'
import { BrowserFilterBuilder } from './browser.filter-builder'
import { OsFilterBuilder } from './os.filter-builder'
import { PlacementFilterBuilder } from './placement.filter-builder'
import { PromptIdFilterBuilder } from './prompt-id.filter-builder'
import { TestIdFilterBuilder } from './test-id.filter-builder'
import { SimpleTextFilterBuilder } from './simple-text.filter-builder'
import { NotificationKeywordsFilterBuilder } from './notification-keywords.filter-builder'
import { BetterSelect } from '../../../components/better-select/better-select'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { CampaignIdFilterBuilder } from './campaign-id.filter-builder'
import { NotificationSourceFilterBuilder } from './notification-source-filter.builder'
import './style/filter-builder.scss'
import { SegmentsFilterBuilder } from './segments-filter-builder'
import { NotificationChannelFilterBuilder } from './notification-channel-filter.builder'
import { AppMessageStyleFilterBuilder } from './app-message-style-filter.builder'
import { AppMessageIdFilterBuilder } from './app-message-id.filter-builder'

const SELECTOR_KEY = 'selector'

type FilterBuilderMode = 'display' | 'edit' | 'new'

interface IFilterBuilder extends IFilterBuilderProps {
    reportType: string
    reportLevel: string
    reportBreakdown: string
    allTypeOptions: any[]
    availableTypeOptions: any[]
    initialMode?: FilterBuilderMode
}

interface IFilterBuilderState {
    mode: FilterBuilderMode
    value: any
}

export class FilterBuilder extends React.Component<IFilterBuilder, IFilterBuilderState> {
    public constructor(props: IFilterBuilder) {
        super(props)

        let initialMode = this.props.initialMode || 'display'
        if (initialMode === 'new') {
            initialMode = 'edit'
        }

        this.state = {
            mode: initialMode,
            value: undefined,
        }
    }

    public render() {
        const displayClass = getClassNames('filter-builder-display')
        const editorClass = getClassNames('filter-builder-editor')

        return (
            <div
                className={getClassNames('filter-builder', {
                    [`type-${this.currentTypeSelector}`]: true,
                    [`mode-${this.currentMode}`]: true,
                    ['visible']: !!this.propsValue.field,
                })}
            >
                <div className={getClassNames('filter-builder-wrapper')}>
                    <div className={displayClass}>
                        <div className={getClassNames('filter-builder-content')}>
                            <span>{this.currentType}</span>
                        </div>
                    </div>
                    <div className={editorClass}>
                        <BetterSelect
                            className={getClassNames('filter-builder-type-select')}
                            dropdownClassName={getClassNames('filter-builder-type-select-dd')}
                            size="small"
                            placeholder="Select a filter"
                            disableSearch={true}
                            disableSelectAll={true}
                            value={this.propsValue.field}
                            onChange={this.handleTypeUpdate}
                            options={this.props.allTypeOptions.map((opt) => ({
                                ...opt,
                                disabled: !this.props.availableTypeOptions.find((ato) => ato.value === opt.value),
                            }))}
                        />
                    </div>

                    <div
                        className={getClassNames('filter-builder-filter', {
                            [displayClass]: this.currentMode === 'display',
                            [editorClass]: this.currentMode !== 'display',
                        })}
                    >
                        {this.currentTypeSelector !== SELECTOR_KEY && this.getBuilder()}
                    </div>

                    <div className={displayClass}>
                        <div className={getClassNames('filter-builder-edit')}>
                            <AsyncButton size="small" onClick={() => this.handleModeUpdate('edit')}>
                                <EditOutlined />
                            </AsyncButton>
                        </div>
                    </div>
                    <div className={editorClass}>
                        <div className={getClassNames('filter-builder-save')}>
                            <AsyncButton size="small" onClick={this.handleBuilderSave}>
                                <CheckOutlined />
                            </AsyncButton>
                        </div>
                    </div>
                    <div className={getClassNames('filter-builder-remove')}>
                        <AsyncButton size="small" onClick={this.handleBuilderCancelRemove}>
                            <LegacyIcon type={this.currentMode === 'display' ? 'delete' : 'close'} />
                        </AsyncButton>
                    </div>
                </div>
            </div>
        )
    }

    protected getBuilder(): any | undefined {
        let builder: any

        const builderProps: any = {
            mode: this.state.mode,
            value: this.currentValue,
            onChange: this.handleBuilderUpdate,
        }

        switch (this.currentValue.field) {
            case 'NOTIFICATION_SOURCE':
                builder = <NotificationSourceFilterBuilder {...builderProps} />
                break
            case 'NOTIFICATION_ID':
                builder = <NotificationIdFilterBuilder {...builderProps} />
                break
            case 'NOTIFICATION_TITLE':
                builder = (
                    <SimpleTextFilterBuilder
                        {...builderProps}
                        field="NOTIFICATION_TITLE"
                        onInputPressEnter={this.handleBuilderSave}
                    />
                )
                break
            case 'NOTIFICATION_LANDING_URL':
                builder = (
                    <SimpleTextFilterBuilder
                        {...builderProps}
                        field="NOTIFICATION_LANDING_URL"
                        onInputPressEnter={this.handleBuilderSave}
                    />
                )
                break
            case 'NOTIFICATION_BODY':
                builder = (
                    <SimpleTextFilterBuilder
                        {...builderProps}
                        field="NOTIFICATION_BODY"
                        onInputPressEnter={this.handleBuilderSave}
                    />
                )
                break
            case 'NOTIFICATION_KEYWORDS':
                builder = <NotificationKeywordsFilterBuilder {...builderProps} />
                break
            case 'NOTIFICATION_SEGMENTS':
                builder = <SegmentsFilterBuilder {...builderProps} field="NOTIFICATION_SEGMENTS" />
                break
            case 'CAMPAIGN_ID':
                builder = <CampaignIdFilterBuilder {...builderProps} />
                break
            case 'SEGMENT_ID':
                builder = <SegmentsFilterBuilder {...builderProps} field="SEGMENT_ID" />
                break
            case 'CHANNEL':
                builder = <NotificationChannelFilterBuilder {...builderProps} />
                break
            case 'BROWSER':
                builder = <BrowserFilterBuilder {...builderProps} />
                break
            case 'OPERATING_SYSTEM':
                builder = <OsFilterBuilder {...builderProps} />
                break
            case 'PLACEMENT':
                builder = <PlacementFilterBuilder {...builderProps} />
                break
            case 'PROMPT_ID':
                builder = <PromptIdFilterBuilder {...builderProps} />
                break
            case 'TEST_ID':
                builder = (
                    <TestIdFilterBuilder
                        {...builderProps}
                        testEntity={this.props.reportType === 'SUBSCRIBER' ? 'prompts' : 'notifications'}
                    />
                )
                break
            case 'APP_MESSAGE_STYLE':
                builder = <AppMessageStyleFilterBuilder {...builderProps} />
                break
            case 'APP_MESSAGE_ID':
                builder = <AppMessageIdFilterBuilder {...builderProps} />
                break
            case 'ACTIVITY_ID':
                builder = (
                    <SimpleTextFilterBuilder
                        {...builderProps}
                        field="activity.id"
                        onInputPressEnter={this.handleBuilderSave}
                    />
                )
                break
        }

        return builder
    }

    protected get propsValue(): any {
        return this.props.value || {}
    }

    protected get currentValue(): any {
        return {
            ...this.propsValue,
            ...this.state.value,
        }
    }

    protected get currentMode(): FilterBuilderMode {
        return this.state.mode
    }

    protected get currentType(): string {
        let type = 'Unknown'

        const selection = (this.props.allTypeOptions || []).find((opt) => opt.value === this.currentValue.field)

        if (selection) {
            type = selection.label || type
        }

        return type
    }

    protected get currentTypeSelector(): string {
        return (this.currentValue.field || SELECTOR_KEY).toLowerCase()
    }

    protected handleModeUpdate = async (mode: FilterBuilderMode) => {
        await this.setState({ mode })

        if (mode === 'display') {
            this.emitChange({ $mode: mode })
        }
    }

    protected handleTypeUpdate = async (type: string) => {
        if (type !== this.propsValue.field) {
            this.emitChange({
                field: type,
                operator: undefined,
                value: undefined,
            })
        }
    }

    protected handleBuilderUpdate = async (update: any) => {
        this.setTempValue(update)
    }

    protected handleBuilderSave = async (_ev: React.MouseEvent<any>) => {
        this.saveAndClose()
    }

    protected handleBuilderCancelRemove = async () => {
        if (this.currentMode === 'display') {
            this.handleBuilderRemove()
        } else {
            if (this.currentValue.$mode === 'new') {
                this.handleBuilderRemove()
            } else {
                this.handleBuilderCancel()
            }
        }
    }

    protected handleBuilderCancel = async () => {
        await this.flushTempValue()
        this.saveAndClose()
    }

    protected handleBuilderRemove = async () => {
        !!this.props.onRemove && this.props.onRemove()
    }

    protected async saveAndClose(): Promise<void> {
        const newMode = 'display'
        await this.setState({ mode: newMode })
        this.emitChange({ $mode: newMode })
    }

    protected async emitChange(change?: any): Promise<void> {
        this.props.onChange({
            ...(this.props.value || {}),
            ...(this.state.value || {}),
            ...(change || {}),
        })

        this.flushTempValue()
    }

    protected async setTempValue(update: any): Promise<void> {
        return this.setState(({ value: currentValue }) => ({
            value: {
                ...currentValue,
                ...update,
            },
        }))
    }

    protected async flushTempValue(): Promise<void> {
        return this.setState({ value: undefined })
    }
}
