import React from 'react'
import './style/app-menu.scss'
import classnames from 'classnames'
import { Menu } from 'antd'
import Icon, {
    AppstoreFilled,
    AreaChartOutlined,
    BuildFilled,
    SelectOutlined,
    SettingFilled,
    TeamOutlined,
    WifiOutlined,
} from '@ant-design/icons'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { getPathClassName } from '../../_utils/get-path-classname'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { asCaslSubject } from '../../stores/app-ability'
import AppMessageFilled from '../icons/app-message-filled'
import { useService } from '@pushly/aqe/lib/hooks'
import { FEAT_MULTI_DOMAIN_NOTIFICATIONS } from '../../constants'
import { AppMenuItem } from './app-menu-item'
import { getSidebarPathModule } from './helpers'
import { FEAT_APP_MESSAGES } from '../../constants'

const AppMenu = () => {
    const appState = useService(AppState)
    const appService = useService(AppService)

    const buildMultiTierConditon = (domainEntity: SubjectEntity, orgEntity: SubjectEntity, orgFlag?) => {
        return () => {
            const entity = appState.inDomainContext ? domainEntity : orgEntity
            const abilityConstraints = appState.inDomainContext
                ? { domainId: appState.currentDomain?.id }
                : { accountId: appState.currentDomain?.accountId }

            /**
             * Menu Visibility:
             * Domain Context  - user has read access to domain
             * Org Context     - org has key & user has read access to org
             */
            let can = appState.abilityStore.can(AbilityAction.READ, asCaslSubject(entity, abilityConstraints))
            if (can && appState.inOrgContext && orgFlag) {
                can = appState.currentDomain?.accountFlags?.includes(orgFlag) ?? false
            }

            return can ?? false
        }
    }

    const { sidebar } = appState
    const inOrgContext = appState.inOrgContext
    const routeEntity = inOrgContext ? 'org' : 'domain'

    const pathClassName = getPathClassName(location.pathname)
    const selectedPathKey = getSidebarPathModule(location.pathname)

    // access observable selected domain/account in render fn to ensure redraw
    const activeAcctId = appState.currentDomain?.accountId ?? 0
    const activeDomainId = appState.currentDomain?.id ?? 0

    if (appState?.abilityStore?.acs?.state && !appState.abilityStore.abilities.rules.length) {
        Promise.resolve(appState.abilityStore.acs.getAbilities())
    }

    return (
        <Menu
            className={classnames(
                'app-menu',
                `active-account-${activeAcctId}`,
                `active-domain-${activeDomainId}`,
                appState.abilityStore.acs?.state,
                {
                    collapsed: sidebar.collapsed,
                    open: !sidebar.collapsed,
                },
            )}
            mode="inline"
            inlineCollapsed={sidebar.collapsed}
            selectedKeys={!selectedPathKey ? [] : [selectedPathKey]}
            onClick={() => {
                const newPathClassName = getPathClassName(location.pathname)
                if (newPathClassName !== pathClassName) {
                    console.debug('FORCE_UPDATE - still needed?')
                    // forceUpdate()
                }
            }}
        >
            <AppMenuItem
                key="dashboard"
                disabled={!appState.currentDomain?.id}
                icon={<AppstoreFilled />}
                title="Dashboard"
                linkTo={appService.routeWithin(routeEntity, '/dashboard', true)}
                condition={() =>
                    appState.abilityStore.can(AbilityAction.READ, appState.abilityStore.currentDomainIdentity) &&
                    appState.inDomainContext
                }
            />
            <AppMenuItem
                key="notifications"
                disabled={!appState.currentDomain?.id}
                icon={<WifiOutlined />}
                title="Notifications"
                linkTo={appService.routeWithin(routeEntity, '/notifications', true)}
                condition={buildMultiTierConditon(
                    SubjectEntity.NOTIFICATION,
                    SubjectEntity.ORG_NOTIFICATION,
                    FEAT_MULTI_DOMAIN_NOTIFICATIONS,
                )}
            />
            <AppMenuItem
                key="appMessages"
                disabled={!appState.currentDomain?.id}
                icon={<Icon component={AppMessageFilled} />}
                title="App Messages"
                linkTo={appService.routeWithin(routeEntity, '/app-messages', true)}
                condition={() =>
                    (appState.currentDomain?.flags.includes(FEAT_APP_MESSAGES) ?? false) &&
                    appState.abilityStore.can(AbilityAction.READ, appState.abilityStore.currentDomainIdentity) &&
                    appState.abilityStore.can(
                        AbilityAction.READ,
                        appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.APP_MESSAGE),
                    ) &&
                    appState.inDomainContext
                }
            />
            <AppMenuItem
                key="campaigns"
                disabled={!appState.currentDomain?.id}
                icon={<BuildFilled />}
                title="Campaigns"
                linkTo={appService.routeWithin(routeEntity, '/campaigns', true)}
                condition={() =>
                    appState.abilityStore.can(AbilityAction.READ, appState.abilityStore.currentDomainIdentity) &&
                    appState.abilityStore.can(
                        AbilityAction.READ,
                        appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.CAMPAIGN),
                    ) &&
                    appState.inDomainContext
                }
            />
            {/*
             Org Segments will not have a separate flag for multi-domain level.
             If an org has access to multi-domain notifications they will
             also implicitly have access to segmentation.
             */}
            <AppMenuItem
                key="segments"
                disabled={!appState.currentDomain?.id}
                icon={<TeamOutlined />}
                title="Segments"
                linkTo={appService.routeWithin(routeEntity, '/segments', true)}
                condition={buildMultiTierConditon(
                    SubjectEntity.SEGMENT,
                    SubjectEntity.ORG_SEGMENT,
                    FEAT_MULTI_DOMAIN_NOTIFICATIONS,
                )}
            />
            <AppMenuItem
                key="prompts"
                disabled={!appState.currentDomain?.id}
                icon={<SelectOutlined />}
                title="Prompts"
                linkTo={appService.routeWithin(routeEntity, '/prompts', true)}
                condition={() =>
                    appState.abilityStore.can(AbilityAction.READ, appState.abilityStore.currentDomainIdentity) &&
                    appState.abilityStore.can(
                        AbilityAction.READ,
                        appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.PROMPT),
                    ) &&
                    appState.inDomainContext
                }
            />
            <AppMenuItem
                key="insights"
                disabled={!appState.currentDomain?.id}
                icon={<AreaChartOutlined />}
                title="Insights"
                linkTo={appService.routeWithin(routeEntity, '/insights', true)}
                condition={() =>
                    appState.abilityStore.can(AbilityAction.READ, appState.abilityStore.currentDomainIdentity) &&
                    appState.inDomainContext
                }
            />
            <AppMenuItem
                key="settings"
                disabled={!appState.currentDomain?.id}
                icon={<SettingFilled />}
                title="Settings"
                linkTo={appService.routeWithin(routeEntity, '/#settings', true)}
                condition={() => {
                    const entity = appState.inDomainContext
                        ? appState.abilityStore.currentDomainIdentity
                        : appState.abilityStore.currentOrgIdentity

                    return appState.abilityStore.can(AbilityAction.UPDATE, entity)
                }}
            />
        </Menu>
    )
}

export default AppMenu
