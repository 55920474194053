import { AppMessageBuilderState } from '../../components/app-message-builder/interfaces/app-message-builder-state.interface'
import { ActionDispatcher, AppMessageReducerElements } from '../../components/app-message-builder/types'
import { createContext, useContext } from 'react'

type AppMessageBuilderContext = { state: AppMessageBuilderState; dispatch: ActionDispatcher }
export const AppMessageBuilderContext = createContext<AppMessageBuilderContext>(null!)

export const useAppMessageBuilderContext = (): AppMessageReducerElements => {
    const { state, dispatch } = useContext(AppMessageBuilderContext) as AppMessageBuilderContext
    return [state, dispatch]
}
