import { AppMessageBuilderState } from './interfaces/app-message-builder-state.interface'
import {
    DetailsActionPack,
    AudienceActionPack,
    TemplateActionPack,
    AppMessageDispatchActionPack,
    ConditionActionPack,
    BehaviorActionPack,
    BaseActionPack,
    ScheduleActionPack,
    PatchAvailableSegmentsActionPack,
    OverrideBuilderStateActionPack,
    PatchBuilderStateActionPack,
    AppMessageReducerElements,
    AppMessageBuilderProps,
    ReachEstimateActionPack,
    NamedTemplateActionPack,
    AnimationActionPack,
} from './types'
import { useReducer } from 'react'
import { parseIdObject } from '@pushly/models/lib/utils/parse-id-object'
import {
    MutableAppMessageBehavior,
    MutableAppMessageRedisplayBehavior,
    MutableAppMessageUiTemplate,
    MutableAppMessageTemplateImageConfig,
    MutableTogglableTextField,
    MutableAppMessageTemplateButtonConfig,
    MutableAppMessageTheme,
} from '@pushly/models/lib/structs'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { LayoutOrientation, AppMessagePriority, AppMessageTemplateStyle, StatusType } from '@pushly/models/lib/enums'
import moment from 'moment/moment'
import { MutableAudienceConfig } from '@pushly/models/lib/structs/audience-config'
import { MutableAppMessageBuilderPayload, MutableAppMessageBuilderSchedulePayload } from './model-extensions'
import { StatusName } from '@pushly/aquarium-utils/lib/enums'
import { ScheduleWindow } from '@pushly/models/lib/enums/schedule-window'
import { DomainDto } from '../../dtos/domain'
import { MutableAppMessageConditions } from '@pushly/models/lib/structs/app-messages/app-message-conditions'
import * as randomstring from 'randomstring'
import { AppMessageActionType } from '@pushly/models/lib/enums/app-messages/app-message-action-type'
import { AppMessageImageSize } from '@pushly/models/lib/enums/app-messages/app-message-image-size'
import { Position } from '@pushly/models/lib/enums/position'
import { DEFAULT_TOGGLEABLE_BG_COLOR, DEFAULT_TOGGLEABLE_FG_COLOR } from './constants'
import { MutableDisplayAnimationConfig } from '@pushly/models/lib/structs/display-animation-configuration'
import { MutableDisplayAnimationBehavior } from '@pushly/models/lib/structs/animation-behavior'
import { AnimationType } from '@pushly/models/lib/enums/app-messages/app-message-animation-type'

export const useAppMessageReducer = (props: AppMessageBuilderProps): AppMessageReducerElements => {
    const [context, dispatch] = useReducer(handleAppMessageStateRequest, getBuilderStateInitializer(props))

    return [context, dispatch]
}

export const getInitialTemplate = (domain: DomainDto, type: 'message' | 'template') => {
    const template = MutableAppMessageUiTemplate.withDefaultValues()

    template.domain = parseIdObject(domain.id)
    template.account = parseIdObject(domain.accountId)
    template.channels = [DeliveryChannel.NATIVE_IOS, DeliveryChannel.NATIVE_ANDROID]

    template.image = MutableAppMessageTemplateImageConfig.withDefaultValues()
    template.image.enabled = true
    template.image.size = AppMessageImageSize.COVER
    template.image.position = Position.TOP

    template.header = new MutableTogglableTextField()
    template.header.enabled = false
    template.header.fgColor = DEFAULT_TOGGLEABLE_FG_COLOR

    template.body = new MutableTogglableTextField()
    template.body.enabled = true
    template.body.fgColor = DEFAULT_TOGGLEABLE_FG_COLOR

    template.title = new MutableTogglableTextField()
    template.title.enabled = true
    template.title.fgColor = DEFAULT_TOGGLEABLE_FG_COLOR

    template.ctaPrimary = MutableAppMessageTemplateButtonConfig.withDefaultValues()
    template.ctaPrimary.enabled = true
    template.ctaPrimary.action = AppMessageActionType.OPEN_URL
    template.ctaPrimary.bgColor = DEFAULT_TOGGLEABLE_FG_COLOR
    template.ctaPrimary.fgColor = DEFAULT_TOGGLEABLE_BG_COLOR

    template.ctaSecondary = MutableAppMessageTemplateButtonConfig.withDefaultValues()
    template.ctaSecondary.enabled = false
    template.ctaSecondary.action = AppMessageActionType.OPEN_URL
    template.ctaSecondary.bgColor = DEFAULT_TOGGLEABLE_FG_COLOR
    template.ctaSecondary.fgColor = DEFAULT_TOGGLEABLE_BG_COLOR

    template.ctaLayout = LayoutOrientation.VERTICAL
    template.style = AppMessageTemplateStyle.MODAL

    // isShared set for template creation flow only
    template.isShared = type === 'template'

    return template
}

const getInitialAppMessageVariant = (props: AppMessageBuilderProps) => {
    const initialVariant = MutableAppMessageBuilderPayload.withDefaultValues()
    initialVariant.domain = parseIdObject(props.domain.id)
    // initial base
    initialVariant.priority = AppMessagePriority.DEFAULT
    initialVariant.style = AppMessageTemplateStyle.MODAL
    initialVariant.theme = MutableAppMessageTheme.withDefaultValues()

    // intial schedules
    initialVariant.schedules = []
    const initialSchedule = MutableAppMessageBuilderSchedulePayload.withDefaultValues()
    initialSchedule.runDateWindow = ScheduleWindow.STANDARD
    initialSchedule.runDateTimeZone = props.domain.timezone
    initialSchedule.runDateStartUtc = moment().utc().toISOString()
    initialSchedule.audience = MutableAudienceConfig.withDefaultValues()
    initialSchedule.statusId = StatusType.ACTIVE
    initialSchedule.status = StatusName.ACTIVE
    initialSchedule.conditions = MutableAppMessageConditions.withDefaultValues()

    initialVariant.schedules.push(initialSchedule)
    // behavior initial values
    const behavior = (initialSchedule.behavior = MutableAppMessageBehavior.withDefaultValues())

    // initial display animation config (enabled and FADE type)
    const initialDisplayAnimation = new MutableDisplayAnimationBehavior()
    const initialDisplayAnimationConfig = new MutableDisplayAnimationConfig()
    initialDisplayAnimationConfig.enabled = true
    initialDisplayAnimationConfig.animations = [AnimationType.FADE]
    initialDisplayAnimation.enter = initialDisplayAnimationConfig
    initialDisplayAnimation.exit = initialDisplayAnimationConfig

    behavior.redisplay = MutableAppMessageRedisplayBehavior.withDefaultValues()
    behavior.displayAnimations = initialDisplayAnimation

    // template initial values
    initialVariant.template = getInitialTemplate(props.domain, props.type)

    return initialVariant
}

const getBuilderStateInitializer = (props: AppMessageBuilderProps) => {
    const initialVariant = getInitialAppMessageVariant(props)

    return {
        instanceId: btoa(`${props.domain.id}|${props.defaultSelectedId}`),
        mode: props.mode,
        type: props.type,
        domain: props.domain,
        disabled: false,
        loading: true,
        initialAppMessageStateLoaded: false,
        availableSegmentsLoaded: false,
        availableSegments: [],
        reachEstimateLoaded: false,
        availableTemplates: [],
        selectedTemplateId: props.defaultSelectedId,
        templatesLoaded: true,
        usingTemplate: false,
        details: {},
        selectedVariantIdx: 0,
        variants: [initialVariant],
    } as AppMessageBuilderState
}

function handleAppMessageStateRequest(state: AppMessageBuilderState, action: AppMessageDispatchActionPack) {
    // ensure clone of current state is used to prevent
    // possible active-state mutations
    let currentState = { ...state }

    switch (action.entity) {
        case 'root':
            return handleRootStateReducerRequest(currentState, action)
        case 'base':
            return handleBaseAppMessageReducerRequest(currentState, action)
        case 'details':
            return handleDetailsReducerRequest(currentState, action)
        case 'named-template':
            return handleNamedTemplateStateReducerRequest(currentState, action)
        case 'audience':
            return handleAudienceReducerRequest(currentState, action)
        case 'available-segments':
            return handleAvailableSegmentsReducerRequest(currentState, action)
        case 'reach-estimate':
            return handleReachEstimateReducerRequest(currentState, action)
        case 'template':
            return handleTemplateReducerRequest(currentState, action)
        case 'conditions':
            return handleConditionsReducerRequest(currentState, action)
        case 'behavior':
            return handleBehaviorReducerRequest(currentState, action)
        case 'animation':
            return handleAnimationReducerRequest(currentState, action)
        case 'schedule':
            return handleScheduleReducerRequest(currentState, action)
    }
}

function handleDetailsReducerRequest(state: AppMessageBuilderState, action: DetailsActionPack) {
    const { name, description, templateId, usingTemplate, domain } = action.data

    const variant = state.variants[state.selectedVariantIdx]
    const template: MutableAppMessageUiTemplate = variant.template!
    if (name) {
        state.details.name = name
        variant.name = name
        template.name = name
    }

    if (description) {
        state.details.description = description
        template.description = description
    }

    return state
}

function handleNamedTemplateStateReducerRequest(state: AppMessageBuilderState, action: NamedTemplateActionPack) {
    const { templateId, usingTemplate } = action.data

    const currVariant = state.variants[state.selectedVariantIdx]
    switch (usingTemplate) {
        case true:
            state.usingTemplate = true
            break

        case false:
            state.usingTemplate = false

            // only update form if a template has been used to overwrite values
            if (state.selectedTemplateId !== undefined) {
                state.selectedTemplateId = undefined
                currVariant.template = getInitialTemplate(state.domain, state.type)

                state.variants.splice(state.selectedVariantIdx, 1, currVariant)
            }
            break
    }

    if (templateId !== undefined) {
        let selectedTemplate = state.availableTemplates.find((t) => t.id === templateId)

        if (selectedTemplate) {
            state.selectedTemplateId = selectedTemplate.id
            currVariant.template = selectedTemplate.clone()
            currVariant.template.name = randomstring.generate({ length: 36 })
            currVariant.template.isShared = false
            currVariant.template.sourceTemplate = parseIdObject(templateId)
            // keep variant ID to maintain clean app message form render process - only changes are content
            state.variants.splice(state.selectedVariantIdx, 1, currVariant)
        }
    }

    return state
}

export function handleRootStateReducerRequest(
    state: AppMessageBuilderState,
    action: OverrideBuilderStateActionPack | PatchBuilderStateActionPack,
) {
    switch (action.type) {
        case 'put':
            state = action.data
            break

        case 'patch':
            state = {
                ...state,
                ...action.data,
            }
            break
    }

    return state
}

// TODO: Audience, AvailableSegments, Conditions, Behavior and Schedules
function handleAudienceReducerRequest(state: AppMessageBuilderState, action: AudienceActionPack) {
    const nextAudience = action.data

    for (const variant of state.variants) {
        const schedule = variant.schedules[0]
        schedule.audience = nextAudience
    }

    return state
}

function handleAvailableSegmentsReducerRequest(
    state: AppMessageBuilderState,
    action: PatchAvailableSegmentsActionPack,
) {
    state.availableSegmentsLoaded = true
    state.availableSegments = action.data
    return state
}

function handleReachEstimateReducerRequest(state: AppMessageBuilderState, action: ReachEstimateActionPack) {
    state.reachEstimateLoaded = true
    state.reachEstimate = action.data
    return state
}

function handleTemplateReducerRequest(state: AppMessageBuilderState, action: TemplateActionPack) {
    const nextTemplate = action.data

    for (const variant of state.variants) {
        variant.template = variant.template!.clone({ ...nextTemplate })
    }

    return state
}

function handleConditionsReducerRequest(state: AppMessageBuilderState, action: ConditionActionPack) {
    const nextCondition = action.data
    for (const variant of state.variants) {
        const schedule = variant.schedules[0]
        schedule.conditions = nextCondition
    }

    return state
}

function handleBehaviorReducerRequest(state: AppMessageBuilderState, action: BehaviorActionPack) {
    const nextBehavior = action.data
    for (const variant of state.variants) {
        const schedule = variant.schedules[0]
        schedule.behavior = nextBehavior
    }

    return state
}

function handleAnimationReducerRequest(state: AppMessageBuilderState, action: AnimationActionPack) {
    const nextAnimation = action.data
    for (const variant of state.variants) {
        const schedule = variant.schedules[0]
        schedule.behavior.displayAnimations = nextAnimation
    }

    return state
}

/**
 * Current logic only accounts for a single schedule, should multiple be supported for UI updating
 * this logic will no longer handle
 * @param state AppMessageBuilderState
 * @param action ScheduleActionPack
 */
function handleScheduleReducerRequest(state: AppMessageBuilderState, action: ScheduleActionPack) {
    const nextSchedule = action.data
    for (const variant of state.variants) {
        variant.schedules = [nextSchedule]
    }

    return state
}

function handleBaseAppMessageReducerRequest(state: AppMessageBuilderState, action: BaseActionPack) {
    const nextBaseMessage = action.data.toMutable()
    state.variants = state.variants.map((variant) => nextBaseMessage)

    return state
}
