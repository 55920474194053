import * as React from 'react'
import './sandbox.scss'
import { PageHeader } from '../../components/page-header/page-header'
import { useService } from '../../hooks/use-service'
import { AppState } from '../../stores/app'
import { DomainService } from '../../services'
import { CustomQuillEditor } from '../../components/custom-quill-editor/custom-quill-editor'

function Sandbox() {
    // const qs = getQueryString();
    const appState = useService(AppState)
    const domainSvc = useService(DomainService)

    const [text, setText] = React.useState<string>()

    return (
        <React.Fragment>
            <PageHeader title="Sandbox" />

            <CustomQuillEditor value={text} onChange={(value) => setText(value)} disabled={false} />
        </React.Fragment>
    )
}

export default Sandbox
