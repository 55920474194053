import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { RecurrenceSchedule } from './recurrence-schedule'
import { RecurrenceSubscriberFcap } from './recurrence-subscriber-fcap'
import { CampaignExitBehavior, CampaignExitStatus } from '../../components/campaign-builder/enums'
import { stripUndefined } from '../../_utils/strip-undefined'
import { CampaignAudienceModel } from './campaign-audience.model'
import { CampaignItemGroupTargetingModel } from './campaign-item-group-targeting.model'
import { IRelativeDate } from '@pushly/aqe/lib/interfaces/index'
import { RelativeDateDisplayMetric } from '@pushly/aqe/lib/enums/index'
import { CAMPAIGN_DEFAULT_ABANDONED_CART_COOLDOWN_PERIOD } from '../../components/campaign-builder/configuration-editor/constants'

export class CampaignConfigurationModel {
    public static build(props: CampaignConfigurationModel | any): CampaignConfigurationModel {
        const data = props instanceof CampaignConfigurationModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new CampaignConfigurationModel()
        model.type = scData['@type'] ?? scData.type
        model.setSchedule(scData.schedule)
        model.setSubscriberFcap(scData.subscriber_fcap)
        model.setEndBehavior(scData.end_behavior ?? CampaignExitBehavior.BLEED)
        model.setEndStatus(scData.end_status ?? CampaignExitStatus.COMPLETED)

        if (typeof scData.quiet_hours === 'object' && (!!scData.quiet_hours.start || !!scData.quiet_hours.end)) {
            model.setQuietHoursStart(scData.quiet_hours.start)
            model.setQuietHoursEnd(scData.quiet_hours.end)
        }

        // TODO: remove legacy key support in final rollout
        model.setAbandonmentSeconds(scData.abandonment_seconds ?? scData.cart_abandonment_seconds)
        model.setAbandonmentMetric(scData.abandonment_metric ?? scData.cart_abandonment_metric)

        model.setAbandonedCartCooldownPeriod(scData.abandoned_cart_cooldown_period?.interval_seconds)
        model.setAbandonedBrowseMinimumItemViews(scData.abandoned_browse_minimum_item_views)

        model.setAudience(CampaignAudienceModel.build(scData.audience ?? {}))
        model.setItemGroupTargeting(scData.item_group_targeting)

        return model
    }

    private type?: string
    private schedule?: RecurrenceSchedule
    private subscriber_fcap?: RecurrenceSubscriberFcap
    private end_behavior: CampaignExitBehavior = CampaignExitBehavior.BLEED
    private end_status: CampaignExitStatus = CampaignExitStatus.COMPLETED
    private quiet_hours_start_time?: string
    private quiet_hours_end_time?: string
    private abandonment_seconds?: number
    private abandonment_metric?: string
    private audience: CampaignAudienceModel
    private abandoned_cart_cooldown_period?: IRelativeDate
    private abandoned_browse_minimum_item_views?: number
    private item_group_targeting?: CampaignItemGroupTargetingModel

    public clone(): CampaignConfigurationModel {
        return CampaignConfigurationModel.build(this)
    }

    public serialize(_: boolean = true): any {
        let quietHours = stripUndefined({
            start: this.getQuietHoursStart(),
            end: this.getQuietHoursEnd(),
        })
        if (Object.keys(quietHours).length === 0) {
            quietHours = undefined
        }

        return stripUndefined({
            ['@type']: this.getLegacyType(),
            schedule: this.getSchedule()?.serialize(),
            subscriber_fcap: this.getSubscriberFcap()?.serialize(),
            end_behavior: this.getEndBehavior(),
            end_status: this.getEndStatus(),
            quiet_hours: quietHours,
            abandonment_seconds: this.getAbandonmentSeconds(),
            abandonment_metric: this.getAbandonmentMetric(),
            audience: this.getAudience()?.serialize(),
            abandoned_cart_cooldown_period: this.getAbandonedCartCooldownPeriod(),
            abandoned_browse_minimum_item_views: this.getAbandonedBrowseMinimumItemViews(),
            item_group_targeting: this.getItemGroupTargeting()?.serialize(),
        })
    }

    public getLegacyType(): string | undefined {
        return this.type
    }

    public setSchedule(schedule: RecurrenceSchedule | any): void {
        this.schedule = !schedule ? undefined : RecurrenceSchedule.build(schedule)
    }
    public getSchedule(): RecurrenceSchedule | undefined {
        return this.schedule
    }

    public setSubscriberFcap(fcap: RecurrenceSubscriberFcap | any): void {
        this.subscriber_fcap = !fcap ? undefined : RecurrenceSubscriberFcap.build(fcap)
    }
    public getSubscriberFcap(): RecurrenceSubscriberFcap | undefined {
        return this.subscriber_fcap
    }

    public setEndBehavior(behavior: CampaignExitBehavior | string | undefined): void {
        behavior = !behavior ? CampaignExitBehavior.BLEED.toUpperCase() : behavior.toString().trim().toUpperCase()

        if (!CampaignExitBehavior[behavior]) {
            throw new Error('Invalid Argument. Argument behavior is invalid.')
        }

        this.end_behavior = CampaignExitBehavior[behavior]
    }
    public getEndBehavior(): CampaignExitBehavior {
        return this.end_behavior ?? CampaignExitBehavior.BLEED
    }
    public getComputedEndBehavior(): string {
        const behavior = this.getEndBehavior()
        const status = this.getEndStatus()

        return behavior === CampaignExitBehavior.STOP ? `${behavior}:${status}` : behavior
    }

    public setEndStatus(status: CampaignExitStatus | string | undefined): void {
        status = !status ? CampaignExitStatus.COMPLETED.toUpperCase() : status.toString().trim().toUpperCase()

        if (!CampaignExitStatus[status]) {
            throw new Error('Invalid Argument. Argument status is invalid.')
        }

        this.end_status = CampaignExitStatus[status]
    }
    public getEndStatus(): CampaignExitStatus {
        return this.end_status ?? CampaignExitStatus.COMPLETED
    }

    public setQuietHoursStart(start: string | any): void {
        this.quiet_hours_start_time = !start ? undefined : start
    }
    public getQuietHoursStart(): string | undefined {
        return this.quiet_hours_start_time
    }

    public setQuietHoursEnd(end: string | any): void {
        this.quiet_hours_end_time = !end ? undefined : end
    }
    public getQuietHoursEnd(): string | undefined {
        return this.quiet_hours_end_time
    }

    public setAbandonmentSeconds(seconds: number | any): void {
        this.abandonment_seconds = seconds === null || seconds === undefined ? undefined : seconds
    }
    public getAbandonmentSeconds(): number | undefined {
        return this.abandonment_seconds
    }

    public setAbandonmentMetric(metric: string | any): void {
        this.abandonment_metric = !metric ? undefined : metric
    }
    public getAbandonmentMetric(): string | undefined {
        return this.abandonment_metric
    }

    public setAbandonedCartCooldownPeriod(seconds: number | undefined): void {
        this.abandoned_cart_cooldown_period = !seconds
            ? undefined
            : {
                  interval_seconds: seconds,
                  display_metric: CAMPAIGN_DEFAULT_ABANDONED_CART_COOLDOWN_PERIOD.display_metric,
              }
    }

    public getAbandonedCartCooldownPeriod(): IRelativeDate | undefined {
        return this.abandoned_cart_cooldown_period
    }

    public setAbandonedBrowseMinimumItemViews(minimum_item_views: number | undefined): void {
        this.abandoned_browse_minimum_item_views = minimum_item_views === null || minimum_item_views === undefined
            ? undefined
            : minimum_item_views
    }

    public getAbandonedBrowseMinimumItemViews(): number | undefined {
        return this.abandoned_browse_minimum_item_views
    }

    public getAudience(): CampaignAudienceModel {
        return this.audience
    }
    public setAudience(audience: CampaignAudienceModel | any): void {
        this.audience = CampaignAudienceModel.build(audience ?? {})
    }

    public getItemGroupTargeting(): CampaignItemGroupTargetingModel | undefined {
        return this.item_group_targeting
    }
    public setItemGroupTargeting(itemGroupTargeting: CampaignItemGroupTargetingModel | any): void {
        this.item_group_targeting = !itemGroupTargeting
            ? undefined
            : CampaignItemGroupTargetingModel.build(itemGroupTargeting)
    }
}
