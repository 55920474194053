import * as React from 'react'
import autobind from 'autobind-decorator'
import { BetterComponent } from '../better-component/better-component'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { LoadingOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Input as AqeInput } from '@pushly/aqe/lib/components'
import { preventBubbling } from '../../_utils/utils'
import { axiosCancellationRequests } from '../../config/axios-setup'
import { SwPortal } from '../sw-portal/sw-portal'
import * as randomstring from 'randomstring'
import './app-message-search-bar.scss'
import { AppMessage } from '@pushly/models/lib/structs/app-messages/app-message'
import { AppMessageService } from '../../services/app-message.service'

interface IAppMessageSearchBarProps {
    value: number
    onChange?: (value: number) => any
    mode?: 'display' | 'search'
    autofocus?: boolean

    className?: string
    disabled?: boolean
    renderOption?: (appMessage: AppMessage) => React.ReactNode
    getDropdownContainer?: () => HTMLElement | any

    placeholder?: string
    reactive?: boolean
}

interface IAppMessageSearchBarState {
    expanded: boolean
    searching?: boolean
    appMessages: AppMessage[]
    tmpValue?: number
}

export class AppMessageSearchBar extends BetterComponent<IAppMessageSearchBarProps, IAppMessageSearchBarState> {
    private defaultClassName = 'sw-v2-app-message-search-bar'
    private axiosCancellationKey = 'nsb.searchAppMessages'

    private appState: AppState
    private appMsgService: AppMessageService

    private uid: string = randomstring.generate()
    private ref: any
    private inputRef: any
    private debounceTimer: any
    private debounceValue = 450

    public constructor(props: IAppMessageSearchBarProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appMsgService = Container.get(AppMessageService)

        this.state = {
            expanded: false,
            searching: false,
            appMessages: [],
        }
    }

    public async componentDidMount(): Promise<void> {
        this.wireEventHandlers()

        if (this.hasValue) {
            return this.searchAppMessages(this.props.value.toString(), true)
        } else if (this.props.autofocus && !!this.inputRef && !!this.inputRef.inputRef) {
            this.inputRef.inputRef.focus()
        }
    }

    public async componentDidUpdate(): Promise<void> {
        if (this.isDisplayMode && this.hasValue) {
            if (!this.state.tmpValue || this.props.value.toString() !== this.state.tmpValue.toString()) {
                this.searchAppMessages(this.props.value.toString(), true)
            }
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        this.unwireEventHandlers()
    }

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <span className={this.buildClassName('display')}>
                        {this.hasValue ? (
                            <>
                                <span className="option-id">#{this.props.value}:</span>
                                &nbsp;
                                <span className="option-name">
                                    {this.selectedAppMessage ? (
                                        this.selectedAppMessage.name
                                    ) : (
                                        <LoadingOutlined spin={true} />
                                    )}
                                </span>
                            </>
                        ) : (
                            '...'
                        )}
                    </span>

                    <div className={this.buildClassName('search')}>
                        <AqeInput
                            ref={(el) => (this.inputRef = el)}
                            className={this.buildClassName('search-input')}
                            reactive={this.props.reactive !== false}
                            onChange={this.handleInputChange}
                            onFocus={this.activate}
                            placeholder={this.props.placeholder || 'Search by ID or Name'}
                            maxWidth={400}
                            icon={
                                (
                                    <LegacyIcon
                                        className="search-icon"
                                        type={this.state.searching ? 'loading' : 'search'}
                                        spin={this.state.searching}
                                    />
                                ) as any
                            }
                        />
                    </div>

                    <SwPortal
                        className={this.buildRootClassNames('portal')}
                        portalId={this.buildClassName(`portal-${this.uid}`)}
                        container={this.portalContainer}
                        style={this.getPortalPositioning()}
                    >
                        <div className={this.buildRootClassNames('dropdown')}>
                            <div className={this.buildClassName('dropdown-wrapper')}>
                                <div
                                    className={`${this.buildClassName('dropdown-options')}${
                                        !this.hasOptions ? ' empty' : ''
                                    }`}
                                >
                                    {!this.hasOptions ? (
                                        <span>{this.isSearching ? 'Searching ...' : 'No app messages found'}</span>
                                    ) : (
                                        this.state.appMessages.map((n) => this.renderOption(n))
                                    )}
                                </div>
                            </div>
                        </div>
                    </SwPortal>
                </div>
            </div>
        )
    }

    public renderOption(message: AppMessage): React.ReactNode {
        const selected = this.hasValue && this.props.value.toString() === message.id!.toString()

        return (
            <div key={message.id} className={`${this.buildClassName('dropdown-option')}${selected ? ' selected' : ''}`}>
                <div
                    className={this.buildClassName('dropdown-option-wrapper')}
                    onClick={() => this.handleValueChange(message)}
                >
                    {!!this.props.renderOption ? (
                        this.props.renderOption(message)
                    ) : (
                        <>
                            <div className={this.buildClassName('dropdown-option-upper')}>
                                <span className="option-id">#{message.id}</span>
                            </div>
                            <div className={this.buildClassName('dropdown-option-lower')}>
                                <span className="option-name">{message.name}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    public getPortalPositioning(): any {
        if (this.ref) {
            const rect = this.ref.getBoundingClientRect()

            return [
                ['position', 'absolute'],
                ['top', `${Math.floor(rect.top) + Math.floor(rect.height)}px`],
                ['left', `${Math.floor(rect.left)}px`],
                ['width', `${Math.floor(rect.width)}px`],
            ]
                .map((p) => p.join(':'))
                .join(';')
        }
    }

    protected get isSearchMode(): boolean {
        return this.props.mode !== 'display'
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected get isExpanded(): boolean {
        return this.state.expanded
    }

    protected get isSearching(): boolean | undefined {
        return this.state.searching
    }

    protected get hasOptions(): boolean {
        return this.state.appMessages.length > 0
    }

    protected get hasValue(): boolean {
        return !!this.props.value
    }

    protected get hasInput(): boolean {
        return !!this.inputRef && !!this.inputRef.input.value.trim()
    }

    protected get inputValue(): string {
        return this.hasInput ? this.inputRef.input.value.trim() : ''
    }

    protected get selectedAppMessage(): AppMessage | undefined {
        let appMessage: AppMessage | undefined

        if (this.hasOptions && this.hasValue) {
            appMessage = this.state.appMessages.find((n) => n.id!.toString() === this.props.value.toString())
        }

        return appMessage
    }

    protected get portalContainer(): HTMLElement {
        return !this.props.getDropdownContainer ? document.body : this.props.getDropdownContainer()
    }

    protected updateInputRefValue(value: any): void {
        if (!!this.inputRef) {
            this.inputRef.inputRef.setState({ value })
            this.inputRef.calculateWidth(value)
        }
    }

    @autobind
    protected async handleValueChange(appMessage: AppMessage): Promise<void> {
        // clear loaded tmpValue
        await this.setState({ tmpValue: undefined })

        this.emitChangeEvent(appMessage.id!)
        this.deactivate()

        this.updateInputRefValue(appMessage.name)
    }

    @autobind
    protected async handleInputChange(ev: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        const value = ev.target.value

        this.emitChangeEvent(undefined as any)

        if (value.length >= 3) {
            this.searchAppMessages(value)
        } else {
            this.deactivate()
        }
    }

    @autobind
    protected async activate(): Promise<void> {
        this.debounce(() => {
            if (this.hasInput && this.inputValue.length > 3) {
                this.searchAppMessages(this.inputValue)
            }
        }, this.debounceValue / 2)
    }

    @autobind
    protected async deactivate(): Promise<void> {
        this.debounce(() => {
            this.cancelRequests()
            return this.setState(() => ({ expanded: false }))
        }, this.debounceValue / 2)
    }

    @autobind
    protected async cancelRequests(): Promise<void> {
        if (this.axiosCancellationKey in axiosCancellationRequests) {
            axiosCancellationRequests[this.axiosCancellationKey]()
        }

        return this.setState(() => ({
            searching: false,
            appMessages: [],
        }))
    }

    protected async searchAppMessages(query: string, reload: boolean = false): Promise<void> {
        this.setState(() => ({
            expanded: !reload,
            searching: true,
            tmpValue: reload ? (query as any) : undefined,
        }))

        this.debounce(async () => {
            const domainId = this.appState.currentDomain!.id
            const cancellationKey = `${this.axiosCancellationKey}-${this.uid}`
            const opts = {
                search: query,
                pagination: 0,
                showLoadingScreen: false,
                cancellationKey,
            }

            const { messages, meta, cancelled } = await this.appMsgService.fetchAppMessagesByDomainId(domainId, opts)

            await this.setState(({ expanded, appMessages: currentAppMessages }) => ({
                appMessages: cancelled ? currentAppMessages : messages,
                searching: expanded && cancelled,
            }))

            if (reload && !!this.selectedAppMessage) {
                this.updateInputRefValue(this.selectedAppMessage.name)
            }
        })
    }

    protected debounce(fn: Function, timeout?: number) {
        if (!!this.debounceTimer) clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(fn, !!timeout ? timeout : this.debounceValue)
    }

    protected async emitChangeEvent(value: number): Promise<void> {
        if (!!this.props.onChange) {
            this.props.onChange(value)
        }
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(append?: string): string {
        const classNames: string[] = [
            !!append ? `${this.defaultClassName}-${append}` : this.defaultClassName,
            this.isDisplayMode ? 'mode-display' : 'mode-search',
            this.isExpanded ? 'expanded' : 'collapsed',
            this.isSearching ? 'searching' : 'static',
        ]
        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }

    protected wireEventHandlers(): void {
        document.addEventListener('mousedown', this.handleDocumentClick.bind(this))
        this.ref.addEventListener('mousedown', preventBubbling)
    }

    protected unwireEventHandlers(): void {
        document.removeEventListener('mousedown', this.handleDocumentClick.bind(this))
        this.ref.removeEventListener('mousedown', preventBubbling)
    }

    protected handleDocumentClick(event: MouseEvent): void {
        if (this.isExpanded) this.deactivate()
    }
}
