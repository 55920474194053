import * as React from 'react'
import TemplatizedTextField, {
    InputFocusRef,
    ITemplatizedTextField,
} from '../templatized-text-field/templatized-text-field'
import { getClassNames } from '../../_utils/classnames'
import ColorPickerPopover from '../color-picker-popover/color-picker-popover'
import { Button, Tooltip } from 'antd'
import colorConvert from 'color-convert'
import './templatized-text-field-with-color.scss'
import { FontColorsOutlined } from '@ant-design/icons'
import { Limits } from '../templatized-text-field/templatized-text-field'
import { forwardRef } from 'react'

type TTFWCProps = Omit<ITemplatizedTextField, 'value'> & {
    fieldValue?: string
    onFieldChange?: (field: string) => void
    colorValue?: string
    onColorChange: (color: string) => void
    colorIcon?: React.ReactNode
    colorTitle?: string | React.ReactNode
    // TODO: Why is the necessary, when it exists on ITemplatizedTextField?
    limits?: Limits
}

export const TemplatizedTextFieldWithColor = forwardRef<InputFocusRef, TTFWCProps>((props: TTFWCProps, ref) => {
    const { fieldValue, colorValue, onColorChange, onFieldChange } = props

    // field can be tied directly to color picker - if no seperate fieldChange function passed assign color value
    const handleFieldChange = onFieldChange ?? onColorChange
    const textValue = onFieldChange === undefined ? colorValue : fieldValue

    return (
        <>
            <TemplatizedTextField
                {...props}
                ref={ref}
                className={getClassNames(null, props.className, 'color-field')}
                value={textValue}
                onChange={handleFieldChange}
            />
            <ColorPickerPopover
                className="color-popover-btn"
                content={
                    <Tooltip trigger="hover" title={props.colorTitle ?? 'Font Color'}>
                        <Button
                            disabled={props.disabled}
                            style={{
                                color:
                                    colorConvert.hex.hsl(colorValue ?? '#ffffff')?.[2] < 70
                                        ? 'white'
                                        : 'black' ?? 'black',
                            }}
                        >
                            <span
                                className="color-wrap"
                                style={{
                                    backgroundColor: colorValue ?? '#ffffff',
                                }}
                            >
                                {props.colorIcon ?? <FontColorsOutlined />}
                            </span>
                        </Button>
                    </Tooltip>
                }
                defaultColor={'#ffffff'}
                color={colorValue ?? '#ffffff'}
                onChange={onColorChange}
                disabled={props.disabled}
            />
        </>
    )
})
