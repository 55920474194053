import * as React from 'react'
import { ScheduleWindow } from '@pushly/models/lib/enums/schedule-window'
import { BASE_TIME_FORMAT, BASE_TIME_FORMAT_WITHOUT_TZ } from '../../constants'
import moment from 'moment-timezone'
import './styles/delivery-date-range-slug.scss'

type DeliveryDateRangeDetailProps = {
    start: string | Date
    end?: string | Date | null
    timeZone?: string
    window: ScheduleWindow
}

export const DeliveryDateRangeSlug = (props: DeliveryDateRangeDetailProps) => {
    const { start, end, timeZone, window } = props
    const isSTZ = window === ScheduleWindow.TIMEZONE
    const dateFormat = isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT

    const startDate = moment.tz(start, timeZone).format(dateFormat)
    const endDate = end ? moment.tz(end, timeZone).format(dateFormat) : 'Ongoing'

    return (
        <span>
            <b>Delivery Date Range</b> {startDate} through {endDate}
        </span>
    )
}
