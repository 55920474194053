import { RelativeDateDisplayMetric } from '@pushly/models/lib/enums/relative-date-display-metric'
import { AnimationType } from '@pushly/models/lib/enums/app-messages/app-message-animation-type'
import { AnimationEffect } from './types'

export const SESSION_METRICS_FILTERED = [RelativeDateDisplayMetric.SECONDS, RelativeDateDisplayMetric.MINUTES]
export const LIFETIME_METRICS = Object.values(RelativeDateDisplayMetric)

// Display Values slightly differ from assigned property value for 'X_TIME_IN_APP' [type: AppMessageConditionType] - calculated with config requirements
export const SESSION_TRIGGER_TYPES = ['SESSION_START', 'TIME_IN_APP', 'CUSTOM']

export const DEFAULT_TOGGLEABLE_FG_COLOR = '#111111'
export const DEFAULT_CTA_FG_COLOR = '#FFFFFF'

export const DEFAULT_TOGGLEABLE_BG_COLOR = '#FFFFFF'
export const DEFAULT_CTA_BG_COLOR = '#111111'

export const DEFAULT_IAM_ENTER_ANIMATIONS = [AnimationType.FADE]
export const DEFAULT_IAM_EXIT_ANIMATIONS = [AnimationType.FADE]
export const DEFAULT_IAM_ANIMATION_DURATION_MS = 200
export const ANIMATION_DISPLAY_NAME_MAP = {
    [AnimationEffect.Enter]: {
        [AnimationType.FADE]: 'Fade',
        [AnimationType.SLIDE_LEFT]: 'Slide from Right',
        [AnimationType.SLIDE_RIGHT]: 'Slide from Left',
        [AnimationType.SLIDE_DOWN]: 'Slide from Top',
        [AnimationType.SLIDE_UP]: 'Slide from Bottom',
        [AnimationType.ZOOM_IN]: 'Zoom In',
        [AnimationType.ZOOM_OUT]: 'Zoom Out',
    },
    [AnimationEffect.Exit]: {
        [AnimationType.FADE]: 'Fade',
        [AnimationType.SLIDE_LEFT]: 'Slide to Left',
        [AnimationType.SLIDE_RIGHT]: 'Slide to Right',
        [AnimationType.SLIDE_DOWN]: 'Slide to Bottom',
        [AnimationType.SLIDE_UP]: 'Slide to Top',
        [AnimationType.ZOOM_IN]: 'Zoom In',
        [AnimationType.ZOOM_OUT]: 'Zoom Out',
    },
}
