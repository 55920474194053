import { DeliveryChannel } from '@pushly/models/lib/enums/delivery-channels.enum'
import { Well } from '@pushly/aqe/lib/components'
import * as React from 'react'
import { ReactNode, useState } from 'react'
import { BetterSelect } from '../../better-select/better-select'
import { Button, Modal, Popover, Tooltip } from 'antd'
import { InfoCircleOutlined, RotateLeftOutlined } from '@ant-design/icons'
import { getClassNames } from '../../../_utils/classnames'
import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import PreviewSDK from '@pushly/cuttlefish'
import classNames from 'classnames'

type AppMessagePreviewProps = {
    template: AppMessageUiTemplate
    hideHeader?: boolean
    loading?: boolean | ReactNode
}

export const AppMessagePreview = ({ template, hideHeader, loading }: AppMessagePreviewProps) => {
    const previewTemplate = template

    const sortedChannels = [...previewTemplate.channels].sort()
    const defaultChannel = sortedChannels.length === 2 ? DeliveryChannel.NATIVE_IOS : sortedChannels[0]

    const [channel, setChannel] = useState(defaultChannel)
    const [theme, setTheme] = useState<'light' | 'dark'>('light')
    const [landscapeVisible, setLandscapeVisible] = useState(false)

    const channelOptions = sortedChannels.map((option) => {
        return {
            value: option,
            label: option === DeliveryChannel.NATIVE_ANDROID ? 'Android' : 'Apple / iOS',
        }
    })

    return (
        <Well
            className={getClassNames('app-message-builder-preview', 'nested')}
            title={
                <span>
                    <Tooltip title="The platform preview is a best-guess at how this app message will look across the most common devices for the selected operating system.">
                        Preview
                        <InfoCircleOutlined className="info-icon" />
                    </Tooltip>
                </span>
            }
            hideHeader={hideHeader}
            hideFooter={true}
        >
            <div className="preview-select">
                <BetterSelect
                    className="platform-select"
                    prefix="Platform"
                    disableSearch={true}
                    onChange={(val: DeliveryChannel) => {
                        setChannel(val)
                    }}
                    value={[channel]}
                    options={channelOptions}
                    disabled={channelOptions.length === 1}
                />

                <BetterSelect
                    className="theme-select"
                    prefix="Theme"
                    disableSearch={true}
                    defaultValue={['light']}
                    value={[theme]}
                    onChange={(v: 'light' | 'dark') => {
                        setTheme(v)
                    }}
                    options={[
                        {
                            value: 'light',
                            label: 'Light',
                        },
                        {
                            value: 'dark',
                            label: 'Dark',
                        },
                    ]}
                />

                <Popover content="Preview app message in landscape orientation" trigger={['hover']} placement="topLeft">
                    <Button
                        className="orientation-toggle"
                        icon={<RotateLeftOutlined />}
                        onClick={() => setLandscapeVisible(true)}
                    />
                </Popover>
            </div>

            <Well
                className={classNames('app-message-portrait-preview')}
                mode="ghost"
                hideHeader={true}
                hideFooter={true}
            >
                <PreviewSDK.AppMessagePreview
                    channel={channel}
                    template={previewTemplate}
                    systemMode={theme}
                    loading={loading}
                />
            </Well>

            <Modal
                visible={landscapeVisible}
                onCancel={() => setLandscapeVisible(false)}
                onOk={() => setLandscapeVisible(false)}
                footer={false}
                closeIcon={false}
                width={750}
                centered={true}
            >
                <PreviewSDK.AppMessagePreview
                    channel={channel}
                    template={previewTemplate}
                    systemMode={theme}
                    orientation="landscape"
                />
            </Modal>
        </Well>
    )
}
