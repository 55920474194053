import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'

export class NotificationNativeAndroidContentModel {
    public static build(props: NotificationNativeAndroidContentModel | any): NotificationNativeAndroidContentModel {
        const data = props instanceof NotificationNativeAndroidContentModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationNativeAndroidContentModel()
        model.setChannel(scData.channel)
        model.setPriority(scData.priority)
        model.setTitle(scData.title)
        model.setBody(scData.body)
        model.setIconUrl(scData.icon_url)
        model.setImageUrl(scData.image_url)

        return model
    }

    private channel: string | undefined
    private priority: string | undefined
    private title: string | undefined
    private body: string | undefined | null
    private imageUrl: string | undefined | null
    private iconUrl: string | undefined | null

    public clone(): NotificationNativeAndroidContentModel {
        return NotificationNativeAndroidContentModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            priority: this.getPriority(),
            channel: this.getChannel(),
            title: this.getTitle(),
            body: this.getBody(),
            icon_url: this.getIconUrl(),
            image_url: this.getImageUrl(),
        })
    }

    public getChannel(): string | undefined {
        return this.channel
    }
    public setChannel(channel: string | undefined) {
        this.channel = channel
    }

    public getPriority(): string | undefined {
        return this.priority
    }
    public setPriority(priority: string | undefined) {
        this.priority = priority
    }

    public getTitle(): string | undefined {
        return this.title
    }
    public setTitle(title: string | undefined) {
        this.title = title
    }

    public getBody(): string | undefined | null {
        return this.body
    }
    public setBody(body: string | undefined): void {
        this.body = body
    }

    public getIconUrl(): string | undefined | null {
        return this.iconUrl
    }

    public setIconUrl(iconUrl: string | undefined | null): void {
        this.iconUrl = iconUrl
    }
    public getImageUrl(): string | undefined | null {
        return this.imageUrl
    }
    public setImageUrl(imageUrl: string | undefined | null): void {
        this.imageUrl = imageUrl
    }
}
