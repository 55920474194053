import * as React from 'react'
import { Segment } from '@pushly/models/lib/structs/segment'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService } from '../../services'
import { Tag } from 'antd'
import { preventBubbling } from '../../_utils/utils'
import { ReactNode } from 'react'
import { SegmentType } from '@pushly/models/lib/enums/segment-type'
import './styles/audience-details-slug.scss'

type AudienceDetailsSlugProps = {
    segments?: Segment[]
    excludedSegments?: Segment[]
}

export const AudienceDetailsSlug = (props: AudienceDetailsSlugProps) => {
    const appSvc = useService(AppService)
    const { segments, excludedSegments } = props
    let subscribers: React.ReactNode
    let excludedSubscribers: React.ReactNode
    const allPushSubscriberTag: React.ReactNode = (
        <a key="all-subs" className="no-click">
            <Tag>All Push Subscribers</Tag>
        </a>
    )
    const allAppSubscriberTag: React.ReactNode = (
        <a key="all-subs" className="no-click">
            <Tag>All App Users</Tag>
        </a>
    )

    const buildSegmentUrl = (segment) => {
        return appSvc.routeWithinDomain(`/segments/${segment.id}/summary`, true)
    }

    const jumpToSegment = (segment: Segment, ev?: any) => {
        if (!ev.metaKey) {
            preventBubbling(ev)
            appSvc.route(buildSegmentUrl(segment))
        }
    }

    if (segments?.length) {
        let subscriberTags: ReactNode[] = []
        const isAllAppSubscribers = segments.find((s) => s.type === SegmentType.ALL_APP_USERS)
        const isAllPushSubscribers = segments.find((s) => s.type === SegmentType.ALL_PUSH_SUBSCRIBERS)

        if (isAllAppSubscribers) {
            subscriberTags.push(allAppSubscriberTag)
        } else if (isAllPushSubscribers) {
            subscriberTags.push(allPushSubscriberTag)
        } else {
            subscriberTags = segments
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                .map((segment: any, idx: number) => (
                    <a key={`i-${idx}`} onClick={(ev) => jumpToSegment(segment, ev)} href={buildSegmentUrl(segment)}>
                        <Tag>{segment.name}</Tag>
                    </a>
                ))
        }

        subscribers = subscriberTags
    }

    if (excludedSegments?.length) {
        excludedSubscribers = excludedSegments
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
            .map((segment: any, idx: number) => (
                <a key={`x-${idx}`} onClick={(ev) => jumpToSegment(segment, ev)} href={buildSegmentUrl(segment)}>
                    <Tag>{segment.name}</Tag>
                </a>
            ))
    }

    return (
        <div className="audience-slug">
            <div className="audience-section">
                <div>
                    <b>Included Segments</b>
                </div>
                <div>{subscribers}</div>
            </div>
            {!!excludedSubscribers ? (
                <div className="audience-section">
                    <div>
                        <b>Excluded Segments</b>
                    </div>
                    <div>{excludedSubscribers}</div>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}
