import { NotificationTemplateDto } from './notification-template-dto'
import { SegmentDto } from '../../../dtos/segment'
import { NotificationDeliverySpec, NotificationScheduleDto } from './notification-schedule-dto'
import { StatusType } from '../../../enums/status-type'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'
import { NotificationDefaultTemplateDto } from './notification-default-template.dto'
import { NotificationNativeIosTemplateDto } from './notification-native-ios-template.dto'
import { NotificationNativeAndroidTemplateDto } from './notification-native-android-template.dto'
import { NotificationWebTemplateDto } from './notification-web-template.dto'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { NotificationSource } from '../../../enums/notification-source'
import { NotificationDefaultContent } from '@pushly/models/lib/structs/notification-default-content'
import { NotificationContentBase } from '@pushly/models/lib/structs/notification-content-base'
import { NotificationIOSContent } from '@pushly/models/lib/structs/notification-ios-content'

export class NotificationDto {
    public static parse(fields: Partial<NotificationDto>): NotificationDto {
        const dto = new NotificationDto()

        Object.assign(dto, fields)

        if (Array.isArray(dto.schedules) && dto.schedules.length > 0) {
            dto.status = dto.schedules[0].status ?? dto.status
        }

        if (dto.channels && dto.template?.channels?.default && !dto.template.channels.default.channels) {
            dto.template.channels.default.channels = dto.channels
        }

        return dto
    }

    public id: number
    public domainId: number
    public status: string
    public template: {
        channels: {
            default: NotificationDefaultTemplateDto | NotificationDefaultContent
            web?: NotificationWebTemplateDto
            nativeIos?: NotificationNativeIosTemplateDto | NotificationIOSContent
            nativeAndroid?: NotificationNativeAndroidTemplateDto
        }
        keywords: string[]
        // named template fields
        name?: string
        description?: string
    }
    public deliverySpec: NotificationDeliverySpec
    public channels: DeliveryChannel[]
    public audience: {
        segments?: SegmentDto[]
        excludedSegmentIds?: number[]
        excludedSegments?: SegmentDto[]
        segmentIds?: number[]
        subscriberIds?: string[]
        externalSubscriberIds?: string[]
        domainIds?: number[]
    }
    public abTest: any
    public notificationGroup?: {
        id: string
        isMultiDomain: boolean
    }
    public createdAt: Date
    public createdBy: number
    public createdByUserName: string
    public updatedAt: Date
    public schedules?: NotificationScheduleDto[]
    public source?: NotificationSource

    public get defaultTemplate(): NotificationDefaultTemplateDto {
        const wrapper: any = this.template || {}
        const channels: any = wrapper.channels || {}
        if (channels.nativeIos || channels.nativeAndroid) {
            channels.default.altNativeUrl =
                channels.nativeIos?.landingUrl ?? channels.nativeAndroid?.landingUrl ?? undefined
        }

        if (this.template.keywords) {
            channels.default.keywords = this.template.keywords
        }
        return channels.default || {}
    }

    public get webTemplate(): NotificationWebTemplateDto {
        const wrapper: any = this.template || {}
        const channels: any = wrapper.channels || {}
        return channels.web || {}
    }

    public get nativeIosTemplate(): NotificationNativeIosTemplateDto {
        const wrapper: any = this.template || {}
        const channels: any = wrapper.channels || {}
        return channels.nativeIos || {}
    }

    public get nativeAndroidTemplate(): NotificationNativeAndroidTemplateDto {
        const wrapper: any = this.template || {}
        const channels: any = wrapper.channels || {}
        return channels.nativeAndroid || {}
    }

    public get keywords(): string[] {
        const wrapper: any = this.template || {}
        return wrapper.keywords || []
    }

    public set keywords(value) {
        const wrapper: any = this.template || {}
        wrapper.keywords = value
    }

    public getLegacyAudienceType(): string {
        let type = 'SEGMENTS'

        if (this.audience.subscriberIds && this.audience.subscriberIds.length > 0) {
            type = 'SUBSCRIBERS'
        } else if (this.audience.externalSubscriberIds && this.audience.externalSubscriberIds.length > 0) {
            type = 'EXTERNAL_SUBSCRIBERS'
        }

        return type
    }

    public isTzDelivery(): boolean {
        const tzDelivery = this.deliverySpec.window === NotificationDeliveryWindow.TIMEZONE
        return tzDelivery
    }

    public isTzDeliveryInProgress(): boolean {
        const tzDeliveryInProgress = this.isTzDelivery() && this.status === StatusType.DELIVERING.name
        return tzDeliveryInProgress
    }
}
