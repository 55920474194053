import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import {
    IosBadgeBehaviorAction,
    IosInterruptionLevel,
    IosRelevanceScore,
} from '../../components/notification-builder/enums'

export interface IBadgeBehavior {
    action: IosBadgeBehaviorAction
    value: number | null
}

export class BadgeBehaviorModel {
    public static build(props: BadgeBehaviorModel | any): BadgeBehaviorModel {
        const data = props instanceof BadgeBehaviorModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new BadgeBehaviorModel()
        model.setAction(scData?.action ?? IosBadgeBehaviorAction.DO_NOTHING)
        model.setValue(scData?.value ?? model.value)

        return model
    }

    private action: IosBadgeBehaviorAction = IosBadgeBehaviorAction.DO_NOTHING
    private value: number | null

    public clone(): BadgeBehaviorModel {
        return BadgeBehaviorModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            action: this.getAction(),
            value: this.getValue(),
        })
    }

    public getAction(): IosBadgeBehaviorAction {
        return this.action
    }
    public setAction(action: IosBadgeBehaviorAction) {
        switch (action) {
            case IosBadgeBehaviorAction.INCREMENT:
            case IosBadgeBehaviorAction.SET:
                this.value = 1
                break
            case IosBadgeBehaviorAction.DO_NOTHING:
                this.value = null
                break
            default:
                this.value = 1
        }
        this.action = action
    }

    public getValue(): number | null {
        return this.value
    }
    public setValue(value: number) {
        this.value = value
    }
}

export class NotificationNativeIosContentModel {
    public static build(props: NotificationNativeIosContentModel | any): NotificationNativeIosContentModel {
        const data = props instanceof NotificationNativeIosContentModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationNativeIosContentModel()
        model.setCategory(scData.category ?? scData.native_ios_category ?? 'GENERAL')
        model.setSubtitle(scData.subtitle ?? scData.native_ios_subtitle)
        model.setSound(scData.sound ?? scData.native_ios_sound)
        model.setInterruptionLevel(
            scData.interruption_level ?? scData.native_ios_interruption_level ?? IosInterruptionLevel.ACTIVE,
        )
        model.setBadgeBehavior(scData.badge_behavior)
        model.setRelevance(scData.relevance_score ?? scData.native_ios_relevance_score ?? IosRelevanceScore.NORMAL)
        model.setTargetContentId(scData.target_content_id ?? scData.native_ios_target_content_id)

        // overrides
        model.setTitle(scData.title)
        model.setBody(scData.body)
        model.setImageUrl(scData.image_url)
        model.setIconUrl(scData.icon_url)
        model.setIsUsingDomainDefaultIcon(scData.is_using_domain_default_icon)

        return model
    }

    private category: string | undefined
    private subtitle: string | undefined
    private sound: string | undefined
    private interruptionLevel: IosInterruptionLevel
    private relevanceScore: IosRelevanceScore | number | undefined
    private badgeBehavior: BadgeBehaviorModel
    private targetContentId: string | undefined
    private title: string | undefined
    private body: string | undefined | null
    private imageUrl: string | undefined | null
    private iconUrl: string | undefined | null
    private isUsingDomainDefaultIcon: boolean

    public clone(): NotificationNativeIosContentModel {
        return NotificationNativeIosContentModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            category: this.getCategory(),
            subtitle: this.getSubtitle(),
            sound: this.getSound(),
            interruption_level: this.getInterruptionLevel(),
            relevance_score: this.getRelevance(),
            badge_behavior: this.getBadgeBehavior().serialize(),
            target_content_id: this.getTargetContentId(),
            title: this.getTitle(),
            body: this.getBody(),
            icon_url: this.getIconUrl(),
            image_url: this.getImageUrl(),
        })
    }

    public getCategory(): string | undefined {
        return this.category
    }
    public setCategory(category: string | undefined) {
        this.category = category
    }

    public getSubtitle(): string | undefined {
        return this.subtitle
    }
    public setSubtitle(subtitle: string | undefined) {
        this.subtitle = subtitle
    }

    public getSound(): string | undefined {
        return this.sound
    }
    public setSound(sound: string | undefined) {
        this.sound = sound
    }

    public getInterruptionLevel(): IosInterruptionLevel {
        return this.interruptionLevel
    }
    public setInterruptionLevel(interruptionLevel: IosInterruptionLevel) {
        this.interruptionLevel = interruptionLevel
    }

    public getRelevance(): number | undefined {
        return this.relevanceScore
    }
    public setRelevance(relevance: number | undefined) {
        this.relevanceScore = relevance
    }

    public getBadgeBehavior(): BadgeBehaviorModel {
        return this.badgeBehavior
    }
    public setBadgeBehavior(badgeBehavior: IBadgeBehavior) {
        this.badgeBehavior = BadgeBehaviorModel.build(badgeBehavior ?? {})
    }

    public getTargetContentId(): string | undefined {
        return this.targetContentId
    }
    public setTargetContentId(targetContentId: string | undefined) {
        this.targetContentId = targetContentId
    }

    public getTitle(): string | undefined {
        return this.title
    }
    public setTitle(title: string | undefined): void {
        this.title = title
    }

    public getBody(): string | undefined | null {
        return this.body
    }
    public setBody(body: string | undefined): void {
        this.body = body
    }

    public getIconUrl(): string | undefined | null {
        return this.iconUrl
    }

    public setIconUrl(iconUrl: string | undefined | null, isSegmentIcon: boolean = false): void {
        this.iconUrl = iconUrl

        // TODO: determine this implementation once added to form
        // if (iconUrl && isSegmentIcon) {
        //     this.setSegmentDefaultIconUrl(iconUrl)
        // } else {
        //     this.setSegmentDefaultIconUrl(undefined)
        // }
    }

    public getIsUsingDomainDefaultIcon() {
        return this.isUsingDomainDefaultIcon
    }
    public setIsUsingDomainDefaultIcon(value: boolean) {
        this.isUsingDomainDefaultIcon = value ?? false
    }

    public getImageUrl(): string | undefined | null {
        return this.imageUrl
    }
    public setImageUrl(imageUrl: string | undefined | null): void {
        this.imageUrl = imageUrl
    }
}
