import * as React from 'react'
import { AppState } from '../../../stores/app'
import { AppService } from '../../../services'
import { InsightsService } from '../../../services/insights'
import { Container } from 'typescript-ioc'
import { DatePicker, Select, Skeleton, Tooltip } from 'antd'
import { InfoCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Well } from '@pushly/aqe/lib/components'
import { SegmentAudienceSizeGraph } from './segment-audience-size-graph'
import { SHORT_DATE_FORMAT } from '../../../constants'
import { RangeValueWithPreset } from '@pushly/aqe/lib/types'
import { Moment } from 'moment-timezone/moment-timezone'
import moment from 'moment-timezone'
import { SegmentService } from '../../../services/segment'
import { SegmentModel } from '../../../models/segments/segment.model'
import { SegmentPerformanceGraph } from './segment-performance-graph'
import { numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import { TIME_RANGE_OPTIONS } from '../../reporting_v2/constants/time-range-options'

interface ISegmentSummaryTabProps {
    segment?: SegmentModel
}

interface ISegmentSummaryTabState {
    timeBreakdown: string
    loadingLifetimeStats: boolean
    loadingStats: boolean
    lifetimeStats: any
    genStats: any
    timeRangeSince?: any
    timeRangeUntil?: any
    currentContract?: any
}

export class SegmentSummaryTab extends BetterComponent<ISegmentSummaryTabProps, ISegmentSummaryTabState> {
    protected static tabName = 'summary'
    protected static tabLabel = 'Summary'

    private appState: AppState
    private appService: AppService
    private segmentService: SegmentService
    private insightsService: InsightsService

    public constructor(props: ISegmentSummaryTabProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.segmentService = Container.get(SegmentService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            timeBreakdown: 'day',
            loadingLifetimeStats: true,
            loadingStats: true,
            lifetimeStats: [],
            genStats: [],
            timeRangeSince: TIME_RANGE_OPTIONS['Last 90 Days']()[0],
            timeRangeUntil: TIME_RANGE_OPTIONS['Last 90 Days']()[1],
        }
    }

    public async componentDidMount() {
        await this.refreshStats()
    }

    public render(): React.ReactNode {
        return (
            <div>
                <Skeleton active={true} loading={false}>
                    <SegmentGenStatsRow loading={this.state.loadingLifetimeStats} stats={this.state.lifetimeStats} />

                    <Well
                        className="segment-insights-view"
                        title="Insights"
                        hideFooter={true}
                        actions={this.renderActions()}
                    >
                        <div className="segment-audience-size">
                            {this.state.loadingStats ? (
                                <Loading3QuartersOutlined spin={this.state.loadingStats} className="loading-well" />
                            ) : (
                                <>
                                    <SegmentAudienceSizeGraph
                                        stats={this.state.genStats}
                                        timeBreakdown={this.state.timeBreakdown}
                                    />
                                    <div className="segment-performance">
                                        <SegmentPerformanceGraph
                                            stats={this.state.genStats}
                                            contract={this.state.currentContract}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </Well>
                </Skeleton>
            </div>
        )
    }

    protected renderActions(): React.ReactNode {
        let dateGroupingLabel: any = 'Date Grouping'

        if (this.isLifetimeBreakdown) {
            dateGroupingLabel = (
                <div>
                    <Tooltip title='Only "Weekly" or "Monthly" date grouping is supported when selected timeframe is "All Time".'>
                        Date Grouping
                        <InfoCircleOutlined className="info-icon" />
                    </Tooltip>
                </div>
            )
        }
        return (
            <>
                <label htmlFor="breakdown" className="action-label">
                    {dateGroupingLabel}
                </label>
                <Select
                    id="breakdown"
                    size="small"
                    dropdownClassName="date-group-dropdown"
                    value={this.state.timeBreakdown}
                    onChange={this.setTimeBreakdownState}
                >
                    <Select.Option value="day" disabled={this.isLifetimeBreakdown}>
                        Daily
                    </Select.Option>
                    <Select.Option value="week">Weekly</Select.Option>
                    <Select.Option value="month">Monthly</Select.Option>
                </Select>

                <label htmlFor="timeframe" className="action-label">
                    Timeframe
                </label>
                <DatePicker.RangePicker
                    id="timeframe"
                    size="small"
                    className="segment-date-picker"
                    dropdownClassName="segment-size-date-range"
                    getPopupContainer={this.appService.getAppContainer}
                    format={SHORT_DATE_FORMAT}
                    ranges={TIME_RANGE_OPTIONS as any}
                    value={
                        this.state.timeRangeSince
                            ? [this.state.timeRangeSince, this.state.timeRangeUntil]
                            : [null, null]
                    }
                    onCalendarChange={this.setDateRangeState}
                    disabledDate={(date) => date >= moment(new Date()).subtract(1, 'day')}
                ></DatePicker.RangePicker>
            </>
        )
    }

    private setDateRangeState = async (value: RangeValueWithPreset<Moment>) => {
        if (value?.[0] === null && value?.[1] === null) {
            await this.setState({ timeBreakdown: 'week' })
        }

        await this.setState({
            timeRangeSince: value?.[0],
            timeRangeUntil: value?.[1],
        })

        await this.updateGenStats()
    }

    private refreshStats = async () => {
        await this.setState({ loadingStats: true, loadingLifetimeStats: true })

        await this.updateAllStats()
    }

    private async updateAllStats(): Promise<void> {
        const requests = [await this.loadGenStats(true), await this.loadGenStats()]

        await Promise.all(requests)
    }

    private async updateGenStats(): Promise<void> {
        await this.setState({ loadingStats: true })
        await this.loadGenStats()
    }

    private async loadGenStats(loadLifetimeStats: boolean = false) {
        const contract: any = {
            entity: 'segments',
            breakdowns: ['segment'],
            date_increment: this.state.timeBreakdown,
            fields: ['impressions', 'clicks', 'ctr_decimal', 'notifications_sent', 'clicks_per_send', 'size'],
            filters: [
                { field: 'domain.id', operator: 'eq', value: this.appState.currentDomain!.id },
                { field: 'segment.id', operator: 'in', value: [this.props.segment?.getId()] },
            ],
        }

        if (this.isLifetimeBreakdown || loadLifetimeStats) {
            contract.date_preset = 'lifetime'
            // loadLifetimeStats boolean only called on initial load
            contract.date_increment = loadLifetimeStats ? 'lifetime' : this.state.timeBreakdown
        } else {
            contract.date_range = {
                since: moment(this.state.timeRangeSince).format('YYYY-MM-DD'),
                through: moment(this.state.timeRangeUntil).format('YYYY-MM-DD'),
            }
        }

        let stats = (await this.insightsService.fetch(contract, false, 'segmentDetails.stats')) ?? []

        if (!this.appState.currentDomain?.flags.includes('DEMO')) {
            stats = stats.filter((stat) => {
                return stat.size > 0
            })
        }

        return loadLifetimeStats
            ? await this.setState(({ lifetimeStats }) => ({
                  loadingLifetimeStats: !lifetimeStats,
                  lifetimeStats: stats,
              }))
            : await this.setState(({ genStats }) => ({
                  loadingStats: !genStats,
                  genStats: stats,
                  currentContract: contract,
              }))
    }

    private setTimeBreakdownState = async (value: string) => {
        await this.setState({ timeBreakdown: value, loadingStats: true })

        await this.loadGenStats()
    }

    private get isLifetimeBreakdown(): boolean {
        return !this.state.timeRangeSince
    }
}

interface ISegmentGenStatsRow {
    loading: boolean
    stats: any
}

const SegmentGenStatsRow = ({ stats, loading }: ISegmentGenStatsRow) => {
    const displayStats = stats[0]
    return (
        <div className="gen-stats-wrapper">
            <Well className="thin gen-stats-well" loading={loading} hideHeader={true} hideFooter={true}>
                <div className="gen-stats-row">
                    <div className="gen-stat">
                        <div className="chart-title">Impressions</div>
                        <div className="stat stat-status">
                            <span className="stat-impressions">
                                {numberWithCommas(displayStats?.impressions) ?? '--'}
                            </span>
                        </div>
                    </div>
                    <div className="gen-stat">
                        <div className="chart-title">Clicks</div>
                        <div className="stat stat-status">
                            <span className="stat-clicks">{numberWithCommas(displayStats?.clicks) ?? '--'}</span>
                        </div>
                    </div>
                    <div className="gen-stat">
                        <div className="chart-title">CTR</div>
                        <div className="stat stat-status">
                            <span className="stat-ctr">{numberWithPercent(displayStats?.ctr_decimal) ?? '--'}</span>
                        </div>
                    </div>
                    <div className="gen-stat">
                        <div className="chart-title">Notifications Sent</div>
                        <div className="stat stat-status">
                            <span className="stat-notifs-sent">
                                {numberWithCommas(displayStats?.notifications_sent) ?? '--'}
                            </span>
                        </div>
                    </div>
                    <div className="gen-stat">
                        <div className="chart-title">CPS</div>
                        <div className="stat stat-status">
                            <span className="stat-cps">{numberWithCommas(displayStats?.clicks_per_send) ?? '--'}</span>
                        </div>
                    </div>
                </div>
            </Well>
        </div>
    )
}
