import { UserType } from '../enums/user-type'
import { SubjectEntity } from '../enums/ability-entity.enum'
import { AccessRoleId, ExternalApiAccessRoleIds } from '../enums/access-role.enum'
import { EXTERNAL_ORG_API_KEY } from '../constants'

export class UserDto {
    public static __caslSubjectType__ = SubjectEntity.USER

    public static fromApiResponse(fields: Partial<UserDto>): UserDto {
        const dto = new UserDto()
        Object.assign(dto, fields)
        return dto
    }

    public readonly id: number
    public name: string
    public email: string
    public statusId: number
    public status: string
    public userTypeId: number
    public userRoleId: number
    public userType: UserType
    public puuids: any[]
    public flags: string[]
    public isDbUser: boolean

    public get isInternalUser(): boolean {
        return this.userType === UserType.INTERNAL
    }

    public get isInternalDevUser(): boolean {
        return (
            this.isInternalUser && (this.userRoleId === AccessRoleId.INTERNAL_DEVELOPER || this.hasFlag('PUSHLY_ENG')) // legacy flag
        )
    }

    public get isApiUser(): boolean {
        return ExternalApiAccessRoleIds.includes(this.userRoleId) || this.userRoleId === AccessRoleId.INTERNAL_API_USER
    }

    public hasFlag(key: string) {
        return this.flags?.includes(key) ?? false
    }
}
