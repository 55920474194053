import * as querystring from 'query-string'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { AppState } from '../stores/app'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { AccountDto } from '../dtos/account.dto'
import { simpleNotification } from '../_utils/utils'
import { DomainDto } from '../dtos/domain'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import fileDownload from 'js-file-download'
import aqe from '@pushly/aqe'
import { AbstractEntityService } from '@pushly/aqe/lib/services'
import IApiCallOptions from '../interfaces/api-call-options'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

@Singleton
export class AccountService extends AbstractEntityService {
    private appService: AppService

    public constructor() {
        super()

        this.appService = Container.get(AppService)
    }

    public async fetchAll(
        opts?: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<any | undefined> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/accounts?${options}`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                cancellationKey,
            )

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }

            return req.data
        } catch (error) {
            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            return []
        }
    }

    public async fetchById(
        accountId: number,
        opts?: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<AccountDto> {
        let dto: AccountDto

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}?${options}`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                cancellationKey,
            )

            dto = AccountDto.fromApiResponse(req.data.data)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return dto!
    }

    public async post(
        createDto: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<AccountDto> {
        let dto: AccountDto

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts`

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: serviceURL,
                    data: createDto,
                },
                cancellationKey,
            )

            dto = AccountDto.fromApiResponse(req.data.data)
            simpleNotification('success', `Organization '${createDto.name}' has been successfully created.`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return dto!
    }

    public async patch(
        accountId: number,
        updateDto: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<AccountDto>> {
        let dto: AccountDto
        let ok = false

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}`

        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: serviceURL,
                    data: updateDto,
                },
                cancellationKey,
            )

            ok = true
            dto = AccountDto.fromApiResponse(req.data.data)
            simpleNotification('success', 'Your organization has been successfully updated.')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: dto! }
    }

    public async fetchKeywords(accountId: number, options: IApiCallOptions & { rawOrder?: boolean } = {}) {
        if (options.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const res = await this.execFetchMany(`${aqe.defaults.publicApiDomain}/accounts/${accountId}/keywords`, options)
        if (options.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        if (!res.ok && res.error) {
            if (!isAxiosCancellation(res.error)) {
                simpleNotification('error', 'Organization keywords could not be loaded at this time.')
            }
        }

        return res
    }

    public async fetchSubscriberPreferences(orgId: number, options: IApiCallOptions = {}) {
        if (options.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const res = await this.execGet<{ domainId: number; preference: string }[]>(
            `${aqe.defaults.publicApiDomain}/accounts/${orgId}/subscriber-preferences`,
            options,
        )
        if (options.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        if (!res.ok && res.error) {
            if (!isAxiosCancellation(res.error)) {
                simpleNotification('error', 'Organization subscriber preferences could not be loaded at this time.')
            }
        }

        return res
    }

    public async fetchBillingRecordsByAccountId(
        accountId: number,
        opts: any = {},
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<any[]>> {
        let ok = false
        let invoices: any = []
        let meta: any

        const options = querystring.stringify(opts || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/billing-records?${options}`

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                cancellationKey,
            )

            ok = true

            const responseIsCSV = /text\/csv/i.test(req.request.getResponseHeader('content-type'))
            if (responseIsCSV) {
                const csvDisposition = req.request.getResponseHeader('Content-Disposition')
                let filename = 'pushly-billing-history.csv'

                if (csvDisposition) {
                    filename = csvDisposition.match(/"([^"]+)"/i)[1]
                }

                fileDownload(req.data, filename)
            } else {
                const { data, ..._meta } = req.data
                invoices = data
                meta = _meta
            }
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: invoices, meta }
    }

    public async manageBilling(
        accountId: number,
        billingDto: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<AccountDto> {
        // TODO: investigate why we are possibly returning an empty account object
        let account: AccountDto = {} as any

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}/billing-data`

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: serviceURL,
                    data: billingDto,
                },
                cancellationKey,
            )

            account = AccountDto.fromApiResponse(req.data.data)
            simpleNotification('success', 'Your account billing has been successfully updated.')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return account!
    }

    public async fetchAccountDomains(
        accountId: number,
        opts?: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<DomainDto[]> {
        let domains: DomainDto[] = []

        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/domains?${options}`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                cancellationKey,
            )

            domains = req.data.data.map(DomainDto.fromApiResponse)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return domains
    }
}
