interface IPlatformNotifications {
    supported: boolean
}

export const getPlatformNotifications = (): IPlatformNotifications => {
    return (
        (window as any).PlatformNotifications ?? {
            supported: false,
        }
    )
}
