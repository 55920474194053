import { Flags } from '@pushly/aquarium-utils/lib/enums/flags'
import { InsightsDropdownGroup, TypeDropdownOption } from '../types'
import { FEAT_APP_MESSAGES, FEAT_LIVE_ACTIVITIES } from '../../../constants'

export const DEFAULT_TYPE = {
    value: 'DELIVERY',
    label: 'Notification Report',
}

export const TYPE_DROPDOWN_OPTIONS: InsightsDropdownGroup<TypeDropdownOption>[] = [
    {
        label: 'New',
        options: [
            { value: 'DELIVERY', label: 'Notification Report' },
            { value: 'SUBSCRIBER', label: 'Acquisition Report' },
            {
                value: 'APP_MESSAGE',
                label: 'App Message Report',
                condition: (domain) => {
                    return domain.flags?.includes(FEAT_APP_MESSAGES) ?? false
                },
            },
            {
                value: 'LIVE_ACTIVITY',
                label: 'Live Activity Report',
                condition: (domain) => {
                    return domain.flags?.includes(FEAT_LIVE_ACTIVITIES) ?? false
                },
            },
            { value: 'MONTHLY_UNIQUES', label: 'Monthly Uniques Report' },
            {
                value: 'PUB_NETWORK',
                label: 'Publisher Network Report',
                condition: (domain) => {
                    return domain.flags?.includes(Flags.domain.FEAT_DOMAIN_INTEGRATIONS_PUB_NETWORK.key) ?? false
                },
            },
            // { value: 'ATTRITION', label: 'Attrition Report' },
        ],
    },
]
