import * as React from 'react'
import { NotificationNamedTemplate } from '@pushly/models/lib/structs'
import { PromiseableProp } from '@pushly/aqe/lib/utils'
import { useService } from '@pushly/aqe/lib/hooks'
import { NotificationTemplateService } from '../../../services/notification-template'
import { NotificationDto } from '../dtos/notification-dto'
import { NotificationDeliveryType } from '../../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'
import { NotificationWebTemplateDto } from '../dtos/notification-web-template.dto'
import { NotificationNativeIosTemplateDto } from '../dtos/notification-native-ios-template.dto'
import { NotificationNativeAndroidTemplateDto } from '../dtos/notification-native-android-template.dto'
import { onResponseError403 } from '../../../_utils/on-response-error-403'
import { CustomApiResponseErrorHandler } from '../../../types/api-call-options-with-custom-error-handler'

interface INotificationTemplatesLoader {
    domainId: number
    templateId?: number
    children: (templates: PromiseableProp<NotificationDto[] | undefined>) => JSX.Element
    customErrorHandler?: CustomApiResponseErrorHandler
}

const NotificationTemplatesLoader = ({
    domainId,
    templateId,
    customErrorHandler,
    children,
}: INotificationTemplatesLoader) => {
    const notifTemplateSvc = useService(NotificationTemplateService)

    const loadData = async (): Promise<NotificationDto[] | undefined> => {
        const notifs: NotificationDto[] = []
        const templates: NotificationNamedTemplate[] = []

        if (templateId) {
            const { data: template, ok } = await notifTemplateSvc.fetchNamedNotificationTemplateById(
                domainId,
                templateId,
                {
                    errorHandler: customErrorHandler,
                },
            )

            if (template) {
                const {
                    name,
                    description,
                    templateContent,
                    templateSegmentIds,
                    templateExcludedSegmentIds,
                    templateTtlSpec,
                } = template

                const notification = NotificationDto.parse({
                    deliverySpec: {
                        type: NotificationDeliveryType.IMMEDIATE,
                        window: NotificationDeliveryWindow.STANDARD,
                        ...templateTtlSpec,
                    } as any,
                    audience: {
                        segmentIds: templateSegmentIds?.length ? Array.from(templateSegmentIds) : [],
                        excludedSegmentIds: templateExcludedSegmentIds?.length
                            ? Array.from(templateExcludedSegmentIds)
                            : undefined,
                    },
                    template: {
                        channels: {
                            default: {
                                ...templateContent.default,
                                keywords: template.keywords,
                                domainId,
                            } as any,
                            web: templateContent.web,
                            nativeIos: templateContent.nativeIos,
                            nativeAndroid: templateContent.nativeAndroid,
                        },
                        keywords: template.keywords?.length ? Array.from(template.keywords) : [],
                        name,
                        description,
                    },
                    channels: Array.from(templateContent.default.channels),
                })

                notifs.push(notification)
            }
        }

        return notifs.length ? notifs : undefined
    }

    return children(loadData())
}

export default React.memo(NotificationTemplatesLoader, () => true)
