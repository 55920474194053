import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import { PlatformLevel } from '../../types/platform-level'
import { AppService } from '../../services/index'
import { AppMessageScheduleViewable } from '../app-message-builder/model-extensions'

export function buildPrimaryLinkUrl(
    level: PlatformLevel,
    data: AppMessageScheduleViewable | AppMessageUiTemplate,
    routeBuilder: AppService['routeWithin'],
    nativeDisabled?: boolean,
): string {
    const newRoute =
        data instanceof AppMessageScheduleViewable && !nativeDisabled
            ? `/app-messages/${data.message.id}/summary`
            : `/app-messages/new?template_id=${data.id}`

    return routeBuilder(level, newRoute, true)
}

export const buildDuplicateUrl = (
    level: PlatformLevel,
    data: AppMessageScheduleViewable | AppMessageUiTemplate,
    routeBuilder: AppService['routeWithin'],
): string => {
    const newRoute =
        data instanceof AppMessageScheduleViewable
            ? `/app-messages/new?app_msg_id=${data.message.id}`
            : `/app-messages/templates/new?template_id=${data.id}`

    return routeBuilder(level, newRoute, true)
}

export const buildEditUrl = (
    level: PlatformLevel,
    data: AppMessageScheduleViewable | AppMessageUiTemplate,
    routeBuilder: AppService['routeWithin'],
): string => {
    const newRoute =
        data instanceof AppMessageScheduleViewable
            ? `/app-messages/${data.message.id}/edit/`
            : `/app-messages/templates/${data.id}/edit/`

    return routeBuilder(level, newRoute, true)
}
