import * as React from 'react'
import * as bowser from 'bowser'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService, AuthService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import './footer.scss'
import { AppstoreFilled, LogoutOutlined, WifiOutlined, MobileOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { asCaslSubject, CurrentUserCan } from '../../stores/app-ability'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'

interface IFooterProps {}

interface IFooterState {
    drawerOpen?: boolean
}

@observer
export class Footer extends BetterComponent<IFooterProps, IFooterState> {
    public readonly defaultClassName: string = 'sw-mv-footer'

    protected appState: AppState
    protected appService: AppService
    protected authService: AuthService

    public constructor(props: IFooterProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.authService = Container.get(AuthService)

        this.state = {}
    }

    public render(): React.ReactNode {
        const { abilityStore } = this.appState
        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('left')}>
                        <div className={this.buildClassName('action', 'dashboard')} onClick={this.handleDashboardClick}>
                            <AppstoreFilled />
                            <span>Dashboard</span>
                        </div>
                    </div>

                    {bowser.android ? (
                        <>
                            <div className={this.buildClassName('left-mid')}>
                                <CurrentUserCan
                                    do={AbilityAction.CREATE}
                                    on={abilityStore.getDomainOwnedIdentityFor(SubjectEntity.NOTIFICATION)}
                                >
                                    {this.renderNotifOption()}
                                </CurrentUserCan>
                            </div>

                            <div className={this.buildClassName('mid')}></div>

                            <div className={this.buildClassName('right-mid')}>
                                <CurrentUserCan
                                    do={AbilityAction.CREATE}
                                    on={abilityStore.getDomainOwnedIdentityFor(SubjectEntity.NOTIFICATION)}
                                >
                                    {this.renderRegisterOption()}
                                </CurrentUserCan>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={this.buildClassName('left-mid')}></div>

                            <div className={this.buildClassName('mid')}>
                                <CurrentUserCan
                                    do={AbilityAction.CREATE}
                                    on={abilityStore.getDomainOwnedIdentityFor(SubjectEntity.NOTIFICATION)}
                                >
                                    {this.renderNotifOption()}
                                </CurrentUserCan>
                            </div>

                            <div className={this.buildClassName('right-mid')}></div>
                        </>
                    )}

                    <div className={this.buildClassName('right')}>
                        <div className={this.buildClassName('action', 'logout')} onClick={this.handleLogout}>
                            <LogoutOutlined />
                            <span>Logout</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected renderNotifOption() {
        return (
            <div
                className={this.buildClassName('action', 'key-action send-notification')}
                onClick={this.handleCreateNotificationClick}
            >
                <WifiOutlined />
                <span>Send</span>
            </div>
        )
    }

    protected renderRegisterOption() {
        return (
            <div className={this.buildClassName('action', 'register-device')} onClick={this.handleRegisterClick}>
                <MobileOutlined />
                <span>Register</span>
            </div>
        )
    }

    protected get currentPathIsNotifications(): boolean {
        return /\/notifications/i.test(this.injectedProps.location.pathname)
    }

    protected get currentPathIsDashboard(): boolean {
        return /\/dashboard/i.test(this.injectedProps.location.pathname)
    }

    protected handleDashboardClick = () => {
        return this.appService.routeWithinDomain('/dashboard')
    }

    protected handleCreateNotificationClick = () => {
        return this.appService.routeWithinDomain('/notifications/new')
    }

    protected handleRegisterClick = () => {
        return this.appService.route('/register-preview-device')
    }

    protected handleLogout = () => {
        return this.authService.logout()
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.currentPathIsNotifications) classNames.push('notif-path')
        if (this.currentPathIsDashboard) classNames.push('dashboard-path')

        return classNames.join(' ')
    }
}
