import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NotificationVariantModel } from './notification-variant.model'
import { StatusType } from '../../enums/status-type'

interface ISegmentPartial {
    id: number
    name: string
    isDefault: boolean
    groupId: number
    groupName: string
}

interface IGroupNotification {
    id: string
    accountId: number
    domainId: number
    domainName: string
    domainDisplayName: string
    abTestId: number | null
    abTestName: string | null
    abTestWinnerOriginId: number | null
    statusId: number
    status: string
    includedSegmentIds: number[]
    excludedSegmentIds: number[]
    includedSegments?: ISegmentPartial[]
    excludedSegments?: ISegmentPartial[]
}

interface IGroupNotificationStats {
    deliveries: number
    impressions: number
    clicks: number
    // rates
    ctr: number | null
    ctr_decimal: number | null
    delivery_rate: number | null
    delivery_rate_decimal: number | null
}

export class OrgNotificationModel {
    public static build(props: OrgNotificationModel | any): OrgNotificationModel {
        const data = props instanceof OrgNotificationModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new OrgNotificationModel()
        model.setId(scData.id)
        model.setAccountId(scData.account_id)
        model.setNotifications(data.notifications)
        model.setDeliverySpec(scData.delivery_spec)
        model.setSegmentIds(scData.segment_ids)
        model.setIsMultiDomain(scData.is_multi_domain)
        model.setNotes(scData.notes)
        model.setSource(scData.source)
        model.setVariant(scData.variant)
        model.setCreatedAt(scData.created_at)
        model.setCreatedBy(scData.created_by)
        model.setCreatedByUserName(scData.created_by_user_name)
        model.setStats(scData.stats)

        return model
    }

    private id?: number
    private accountId?: number
    private notifications?: IGroupNotification[]
    private deliverySpec?: any
    private segmentIds?: number[] | null
    private isMultiDomain?: boolean
    private notes?: string
    private source?: string
    private variant?: NotificationVariantModel
    private createdAt?: string
    private createdBy?: number
    private createdByUserName?: string
    private stats?: IGroupNotificationStats

    public clone(): OrgNotificationModel {
        return OrgNotificationModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            id: this.getId(),
            account_id: this.getAccountId(),
            notifications: this.getNotifications(),
            delivery_spec: this.getDeliverySpec(),
            segment_ids: this.getSegmentIds(),
            is_multi_domain: this.getIsMultiDomain(),
            notes: this.getNotes(),
            source: this.getSource(),
            variant: this.getVariant()?.serialize(),
            created_at: this.getCreatedAt(),
            created_by: this.getCreatedBy(),
            created_by_user_name: this.getCreatedByUserName(),
            stats: this.getStats(),
        })
    }

    public getId(): number | undefined {
        return this.id
    }
    public setId(id: number | undefined) {
        this.id = id
    }

    public getAccountId() {
        return this.accountId
    }
    public setAccountId(id: number) {
        this.accountId = id
    }

    public getNotifications() {
        return this.notifications ?? []
    }
    public setNotifications(notifications: IGroupNotification[]) {
        this.notifications = notifications
    }

    public getDeliverySpec() {
        return this.deliverySpec
    }
    public setDeliverySpec(spec: any) {
        this.deliverySpec = spec
    }

    public getSegmentIds() {
        return this.segmentIds
    }

    public setSegmentIds(ids: number[]) {
        this.segmentIds = ids
    }

    public getIsMultiDomain() {
        return this.isMultiDomain ?? false
    }
    public setIsMultiDomain(value: boolean) {
        this.isMultiDomain = value ?? false
    }

    public getNotes() {
        return this.notes
    }
    public setNotes(notes: string) {
        this.notes = notes
    }

    public getSource() {
        return this.source
    }
    public setSource(source: string) {
        this.source = source
    }

    public getVariant() {
        return this.variant
    }
    public setVariant(value: any) {
        this.variant = !value ? undefined : NotificationVariantModel.build(value)
    }

    public getCreatedAt() {
        return this.createdAt
    }
    public setCreatedAt(date: any) {
        this.createdAt = date
    }

    public getCreatedBy() {
        return this.createdBy
    }
    public setCreatedBy(createdBy: number) {
        this.createdBy = createdBy
    }

    public getCreatedByUserName() {
        return this.createdByUserName
    }
    public setCreatedByUserName(name: string) {
        this.createdByUserName = name
    }

    public getComputedStatus(): string {
        const children = this.getNotifications() ?? []
        let status = children[0]?.status ?? StatusType.SCHEDULED.name

        if (children.length > 0) {
            const statuses = Array.from(new Set(children.map((n) => n.status)))
            if (statuses.includes(StatusType.DELIVERING.name)) {
                status = StatusType.DELIVERING.name
            } else if (statuses.includes(StatusType.QUEUED.name)) {
                status = StatusType.QUEUED.name
            } else if (
                statuses.length === 2 &&
                statuses.includes(StatusType.COMPLETED.name) &&
                statuses.includes(StatusType.FAILED.name)
            ) {
                status = StatusType.COMPLETED_WITH_FAILURES.name
            }
        }

        return status
    }

    public getStats() {
        return this.stats
    }
    public setStats(stats: IGroupNotificationStats | undefined) {
        this.stats = stats
    }
}
