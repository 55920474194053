import { NotificationTemplateDto } from './notification-template-dto'
import { SegmentDto } from '../../../dtos/segment'
import { StatusType } from '../../../enums/status-type'
import { NotificationDeliveryType } from '../../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'
import { NotificationDefaultTemplateDto } from './notification-default-template.dto'
import { NotificationNativeIosTemplateDto } from './notification-native-ios-template.dto'
import { NotificationNativeAndroidTemplateDto } from './notification-native-android-template.dto'
import { NotificationWebTemplateDto } from './notification-web-template.dto'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export class NotificationDeliverySpec {
    public static fromSpec(fields: Partial<NotificationDeliverySpec>): NotificationDeliverySpec {
        const spec = new NotificationDeliverySpec()

        Object.assign(spec, fields)
        spec.type = spec.type.toUpperCase() as NotificationDeliveryType
        spec.window = spec.window.toUpperCase() as NotificationDeliveryWindow

        return spec
    }

    public type: NotificationDeliveryType = NotificationDeliveryType.IMMEDIATE
    public window: NotificationDeliveryWindow = NotificationDeliveryWindow.STANDARD
    public sendDateUtc?: Date
    public timezone?: string
    public ttlSeconds?: number
    public ttlMetric: string = 'days'
}

export class NotificationScheduleDto {
    public static parse(fields: Partial<NotificationScheduleDto>): NotificationScheduleDto {
        const dto = new NotificationScheduleDto()
        Object.assign(dto, fields)

        if (dto.channels && dto.template?.channels?.default && !dto.template.channels.default.channels) {
            dto.template.channels.default.channels = dto.channels
        }

        return dto
    }

    public id: number
    public domainId: number
    public notificationId: number
    public status: string
    public channels?: DeliveryChannel[]
    public source: string
    public additionalDetails: string
    public startedDateUtc: Date
    public completedDateUtc: Date
    public template: {
        channels: {
            default: NotificationDefaultTemplateDto
            web: NotificationTemplateDto | undefined
            nativeIos: NotificationNativeIosTemplateDto | undefined
            nativeAndroid: NotificationNativeAndroidTemplateDto | undefined
        }
        keywords: string[]
    }
    public deliverySpec: NotificationDeliverySpec
    public audience: {
        segments?: SegmentDto[]
        segmentIds?: number[]
        excludedSegments?: SegmentDto[]
        excludedSegmentIds?: number[]
        subscriberIds?: string[]
        externalSubscriberIds?: string[]
    }
    public abTest?: {
        id: number
        name: string
        weight: number
        winner: boolean
        winnerOriginId?: number
    }
    public notificationGroup?: {
        id: string
        isMultiDomain: boolean
    }
    public createdAt: Date
    public updatedAt: Date

    public get defaultTemplate(): NotificationDefaultTemplateDto {
        const wrapper: any = this.template || {}
        const channels: any = wrapper.channels || {}
        return channels.default || {}
    }

    public get webTemplate(): NotificationWebTemplateDto {
        const wrapper: any = this.template || {}
        const channels: any = wrapper.channels || {}
        return channels.web || {}
    }

    public get nativeIosTemplate(): NotificationNativeIosTemplateDto {
        const wrapper: any = this.template || {}
        const channels: any = wrapper.channels || {}
        return channels.nativeIos || {}
    }

    public get nativeAndroidTemplate(): NotificationDefaultTemplateDto {
        const wrapper: any = this.template || {}
        const channels: any = wrapper.channels || {}
        return channels.nativeAndroid || {}
    }

    public get keywords(): string[] {
        const wrapper: any = this.template || {}
        return wrapper.keywords || []
    }

    public getLegacyAudienceType(): string {
        let type = 'SEGMENTS'

        if (this.audience.subscriberIds && this.audience.subscriberIds.length > 0) {
            type = 'SUBSCRIBERS'
        } else if (this.audience.externalSubscriberIds && this.audience.externalSubscriberIds.length > 0) {
            type = 'EXTERNAL_SUBSCRIBERS'
        }

        return type
    }

    public canShowStats(stats: any): boolean {
        const canShow = !!stats && (this.isDelivered(stats) || this.isTzDeliveryInProgress())
        return canShow
    }

    public isDelivered(stats: any = {}): boolean {
        return this.status === StatusType.COMPLETED.name || this.isPartialFailure(stats)
    }

    public isPartialFailure(stats: any = {}): boolean {
        const partialFailure = this.status === StatusType.FAILED.name && stats.impressions > 0
        return partialFailure
    }

    public isTzDelivery(): boolean {
        const tzDelivery = this.deliverySpec.window === NotificationDeliveryWindow.TIMEZONE
        return tzDelivery
    }

    public isTzDeliveryInProgress(): boolean {
        const tzDeliveryInProgress = this.isTzDelivery() && this.status === StatusType.DELIVERING.name
        return tzDeliveryInProgress
    }
}
