import React from 'react'
import classnames from 'classnames'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'

type AppMenuItemProps = MenuItemProps & {
    linkTo: string
    condition?: () => boolean
}

export const AppMenuItem = ({ linkTo, condition, ...props }: AppMenuItemProps) => {
    const isExternalLink = /^http/i.test(linkTo)
    const canRender = !!condition ? condition() : true

    return !canRender ? null : (
        <Menu.Item
            onClick={({ domEvent }) => {
                if (!isExternalLink) {
                    domEvent.stopPropagation()
                }
            }}
            {...props}
            className={classnames('app-menu-item', props.className)}
        >
            {!isExternalLink ? (
                <Link to={linkTo}>{props.title}</Link>
            ) : (
                <a href={linkTo} target="_blank" rel="noopener noreferrer">
                    {props.title}
                </a>
            )}
        </Menu.Item>
    )
}
