import React from 'react'
import './notification-action-builder.scss'
import * as clone from 'clone'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { getDomainDefaultActionLabels } from '../../_utils/domain'
import { INotificationAction, NotificationActionType } from '../../interfaces/notification-action'
import { getClassNames } from '../../_utils/classnames'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Input, Select, Switch } from 'antd'
import { MacroManager } from '../macro-manager/macro-manager'
import { EmojiManager } from '../emoji-manager/emoji-manager'
import { MacroOptionType } from '../macro-manager/types'

type ActionConfig = INotificationAction & { usePrimaryLandingUrl?: boolean }

interface INotificationActionBuilder {
    mode?: 'builder' | 'static'
    enabled: boolean
    removable?: boolean
    removeLabel?: string
    title?: string
    hidePrimaryLandingUrl?: boolean
    primaryLandingUrl?: string
    config?: ActionConfig
    onChange?: (action: INotificationAction) => any
    onRemove?: (action: INotificationAction) => any
    onAdd?: (action: INotificationAction) => any
    rootActions?: INotificationAction[]
    addActionIcon?: React.ReactNode
    addActionText?: string
    noActionText?: string
    macroTypes?: any[]
    customMacroOptions?: any
    validMacroOptionTypes?: MacroOptionType[]
}

export class NotificationActionBuilder extends React.Component<INotificationActionBuilder, any> {
    private appState: AppState

    public constructor(props: INotificationActionBuilder) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        const domain = this.appState.currentDomain
        const domainDefaultLabels = getDomainDefaultActionLabels(domain)
        const { mode, enabled, title, hidePrimaryLandingUrl, removable } = this.props
        const config = this.props.config ?? ({} as ActionConfig)

        const isStaticMode = mode === 'static'

        const actionType = config.type || NotificationActionType.OPEN_URL
        const isOpenUrlAction = actionType === NotificationActionType.OPEN_URL
        const isCloseAction = actionType === NotificationActionType.CLOSE

        const usePrimaryLandingUrl = config.usePrimaryLandingUrl
        const landingUrlValue = config.landingUrl

        const defaultLabelValue = isOpenUrlAction ? domainDefaultLabels.openUrl : domainDefaultLabels.close
        let labelValue = config.label === undefined ? defaultLabelValue : config.label
        if (labelValue === domainDefaultLabels.openUrl && isCloseAction) {
            labelValue = domainDefaultLabels.close
        } else if (labelValue === domainDefaultLabels.close && isOpenUrlAction) {
            labelValue = domainDefaultLabels.openUrl
        }

        const eid = config.displayMeta?.eid
        const rootActions = this.props.rootActions || []

        const _match = (action: any, type: NotificationActionType) =>
            action.displayMeta?.eid !== eid && action.type === type
        const disableCloseSelection = !!rootActions.find((a) => _match(a, NotificationActionType.CLOSE))
        const disablePrimaryLandingUrl = !!rootActions.find(
            (a: any) => _match(a, NotificationActionType.OPEN_URL) && a.usePrimaryLandingUrl,
        )

        const macroTypes = this.props.macroTypes ?? ['domain', 'device', 'location', 'notification', 'custom']
        // domain macros should only be visible on URL fields
        const labelSafeMacroTypes = macroTypes.filter((type) => type !== 'domain' && type !== 'device')

        return (
            <>
                <div
                    className={getClassNames('notif-action-builder', {
                        [`action-${(config.ordinal ?? 0) + 1}`]: true,
                        ['mode-static']: isStaticMode,
                    })}
                >
                    <div className={getClassNames('notif-action-builder-wrapper')}>
                        {!enabled ? (
                            <div className={getClassNames('notif-action-add')} onClick={this.handleAdd}>
                                {!isStaticMode ? (
                                    <>
                                        {this.props.addActionIcon ?? <PlusOutlined />}
                                        <div>
                                            <span>{this.props.addActionText ?? 'Add Button'}</span>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <span>{this.props.noActionText ?? 'Button not enabled'}</span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className={getClassNames('notif-action-ns')}>
                                    <span>{title}</span>

                                    {!isStaticMode && (removable ?? config.ordinal !== 0) && (
                                        <span
                                            className={getClassNames('notif-action-remove')}
                                            onClick={this.handleRemove}
                                        >
                                            {this.props.removeLabel ?? <CloseCircleOutlined />}
                                        </span>
                                    )}
                                </div>
                                <div className={getClassNames('notif-action-type')}>
                                    <span>Action</span>
                                    <Select
                                        size="small"
                                        value={actionType}
                                        onChange={this.handleActionChange}
                                        disabled={isStaticMode}
                                    >
                                        <Select.Option value={NotificationActionType.OPEN_URL}>Open URL</Select.Option>
                                        <Select.Option
                                            value={NotificationActionType.CLOSE}
                                            disabled={disableCloseSelection}
                                        >
                                            Dismiss Notification
                                        </Select.Option>
                                    </Select>
                                </div>
                                <div className={getClassNames('notif-action-content')}>
                                    <div className={getClassNames('notif-action-label')}>
                                        <span>Label</span>
                                        <EmojiManager>
                                            <MacroManager
                                                types={labelSafeMacroTypes}
                                                customOptions={this.props.customMacroOptions}
                                                validOptionTypes={this.props.validMacroOptionTypes}
                                            >
                                                <Input
                                                    autoComplete="off"
                                                    size="small"
                                                    onChange={this.handleLabelChange}
                                                    disabled={isStaticMode}
                                                    defaultValue={this.props.config?.label}
                                                />
                                            </MacroManager>
                                        </EmojiManager>
                                    </div>
                                    {actionType === NotificationActionType.OPEN_URL && (
                                        <div className={getClassNames('notif-action-landing-url')}>
                                            {!hidePrimaryLandingUrl && (
                                                <div className="switch-row">
                                                    <div className="switch-left">
                                                        <Switch
                                                            size="small"
                                                            checked={usePrimaryLandingUrl}
                                                            disabled={isStaticMode || disablePrimaryLandingUrl}
                                                            onChange={this.setUsePrimaryLandingUrl}
                                                        />
                                                    </div>
                                                    <div className="switch-right">Use Primary Landing URL</div>
                                                </div>
                                            )}
                                            {(hidePrimaryLandingUrl || !usePrimaryLandingUrl) && (
                                                <MacroManager
                                                    types={macroTypes}
                                                    customOptions={this.props.customMacroOptions}
                                                    validOptionTypes={this.props.validMacroOptionTypes}
                                                >
                                                    <Input
                                                        size="small"
                                                        autoComplete="off"
                                                        placeholder="https://my.landingpage.domain/my-article"
                                                        defaultValue={landingUrlValue}
                                                        onChange={this.handleLandingUrlChange}
                                                        disabled={isStaticMode}
                                                    />
                                                </MacroManager>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    }

    protected handleActionChange = async (action: NotificationActionType) => {
        const update = clone(this.props.config ?? ({} as ActionConfig))
        update.type = action

        const domain = this.appState.currentDomain
        const domainDefaultLabels = getDomainDefaultActionLabels(domain)

        if (update.type === NotificationActionType.CLOSE) {
            update.label = domainDefaultLabels.close
            update.landingUrl = undefined
            update.usePrimaryLandingUrl = false
        } else {
            update.label = domainDefaultLabels.openUrl
        }

        this.handleChange(update)
    }

    protected handleLabelChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const update = clone(this.props.config ?? ({} as ActionConfig))
        update.label = ev.target.value

        this.handleChange(update)
    }

    protected handleLandingUrlChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const update = clone(this.props.config ?? ({} as ActionConfig))
        update.landingUrl = ev.target.value

        this.handleChange(update)
    }

    protected setUsePrimaryLandingUrl = async (usePrimaryLandingUrl: boolean) => {
        const update = clone(this.props.config ?? ({} as ActionConfig))
        update.usePrimaryLandingUrl = usePrimaryLandingUrl

        if (usePrimaryLandingUrl) {
            update.landingUrl = this.props.primaryLandingUrl
        } else {
            update.landingUrl = undefined
        }

        this.handleChange(update)
    }

    protected handleAdd = () => {
        const domain = this.appState.currentDomain
        const domainDefaultLabels = getDomainDefaultActionLabels(domain)
        const nextOrdinal = (this.props.rootActions ?? []).length

        this.props.onAdd?.({
            type: NotificationActionType.OPEN_URL,
            ordinal: nextOrdinal,
            label: domainDefaultLabels.openUrl,
            landing_url: undefined,
            usePrimaryLandingUrl: false,
        } as any)
    }

    protected handleRemove = () => {
        this.props.onRemove?.(this.props.config ?? ({} as ActionConfig))
    }

    protected handleChange(config: INotificationAction) {
        this.props.onChange?.({
            ...this.props.config,
            ...config,
        })
    }
}
