import React from 'react'
import { NNTDataTableControlOptions } from './interfaces'
import { ILoadableDataState } from '../../../hooks/use-loadable-data-state'
import { NotificationNamedTemplate } from '@pushly/models/lib/structs'

export type NotificationTemplateListContext = ILoadableDataState<NotificationNamedTemplate[]> &
    NNTDataTableControlOptions & {
        onSearchClick(value: string): Promise<void>
        onFilterChange<
            FilterKey extends keyof NNTDataTableControlOptions['filters'],
            FilterValue extends NNTDataTableControlOptions['filters'][FilterKey],
        >(
            key: FilterKey,
            value: FilterValue,
        ): Promise<void>
    }

export const NotificationTemplateListContext = React.createContext<NotificationTemplateListContext>(
    {} as NotificationTemplateListContext,
)
