import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as queryString from 'query-string'
import { Container } from 'typescript-ioc/es5'
import { AppService, NotificationService } from '../../services'
import { AppState } from '../../stores/app'
import { PageHeader } from '../../components/page-header/page-header'
import { tryParseInt } from '../../_utils/try-parse'
import { useRefEffect } from '../../hooks/use-ref-effect'
import { getCustomMacroFields, getDomainKeywords, getEnabledDeliveryChannels } from '../../_utils/domain'
import NotificationsLoader from '../../components/notification-builder/notifications-loader'
import NotificationBuilder from '../../components/notification-builder/notification-builder'
import NotificationBuilderMain from '../../components/notification-builder/elements/notification-builder-main'
import NotificationTestBuilder from '../../components/notification-builder/elements/notification-test-builder'
import NotificationAudienceBuilder from '../../components/notification-builder/elements/notification-audience-builder'
import NotificationVariantBuilder from '../../components/notification-builder/elements/notification-variant-builder'
import { useActiveDomainChangeEffect } from '../../hooks/use-active-domain-change-effect'
import { buildSubmittedNotificationPackages, validateSaveDraft } from '../../components/notification-builder/helpers'
import { ApiVersion } from '../../enums/api-version.enum'
import { DeliveryChannelSelector } from '@pushly/aqe/lib/components/delivery-channel-selector/delivery-channel-selector'
import { onResponseError403 } from '../../_utils/on-response-error-403'
import { NotificationBuilderTheme } from '../../components/notification-builder/enums'

export const MobileEditNotification = () => {
    useActiveDomainChangeEffect((activeDomain, appSvc) => {
        appSvc.routeWithinDomain('/notifications')
    })

    const { push: updateRoute, replace: replaceRoute } = useHistory()
    const qs = queryString.parse(location.search)
    const appState = Container.get(AppState)
    const appService: AppService = Container.get(AppService)
    const notifService: NotificationService = Container.get(NotificationService)

    const domain = appState.currentDomain!
    const { notifId: notificationId } = useParams<{ notifId: string }>()
    const requestedNotifId = tryParseInt(notificationId!) || undefined
    const requestedTestId = tryParseInt(qs.test?.toString() ?? '') || undefined

    const [keywordOptions] = useRefEffect(() => getDomainKeywords(domain.id), [domain.id])
    const [customMacroOptions] = useRefEffect(() => getCustomMacroFields(domain.id), [domain.id])

    const goBackToNotifs = (isDraft?: boolean) => {
        updateRoute(isDraft ? `/domains/${domain.id}/notifications/drafts` : `/domains/${domain.id}/dashboard`)
    }

    const goBacktoPreviousNotifsTab = () => {
        appService.routeBack()
    }

    const domainActiveChannels = getEnabledDeliveryChannels(domain)
    const domainHasNativePush = domainActiveChannels.length > 1

    return (
        <>
            <PageHeader title="Edit Notification" append={`#${requestedNotifId}`} />

            <NotificationsLoader
                domainId={domain.id}
                notifId={requestedNotifId!}
                testId={requestedTestId}
                errorHandler={onResponseError403(() => {
                    goBackToNotifs()
                })}
            >
                {(notifications) => {
                    return (
                        <NotificationBuilder
                            mode="edit"
                            theme={NotificationBuilderTheme.MOBILE}
                            domain={domain}
                            notifications={notifications}
                            defaultSelectedNotifId={requestedNotifId}
                        >
                            {(props) => {
                                const {
                                    builder,
                                    dispatchChanges,
                                    validateSubmit,
                                    onChannelChange,
                                    onAudienceChange,
                                    variants,
                                    onVariantChange,
                                    variantManager,
                                } = props
                                const activeVariant = variants[builder.selectedVariantIdx]
                                const deliveryHasStarted = variants.some((v) => v.getDisplayMeta().delivering)
                                const activeVariantNotEditable = activeVariant.getDisplayMeta().notEditable ?? false
                                const disabled = deliveryHasStarted || activeVariantNotEditable

                                return (
                                    <NotificationBuilderMain>
                                        {domainHasNativePush && (
                                            <DeliveryChannelSelector
                                                type="multiple"
                                                loading={builder.loading}
                                                value={builder.channels}
                                                onChange={onChannelChange}
                                                visibleChannels={domainActiveChannels}
                                                compact={true}
                                            />
                                        )}

                                        <NotificationAudienceBuilder
                                            loading={builder.loading}
                                            domain={domain}
                                            setBuilder={dispatchChanges}
                                            disabled={disabled}
                                            value={activeVariant.getAudience()}
                                            onChange={onAudienceChange}
                                        />

                                        <NotificationVariantBuilder
                                            key={activeVariant.getId()}
                                            id={builder.selectedVariantIdx}
                                            domain={domain}
                                            builder={builder}
                                            variant={activeVariant}
                                            manager={variantManager}
                                            hideImageMacroToggle={true}
                                            onChange={onVariantChange}
                                            onCancel={goBacktoPreviousNotifsTab}
                                            onSubmit={async (isDraft?: boolean) => {
                                                let sent
                                                let updated

                                                if (isDraft) {
                                                    const valid = await validateSaveDraft(builder)

                                                    if (valid) {
                                                        const builds = buildSubmittedNotificationPackages(
                                                            domain,
                                                            builder,
                                                            appState.flags,
                                                        )

                                                        if (builds.length === 1) {
                                                            updated = await notifService.updateNotificationDraft(
                                                                domain.id,
                                                                requestedNotifId,
                                                                builds[0],
                                                                {
                                                                    cancellationKey: `notif-update-draft`,
                                                                    version: ApiVersion.V4,
                                                                },
                                                            )

                                                            if (
                                                                updated &&
                                                                updated.toString() !== requestedNotifId.toString()
                                                            ) {
                                                                // updateNotification response is notification id
                                                                replaceRoute(
                                                                    `/domains/${domain.id}/notifications/edit/${updated}`,
                                                                )
                                                            }
                                                        } else {
                                                            updated = await notifService.updateTestDraft(
                                                                domain.id,
                                                                requestedTestId,
                                                                builds[0].abTest,
                                                                builds,
                                                                `notif-update-draft`,
                                                            )
                                                        }
                                                    }
                                                } else {
                                                    const convertDraftToNotification =
                                                        builder.variants[0].getDisplayMeta().isDraft
                                                    const valid = await validateSubmit(
                                                        builder,
                                                        domain,
                                                        appState.flags,
                                                        false,
                                                        convertDraftToNotification,
                                                    )

                                                    if (valid) {
                                                        const builds = buildSubmittedNotificationPackages(
                                                            domain,
                                                            builder,
                                                            appState.flags,
                                                        )

                                                        if (builds.length === 1) {
                                                            sent = await notifService.updateNotification(
                                                                domain.id,
                                                                requestedNotifId,
                                                                builds[0],
                                                                {
                                                                    cancellationKey: `notif-send`,
                                                                    version: ApiVersion.V4,
                                                                },
                                                            )

                                                            if (
                                                                sent &&
                                                                sent.toString() !== requestedNotifId.toString()
                                                            ) {
                                                                // updateNotification response is notification id
                                                                replaceRoute(
                                                                    `/domains/${domain.id}/notifications/edit/${sent}`,
                                                                )
                                                            }
                                                        } else {
                                                            sent = await notifService.updateTest(
                                                                domain.id,
                                                                requestedTestId,
                                                                builds[0].abTest,
                                                                builds,
                                                                `notif-send`,
                                                            )
                                                        }
                                                    }
                                                }

                                                if (sent || updated) {
                                                    goBackToNotifs(updated)
                                                }
                                            }}
                                            keywordOptions={keywordOptions.current}
                                            customMacroOptions={customMacroOptions.current}
                                        />
                                    </NotificationBuilderMain>
                                )
                            }}
                        </NotificationBuilder>
                    )
                }}
            </NotificationsLoader>
        </>
    )
}
