import * as React from 'react'
import './styles/notification-builder-reach.scss'
import { Well, ReachWell } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { Descriptions, Popover } from 'antd'
import { Loading3QuartersOutlined, PartitionOutlined } from '@ant-design/icons'
import { numberWithCommas } from '../../../_utils/numeral'
import { INotificationBuilderState } from '../interfaces/notification-builder-state.interface'
import { Container } from 'typescript-ioc/es5'
import { DomainService } from '../../../services'
import { useMountedRef } from '../../../_utils/use-mounted-ref'
import { DomainDto } from '../../../dtos/domain'
import DistributionManager from './distribution-manager'
import { getDefaultElapsedSchedule, isDeliveryTest } from '../helpers'
import { NotificationDispatchActionPack } from '../types'
import { INestedBatchRequest } from '../../../interfaces/batch-requests'
import { BatchService } from '../../../services/batch'
import { NotificationBuilderLevel } from '../enums'
import { AccountDto } from '../../../dtos/account.dto'
import { OrgSegmentModel } from '../../../models/segments/org-segment.model'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface INotificationBuilderReach {
    className?: string
    domain: DomainDto | DomainDto[]
    account?: AccountDto
    builder: INotificationBuilderState
    setBuilder: (action: NotificationDispatchActionPack) => void
    hideVariantReach?: boolean
    onVariantSelect?: (builder: INotificationBuilderState) => any
    hideDistributionManager?: boolean
    onDistributionChange?: (builder: INotificationBuilderState) => any
}

const extractDomainSegment = (domainId: number, group: OrgSegmentModel) => {
    return group.getSegments().find((s) => s.domain_id === domainId)
}

const getReachDisabledReason = (
    builder: INotificationBuilderState,
    reachEstimate: number | undefined,
): string | undefined => {
    let reason: string | undefined
    reachEstimate = reachEstimate ?? 0

    const test = builder.test
    const isVariableDelivery = isDeliveryTest(test?.getTestTypes() ?? [])
    const delivery = builder.variants[0].getDelivery()
    const isUnsupportedDeliveryType = isVariableDelivery || !delivery.isUsingFixedStrategy

    if (isUnsupportedDeliveryType) {
        reason = 'Sampling cannot be enabled when testing Delivery.'
    } else if (!reachEstimate || reachEstimate < 5000) {
        reason = 'Sampling cannot be enabled for audiences smaller than 5,000 subscribers.'
    }

    return reason
}

export type ReachByChannel = { [key in DeliveryChannel]?: number } & { total: number }

const NotificationBuilderReach = (props: INotificationBuilderReach) => {
    const {
        className,
        domain,
        builder,
        setBuilder,
        hideVariantReach,
        onVariantSelect,
        hideDistributionManager,
        onDistributionChange,
    } = props

    const level = builder.level
    const isOrgLevel = level === NotificationBuilderLevel.ORG
    const isDomainLevel = level === NotificationBuilderLevel.DOMAIN
    const isCampaignLevel = level === NotificationBuilderLevel.CAMPAIGN
    const levelClassName = isCampaignLevel ? 'campaign' : isOrgLevel ? 'org' : 'domain'

    const hasVariants = !!builder.test
    const reachPcts = builder.test?.getDistributionMap() ?? []
    const currVariant = builder.variants[builder.selectedVariantIdx]
    const audience = currVariant.getAudience()
    const targetedChannels = builder.channels
    let reachByChannel: ReachByChannel = {
        total: 0,
        [DeliveryChannel.WEB]: 0,
        [DeliveryChannel.NATIVE_IOS]: 0,
        [DeliveryChannel.NATIVE_ANDROID]: 0,
    }

    const [_mounted, runIfMounted] = useMountedRef()
    const [currReach, setCurrReach] = React.useState<ReachByChannel | undefined>()
    const [loadingReach, setLoadingReach] = React.useState(true)

    React.useEffect(() => {
        // Should not attempt to fetch reach until available segments have been loaded
        if (!builder.availableSegmentsLoaded) {
            return
        }

        const fetchReach = async () => {
            setLoadingReach(true)

            const domainSvc: DomainService = Container.get(DomainService)
            const batchSvc: BatchService = Container.get(BatchService)
            const domainsToCheck = Array.isArray(domain) ? domain : [domain]

            if (level === NotificationBuilderLevel.ORG) {
                const segmentGroupIds = audience.getSegmentIds() ?? []
                const segmentGroups: OrgSegmentModel[] =
                    (props.builder.availableSegments as any[])?.filter?.((s) => segmentGroupIds.includes(s.id)) ?? []
                const domainIds = audience.getDomainIds() ?? props.builder.availableDomains.map((d) => d.id)

                // gather & group all domain specific segment ids
                const domainSegmentIds: Array<[number, number[]]> = []
                for (const domainId of domainIds) {
                    const segments = segmentGroups
                        .filter((g) => extractDomainSegment(domainId, g))
                        .map((g) => extractDomainSegment(domainId, g))

                    if (segments.length) {
                        domainSegmentIds.push([domainId, segments.map((s: any) => s.id)])
                    }
                }

                const batchReqs: INestedBatchRequest[] = []
                if (domainSegmentIds.length) {
                    const singleRequest = (domainId: number, segmentIds: number[], channels?: string[]) => ({
                        method: 'post',
                        relative_path: `/domains/${domainId}/segments/estimate-reach`,
                        body: {
                            segmentIds,
                            targetedChannels: channels,
                            returnType: channels?.length ? 'count_by_channel' : 'count',
                        },
                        meta: { domain_id: domainId },
                    })

                    // get reach for each domain using specific segment ids
                    domainSegmentIds.forEach(([domainId, segmentIds]) => {
                        batchReqs.push(singleRequest(domainId, segmentIds, targetedChannels))
                    })
                } else {
                    const singleRequest = (domainId: number, channels?: string[]) => ({
                        method: 'post',
                        relative_path: `/domains/${domainId}/segments/estimate-reach`,
                        body: {
                            query: {
                                domain_id: domainId,
                                is_default: true,
                            },
                            targetedChannels: channels,
                            returnType: channels?.length ? 'count_by_channel' : 'count',
                        },
                        meta: { domain_id: domainId },
                    })
                    // get reach for each domain using default all filter
                    domainsToCheck.forEach((d) => {
                        batchReqs.push(singleRequest(d.id, targetedChannels))
                    })
                }

                let persist = true
                if (batchReqs.length > 0) {
                    const res = await batchSvc.batch(
                        {
                            requests: batchReqs,
                        },
                        {
                            cancellationKey: 'nba-batch-reach',
                        },
                    )

                    if (res.cancelled) {
                        persist = false
                    } else {
                        res.data.forEach((batchRes) => {
                            if (batchRes.code === 200 || batchRes.code === 201) {
                                try {
                                    const { data } = JSON.parse(batchRes.body)
                                    for (const channel in data.reach) {
                                        if (channel) {
                                            reachByChannel.total += data.reach[channel]
                                            reachByChannel[channel.toUpperCase()] += data.reach[channel]
                                        }
                                    }
                                } catch (_) {}
                            }
                        })
                    }
                }

                if (persist) {
                    runIfMounted(() => {
                        setCurrReach(reachByChannel)
                        setLoadingReach(false)

                        setBuilder({ type: 'patch', entity: 'reach-estimate', data: reachByChannel.total })
                    })
                }
            } else {
                let res

                if (targetedChannels.length > 1) {
                    res = await domainSvc.estimateSegmentReach(
                        domainsToCheck[0].id,
                        audience.getSegmentIds() ?? [],
                        audience.getExcludedSegmentIds() ?? [],
                        targetedChannels,
                        'count_by_channel',
                        ['NOTIFICATION'],
                        {
                            cancellationKey: 'nba-fetch-reach',
                        },
                    )

                    for (const channel in res.data) {
                        if (channel) {
                            reachByChannel.total += res.data[channel]
                            reachByChannel[channel.toUpperCase()] = res.data[channel]
                        }
                    }
                } else {
                    res = await domainSvc.estimateSegmentReach(
                        domainsToCheck[0].id,
                        audience.getSegmentIds() ?? [],
                        audience.getExcludedSegmentIds() ?? [],
                        targetedChannels,
                        'count',
                        ['NOTIFICATION'],
                        {
                            cancellationKey: 'nba-fetch-reach',
                        },
                    )

                    reachByChannel = { total: res.data }
                }

                if (!res.cancelled) {
                    runIfMounted(() => {
                        setCurrReach(reachByChannel)
                        setLoadingReach(false)

                        setBuilder({ type: 'patch', entity: 'reach-estimate', data: reachByChannel.total })
                    })
                }
            }
        }

        fetchReach()
    }, [
        JSON.stringify(audience.serialize()),
        builder.availableSegmentsLoaded,
        builder.availableSegments?.length,
        JSON.stringify(builder.channels),
    ])

    const domains = Array.isArray(domain) ? domain : [domain]

    const hasReachEstimate = currReach !== undefined
    const variantEstimates =
        hasVariants && hasReachEstimate ? reachPcts.map((pct) => Math.ceil(currReach?.total! * (pct / 100))) : []
    const totalVariantEst = variantEstimates.reduce((p, c) => (c += p), 0)
    const reachDiff = !totalVariantEst ? 0 : totalVariantEst - currReach?.total!
    if (hasReachEstimate && reachDiff > 0) {
        const maxVariantIdx = reachPcts.indexOf(Math.max(...reachPcts))
        variantEstimates[maxVariantIdx] -= reachDiff
    }

    const deliveryHasStarted = builder.variants.some((v) => v.getDisplayMeta().delivering)
    const sampleDisabledReason = getReachDisabledReason(builder, currReach?.total)

    const currTest = builder.test
    const currDelivery = currVariant.getDelivery()

    return (
        <ReachWell
            className={getClassNames('notification-builder-reach-estimate', 'nested', levelClassName, className, {
                'no-variants': !hasVariants || hideVariantReach,
                'no-distribution': !hasVariants || hideDistributionManager,
                empty: !hasVariants || (hideVariantReach && hideDistributionManager),
            })}
            actions={
                builder.loading || loadingReach ? (
                    <Loading3QuartersOutlined spin={true} />
                ) : !hasReachEstimate ? (
                    <span>--</span>
                ) : (
                    <>
                        <span>{` ~ ${numberWithCommas(currReach?.total!)}`}</span>
                        {targetedChannels.length > 1 && (
                            <Popover
                                title="Reach by Channel"
                                overlayClassName="audience-channel-breakdown-popover"
                                content={targetedChannels.map((ch) => {
                                    return (
                                        <div key={ch} className="reach-breakdown-row">
                                            <span className="reach-breakdown-label">
                                                {DeliveryChannel.getLongName(ch)}:
                                            </span>
                                            <span className="reach-breakdown-value">
                                                {currReach && numberWithCommas(currReach?.[ch.toUpperCase()] ?? 0)}
                                            </span>
                                        </div>
                                    )
                                })}
                                placement="left"
                            >
                                <PartitionOutlined />
                            </Popover>
                        )}
                    </>
                )
            }
        >
            {!hideVariantReach && (
                <Well
                    className={getClassNames('variant-reach-wrapper')}
                    hideHeader={true}
                    hideFooter={true}
                    mode="ghost"
                    disabled={builder.loading}
                >
                    <Descriptions size="small" column={1} colon={false}>
                        {hasVariants &&
                            variantEstimates.map((vReach, vidx) => (
                                <Descriptions.Item
                                    key={vidx}
                                    className={getClassNames(null, `variant-reach variant-${vidx + 1}`, {
                                        active: builder.selectedVariantIdx === vidx,
                                    })}
                                    label={
                                        <span
                                            className="variant-reach-label"
                                            onClick={() => {
                                                builder.selectedVariantIdx = vidx
                                                onVariantSelect?.(builder)
                                            }}
                                        >
                                            Variant {vidx + 1}
                                        </span>
                                    }
                                >
                                    {loadingReach ? (
                                        <Loading3QuartersOutlined spin={true} />
                                    ) : !hasReachEstimate ? (
                                        '--'
                                    ) : (
                                        `~ ${numberWithCommas(vReach)}`
                                    )}
                                </Descriptions.Item>
                            ))}
                    </Descriptions>
                </Well>
            )}

            {!hideDistributionManager && !!currTest && (
                <>
                    <DistributionManager
                        layout="compact"
                        domain={domains[0]}
                        disabled={deliveryHasStarted}
                        loading={builder.loading}
                        builder={builder}
                        disableSampling={!!sampleDisabledReason}
                        disableSamplingTooltip={sampleDisabledReason}
                        value={currTest}
                        delivery={currDelivery}
                        onChange={(v) => {
                            if (v.getDistributionType() === 'sample') {
                                v.setEvaluationSchedule(v.getEvaluationSchedule() ?? getDefaultElapsedSchedule())
                            } else {
                                v.setEvaluationSchedule(undefined)
                            }

                            builder.test = v

                            onDistributionChange?.(builder)
                        }}
                    />
                </>
            )}
        </ReachWell>
    )
}

export default NotificationBuilderReach
