import * as React from 'react'
import './create-campaign.scss'
import { PageHeader } from '@pushly/aqe/lib/components'
import { BetterComponent } from '../../../components/better-component/better-component'
import { AppState } from '../../../stores/app'
import { AppService, CampaignV2Service, NotificationService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { CampaignBuilderMode, NodeType } from '../../../components/campaign-builder/enums'
import { ifDomainChanged } from '../../../_utils/domain'
import { CampaignWizard } from '../../../components/campaign-wizard/campaign-wizard'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { reassignStepIds } from '../../../_utils/campaigns'
import { ApiVersion } from '../../../enums/api-version.enum'

interface ICreateCampaign {}

interface IState {
    loading: boolean
    campaign?: any
}

export class CreateCampaign extends BetterComponent<ICreateCampaign, IState> {
    private appState: AppState
    private appService: AppService
    private campaignService: CampaignV2Service
    private notifService: NotificationService

    private disposeObservers: any[] = []

    public constructor(props: ICreateCampaign) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.campaignService = Container.get(CampaignV2Service)
        this.notifService = Container.get(NotificationService)

        this.state = {
            loading: true,
        }
    }

    public componentDidMount(): void {
        this.loadRequestedTemplate()

        this.disposeObservers.push(
            ifDomainChanged(this.appState, () => this.appService.routeWithinDomain('/campaigns')),
        )
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.disposeObservers.forEach((disposeFn) => disposeFn())
    }

    public render(): React.ReactNode {
        return (
            <div className="create-campaign">
                <PageHeader title="Create Campaign" onTitleSet={this.appService.customizeTabTitle} />

                <CampaignWizard
                    loading={this.state.loading}
                    mode={CampaignBuilderMode.CREATE}
                    domain={this.appState.currentDomain}
                    campaign={this.state.campaign}
                    onChange={this.handleUpdate}
                />
            </div>
        )
    }

    protected get templateId(): number {
        return this.queryString.template as any
    }

    protected handleUpdate = (campaign: any) => {
        this.setState({ campaign })
    }

    protected loadRequestedTemplate = async (): Promise<void> => {
        const update: any = { loading: false }

        if (!!this.templateId) {
            const { ok, data } = await this.campaignService.fetchById(this.templateId)
            const template = CampaignModel.build(data)

            if (ok) {
                update.campaign = {
                    id: 'template',
                    type: template.getType(),
                    name: template.getName(),
                    channels: template.getChannels(),
                    configuration: template.getConfiguration().serialize(),
                    date_time_zone: template.getDateTimeZone(),
                    date_start: template.getDateStart(),
                    date_end: template.getDateEnd(),
                }

                let steps = template.getCurrentRevision()?.serialize()?.steps ?? []
                if (Array.isArray(steps) && steps.length > 0) {
                    steps = reassignStepIds(steps)

                    const actionSteps = steps.filter(
                        (s) => s.type === NodeType.ACTION && !!s.configuration?.params?.notification_id,
                    )
                    if (actionSteps.length > 0) {
                        await Promise.all(
                            actionSteps.map((s) => {
                                let actionLoader

                                const notificationId = s.configuration?.params?.notification_id
                                if (!!notificationId) {
                                    actionLoader = this.notifService
                                        .fetchNotificationById(this.appState.currentDomain!.id, notificationId, {
                                            query: { include_schedules: false },
                                            version: ApiVersion.V4,
                                        })
                                        .then((res) => {
                                            if (!!this.templateId) {
                                                delete s.configuration.params.notification_id
                                            }

                                            if (!!res?.template?.channels?.default) {
                                                s.configuration.params.title = res.template.channels.default.title

                                                // remove previous default actions if override flag is false
                                                // and persisted default actions are returned
                                                //
                                                const useDefaultActions =
                                                    (res?.template.channels.web?.displayMeta
                                                        ?.override_default_actions === false ||
                                                        res?.template.channels.nativeAndroid?.displayMeta
                                                            ?.override_default_actions === false) ??
                                                    false
                                                const hasActions =
                                                    (res.template.channels.default.actions?.length ?? 0) > 0
                                                if (useDefaultActions && hasActions) {
                                                    delete res.template.channels.default.actions
                                                }
                                            }

                                            s.configuration.params.notification = res
                                        })
                                }

                                return actionLoader
                            }),
                        )
                    }
                }

                update.campaign.revision = { steps }
            }
        }

        this.setState(update)
    }
}
