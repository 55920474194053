import * as React from 'react'
import { getClassNames } from '../../_utils/classnames'
import ReactQuill from 'react-quill'
import Quill from 'quill'
// theme stylesheet required
import 'react-quill/dist/quill.snow.css'
// custom styles
import './custom-quill-editor.scss'
import { CustomQuillEditorProps, CustomQuillFormatOptions, EditorActionRef } from './types'
import { CustomQuillEditorToolbar } from './custom-quill-editor-toolbar'
import { forwardRef, RefObject, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { generateShortID } from '../campaign-builder/helpers/uid'

const DirectionAttribute = Quill.import('attributors/attribute/direction')
Quill.register(DirectionAttribute, true)
const AlignClass = Quill.import('attributors/class/align')
Quill.register(AlignClass, true)
const BackgroundClass = Quill.import('attributors/class/background')
Quill.register(BackgroundClass, true)
const ColorClass = Quill.import('attributors/class/color')
Quill.register(ColorClass, true)
const DirectionClass = Quill.import('attributors/class/direction')
Quill.register(DirectionClass, true)
const FontClass = Quill.import('attributors/class/font')
Quill.register(FontClass, true)
const SizeClass = Quill.import('attributors/class/size')
Quill.register(SizeClass, true)
const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)
const BackgroundStyle = Quill.import('attributors/style/background')
Quill.register(BackgroundStyle, true)
const ColorStyle = Quill.import('attributors/style/color')
Quill.register(ColorStyle, true)
const DirectionStyle = Quill.import('attributors/style/direction')
Quill.register(DirectionStyle, true)
const FontStyle = Quill.import('attributors/style/font')
Quill.register(FontStyle, true)
const SizeStyle = Quill.import('attributors/style/size')
Quill.register(SizeStyle, true)

// const for enabling all available formats from Pushly Custom toolbar
const ALL_CUSTOM_QUILL_FORMATS: CustomQuillFormatOptions[] = [
    'bold',
    'italic',
    'strike',
    'list',
    'bullet',
    'indent',
    'align',
    'direction',
    'color',
    'emojis',
    'macros',
]

export const CustomQuillEditor = forwardRef<EditorActionRef, CustomQuillEditorProps>(
    ({ formats, value, disabled, ...props }: CustomQuillEditorProps, ref) => {
        let quillRef = useRef<ReactQuill | null>(null)
        const [instanceId] = useState(`cQE${generateShortID()}`)

        const modules = useMemo(
            () => ({
                toolbar: {
                    container: '#toolbar',
                    history: {
                        delay: 500,
                        maxStack: 100,
                        userOnly: true,
                    },
                },
            }),
            [],
        )

        formats ??= ALL_CUSTOM_QUILL_FORMATS

        useImperativeHandle(ref as RefObject<any>, () => ({
            focusEditor() {
                if (quillRef.current) {
                    setTimeout(() => {
                        quillRef.current?.editor?.focus()
                    }, 20)
                }
            },
            isEmpty() {
                return new Promise((res: (empty: boolean) => void, rej) => {
                    if (quillRef.current) {
                        setTimeout(() => {
                            res(quillRef.current?.editor?.getText()?.trim()?.length === 0)
                        }, 20)
                    }
                })
            },
        }))

        return (
            <div
                className={getClassNames('custom-quill-editor', props.className, {
                    disabled,
                })}
            >
                <CustomQuillEditorToolbar
                    editorInstanceId={instanceId}
                    quill={quillRef}
                    formats={formats}
                    macroOptions={props.macros}
                />

                <ReactQuill
                    style={{
                        backgroundColor: props.contentEditableProps?.backgroundColor,
                        borderColor:
                            props.contentEditableProps?.backgroundColor !== '#FFFFFF'
                                ? props.contentEditableProps?.backgroundColor
                                : '#FFFFFF',
                    }}
                    id={instanceId}
                    ref={(el) => {
                        if (el) {
                            quillRef.current = el
                        }
                    }}
                    theme="snow"
                    defaultValue={value}
                    readOnly={props.readOnly ?? disabled}
                    modules={modules}
                    onChange={(content) => {
                        props.onChange(content)
                    }}
                />
            </div>
        )
    },
)
