import { BetterSelect } from '@pushly/aqe/lib/components/better-select/better-select'
import { getClassNames } from '../../_utils/classnames'
import { AsyncButton } from '../async-button/async-button.component'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import * as React from 'react'
import { INotificationBuilderState } from '../notification-builder/interfaces/notification-builder-state.interface'
import { DomainDto } from '../../dtos/domain'
import './notification-template-selector.scss'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Well } from '@pushly/aqe/lib/components'
import { Switch } from 'antd'

type NotificationTemplateSelectProps = {
    builder: INotificationBuilderState
    domain: DomainDto
    value?: number
    onChange: (change: { id?: number; domain: DomainDto; usingTemplate?: boolean }) => void
}

export const NotificationTemplateSelector = (props: NotificationTemplateSelectProps) => {
    const { builder, domain, value, onChange } = props

    const templateValue = value ? [value] : []
    return (
        <Well title="Template" hideHeader={false} hideFooter={true}>
            <div>
                <div style={{ marginBottom: '6px' }}>
                    <label>
                        <Switch
                            style={{ marginRight: '6px' }}
                            checked={builder.usingTemplate}
                            size="small"
                            title="Use saved template"
                            onChange={() => {
                                onChange({ id: undefined, domain, usingTemplate: !builder.usingTemplate })
                            }}
                        />
                        <span>Use saved template</span>
                    </label>
                </div>
                <div className={getClassNames('notif-template-selector')}>
                    <BetterSelect
                        mode="single"
                        prefix="Template"
                        placeholder={
                            !builder.availableTemplates.length ? (
                                <Loading3QuartersOutlined spin={true} />
                            ) : (
                                'Select a template'
                            )
                        }
                        disabled={!builder.usingTemplate}
                        disableSearch={true}
                        loading={builder.loading}
                        options={builder.availableTemplates.map((t) => ({ label: t.name, value: t.id }))}
                        onChange={(val) =>
                            onChange({
                                id: val as number,
                                domain,
                                usingTemplate: !!val,
                            })
                        }
                        value={templateValue}
                    />
                </div>
            </div>
        </Well>
    )
}
