import * as React from 'react'
import './prompt-table-well.scss'
import { Alert, Button, Modal, Popover, Table } from 'antd'
import {
    CaretDownFilled,
    CaretUpFilled,
    ExclamationCircleOutlined,
    Loading3QuartersOutlined,
    RetweetOutlined,
    SelectOutlined,
} from '@ant-design/icons'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Well } from '@pushly/aqe/lib/components'
import { AppService, DomainService, NotificationService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { titleCase } from '../../../_utils/utils'
import { AppState } from '../../../stores/app'
import { observe } from 'mobx'
import { PromptService } from '../../../services/prompt'
import { PromptTableDataDto } from './prompt-table-data-dto'
import { PromptDto } from '../../../dtos/prompt'
import { PromptGroupDto } from '../../../dtos/prompt-group'
import { PromptStyle } from '../prompt-editor/prompt-editor'
import { TableRowEntityDisplay } from '../../../components/table-row-entity-display/table-row-entity-display'
import { NotificationAbBadge } from '../../../components/badges/notification-ab-badge'
import { StatusType } from '../../../enums/status-type'
import { PromptStatusBadge } from '../../../components/badges/prompt-status-badge'
import { CompoundTable, CompoundTableHeader } from '../../../components/aqe/compound-table/compound-table'
import { AntdTableEmptyPlaceholder } from '../../../components/aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { CurrentUserCan } from '../../../stores/app-ability'
import { AbilityAction } from '../../../enums/ability-action.enum'
import { SubjectEntity } from '../../../enums/ability-entity.enum'
import { NoTranslate } from '../../../components/no-translate/no-translate'
import MenuItem from 'antd/es/menu/MenuItem'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { simpleNotification } from '@pushly/aqe/lib/utils'

const PriorityHandler = ({ promptGroup, onChange, hideUp, hideDown }: any) => {
    return (
        <div className="priority-handler">
            {!hideUp && (
                <span
                    onClick={() => {
                        onChange(promptGroup, -1)
                    }}
                >
                    <CaretUpFilled />
                </span>
            )}
            {!hideDown && (
                <span
                    onClick={() => {
                        onChange(promptGroup, 1)
                    }}
                >
                    <CaretDownFilled />
                </span>
            )}
        </div>
    )
}

// TODO: Add filter to table for channels

interface IProps {
    readonly showSearch?: boolean
    readonly showRefresh?: boolean
    readonly showHeader?: boolean
    readonly title?: string
    readonly actions?: React.ReactNode
    readonly showActions?: boolean
    readonly pageSize?: number
    readonly listType?: DeliveryChannel
}

interface IState {
    loading: boolean
    currentDomainId: number
    promptsLoaded: boolean
    allPrompts: any[]
    visiblePrompts: any[]
    sortedInfo: any
    pageMeta: any
    page: number
    pageSize: number
    query?: string
}

export class PromptTableWell extends BetterComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService
    private domainService: DomainService
    private notificationService: NotificationService
    private promptService: PromptService

    private disposeObservers: any

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)
        this.notificationService = Container.get(NotificationService)
        this.promptService = Container.get(PromptService)

        this.state = {
            page: 1,
            pageSize: this.props.pageSize || 15,
            pageMeta: {},
            loading: false,
            currentDomainId: this.appState.currentDomain!.id,
            promptsLoaded: false,
            allPrompts: [],
            visiblePrompts: [],
            sortedInfo: {
                order: 'descend',
                columnKey: 'id',
            },
        }
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.ensurePromptsForList())]

        this.ensurePromptsForList()
    }

    public componentWillUnmount(): void {
        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        const wellProps: any = {
            hideFooter: true,
            hideHeader: true,
            title: 'Prompts',
        }

        const actions = (
            <React.Fragment>
                {this.props.actions}

                {this.props.showRefresh !== false && (
                    <Button className="refresh-list" size="small" onClick={this.refreshNotifications}>
                        <RetweetOutlined />
                        <span>Refresh</span>
                    </Button>
                )}
            </React.Fragment>
        )

        if (this.props.showHeader !== false) {
            wellProps.showHeader = true
            wellProps.action = actions

            if (this.props.title && this.props.title.trim()) {
                wellProps.title = this.props.title
            }
        }

        const wellClassNames: string[] = ['type-2', 'prompt-table-well']
        if (this.props.showHeader !== false) {
            wellClassNames.push('show-header')
        }
        if (this.props.showSearch === false) {
            wellClassNames.push('hide-search')
        }
        wellProps.className = wellClassNames.join(' ')

        const abTestIds = this.state.visiblePrompts.filter((g) => g.isAbTest).map((g) => g.id)

        let show2StepWarning = false
        const currentDomain = this.appState.currentDomain
        if (!this.state.loading && currentDomain) {
            const match = this.state.allPrompts
                // Must have an active 2-step prompt
                .filter((pg) => pg.isActive)
                // Must have at least one non-system prompt variant
                .filter(
                    (pg) => pg.prompts.length >= 1 && pg.prompts.filter((p) => p.style !== PromptStyle.SYSTEM).length,
                )

            if (match.length === 0 && this.props.listType === DeliveryChannel.WEB) {
                show2StepWarning = true
            }
        }

        const domainOwnedPromptIdentity = this.appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.PROMPT)

        return (
            <Well {...wellProps}>
                <CompoundTable>
                    {show2StepWarning && (
                        <CompoundTableHeader>
                            <Alert
                                className="safari-2step"
                                type="warning"
                                showIcon={true}
                                message="2-step Prompt Requirements"
                                description={
                                    <span>
                                        You must have at least one Slide or Bell prompt configured and enabled in order
                                        to prompt visitors using Safari 12.1 or later and Firefox 72 or later.
                                    </span>
                                }
                            />
                        </CompoundTableHeader>
                    )}
                </CompoundTable>

                <div className="list-content">
                    <Table
                        rowKey="id"
                        loading={
                            this.state.loading && {
                                indicator: <Loading3QuartersOutlined spin={true} />,
                            }
                        }
                        locale={{
                            emptyText: <AntdTableEmptyPlaceholder text="No Prompts" icon="select" />,
                        }}
                        dataSource={this.state.visiblePrompts}
                        onChange={this.handleChange}
                        pagination={false}
                        bordered={true}
                        expandedRowKeys={abTestIds}
                        expandedRowRender={(group: PromptTableDataDto, ...args: any[]): React.ReactNode => {
                            if (!group.isAbTest) {
                                return undefined
                            }

                            return (
                                <Table
                                    rowKey="id"
                                    className="ant-table-expanded-row"
                                    dataSource={[
                                        ...group.prompts.map((p) => {
                                            p.key = p.id
                                            return p
                                        }),
                                    ]}
                                    pagination={false}
                                    bordered={true}
                                >
                                    <Table.Column key="prompt" className="prompt" render={this.renderNestedDisplay} />
                                    <CurrentUserCan do={AbilityAction.UPDATE} on={group}>
                                        <Table.Column
                                            key="actions"
                                            className="actions"
                                            render={this.renderNestedRowActions}
                                            align="center"
                                        />
                                    </CurrentUserCan>
                                </Table>
                            )
                        }}
                    >
                        {this.props.listType === DeliveryChannel.WEB && (
                            <Table.Column
                                key="priority"
                                className="priority"
                                dataIndex="priority"
                                title={
                                    <span>
                                        Priority
                                        <Popover
                                            getPopupContainer={this.appService.getAppContainer}
                                            overlayClassName="pr-priority-header-popover"
                                            content={
                                                <div>
                                                    Prompt priority determines which prompt will be shown to a potential
                                                    subscriber. When a potential subscriber visits a page on your site,
                                                    we will determine the prompt with the highest priority (lowest
                                                    number) that matches all page conditions for that page and display
                                                    that prompt.
                                                </div>
                                            }
                                            trigger="hover"
                                        >
                                            <ExclamationCircleOutlined />
                                        </Popover>
                                    </span>
                                }
                                render={this.renderPriority}
                            />
                        )}
                        <Table.Column
                            key="prompt"
                            className={`prompt${this.props.listType === DeliveryChannel.WEB ? '' : ' native'}`}
                            title="Prompt"
                            render={this.renderDisplay}
                        />

                        {/*
                            Antd Table.Column elements are required to be first class children
                            <CurrentUserCan ... /> cannot be used in this context
                        */}
                        {this.appState.abilityStore.can(AbilityAction.UPDATE, domainOwnedPromptIdentity) && (
                            <Table.Column
                                key="actions"
                                className="actions"
                                title="Actions"
                                render={this.renderRowActions}
                                align="center"
                            />
                        )}
                    </Table>
                </div>
            </Well>
        )
    }

    private handleRowMove = async (dataSource: any[]) => {
        await this.setState({ loading: true })

        const priorityMap = dataSource.map((p, priority) => ({ id: p.id, priority }))
        await this.promptService.updatePriorityMap(this.state.currentDomainId, priorityMap)

        dataSource.forEach((ds) => {
            const mapData = priorityMap.find((m) => m.id === ds.id)
            if (mapData) ds.priority = mapData.priority
        })

        this.setState({
            loading: false,
            visiblePrompts: dataSource,
        })
    }

    private handlePriorityChange = async (group: PromptTableDataDto, modifier: 1 | -1) => {
        const dataSource = [...this.state.visiblePrompts]
        const currIdx = dataSource.findIndex((pg) => pg.id.toString() === group.id.toString())

        if (currIdx !== -1) {
            const elem = dataSource.splice(currIdx, 1)[0]
            dataSource.splice(currIdx + modifier, 0, elem)
        }

        return this.handleRowMove(dataSource)
    }

    private renderPriority = (_: string, group: PromptTableDataDto, idx: number): React.ReactNode => {
        const { abilityStore } = this.appState
        const dragEnabled = this.state.visiblePrompts.length > 1
        const isFirstRow = idx === 0
        const isLastRow = idx === this.state.visiblePrompts.length - 1

        return (
            <React.Fragment>
                <CurrentUserCan
                    do={AbilityAction.UPDATE}
                    on={abilityStore.getDomainOwnedIdentityFor(SubjectEntity.PROMPT)}
                >
                    {!dragEnabled ? (
                        ''
                    ) : (
                        <PriorityHandler
                            promptGroup={group}
                            onChange={this.handlePriorityChange}
                            hideUp={isFirstRow}
                            hideDown={isLastRow}
                        />
                    )}
                </CurrentUserCan>
                {(group.priority || 0) + 1}
            </React.Fragment>
        )
    }

    private renderDisplay = (_: string, group: PromptTableDataDto): React.ReactNode => {
        const title = group.name
        let promotedPromptName: string | undefined

        const prompt: PromptDto = group.prompts[0] || {}
        if (group.prompts.length === 1 && prompt.computedName !== title) {
            promotedPromptName = prompt.name
        }

        const pageConditions: any = group.pageCondtions
        const visitorConditions: any = prompt.visitorConditions

        let pageConditionDisplay = `Shows on all pages`
        if (pageConditions) {
            const hasPageUrlCondition = !!pageConditions.pageUrls || !!pageConditions.excludedPageUrls
            pageConditionDisplay = ''

            if (pageConditions.pageUrls && pageConditions.pageUrls.length > 0) {
                pageConditionDisplay += `<p>Shows on pages matching the following url patterns: ${pageConditions.pageUrls.join(
                    ', ',
                )}.</p>`
            }
            if (pageConditions.excludedPageUrls && pageConditions.excludedPageUrls.length > 0) {
                pageConditionDisplay += `<p>Does not show on pages matching the following url patterns: ${pageConditions.excludedPageUrls.join(
                    ', ',
                )}.</p>`
            }

            if (!hasPageUrlCondition && pageConditions.keywords) {
                pageConditionDisplay = `<p>Shows on pages containing the following meta keywords: ${pageConditions.keywords.join(
                    ', ',
                )}.</p>`
            }
        }

        let visitorConditionDisplay = `Shows immediately`
        if (visitorConditions) {
            if (visitorConditions.timeOnPageSeconds !== undefined) {
                const value = visitorConditions.timeOnPageSeconds
                visitorConditionDisplay = `Shows to users who have been on a single page for at least ${value} seconds in the current session.`
            } else if (visitorConditions.sessionPageViews !== undefined) {
                const value = visitorConditions.sessionPageViews
                visitorConditionDisplay = `Shows to users who have been visited at least ${value} pages on your site in the current session.`
            } else if (visitorConditions.sessionTimeOnSiteSeconds !== undefined) {
                const value = visitorConditions.sessionTimeOnSiteSeconds
                visitorConditionDisplay = `Shows to users who have been on your site for at least ${value} seconds in the current session.`
            }
        }

        const configurationDetails: any = (
            <div>
                <h2>Page Conditions</h2>
                <span dangerouslySetInnerHTML={{ __html: pageConditionDisplay }} />

                {!group.isAbTest && (
                    <React.Fragment>
                        <hr />
                        <h2>Visitor Conditions</h2>
                        {visitorConditionDisplay}
                    </React.Fragment>
                )}

                <hr />
                <h2>Show prompt automatically?</h2>
                {group.prompts[0].isAutoShow
                    ? 'Yes'
                    : 'This prompt will only be shown when the action is specifically triggered via the Platform SDK.'}
            </div>
        )

        const configurationDetailsHandle = (
            <Popover
                getPopupContainer={() => this.appService.getAppContainer()}
                overlayClassName="prompt-row-details-popover"
                content={configurationDetails}
                trigger="hover"
            >
                <span className="prompt-row-details-popover-trigger">
                    <a>Configuration Details</a>
                </span>
            </Popover>
        )

        return (
            <TableRowEntityDisplay
                title={
                    <NoTranslate>
                        {`${title}${promotedPromptName ? ` (Variant: ${promotedPromptName})` : ''}`}
                    </NoTranslate>
                }
                description={
                    this.props.listType === DeliveryChannel.WEB ? (
                        <span className="config-details">
                            <span>
                                <SelectOutlined />
                            </span>
                            {configurationDetailsHandle}
                        </span>
                    ) : null
                }
                status={
                    <PromptStatusBadge
                        status={group.isActive ? StatusType.ACTIVE.name : StatusType.DISABLED.name}
                        expanded={true}
                    />
                }
                badges={
                    group.prompts.length > 1 && (
                        <>
                            <NotificationAbBadge />
                            {!!promotedPromptName && (
                                <span className="promoted-name">
                                    <b>Promoted prompt:</b> {promotedPromptName}
                                </span>
                            )}
                        </>
                    )
                }
            />
        )
    }

    private renderNestedDisplay = (_: string, source: any, rowIndex: number): React.ReactNode => {
        const prompt: PromptDto = source
        const testName = prompt.name || `Test ${rowIndex + 1}`

        let style = prompt.style

        const visitorConditions: any = prompt.visitorConditions
        const isPublisherCustomStyle = style === 'PUBLISHER_CUSTOM'

        if (isPublisherCustomStyle) {
            style = 'CUSTOM'
        }

        let visitorConditionDisplay = `Shows immediately`
        if (visitorConditions) {
            if (visitorConditions.timeOnPageSeconds !== undefined) {
                const value = visitorConditions.timeOnPageSeconds
                visitorConditionDisplay = `Shows to users who have been on a single page for at least ${value} seconds in the current session.`
            } else if (visitorConditions.sessionPageViews !== undefined) {
                const value = visitorConditions.sessionPageViews
                visitorConditionDisplay = `Shows to users who have been visited at least ${value} pages on your site in the current session.`
            } else if (visitorConditions.sessionTimeOnSiteSeconds !== undefined) {
                const value = visitorConditions.sessionTimeOnSiteSeconds
                visitorConditionDisplay = `Shows to users who have been on your site for at least ${value} seconds in the current session.`
            }
        }

        const configurationDetails: any = (
            <div>
                <h2>Visitor Conditions</h2>
                {visitorConditionDisplay}
            </div>
        )

        const configurationDetailsHandle = (
            <Popover
                getPopupContainer={() => this.appService.getAppContainer()}
                overlayClassName="prompt-row-details-popover"
                content={configurationDetails}
                trigger="hover"
            >
                <span className="prompt-row-details-popover-trigger">
                    <a>Configuration Details</a>
                </span>
            </Popover>
        )

        return (
            <TableRowEntityDisplay
                title={<NoTranslate>{testName}</NoTranslate>}
                description={
                    <span className="config-details">
                        <span>
                            <SelectOutlined />
                        </span>
                        <span className="pd-style-type">
                            <strong>{titleCase(style)}</strong>:
                        </span>
                        {configurationDetailsHandle}
                    </span>
                }
                status={
                    <span className="test-weight">
                        <span>{prompt.weight}%</span>
                    </span>
                }
                badges={<NotificationAbBadge tooltip="Multivariate Prompt Test" />}
            />
        )
    }

    private renderRowActions = (group: PromptTableDataDto): React.ReactNode => {
        const actions: any = [
            {
                text: 'Duplicate',
                icon: 'copy',
                onClick: this.handleDuplicate,
                group,
                altHref: this.buildDuplicateUrl(group),
            },
        ]

        if (group.isActive) {
            actions.push({
                text: 'Disable',
                icon: 'close',
                onClick: this.handleDisable,
                group,
            })
        } else {
            actions.push({
                text: 'Enable',
                icon: 'eye',
                onClick: this.handleEnable,
                group,
            })
        }

        if (this.state.visiblePrompts.length > 1) {
            actions.push({ divider: true })
            actions.push({
                text: 'Delete',
                icon: 'delete',
                onClick: this.handleDelete,
                group,
            })
        }

        if (this.props.showActions === false) {
            return ''
        }

        return this.props.listType === DeliveryChannel.WEB ? (
            <AsyncButton
                actions={actions}
                onClick={() => this.handlePrimaryActionClick(group)}
                size="small"
                altHref={this.buildEditUrl(group)}
            >
                <span>Edit</span>
            </AsyncButton>
        ) : (
            <AsyncButton
                onClick={() => (group.isActive ? this.handleDisable(group) : this.handleEnable(group))}
                size="small"
            >
                <span>{group.isActive ? 'Disable' : 'Enable'}</span>
            </AsyncButton>
        )
    }

    private renderNestedRowActions = (source: any): React.ReactNode => {
        const prompt: PromptDto = source
        const group: PromptGroupDto = this.state.visiblePrompts.find((g) => g.id === prompt.promptGroupId)
        const domainOwnedPromptIdentity = this.appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.PROMPT)

        return (
            this.appState.abilityStore.can(AbilityAction.UPDATE, domainOwnedPromptIdentity) && (
                <AsyncButton
                    onClick={() => this.handlePromptTestPromotion(prompt, group)}
                    size="small"
                    confirm={{
                        okText: 'Promote',
                        title: 'Promote Prompt',
                        content: (
                            <div>
                                <p>Promoting this prompt will end the "{group.name}" test.</p>
                                <p>
                                    The "{prompt.name}" prompt will be promoted to 100% display weight and the test will
                                    no longer be visible in the prompt list. Historical test reporting will be available
                                    in the reporting module.
                                </p>
                                <p>Please note, this action cannot be reversed.</p>
                            </div>
                        ),
                    }}
                >
                    <span>Promote</span>
                </AsyncButton>
            )
        )
    }

    private buildEditUrl(group: PromptTableDataDto): string {
        return this.appService.routeWithinDomain(`/prompts/${group.id}`, true)
    }

    private buildDuplicateUrl(group: PromptTableDataDto): string {
        return this.appService.routeWithinDomain(`/prompts/new?template=${group.id}`, true)
    }

    private handleChange = (pagination: any, filters: any, sorter: any) => {
        this.setState({
            sortedInfo: sorter,
        })
    }

    private handlePrimaryActionClick(prompt: PromptTableDataDto): void {
        this.appService.route(this.buildEditUrl(prompt))
    }

    private async handlePromptTestPromotion(prompt: PromptDto, group: PromptGroupDto): Promise<void> {
        const promoted = await this.promptService.promoteTestPrompt(group, prompt)

        if (promoted) {
            this.fetchPrompts()
        }
    }

    private handleDuplicate = async (item: any) => {
        const { group } = item.props
        this.appService.route(this.buildDuplicateUrl(group))
    }

    private handleEnable = async (item: MenuItem | PromptTableDataDto): Promise<void> => {
        let group
        if ('props' in item) {
            group = (item.props as any).group
        } else {
            group = item
        }

        try {
            await this.promptService.enablePromptById(this.state.currentDomainId, group.id)
        } catch (error) {
            simpleNotification('error', error.message)
        }

        group.isActive = true

        const visiblePrompts = this.state.visiblePrompts
        const visibleNotifIdx = visiblePrompts.findIndex((p) => p.id === group.id)
        if (visibleNotifIdx >= 0) {
            visiblePrompts.splice(visibleNotifIdx, 1, group)
            this.setState({ visiblePrompts })
            simpleNotification(
                'success',
                <span>
                    Prompt <b>{group.name}</b> has been enabled.
                </span>,
            )
        }
    }

    private handleDisable = async (item: MenuItem | PromptTableDataDto): Promise<void> => {
        let group
        if ('props' in item) {
            group = (item.props as any).group
        } else {
            group = item
        }

        const finalize = async () => {
            try {
                await this.promptService.disablePromptById(this.state.currentDomainId, group.id)
            } catch (error) {
                simpleNotification('error', error.message)
            }

            group.isActive = false

            const visiblePrompts = this.state.visiblePrompts
            const visibleNotifIdx = visiblePrompts.findIndex((p) => p.id === group.id)
            if (visibleNotifIdx >= 0) {
                const removed = visiblePrompts.splice(visibleNotifIdx, 1, group)
                this.setState({ visiblePrompts })
                simpleNotification(
                    'success',
                    <span>
                        Prompt <b>{group.name}</b> has been disabled.
                    </span>,
                )
            }
        }

        const allPrompts = this.state.allPrompts || []
        const promptIs2Step = !group.isAbTest && group.prompts[0].style !== PromptStyle.SYSTEM
        const enabled2StepPrompts = allPrompts.filter(
            (g) => !g.isAbTest && g.isActive && g.prompts[0].style !== PromptStyle.SYSTEM,
        )

        if (promptIs2Step && enabled2StepPrompts.length <= 1) {
            return new Promise((res) => {
                Modal.confirm({
                    title: 'Disable Prompt',
                    content: (
                        <div>
                            If you disable the only active 2-step prompt, users on Firefox and Safari will no longer be
                            prompted for notification permission. Are you sure you want to do this?
                        </div>
                    ),
                    okText: 'Yes, Disable',
                    cancelText: 'Cancel',
                    onOk: () => res(finalize()),
                    onCancel: () => res(),
                })
            })
        } else {
            return finalize()
        }
    }

    private handleDelete = async (item: any): Promise<void> => {
        const { group } = item.props

        const finalize = async () => {
            await this.promptService.destroy(this.state.currentDomainId, group.id, true)
            return this.fetchPrompts()
        }

        const allPrompts = this.state.allPrompts || []
        const promptIs2Step = !group.isAbTest && group.isActive && group.prompts[0].style !== PromptStyle.SYSTEM
        const enabled2StepPrompts = allPrompts.filter(
            (g) => !g.isAbTest && g.isActive && g.prompts[0].style !== PromptStyle.SYSTEM,
        )

        if (promptIs2Step && enabled2StepPrompts.length <= 1) {
            return new Promise((res) => {
                Modal.confirm({
                    title: 'Delete Prompt',
                    content: (
                        <div>
                            If you remove the only active 2-step prompt, users on Firefox and Safari will no longer be
                            prompted for notification permission. Are you sure you want to do this?
                        </div>
                    ),
                    okText: 'Yes, Delete',
                    cancelText: 'Cancel',
                    onOk: () => res(finalize()),
                    onCancel: () => res(),
                })
            })
        } else {
            return new Promise((res) => {
                Modal.confirm({
                    title: 'Delete Prompt',
                    content: 'Are you sure you want to remove this prompt?',
                    okText: 'Delete',
                    onOk: () => res(finalize()),
                    onCancel: () => res(),
                })
            })
        }
    }

    private async ensurePromptsForList() {
        if (this.state.promptsLoaded && this.state.currentDomainId === this.appState.currentDomain!.id) {
            return
        }

        await this.setState({ loading: true, promptsLoaded: false })
        await this.fetchPrompts()
        await this.setState({ loading: false, promptsLoaded: true })
    }

    private refreshNotifications = async () => {
        this.fetchPrompts()
    }

    private async fetchPrompts(search?: any) {
        await this.setState({ loading: true })

        try {
            const response: any =
                (await this.promptService.fetchAllByDomainId(
                    this.appState.currentDomain!.id,
                    {
                        pagination: 0,
                        search: search ? search : this.state.query,
                        channels: this.props.listType,
                    },
                    false,
                    '',
                )) || []

            if (response) {
                const tableDtos: PromptTableDataDto[] = response.prompts.map(PromptTableDataDto.fromPromptDto)

                this.setState({
                    currentDomainId: this.appState.currentDomain!.id,
                    allPrompts: tableDtos,
                    visiblePrompts: tableDtos,
                    pageMeta: response.meta,
                })
            }
        } catch (error) {
            console.log(error)
        }

        await this.setState({ loading: false })
    }
}
