import { Singleton } from 'typescript-ioc'
import { ApiCallOptionsWithCustomErrorHandler } from '../types/api-call-options-with-custom-error-handler'
import { AppService } from './app'
import { Container } from 'typescript-ioc/es5'
import * as querystring from 'querystring'
import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import { axiosFetch } from '../config/axios-setup'
import aqe from '@pushly/aqe'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { simpleNotification } from '@pushly/aqe/lib/utils'
import { IApiCallOptions } from '@pushly/aqe/lib/interfaces'
import { HttpMethod } from '@pushly/aqe/lib/enums'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import { InvalidFieldException } from '@pushly/models/lib/exceptions/invalid-field-exception'
import { MissingFieldException } from '@pushly/models/lib/exceptions/missing-field-exception'
import { extractFriendlyErrorMessage } from '../exceptions/helpers'

@Singleton
export class AppMessageTemplateService {
    private appSvc: AppService

    public constructor() {
        this.appSvc = Container.get(AppService)
    }

    public async fetchById(
        domainId: number,
        appMsgTemplateId: number,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<IServiceApiResponse<AppMessageUiTemplate | undefined>> {
        this.handleLoadingAnimation('show', opts)

        let data: AppMessageUiTemplate | undefined
        let cancelled = false
        let ok = false
        let meta = {}

        try {
            const response = await axiosFetch(
                'get',
                {
                    url: this.buildSvcRouteFromBase(domainId, appMsgTemplateId.toString(), opts.query),
                },
                opts.cancellationKey,
            )

            const { data: template, ...responseMeta } = response.data
            data = AppMessageUiTemplate.parseJsonObject(template)
            ok = true
            meta = responseMeta
        } catch (error) {
            const defaultHandler = () => handleResponseErrorMessage(error)
            if (opts.errorHandler) {
                opts.errorHandler(error, defaultHandler)
            } else {
                defaultHandler()
            }

            this.handleLoadingAnimation('hide', opts)
        }

        this.handleLoadingAnimation('hide', opts)

        return { ok, data, meta, cancelled }
    }

    public async fetchAllByDomainId(
        domainId: number,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<IServiceApiResponse<AppMessageUiTemplate[] | undefined>> {
        this.handleLoadingAnimation('show', opts)

        let data: AppMessageUiTemplate[] | undefined
        let cancelled = false
        let ok = false
        let meta = {}
        let error

        try {
            const response = await axiosFetch(
                'get',
                {
                    url: this.buildSvcRouteFromBase(domainId, '', opts.query),
                },
                opts.cancellationKey,
            )

            const { data: templates, ...responseMeta } = response.data
            data = templates.map((t) => AppMessageUiTemplate.parseJsonObject(t))
            ok = true
            meta = responseMeta
        } catch (err) {
            const defaultHandler = () => handleResponseErrorMessage(err)
            if (opts.errorHandler) {
                opts.errorHandler(err, defaultHandler)
            } else {
                defaultHandler()
            }
            error = err
        }

        this.handleLoadingAnimation('hide', opts)

        return { ok, data, meta, cancelled, error }
    }

    public async create(
        domainId: number,
        requestDto: AppMessageUiTemplate,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<IServiceApiResponse<AppMessageUiTemplate | undefined>> {
        this.handleLoadingAnimation('show', opts)

        let data: AppMessageUiTemplate | undefined
        let cancelled = false
        let ok = false
        let meta = {}

        try {
            const response = await axiosFetch(
                'post',
                {
                    url: this.buildSvcRouteFromBase(domainId, '', opts.query),
                    data: requestDto.serialize(),
                },
                opts.cancellationKey,
            )

            const { data: template, ...responseMeta } = response.data
            data = AppMessageUiTemplate.parseJsonObject(template)
            ok = true
            meta = responseMeta

            simpleNotification('success', 'App Message Template successfully created.')
        } catch (error) {
            if (error instanceof InvalidFieldException || MissingFieldException) {
                error = extractFriendlyErrorMessage(error)
            }
            handleResponseErrorMessage(error)
        }

        this.handleLoadingAnimation('hide', opts)

        return { ok, data, meta, cancelled }
    }

    public async put(
        domainId: number,
        appMsgTemplateId: number,
        requestDto: AppMessageUiTemplate,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<IServiceApiResponse<AppMessageUiTemplate | undefined>> {
        this.handleLoadingAnimation('show', opts)

        let data: AppMessageUiTemplate | undefined
        let cancelled = false
        let ok = false
        let meta = {}

        try {
            const response = await axiosFetch(
                'put',
                {
                    url: this.buildSvcRouteFromBase(domainId, appMsgTemplateId.toString(), opts.query),
                    data: requestDto.serialize(),
                },
                opts.cancellationKey,
            )

            const { data: template, ...responseMeta } = response.data
            data = AppMessageUiTemplate.parseJsonObject(template)
            ok = true
            meta = responseMeta

            simpleNotification('success', 'App Message Template successfully updated.')
        } catch (error) {
            if (error instanceof InvalidFieldException || MissingFieldException) {
                error = extractFriendlyErrorMessage(error)
            }
            handleResponseErrorMessage(error)
        }

        this.handleLoadingAnimation('hide', opts)

        return { ok, data, meta, cancelled }
    }

    public async archive(
        domainId: number,
        appMsgTemplateId: number,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<boolean> {
        this.handleLoadingAnimation('show', opts)

        try {
            await axiosFetch(
                HttpMethod.DELETE,
                {
                    url: this.buildSvcRouteFromBase(domainId, appMsgTemplateId.toString(), opts.query),
                },
                opts.cancellationKey,
            )

            simpleNotification('success', 'App Message Template successfully archived.')
            this.handleLoadingAnimation('hide', opts)
            return true
        } catch (err) {
            handleResponseErrorMessage(err, {
                fallbackMessage: 'App Message Template could not be archived at this time.',
            })

            this.handleLoadingAnimation('hide', opts)
            return false
        }
    }

    protected handleLoadingAnimation(state: 'show' | 'hide', options: IApiCallOptions = {}) {
        if (options.showLoadingScreen) {
            if (state === 'show') {
                this.appSvc.setModuleLoading()
            } else {
                this.appSvc.unsetModuleLoading()
            }
        }
    }

    protected buildBaseSvcRoute(domainId: number): string {
        return `${aqe.defaults.publicApiDomain}/v4/domains/${domainId}/app-message-templates`
    }

    protected buildSvcRouteFromBase(domainId: number, route: string = '', query: any = {}): string {
        if (query) query = querystring.stringify(query || {})
        return `${this.buildBaseSvcRoute(domainId)}${route ? `/${route}` : ''}${query ? `?${query}` : ''}`
    }
}
