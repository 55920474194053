import { QuillEditorChangeEvent, CustomQuillEditorToolbarProps } from './types'
import { useCallback, useEffect, useRef, useState } from 'react'
import Quill, { DeltaStatic, Sources } from 'quill'
import { getInputContext } from '../macro-manager/helpers'
import * as React from 'react'
import { generateShortID } from '../campaign-builder/helpers/uid'
import { EmojiPopover } from '../emoji-manager/emoji-popover'
import { MacroPopover } from '../macro-manager/macro-popover'
import { useDebouncer } from '@pushly/aqe/lib/hooks'
import { DEFAULT_MACRO_TRIGGER } from '../macro-manager/constants'
import { MacroOption } from '../macro-manager/types'
import { getMacroOptions } from '../macro-manager/helpers'

export const CustomQuillEditorToolbar = (props: CustomQuillEditorToolbarProps) => {
    const { editorInstanceId, formats } = props

    const quill = useRef<Quill | null>(null)
    const [showMacroOptions, _setShowMacroOptions] = useState<boolean>(false)

    // use a 10ms debounce when setting to prevent flashing
    // during state changes when multiple events trigger
    // simultaneously
    const [debounce] = useDebouncer(120)
    const setShowMacroOptions = useCallback((v: boolean, source: string = 'auto') => {
        debounce(() => {
            _setShowMacroOptions(v)
        })
    }, [])

    const inputContext = useRef(getInputContext(quill.current?.getText(), null, DEFAULT_MACRO_TRIGGER))

    useEffect(() => {
        if (props.quill.current) {
            quill.current = props.quill.current!.getEditor()
        }
    }, [editorInstanceId, props.quill.current])

    // v1+ during text-change detect macro pattern match to open options for selection
    // logic below checks on text change for specific macro pattern {{
    const handleQuillTextChange = useCallback(
        (event: QuillEditorChangeEvent, delta: DeltaStatic) => {
            if (!showMacroOptions) {
                const startSelection = inputContext.current.cursor.cursorPos
                // >= 1 ensures must have min characters to satisfy "{{"
                if (startSelection !== undefined && startSelection >= 1) {
                    // quill auto appends a "\n" to text - we need to remove during pos calculations
                    const text = (quill.current!.getText(startSelection - 3) ?? '').replace(/\n$/, '')
                    const isClose = /}}$/.test(text)
                    // offsetting start index to account for nested captures
                    const startOffset = text.length >= 3 ? 1 : 0
                    const isNestedOpen = /[{]{3,}/.test(text)

                    const isDeleteDelta = !!delta.ops?.find((d) => 'delete' in d)
                    const shouldShow = text.startsWith('{{', startOffset) && !isNestedOpen
                    const shouldHide = isClose || (isDeleteDelta && !isNestedOpen && text !== '{{')

                    if (shouldShow || shouldHide) {
                        setShowMacroOptions(shouldShow, 'txtx')
                    }
                }
            }
        },
        [editorInstanceId, !!quill, showMacroOptions],
    )

    const handleQuillSelectionChange = useCallback(
        (_ev: QuillEditorChangeEvent) => {
            inputContext.current = getInputContext(
                quill.current!.getText(),
                quill.current!.getSelection(true).index,
                DEFAULT_MACRO_TRIGGER,
            )

            // a focus event while already open should ignore inTrigger state
            setShowMacroOptions(inputContext.current.cursor.inTrigger, 'selx')
        },
        [editorInstanceId, showMacroOptions],
    )

    useEffect(() => {
        const editor = quill.current

        if (editor) {
            editor.on(
                'editor-change',
                (
                    name: 'text-change' | 'selection-change',
                    deltaOrRange: any,
                    oldDeltaOrRange: any,
                    _source: Sources,
                ) => {
                    const isFocus = !!deltaOrRange && !oldDeltaOrRange
                    const isBlur = !deltaOrRange && !!oldDeltaOrRange
                    const event: QuillEditorChangeEvent = isBlur ? 'blur' : isFocus ? 'focus' : 'change'

                    switch (true) {
                        case name === 'text-change':
                            handleQuillTextChange(event, deltaOrRange)
                            break

                        case !isBlur:
                            handleQuillSelectionChange(event)
                            break
                    }
                },
            )
        }
    }, [editorInstanceId, showMacroOptions])

    // Emojis
    const emojiComponentRefId = useRef(`emp-${generateShortID()}`)

    const handleEmojiSelection = useCallback(
        (_ev: any, emoji: any) => {
            const editor = quill.current

            if (editor) {
                const selectionStart = editor.getSelection(true)
                editor.insertText(selectionStart.index ?? 0, emoji.emoji, 'user')
                editor.setSelection(selectionStart.index + emoji.emoji.length)
                editor.focus()
            }
        },
        [editorInstanceId],
    )

    // Macros
    const [macroComponentId] = useState(`mp-${generateShortID()}`)

    const macroOptions = getMacroOptions({
        customOptions: props.macroOptions,
        validOptionTypes: [],
    })

    const handleMacroSelection = useCallback(
        (macro: MacroOption) => {
            const editor = quill.current

            if (editor) {
                const [tStart, tEnd] = DEFAULT_MACRO_TRIGGER.split('.')
                const macroValue = `${tStart}${macro.value}${tEnd}`

                const selectionStart = editor.getSelection(true).index || inputContext.current.cursor.cursorPos
                let nextCursorPos = selectionStart + macroValue.length

                const activeMacro = inputContext.current.macro
                if (inputContext.current.cursor.inTrigger && activeMacro) {
                    const currStartPos = activeMacro.startPos
                    // end position should pad end for the closing boundary
                    const currEndPos =
                        activeMacro.endPos !== null
                            ? activeMacro.endPos + tEnd.length
                            : activeMacro.startPos + activeMacro.value.length + tEnd.length

                    // inline macro replacement
                    editor.deleteText(currStartPos, currEndPos - currStartPos, 'user')
                    editor.insertText(currStartPos, macroValue, 'user')
                    nextCursorPos = currStartPos + macroValue.length
                } else {
                    // Macro addition
                    editor.insertText(selectionStart, macroValue, 'user')
                }

                editor.setSelection(nextCursorPos, 0)
                editor.focus()

                setShowMacroOptions(false, 'macx')
            }
        },
        [editorInstanceId, !!quill],
    )

    const handleDocClick = useCallback(
        (value: { showMacroOptions: boolean }, ev: React.MouseEvent<HTMLSpanElement>) => {
            let showOptions = value.showMacroOptions !== undefined ? value.showMacroOptions : true

            const toEl = ev.target as HTMLElement
            if (toEl) {
                // determine if clicked element is inside
                // the current custom editor scope and prevent
                // accidental hide when clicking into trigger
                const masterEl = toEl.closest(`#${editorInstanceId}`)
                if (masterEl && inputContext.current.cursor.inTrigger && !showOptions) {
                    showOptions = true
                }
            }

            return setShowMacroOptions(showOptions, 'docx')
        },
        [editorInstanceId],
    )

    return (
        <div id="toolbar" className="ql-snow custom-quill-toolbar">
            {formats.includes('bold') && <button className="ql-bold"></button>}
            {formats.includes('italic') && <button className="ql-italic"></button>}
            {formats.includes('strike') && <button className="ql-strike"></button>}
            {formats.includes('list') && <button className="ql-list" value="ordered"></button>}
            {formats.includes('bullet') && <button className="ql-list" value="bullet"></button>}
            {formats.includes('indent') && <button className="ql-indent" value="-1"></button>}
            {formats.includes('indent') && <button className="ql-indent" value="+1"></button>}
            {formats.includes('align') && (
                <>
                    <button className="ql-align"></button>
                    <button className="ql-align" value="center"></button>
                    <button className="ql-align" value="right"></button>
                    <button className="ql-align" value="justify"></button>
                </>
            )}
            {formats.includes('direction') && <button className="ql-direction" value="rtl"></button>}
            {formats.includes('color') && <select className="ql-color"></select>}

            {formats.includes('emojis') && (
                <button className="ql-emoji-picker">
                    <EmojiPopover componentId={emojiComponentRefId} onChange={handleEmojiSelection} />
                </button>
            )}

            {formats.includes('macros') && (
                <button className="ql-macro-select">
                    <MacroPopover
                        id={macroComponentId}
                        macros={macroOptions}
                        visible={showMacroOptions}
                        onVisibleChange={setShowMacroOptions}
                        onItemSelect={handleMacroSelection}
                        onDocClick={handleDocClick}
                        inputContext={inputContext.current}
                    />
                </button>
            )}
        </div>
    )
}
