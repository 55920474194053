import { useService } from '@pushly/aqe/lib/hooks'
import { AppMessageService } from '../../services/app-message.service'
import { useAppMessageBuilderContext } from '../../features/app-messages/context'
import { useEffect } from 'react'
import { AppMessageTemplateService } from '../../services/app-message-template.service'
import { DomainDto } from '../../dtos/domain'
import { CustomApiResponseErrorHandler } from '../../types/api-call-options-with-custom-error-handler'
import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { AppMessageBuilderPayload } from './model-extensions'

type AppMessagesLoaderProps = {
    domainId: number
    appMsgId?: number
    channels?: DeliveryChannel[]
    errorHandler?: CustomApiResponseErrorHandler
}

/**
 * AppMessagesLoader responsible for fetching app message resources via passed ID or template ID.
 * Loads ALL templates for current domain and applies them to AppMessageBuilderContext.
 *
 * Current implementation loads single app message or template. Will support multiple loading of AppMessage variants
 * and associated schedules.
 */
export const AppMessagesLoader = ({ domainId, appMsgId, channels, errorHandler }: AppMessagesLoaderProps) => {
    const appMsgSvc = useService(AppMessageService)
    const appMsgTemplateSvc = useService(AppMessageTemplateService)
    const [state, dispatch] = useAppMessageBuilderContext()

    const loadData = async () => {
        const appMessages: any = []
        const templateId = state.selectedTemplateId

        if (appMsgId) {
            const response = await appMsgSvc.fetchAppMessageById(AppMessageBuilderPayload, domainId, appMsgId, {
                query: { includeSchedules: true, builder: true },
                cancellationKey: `amf-am${appMsgId}`,
                errorHandler,
            })

            if (response.ok && response.data) {
                dispatch({
                    type: 'patch',
                    entity: 'details',
                    data: {
                        name: response.data.name,
                        description: response.data.template?.description,
                        domain: { id: domainId } as DomainDto,
                    },
                })
                appMessages.push(response.data)
                dispatch({ type: 'put', entity: 'root', data: { ...state, variants: appMessages } })
            }
        }

        if (templateId) {
            const response = await appMsgTemplateSvc.fetchById(domainId, templateId, {
                cancellationKey: `amtf-amt${templateId}`,
                errorHandler,
            })

            if (response.ok && response.data) {
                if (response.data.name || response.data.description) {
                    dispatch({
                        type: 'patch',
                        entity: 'details',
                        data: {
                            name: response.data.name,
                            description: response.data.description,
                            domain: { id: domainId } as DomainDto,
                        },
                    })
                }

                dispatch({
                    type: 'patch',
                    entity: 'template',
                    data: response.data,
                })
            }
        }

        dispatch({
            type: 'patch',
            entity: 'root',
            data: { loading: false, initialAppMessageStateLoaded: state.type === 'message' && state.templatesLoaded },
        })
    }

    const loadTemplates = async () => {
        const templateId = state.selectedTemplateId

        if (state.type === 'message') {
            dispatch({
                type: 'patch',
                entity: 'root',
                data: { templatesLoaded: false },
            })

            const templatesQuery = {
                channels: (channels ?? []).join(','),
                is_shared: true,
            }

            // when in App Message editor - fetch app message templates
            const response = await appMsgTemplateSvc.fetchAllByDomainId(domainId, {
                query: templatesQuery,
                cancellationKey: `amtfa-d${domainId}`,
            })

            if (response.ok && response.data) {
                const templates = response.data
                dispatch({
                    type: 'patch',
                    entity: 'root',
                    data: { availableTemplates: templates },
                })

                if (templateId) {
                    dispatch({
                        type: 'patch',
                        entity: 'named-template',
                        data: { templateId, usingTemplate: true },
                    })
                }

                dispatch({
                    type: 'patch',
                    entity: 'root',
                    data: { templatesLoaded: true, initialAppMessageStateLoaded: true },
                })
            }
        }
    }

    useEffect(() => {
        loadData()
    }, [domainId, appMsgId, state.selectedTemplateId])

    useEffect(() => {
        loadTemplates()
    }, [domainId, JSON.stringify(channels?.sort())])

    return null
}
