import React from 'react'
import classnames from 'classnames'
import { Drawer } from '@pushly/aqe/lib/components'
import { useService } from '../../hooks/use-service'
import { AppState } from '../../stores/app'
import { NotificationDto } from '../../features/notifications'
import { useLoadableDataState } from '../../hooks/use-loadable-data-state'
import NotificationBuilder from '../notification-builder/notification-builder'
import NotificationBuilderAside from '../notification-builder/elements/notification-builder-aside'
import NotificationBuilderPreview from '../notification-builder/elements/notification-builder-preview'
import { buildSubmittedNotificationPackages } from '../notification-builder/helpers'
import { AppService, NotificationService } from '../../services'
import NotificationBuilderMain from '../notification-builder/elements/notification-builder-main'
import NotificationVariantBuilder from '../notification-builder/elements/notification-variant-builder'
import { useRefEffect } from '../../hooks/use-ref-effect'
import { getCustomMacroFields, getDomainKeywords } from '../../_utils/domain'
import { NotificationBuilderLevel } from '../notification-builder/enums'
import { INotificationBuilderState } from '../notification-builder/interfaces/notification-builder-state.interface'
import { DomainDto } from '../../dtos/domain'
import { getClassNames } from '../../_utils/classnames'
import { noop } from '../../_utils/utils'
import FlagList from '../../structs/flags-list'

interface IProps {
    className?: string
    visible: boolean
    defaultValue?: any
    onSubmit: (notification: any, builder: INotificationBuilderState) => any
    onClose: () => void
}

const parseInitialNotificationFromTemplate = (template: NotificationDto | any, domain: DomainDto) => {
    let res = template

    // backfill default abandoned cart details
    if (!!res && !res.deliverySpec) {
        res.deliverySpec = {
            isSilent: false,
            requireInteraction: true,
            ttlSeconds: domain.defaultNotificationTtlSeconds,
            ttlMetric: 'days',
        }
    }

    return res
}

const FallbackNotificationEditor = React.forwardRef<any, IProps>((props, forwardedRef) => {
    const appState = useService<AppState>(AppState)
    const appService = useService<AppService>(AppService)
    const notifService = useService<NotificationService>(NotificationService)

    const domain = appState.currentDomain!
    const { className, visible, onSubmit, onClose, defaultValue } = props

    const [notifState, setNotifState] = useLoadableDataState<NotificationDto | undefined>({ loading: true })
    const [keywordOptions] = useRefEffect(() => getDomainKeywords(domain.id), [domain.id])
    const [customMacroOptions] = useRefEffect(() => getCustomMacroFields(domain.id), [domain.id])

    // references required for outer drawer submit
    const builderRef = React.useRef<INotificationBuilderState>()
    const buildValidatorRef =
        React.useRef<
            (
                builder: INotificationBuilderState,
                domain: DomainDto,
                flags: FlagList,
                isPreview?: boolean,
            ) => Promise<boolean>
        >()

    // run once on mount
    React.useEffect(() => {
        let notification

        if (defaultValue) {
            notification = parseInitialNotificationFromTemplate(defaultValue, domain)
        }

        setNotifState({
            loading: false,
            data: notification,
        })
    }, [!!defaultValue])

    return (
        <Drawer
            getContainer={appService.getAppContainer}
            className={getClassNames('ddf-fallback-notif-editor')}
            title="Update Fallback Notification"
            placement="right"
            closable={true}
            onSubmit={async () => {
                const builder = builderRef.current!
                const validateSubmit = buildValidatorRef.current!

                const valid = await validateSubmit(builder, domain, appState.flags, true)
                if (valid) {
                    const build = buildSubmittedNotificationPackages(domain, builder, appState.flags)[0]

                    // pass back action updates to action.editor.tsx
                    await onSubmit(build, builder)
                }
            }}
            onClose={onClose}
            visible={visible}
        >
            {notifState.loading ? (
                <></>
            ) : (
                <NotificationBuilder
                    className={classnames(className, 'drawer-mode')}
                    level={NotificationBuilderLevel.DOMAIN}
                    mode={'create'}
                    domain={domain}
                    notifications={[notifState.data!]}
                    defaultSelectedNotifId={notifState.data?.id}
                >
                    {(builderProps) => {
                        const { builder, validateSubmit, variants, onVariantChange, variantManager } = builderProps
                        const activeVariant = variants[builder.selectedVariantIdx]

                        builderRef.current = builder
                        if (!buildValidatorRef.current) {
                            buildValidatorRef.current = validateSubmit
                        }

                        return (
                            <>
                                <NotificationBuilderAside>
                                    <NotificationBuilderPreview
                                        level="domain"
                                        levelId={domain.id}
                                        loading={builder.loading}
                                        domain={domain}
                                        source={activeVariant}
                                        onPreviewRequest={async (type) => {
                                            const valid = await validateSubmit(builder, domain, appState.flags, true)

                                            if (valid) {
                                                const builds = buildSubmittedNotificationPackages(
                                                    domain,
                                                    builder,
                                                    appState.flags,
                                                    type,
                                                )
                                                const previewBuild = builds[builder.selectedVariantIdx]

                                                await notifService.sendNotification(domain.id, previewBuild, {
                                                    cancellationKey: `notif-send-preview`,
                                                })
                                            }
                                        }}
                                    />
                                </NotificationBuilderAside>

                                <NotificationBuilderMain>
                                    <NotificationVariantBuilder
                                        key={activeVariant.getId()}
                                        id={builder.selectedVariantIdx}
                                        domain={domain}
                                        builder={builder}
                                        variant={activeVariant}
                                        manager={variantManager}
                                        hideDelivery={true}
                                        hideImageMacroToggle={true}
                                        onChange={onVariantChange}
                                        keywordOptions={keywordOptions.current}
                                        customMacroOptions={customMacroOptions.current}
                                        onSubmit={noop}
                                    />
                                </NotificationBuilderMain>
                            </>
                        )
                    }}
                </NotificationBuilder>
            )}
        </Drawer>
    )
})

export default FallbackNotificationEditor
