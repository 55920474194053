import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import { RefObject } from 'react'
import { EditorActionRef } from '../../../components/custom-quill-editor/types'
import { AppMessageBuilderState } from '../../../components/app-message-builder/interfaces/app-message-builder-state.interface'
import { getEnumKeys } from '../../../_utils/antd'
import { Position } from '@pushly/models/lib/enums/position'
import { AppMessageTemplateStyle } from '@pushly/models/lib/enums/app-messages/app-message-template-style'
import {
    bannerBodyPlaceholder,
    ctaLabelPlaceholder,
    fullScreenBodyPlaceholder,
    headerPlaceholder,
    imagePlaceholderSource,
    modalBodyPlaceholder,
    overridePlaceholderFontColor,
    titlePlaceholder,
} from './constants'
import { AppMessageActionType } from '@pushly/models/lib/enums/app-messages/app-message-action-type'
import { DEFAULT_CTA_BG_COLOR, DEFAULT_TOGGLEABLE_FG_COLOR } from '../../../components/app-message-builder/constants'
import * as randomstring from 'randomstring'
import {
    AppMessageBuilderPayload,
    AppMessageBuilderSchedulePayload,
} from '../../../components/app-message-builder/model-extensions'
import { StatusType } from '@pushly/models/lib/enums'
import { StatusName } from '@pushly/aquarium-utils/lib/enums'

const AM_CONTENT_FIELDS = ['body', 'image', 'title', 'header', 'ctaPrimary', 'ctaSecondary']

// region Validators
function templateHasAtLeastOneField(template: AppMessageUiTemplate) {
    const requiredFields = AM_CONTENT_FIELDS
    let hasAtLeastOneField = false

    for (const field of requiredFields) {
        if (template?.[field]?.enabled) {
            hasAtLeastOneField = true
            break
        }
    }

    if (!hasAtLeastOneField) {
        throw new Error('At least one content field or button must be present to create a template.')
    }
}

async function validateQuillBodyRef(bodyRef: RefObject<EditorActionRef>) {
    if (bodyRef.current) {
        const empty = await bodyRef.current.isEmpty()
        if (empty) {
            throw new Error('Body should not be empty.')
        }
    }
}

export async function cleanAndValidateMessage(
    state: AppMessageBuilderState,
    message: AppMessageBuilderPayload,
    quillBodyRef?: RefObject<EditorActionRef>,
) {
    let validTemplate = await cleanAndValidateTemplate(state, message.template!, quillBodyRef)
    let cleanedSchedules = cleanSchedulePayloadFields(message.schedules)

    const cleanTemplateName = randomstring.generate({ length: 36 })
    // ensure when creating Message template name is scrambled and isShared is always false regardless of selected template values
    message = message.clone({
        template: validTemplate.clone({ name: cleanTemplateName, isShared: false }),
        schedules: cleanedSchedules,
    })

    return message.validate()
}

export async function cleanAndValidateTemplate(
    state: AppMessageBuilderState,
    template: AppMessageUiTemplate,
    quillBodyRef?: RefObject<EditorActionRef>,
) {
    if (state.usingTemplate && !state.selectedTemplateId) {
        throw new Error('A template must be selected if "Use saved template" is enabled.')
    }
    templateHasAtLeastOneField(template)
    if (quillBodyRef && template.body.enabled) {
        await validateQuillBodyRef(quillBodyRef)
    }
    cleanTemplateTogglableFields(template)

    if (template.image.enabled && template.image.position) {
        const allowedPositions = getEnumKeys(Position, 'string').filter((key) =>
            template.style === AppMessageTemplateStyle.BANNER
                ? key === Position.LEFT || key === Position.RIGHT
                : key === Position.TOP || key === Position.BOTTOM,
        )

        if (!allowedPositions.includes(template.image.position)) {
            throw new Error(`Position must be one of values: ${allowedPositions.join(', ')}`)
        }
    }

    return template.validate()
}
// endregion

// region Payload Cleaners
/**
 * Should remove values from TogglableTextFields or TemplateButtonConfigs when enabled = false
 *
 * @param template
 */
function cleanTemplateTogglableFields(template: AppMessageUiTemplate) {
    const togglableFields = ['body', 'image', 'title', 'header', 'ctaPrimary', 'ctaSecondary']

    for (const field of togglableFields) {
        const textField = template[field]
        if (!textField.enabled) {
            Object.keys(textField).forEach((tf) => {
                if (tf !== 'enabled') {
                    textField[tf] = undefined
                }
            })
        }
    }
}

export function cleanSchedulePayloadFields(schedules: readonly AppMessageBuilderSchedulePayload[]) {
    const newSchedules: AppMessageBuilderSchedulePayload[] = []
    schedules.forEach((schedule, idx) => {
        const cleanedSchedule = schedule.toMutable()
        // Animations
        const enterEnabled = schedule.behavior.displayAnimations.enter.enabled
        if (!enterEnabled) {
            cleanedSchedule.behavior.displayAnimations.enter = schedule.behavior.displayAnimations.enter.clone({
                enabled: false,
                animations: undefined,
                durationMilliseconds: undefined,
            })
        }

        const exitEnabled = schedule.behavior.displayAnimations.exit.enabled
        if (!exitEnabled) {
            cleanedSchedule.behavior.displayAnimations.exit = schedule.behavior.displayAnimations.exit.clone({
                enabled: false,
                animations: undefined,
                durationMilliseconds: undefined,
            })
        }

        // Status - must be handled pre PATCH/PUT as computed status is return from API
        let status = schedule.status

        // SCHEDULED is an ACTIVE status with future date - reset to ACTIVE
        if (status === StatusName.SCHEDULED) {
            cleanedSchedule.statusId = StatusType.ACTIVE
            cleanedSchedule.status = StatusName.ACTIVE
        }

        newSchedules.push(cleanedSchedule)
    })

    return newSchedules
}

// endregion

// region Preview Display
function dirtyPlaceholderFieldsDetected(template: AppMessageUiTemplate) {
    let fieldClean = true

    // tslint:disable-next-line:forin
    for (const field of AM_CONTENT_FIELDS) {
        if (template[field].enabled) {
            switch (field) {
                case 'body':
                    fieldClean = !template.body.value?.replace(tagsRegex, '')
                    break
                case 'title':
                    fieldClean = !template.title.value
                    break
                case 'header':
                    fieldClean = !template.header.value
                    break
                case 'image':
                    fieldClean = !template.image.source
                    break
                case 'ctaPrimary':
                    const pButton = template.ctaPrimary
                    fieldClean =
                        !pButton.label ||
                        pButton.fgColor !== overridePlaceholderFontColor ||
                        pButton.action !== AppMessageActionType.OPEN_URL ||
                        !pButton.url
                    break
                case 'ctaSecondary':
                    const sButton = template.ctaSecondary
                    fieldClean =
                        !sButton.label ||
                        sButton.fgColor !== overridePlaceholderFontColor ||
                        sButton.action !== AppMessageActionType.OPEN_URL ||
                        !sButton.url
            }
            // early exit - don't need to look at other values
            if (!fieldClean) {
                return !fieldClean
            }
        }
    }

    return !fieldClean
}

const tagsRegex = /(<([^>]+)>)/gi
// Preview
export function getAMPreviewConfig(template: AppMessageUiTemplate) {
    const previewTemplate = template.toMutable()

    // only override sample placeholders when no value has been input
    if (!dirtyPlaceholderFieldsDetected(previewTemplate)) {
        if (previewTemplate.image.enabled) {
            previewTemplate.image.source = imagePlaceholderSource
        }

        previewTemplate.title.value = template.title.value || titlePlaceholder
        previewTemplate.title.fgColor =
            template.title.fgColor && template.title.fgColor !== DEFAULT_TOGGLEABLE_FG_COLOR
                ? template.title.fgColor
                : overridePlaceholderFontColor

        previewTemplate.header.value = template.header.value || headerPlaceholder
        previewTemplate.header.fgColor =
            template.header.fgColor && template.header.fgColor !== DEFAULT_TOGGLEABLE_FG_COLOR
                ? template.header.fgColor
                : overridePlaceholderFontColor

        previewTemplate.body.value = !!template.body.value?.replace(tagsRegex, '')
            ? template.body.value
            : previewTemplate.style === AppMessageTemplateStyle.FULLSCREEN
            ? fullScreenBodyPlaceholder
            : previewTemplate.style === AppMessageTemplateStyle.MODAL
            ? modalBodyPlaceholder
            : bannerBodyPlaceholder
        previewTemplate.body.fgColor = overridePlaceholderFontColor

        if (previewTemplate.ctaPrimary.enabled) {
            previewTemplate.ctaPrimary.enabled = true
            previewTemplate.ctaPrimary.action = AppMessageActionType.OPEN_URL
            if (previewTemplate.style !== AppMessageTemplateStyle.BANNER) {
                previewTemplate.ctaPrimary.label = ctaLabelPlaceholder
            }
            previewTemplate.ctaPrimary.bgColor = template.ctaPrimary.bgColor ?? DEFAULT_CTA_BG_COLOR
            previewTemplate.ctaPrimary.fgColor = template.ctaPrimary.fgColor ?? DEFAULT_TOGGLEABLE_FG_COLOR
        }

        if (previewTemplate.style !== AppMessageTemplateStyle.BANNER && previewTemplate.ctaSecondary.enabled) {
            previewTemplate.ctaSecondary.enabled = true
            previewTemplate.ctaSecondary.action = AppMessageActionType.OPEN_URL
            previewTemplate.ctaSecondary.label = ctaLabelPlaceholder
            previewTemplate.ctaSecondary.bgColor = template.ctaSecondary.bgColor ?? DEFAULT_CTA_BG_COLOR
            previewTemplate.ctaSecondary.fgColor = template.ctaSecondary.fgColor ?? DEFAULT_TOGGLEABLE_FG_COLOR
        } else {
            previewTemplate.ctaSecondary.enabled = false
        }
    }

    return previewTemplate
}
