import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { HIGHCHARTS_CHANNEL_COLOR_MAP } from '../../constants'
import { mapDataKeyFromObjectList } from '../../components/channel-breakdown-stats-chart/helpers'
import { HighchartsUtils } from '../../_utils/highcharts-utils'
import Highcharts from 'highcharts'
import { titleCase } from '../../_utils/utils'

type KeyofObjectArray<T> = { [K in keyof T]: string }[keyof T]

type SeriesBuilderOptions<T> = {
    key: KeyofObjectArray<T>
    colorType?: 'primary' | 'secondary'
    nameAppend?: string
} & Highcharts.SeriesOptions

export function buildSeriesByChannel<T>(
    stats: T[],
    availableChannels: DeliveryChannel[],
    seriesBuilder: (stats: any, channel: DeliveryChannel, opts: Highcharts.SeriesOptions) => any,
    builderOptions: SeriesBuilderOptions<T>,
): Highcharts.SeriesOptionsType[] {
    const series: any[] = []
    availableChannels.map((ch) => series.push(seriesBuilder(stats, ch, builderOptions)))

    return series
}

export function buildColumnSeriesForChannel<T>(
    stats: T[],
    channel: DeliveryChannel,
    opts: SeriesBuilderOptions<T>,
): Highcharts.SeriesColumnOptions {
    const name = DeliveryChannel.getLongName(channel)
    return {
        ...opts,
        name: name + (opts.nameAppend ? opts.nameAppend : ''),
        color: HIGHCHARTS_CHANNEL_COLOR_MAP[channel][opts.colorType ?? 'primary'],
        data: mapDataKeyFromObjectList(opts.key, stats, channel),
        tooltip: {
            pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
        },
        type: 'column',
    }
}

export function buildSplineSeriesForChannel(stats: any[], channel: DeliveryChannel): Highcharts.SeriesSplineOptions {
    return {
        type: 'spline',
        name: DeliveryChannel.getLongName(channel) + ' (CTR)',
        data: mapDataKeyFromObjectList('ctr', stats, channel).map((ctr) => parseFloat((ctr || 0).toFixed(2))),
        color: HIGHCHARTS_CHANNEL_COLOR_MAP[channel].secondary,
        yAxis: 1,
        marker: {
            lineWidth: 2,
            fillColor: 'white',
            lineColor: HIGHCHARTS_CHANNEL_COLOR_MAP[channel].secondary,
        },
        tooltip: {
            valueSuffix: '%',
        },
    }
}
