import * as React from 'react'
import autobind from 'autobind-decorator'
import { AsyncButton } from '../async-button/async-button.component'
import { BetterComponent } from '../better-component/better-component'
import { type IRule, type IRuleDefinition } from './interfaces/rule'
import './styles/rule-row.scss'
import { ChannelFieldMeta, ExtendedRuleBuilderField, IRuleBuilderField } from '../rule-builder/rule-builder'
import { RuleRowProperty } from './rule-row-property'
import { RuleRowOperator } from './rule-row-operator'
import { RuleRowValue } from './rule-row-value'
import * as deepEqual from 'react-fast-compare'
import { getFieldOperators, stripDomainIdPattern } from './utils'
import { RuleUiCode } from './enums/rule-ui-code'
import { Tooltip, notification, Tag } from 'antd'
import { simpleNotification, titleCase } from '../../_utils/utils'
import { IRuleValidationResponse } from './interfaces/rule-validation-response'
import { RuleNodeType } from './enums/rule-node-type'
import * as clone from 'clone'
import * as randomstring from 'randomstring'
import { CampaignInput } from './inputs/campaign/campaign.input'
import { CloseCircleOutlined } from '@ant-design/icons'
import { baseOperators } from '../rule-builder/operators'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { APP_VERSION_PROPERTY } from '../segment-builder/segment-builder-fields'

interface IRuleRowProps {
    rule: IRule
    onChange: (rule: IRule) => any
    onRemoveRow: (rule: IRule) => any
    onAddRow: (rule?: IRule) => any
    builderFields: IRuleBuilderField[]

    className?: string
    mode?: 'display' | 'edit'
    label?: 'where' | 'or'
    showCancelAction?: boolean
    showRemoveAction?: boolean
    showAppendAction?: boolean
    autofocus?: boolean
    disabled?: boolean
}

interface IRuleRowState {
    mode: 'display' | 'edit'
    value: Partial<IRuleDefinition>
}

export class RuleRow extends React.Component<IRuleRowProps, IRuleRowState> {
    public readonly defaultClassName = 'sw-v2-rb-rule-row'
    public readonly defaultMode = 'display'
    public ref: any
    public valueRef: any

    public constructor(props: IRuleRowProps) {
        super(props)

        this.state = {
            mode: this.props.mode || this.defaultMode,
            value: {},
        }
    }

    public render(): React.ReactNode {
        if (!this.field) {
            return <></>
        }

        const currentRuleState = this.currentRuleState
        const operatorKey = `${currentRuleState.rule.builderProperty}-operator`
        const valueKey = `${currentRuleState.rule.builderProperty}-value`

        const canShowValueBuilder =
            this.field.ignoreExistenceVisibilityCheck || // if: ignore is true - always show
            !this.isSelectionExistenceOperator(currentRuleState.rule.operator) // else if: not existence operator

        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('label')}>
                        <div className={this.buildClassName('label-connector')} />
                        <div className={this.buildClassName('label-display')}>
                            <span>
                                <Tag>{this.ruleLabel}</Tag>
                            </span>
                        </div>
                    </div>

                    <RuleRowProperty
                        mode={this.currentMode}
                        rule={currentRuleState}
                        builderFields={this.props.builderFields}
                        onChange={this.handlePropertyChange}
                    />

                    {!this.fieldTypeIsCustomType && (
                        <RuleRowOperator
                            key={operatorKey}
                            mode={this.currentMode}
                            rule={currentRuleState}
                            builderFields={this.props.builderFields}
                            onChange={this.handleOperatorChange}
                        />
                    )}

                    {canShowValueBuilder && (
                        <RuleRowValue
                            ref={(el) => (this.valueRef = el)}
                            key={valueKey}
                            mode={this.currentMode}
                            rule={currentRuleState}
                            builderFields={this.props.builderFields}
                            onChange={this.handleValueChange}
                            autofocus={this.props.autofocus}
                        />
                    )}

                    <div className={this.buildClassName('actions')}>
                        <div className={this.buildClassName('actions-wrapper')}>
                            <div className="display-mode">{!this.props.disabled && this.buildDisplayActions()}</div>
                            <div className="edit-mode">{!this.props.disabled && this.buildEditorActions()}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected get ruleLabel(): string {
        return titleCase(this.props.label || 'or')
    }

    protected get currentMode(): 'display' | 'edit' {
        return this.props.mode || this.state.mode || this.defaultMode
    }

    protected get currentRuleState(): IRule {
        return {
            ...this.props.rule,
            rule: {
                ...this.props.rule.rule,
                ...this.state.value,
            },
        }
    }

    protected get isEditMode(): boolean {
        return this.currentMode === 'edit'
    }

    protected get isDisplayMode(): boolean {
        return this.currentMode === 'display'
    }

    protected get showCancelAction(): boolean {
        return this.props.showCancelAction !== false
    }

    protected get showRemoveAction(): boolean {
        return this.props.showRemoveAction !== false
    }

    protected get showAppendAction(): boolean {
        return this.props.showAppendAction === true
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get field(): IRuleBuilderField {
        return this.props.builderFields.find((f) => {
            return (
                f.property === this.currentRuleState.rule.builderProperty ||
                stripDomainIdPattern(f.property) === this.currentRuleState.rule.builderProperty
            )
        })!
    }

    protected get fieldTypeIsCustomType(): boolean {
        return (
            !this.field ||
            !!this.field.render ||
            this.field.type === 'campaign' ||
            this.field.type === 'preferences' ||
            this.field.type === 'custom-location' ||
            this.field.type === 'category-affinity' ||
            this.field.type === 'category-visit'
        )
    }

    protected get isNewRule(): boolean {
        return this.rule.meta && this.rule.meta[RuleUiCode.$ROW_STATE_NEW] === true
    }

    protected buildDisplayActions(): React.ReactNode {
        const actions: React.ReactNode[] = []

        actions.push(this.buildAction('duplicate', this.handleDuplicateRow, undefined, 'copy:filled', 'Duplicate'))

        actions.push(this.buildAction('edit', this.setEditMode, undefined, 'edit:filled', 'Edit'))

        if (this.showRemoveAction) {
            actions.push(this.buildAction('remove', this.handleRemoveRow, undefined, 'minus-circle:filled', 'Delete'))
        }

        if (this.showAppendAction) {
            actions.push(
                this.buildAction('add', this.handleAddRow, undefined, 'plus-circle:filled', 'Add New Condition'),
            )
        }

        return actions
    }

    protected buildEditorActions(): React.ReactNode {
        const actions: React.ReactNode[] = []

        if (this.showCancelAction) {
            actions.push(this.buildAction('cancel', this.handleCancel, 'Cancel'))
        }

        actions.push(this.buildAction('save', this.handleSave, 'Save', undefined, undefined, 'primary'))

        return actions
    }

    protected buildAction(
        action: string,
        handler: Function,
        title?: string,
        icon?: string,
        tooltip?: string,
        type?: string,
    ): React.ReactNode {
        const actionKey = `action-${action}`

        const button = (
            <AsyncButton
                key={actionKey}
                className={`${actionKey} isolate`}
                size="small"
                onClick={handler as any}
                icon={icon}
                type={type as any}
            >
                <span>{title}</span>
            </AsyncButton>
        )

        return tooltip ? (
            <Tooltip key={actionKey} title={tooltip}>
                {button}
            </Tooltip>
        ) : (
            button
        )
    }

    protected getPropertyDefaultOperatorValue(builderProperty: string): any {
        const field = this.props.builderFields.find((f) => f.property === builderProperty)
        return (field!.defaultOperator && field!.defaultOperator!.value) || getFieldOperators(field!)[0].value
    }

    protected getPropertyDefaultValue(builderProperty: string): any {
        let value: any
        const field = this.props.builderFields.find((f) => f.property === builderProperty)

        if (field) {
            if (field.defaultValue !== undefined && field.defaultValue !== null) value = field.defaultValue

            if ((value === undefined || value === null) && !!field.options) value = field.options[0].value

            if ((value === undefined || value === null) && field.type === 'boolean') value = true

            if (field.type === 'campaign')
                value = [
                    {
                        id: randomstring.generate(),
                        conditionType: RuleNodeType.AND,
                        rules: CampaignInput.statusOptions.map((o) => ({
                            id: randomstring.generate(),
                            conditionType: RuleNodeType.OR,
                            rule: {
                                property: 'status',
                                builderProperty: 'status',
                                operator: 'eq',
                                value: o.value,
                            },
                        })),
                    },
                ]

            if (field.type === 'preferences')
                value = [
                    {
                        id: randomstring.generate(),
                        conditionType: RuleNodeType.AND,
                        rules: [],
                    },
                ]
        }

        return value
    }

    protected isSelectionExistenceOperator(selection: string): boolean {
        return selection === 'exists' || selection === 'not_exists'
    }

    protected isSelectionBooleanOperator(selection: string): boolean {
        return selection === 'bool_eq' || selection === 'bool_neq'
    }

    @autobind
    protected async setDisplayMode(): Promise<void> {
        // remove all row notifications
        notification.destroy()

        this.setState({ mode: 'display' })
    }

    @autobind
    protected async setEditMode(): Promise<void> {
        const value = {
            ...this.rule,
            ...this.state.value,
            meta: {
                ...this.rule.meta,
                ...(this.state.value || {}).meta,
                [RuleUiCode.$ROW_STATE_EDIT]: true,
            },
        }

        await this.setState({ mode: 'edit' })

        this.addUiCode(this.currentRuleState, RuleUiCode.$ROW_STATE_EDIT, true)
    }

    @autobind
    protected async handleCancel(): Promise<void> {
        await this.setState({ value: this.rule })

        if (this.isNewRule) {
            this.handleRemoveRow()
        } else {
            this.setDisplayMode()
            this.removeUiCode(this.currentRuleState, [
                RuleUiCode.$ROW_STATE_EDIT,
                RuleUiCode.ROW_STATE_EDIT,
                RuleUiCode.$ROW_SNAPSHOT_ID,
                RuleUiCode.ROW_SNAPSHOT_ID,
            ])
        }
    }

    @autobind
    protected async handlePropertyChange(propertyChange: string, rule: IRule): Promise<void> {
        let meta: any | undefined
        let property: string | undefined = propertyChange

        if (propertyChange === 'custom_profile') {
            meta = { custom: true }
            property = undefined
        } else if (propertyChange.includes('app_version')) {
            // app_version property derivation and channel
            const field = this.props.builderFields.find(
                (f) => f.property === `${propertyChange}`,
            ) as ExtendedRuleBuilderField<ChannelFieldMeta>
            if (!field || !field.data?.channel) {
                throw new Error(`Invalid property detected. ${propertyChange}`)
            }

            property = APP_VERSION_PROPERTY
            meta = {
                channel: field.data.channel,
            }
        }

        const update = {
            ...rule.rule,
            ...this.state.value,
            builderProperty: propertyChange,
            property,
            operator: this.getPropertyDefaultOperatorValue(propertyChange),
            value: this.getPropertyDefaultValue(propertyChange),
            meta,
        }

        await this.setState({ value: update })
    }

    @autobind
    protected async handleOperatorChange(operatorChange: string, rule: IRule, oprConfig: any): Promise<void> {
        const update = {
            ...rule.rule,
            ...this.state.value,
        }

        // force TRUE value if switching TO existence
        if (this.isSelectionExistenceOperator(operatorChange) || this.isSelectionBooleanOperator(oprConfig?._key)) {
            update.value = true
        }
        // remove TRUE value if switching away FROM existence
        else if (this.isSelectionExistenceOperator(update.operator)) {
            update.value = undefined
        }

        update.operator = operatorChange

        await this.setState({ value: update })
    }

    @autobind
    protected async handleValueChange(valueChange: any, rule: IRule): Promise<void> {
        const update = {
            ...rule.rule,
            ...this.state.value,
            value: valueChange,
        }

        // Custom components often use internal
        //  operators and fields. Ensure they are
        //  expanded into the proper update params
        if (typeof valueChange === 'object') {
            if ('field' in valueChange) {
                update.property = valueChange.field
            } else if ('property' in valueChange) {
                update.property = valueChange.property
            }

            if ('operator' in valueChange) {
                update.operator = valueChange.operator
            }

            if ('meta' in valueChange) {
                update.meta = valueChange.meta
            }

            if ('value' in valueChange) {
                update.value = valueChange.value
            }
        }

        await this.setState({ value: update })
        this.addUiCode(this.props.rule, RuleUiCode.$ROW_SNAPSHOT_ID, this.props.rule.id)
    }

    @autobind
    protected handleSave(): void {
        const currentRuleState = this.currentRuleState
        const metaExpression = currentRuleState.rule?.meta?.expression
        if (typeof metaExpression === 'string') {
            currentRuleState.rule.meta.expression = currentRuleState.rule.meta.expression.trim()
        }
        if (this.valueRef && this.valueRef.inputRef && 'validate' in this.valueRef.inputRef) {
            const customValidation = this.valueRef.inputRef.validate()
            if (!customValidation.ok) {
                simpleNotification('error', customValidation.error)
                return
            }
        }

        const validation = this.validate()
        if (!validation.ok) {
            simpleNotification('error', validation.error)
            return
        }

        // Ensure NEW state is removed before comparison
        //  to force a save
        const state = this.removeUiCode(
            this.currentRuleState,
            [RuleUiCode.$ROW_STATE_NEW, RuleUiCode.ROW_STATE_NEW],
            false,
        )

        if (!deepEqual(state, this.props.rule)) {
            state.rule = this.cleanRule(state.rule)
        }

        this.removeUiCode(state, [
            RuleUiCode.$ROW_STATE_EDIT,
            RuleUiCode.ROW_STATE_EDIT,
            RuleUiCode.$ROW_SNAPSHOT_ID,
            RuleUiCode.ROW_SNAPSHOT_ID,
        ])
        this.setDisplayMode()
    }

    // Ensure rule values and properties
    //  are trimmed and clean for exporting
    protected cleanRule(rule: IRuleDefinition): IRuleDefinition {
        rule = clone(rule)

        rule.property = rule.property.trim()

        if (typeof rule.value === 'string') {
            rule.value = rule.value.trim()
        } else if (Array.isArray(rule.value) && typeof rule.value[0] === 'string') {
            rule.value = rule.value.map((v) => v.trim())
        }

        return rule
    }

    protected validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        const state = this.currentRuleState

        const useDefaultValidator = this.field.type !== 'campaign' && this.field.type !== 'preferences'

        if (useDefaultValidator) {
            if (this.isNullOrVoid(state.rule.property) || state.rule.property.toString().trim() === 'profile.') {
                response.ok = false
                response.error = 'Please provide a valid property.'
            } else if (this.isNullOrVoid(state.rule.operator) || state.rule.operator.toString().trim() === '') {
                response.ok = false
                response.error = 'Please select a valid operator.'
            } else if (this.isNullOrVoid(state.rule.value) || state.rule.value.toString().trim() === '') {
                response.ok = false
                response.error = 'Please provide a valid value.'
            }
        }

        return response
    }

    protected isNullOrVoid(value: any): boolean {
        return value === undefined || value === null
    }

    @autobind
    protected async handleDuplicateRow(): Promise<void> {
        this.props.onAddRow(this.currentRuleState)
    }

    @autobind
    protected async handleRemoveRow(): Promise<void> {
        this.props.onRemoveRow(this.currentRuleState)
    }

    @autobind
    protected async handleAddRow(): Promise<void> {
        this.props.onAddRow()
    }

    // Returns a copy of the rule, and emits
    //  if directed, with the added
    //  UI code:value combination
    protected addUiCode(rule: IRule, code: RuleUiCode, value: any, emitChange: boolean = true): IRule {
        rule = clone(rule)

        if (!rule.rule.meta) rule.rule.meta = {}

        if (!(code in rule.rule.meta)) {
            rule.rule.meta[code] = value

            if (emitChange) this.emitChange(rule)
        }

        return rule
    }

    // Returns a copy of the rule, and emits
    //  if directed, without the provided
    //  UI codes
    protected removeUiCode(rule: IRule, code: RuleUiCode | RuleUiCode[], emitChange: boolean = true): IRule {
        rule = clone(rule)

        if (!!rule.rule.meta) {
            const codes = Array.isArray(code) ? code : [code]

            for (const codeKey of codes) {
                delete rule.rule.meta[codeKey]
            }

            if (Object.keys(rule.rule.meta).length === 0) delete rule.rule.meta
        }

        if (emitChange) this.emitChange(rule)

        return rule
    }

    protected emitChange(rule: IRule): void {
        this.props.onChange(rule)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [
            this.defaultClassName,
            `mode-${this.currentMode}`,
            `type-${this.field?.type ?? 'invalid'}`,
        ]

        if (this.props.disabled) classNames.push('disabled')
        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
