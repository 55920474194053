import React from 'react'
import { getClassNames } from '../../_utils/classnames'
import { MACRO_MGR_BASE_CLASSNAME } from './constants'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { MacroSelectOptionGroup } from './macro-select-option-group'
import { MacroSelectOptionsListProps } from './types'

export const MacroSelectOptionsList = ({ macros, loading, ...props }: MacroSelectOptionsListProps) => {
    return (
        <ul className={getClassNames(`${MACRO_MGR_BASE_CLASSNAME}-item-list`)}>
            {loading && (
                <li className={getClassNames(`${MACRO_MGR_BASE_CLASSNAME}-group`)}>
                    <span className="heading">
                        <Loading3QuartersOutlined spin={true} />
                        <span> Loading</span>
                    </span>
                </li>
            )}
            {macros.map((group, idx) => (
                <MacroSelectOptionGroup key={idx} group={group} {...props} />
            ))}
        </ul>
    )
}
