import * as React from 'react'
import * as clone from 'clone'
import autobind from 'autobind-decorator'
import * as deepEqual from 'fast-deep-equal'
import * as randomstring from 'randomstring'
import { baseOperators } from '../rule-builder/operators'
import { arrayContains, convertCase } from '../../_utils/utils'
import { type IRule } from './interfaces/rule'
import { RuleGroup } from './rule-group'
import { RuleNodeType } from './enums/rule-node-type'
import { type IRuleBuilderField } from '../rule-builder/rule-builder'
import './styles/rule-builder.scss'
import { type IRuleGroup } from './interfaces/rule-group'
import { getFieldOperators, getHashId, stripDomainIdPattern } from './utils'
import { RuleUiCode } from './enums/rule-ui-code'
import { StarFilled } from '@ant-design/icons'
import { Tag } from 'antd'
import JSONPretty from 'react-json-pretty'
import { hasKey } from '../../_utils/object'
import { Container } from 'typescript-ioc/es5'
import { AppState } from '../../stores/app'

interface IRuleBuilderV2Props {
    value: any
    fields: IRuleBuilderField[]
    onChange: (rules: any) => any

    className?: string
    onValidation?: (data: { ok: boolean }) => any
    // TODO: implement
    disabled?: boolean
    showCode?: boolean
    //
}

interface IRuleBuilderV2State {
    loadValidated: boolean
}

export class RuleBuilderV2 extends React.Component<IRuleBuilderV2Props, IRuleBuilderV2State> {
    public state: IRuleBuilderV2State = {
        loadValidated: false,
    }

    public defaultClassName = 'sw-v2-rb'

    private ref: any
    private appState: AppState

    constructor(props: IRuleBuilderV2Props) {
        super(props)

        this.appState = Container.get(AppState)
        // Run once on initialize to ensure proper view
        this.validateDocumentPropertyNames()
    }

    /**
     * React.PureComponent shallow compare does not work here as we are passing function props
     *   onChange
     *   onValidation
     *   fields[].render
     * comparison of function props returns not-equal
     * Using manual comparison to ensure component does not update unless props or state have changed
     *
     * NOTE: React warns against using JSON.stringify for performance issues - using fast-deep-equal
     * https://reactjs.org/docs/react-component.html#shouldcomponentupdate
     * React notes that the current behavior may change in future releases
     */
    public shouldComponentUpdate(nextProps: IRuleBuilderV2Props, nextState: IRuleBuilderV2State) {
        const { onChange: _npOC, onValidation: _npOV, fields: npFields, ..._npCompare } = nextProps
        const { onChange: _cpOC, onValidation: _cpOV, fields: cpFields, ..._cpCompare } = this.props

        const npCompare = _npCompare as any
        const cpCompare = _cpCompare as any

        npCompare.totalFields = npFields?.length ?? 0
        cpCompare.totalFields = cpFields?.length ?? 0

        const propsChanged = !deepEqual(npCompare, cpCompare)
        const stateChanged = this.state.loadValidated !== nextState.loadValidated

        return propsChanged || stateChanged
    }

    public render(): React.ReactNode {
        return this.props.showCode ? (
            this.renderCodeView()
        ) : (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('content')}>
                        <div className={this.buildClassName('groups-wrapper')}>
                            <div className={this.buildClassName('groups-connector')} />
                            <div className={this.buildClassName('groups-starter')}>
                                <div className={this.buildClassName('groups-starter-wrapper')}>
                                    <div className={this.buildClassName('groups-starter-content')}>
                                        <Tag>
                                            <StarFilled />
                                            All of the following are true
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                            <div className={this.buildClassName('groups-list')}>
                                {!this.props.showCode && this.renderGroups()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    public renderCodeView(): React.ReactNode {
        return (
            <div className="code-snippet-wrapper">
                {this.props.showCode && <h4>Segment contains non-standard rules</h4>}

                <pre className="code-snippet">
                    <JSONPretty json={this.props.value} />
                </pre>
            </div>
        )
    }

    public renderGroups(): React.ReactNode {
        const groups = this.currentRules
        const finalGroupIdx = groups.length - 1

        return groups.map((group, idx) => {
            let trueFirstRow: boolean = false
            if (idx === 0 && group.rules.length === 1) {
                const lastRule = group.rules[group.rules.length - 1]
                if (!!lastRule.rule.meta && !!lastRule.rule.meta[RuleUiCode.$ROW_STATE_NEW]) {
                    trueFirstRow = true
                }
            }

            return (
                <RuleGroup
                    key={`group-${idx}`}
                    className={idx === 0 ? 'group__first' : idx === finalGroupIdx ? 'group__last' : undefined}
                    group={group}
                    builderFields={this.props.fields}
                    onChange={this.handleGroupChange}
                    onAddGroup={this.handleGroupAdd}
                    onRemoveGroup={this.handleGroupRemove}
                    showActivator={idx === finalGroupIdx}
                    autofocus={!trueFirstRow}
                    disabled={this.props.disabled}
                />
            )
        })
    }

    // Runs a simple check for any open state UI codes
    public validate(): { ok: boolean; error?: string } {
        const response: any = {
            ok: true,
        }

        const rules = this.currentRules
        const includesUiCode = (ruleSet, code) => ruleSet.some((s) => s.rules.some((r) => hasKey(r.rule, code, true)))

        if (
            includesUiCode(rules, RuleUiCode.$ROW_STATE_NEW) ||
            includesUiCode(rules, RuleUiCode.ROW_STATE_NEW) ||
            includesUiCode(rules, RuleUiCode.$ROW_STATE_EDIT) ||
            includesUiCode(rules, RuleUiCode.ROW_STATE_EDIT)
        ) {
            response.ok = false
            response.error = 'Please finalize all fields before saving.'
        }

        return response
    }

    // Returns a new copy of the document without
    //  any document codes or new rows
    //  primary use case: comparing input document with output
    public getCleanDocument(doc: any): any {
        const rules = this.buildRulesFromDocument(clone(doc), true, true)
        return this.rebuildDocumentFromRules(rules, true)
    }

    // Ensures that all properties in the document
    //  are members of the passed configuration
    public validateDocumentPropertyNames(rules?: any, emit?: boolean) {
        let valid = true

        try {
            rules = this.buildRulesFromDocument(rules) ?? this.currentRules
            rules.forEach((group: any) => {
                group.rules.forEach((rule: IRule) => {
                    const prop = this.props.fields.find((f) => {
                        return (
                            f.property === rule.rule.builderProperty ||
                            stripDomainIdPattern(f.property) === rule.rule.builderProperty
                        )
                    })

                    if (!prop) {
                        throw new Error('Invalid Rule Property')
                    }
                })
            })
        } catch {
            valid = false
        }

        if (this.props.onValidation && emit !== false) {
            this.props.onValidation({ ok: valid })
        }

        return valid
    }

    public purgeInvalidDocumentProperties() {
        let rules = this.currentRules

        rules.forEach((group: any) => {
            group.rules.forEach((rule: IRule, ruleIdx: number) => {
                const prop = this.props.fields.find((f) => {
                    return (
                        f.property === rule.rule.builderProperty ||
                        stripDomainIdPattern(f.property) === rule.rule.builderProperty
                    )
                })

                if (!prop) {
                    group.rules.splice(ruleIdx, 1)
                }
            })
        })

        this.emitChange(rules.filter((group: any) => group.rules.length))
    }

    protected get currentRules(): IRuleGroup[] {
        return this.buildRulesFromDocument(this.props.value)
    }

    @autobind
    protected handleGroupChange(group: IRuleGroup): void {
        const groups = this.currentRules

        const affectedGroupIndex = groups.findIndex((g) => g.id === group.id)
        if (affectedGroupIndex === undefined) {
            groups.push(group)
        } else {
            groups.splice(affectedGroupIndex, 1, group)
        }

        this.emitChange(groups)
    }

    @autobind
    protected handleGroupAdd(): void {
        const groups = this.currentRules

        groups.push(this.buildDefaultGroup())

        this.emitChange(groups)
    }

    @autobind
    protected handleGroupRemove(group: IRuleGroup, source: 'group' | 'row'): void {
        const groups = this.currentRules
        const affectedGroupIdx = groups.findIndex((g) => g.id === group.id)

        if (affectedGroupIdx !== -1) {
            // We add 1 to the index to remove due to each groups
            //  (AND) handle representing the "and-ing" of the
            //  next group next group in the sequence
            const removeGroupIdx = source === 'group' ? affectedGroupIdx + 1 : affectedGroupIdx

            groups.splice(removeGroupIdx, 1)
        }

        if (groups.length === 0) {
            this.resetGroups()
        } else {
            this.emitChange(groups)
        }
    }

    @autobind
    protected resetGroups(): void {
        this.emitChange([this.buildDefaultGroup()])
    }

    protected emitChange(groups: IRuleGroup[]): void {
        const docUpdate = this.rebuildDocumentFromRules(groups)

        this.props.onChange(clone(docUpdate))
    }

    protected buildDefaultGroup(): IRuleGroup {
        const group: IRuleGroup = {
            id: randomstring.generate(),
            conditionType: RuleNodeType.AND,
            rules: [this.buildDefaultRule()],
        }

        return group
    }

    protected buildDefaultRule(): IRule {
        const defaultField = this.props.fields[0]
        const defaultOperator = getFieldOperators(defaultField)[0]

        const rule: IRule = {
            id: randomstring.generate(),
            conditionType: RuleNodeType.OR,
            rule: {
                property: defaultField.property,
                builderProperty: defaultField.property,
                operator: defaultOperator.value,
                value: undefined,
                meta: {
                    [RuleUiCode.$ROW_STATE_NEW]: true,
                },
            },
        }

        return rule
    }

    protected buildRulesFromDocument(doc: any, stripUiCodes: boolean = false, stripNewRows: boolean = false): any[] {
        let rules: any[] = []
        if (!doc || Object.keys(doc).length === 0) {
            return [this.buildDefaultGroup()]
        }

        // Root node is always a single wrapper AND
        const rootNode = doc[RuleNodeType.AND]
        if (!rootNode) return rules
        rules = this.parseAndNodes(rootNode, stripUiCodes, stripNewRows)

        return rules
    }

    protected parseAndNodes(andNodes: any[], stripUiCodes: boolean = false, stripNewRows: boolean = false): any {
        const groups: any[][] = []

        andNodes.forEach((andNode, idx) => {
            const rules: any[] = this.parseOrNode(andNode[RuleNodeType.OR], stripUiCodes, stripNewRows)
            if (rules.length > 0) {
                const group: any = {
                    conditionType: RuleNodeType.AND,
                    rules,
                }

                group.id = getHashId(this.stripUiCodesFromGroupRules(group, !stripUiCodes), idx.toString())

                groups.push(group)
            }
        })

        return groups
    }

    protected parseOrNode(orNodes: any[], stripUiCodes: boolean = false, stripNewRows: boolean = false): any {
        const rules: any[] = []

        if (!orNodes) return rules

        orNodes.forEach((orNode, idx) => {
            const nodeKeys = Object.keys(orNode)
            const hasAndType = RuleNodeType.AND in orNode

            if (hasAndType) {
                const andRules: any[] = this.parseAndNodes(orNode[RuleNodeType.AND], stripUiCodes, stripNewRows)
                if (andRules.length > 0) {
                    const rule: any = {
                        conditionType: RuleNodeType.AND,
                        rules: andRules,
                    }

                    rule.id = getHashId(rule, idx.toString())

                    rules.push(rule)
                }
            } else {
                if (nodeKeys.length > 0) {
                    const propertyKey = nodeKeys[0]
                    const nodeValue = this.parseNodeValue(orNode, propertyKey)

                    if (!!nodeValue) {
                        if (!stripNewRows || !nodeValue.meta || !(RuleUiCode.$ROW_STATE_NEW in nodeValue.meta)) {
                            const rule: any = {
                                conditionType: RuleNodeType.OR,
                                rule: nodeValue,
                            }

                            if (!!rule.rule.meta && RuleUiCode.$ROW_SNAPSHOT_ID in rule.rule.meta) {
                                rule.id = rule.rule.meta[RuleUiCode.$ROW_SNAPSHOT_ID]
                            } else {
                                rule.id = getHashId(this.stripUiCodesFromRule(rule, !stripUiCodes), idx.toString())
                            }

                            try {
                                const field = this.props.fields.find((f) => f.property === rule.rule.builderProperty)
                                if (field) {
                                    if (!!field.ruleTransformIn) {
                                        rule.rule = field.ruleTransformIn(rule.rule, field)
                                    }
                                }
                            } catch (_err) {}

                            rules.push(rule)
                        }
                    }
                }
            }
        })

        return rules
    }

    protected parseNodeValue(node: any, propertyKey: string): any {
        const nodeValue: any = {
            property: propertyKey,
            builderProperty: propertyKey,
        } as any

        const unwrappedNode = node[propertyKey]
        const validOperatorKeys = Object.keys(baseOperators).map((op) => baseOperators[op].value)

        if ('meta' in unwrappedNode && Object.keys(unwrappedNode.meta).length > 0) {
            nodeValue.meta = unwrappedNode.meta

            if (nodeValue.meta.custom) {
                nodeValue.builderProperty = 'custom_profile'
            }

            if (nodeValue.meta.channel) {
                nodeValue.builderProperty = `${propertyKey}.${nodeValue.meta.channel.toLowerCase()}`
            }
        }

        const nodeKeys = Object.keys(unwrappedNode).filter((k) => k !== 'meta')
        let operatorKey = nodeKeys[0]

        const field = this.props.fields.find((f) => f.property === nodeValue.builderProperty)
        let fieldOperators: any[] = []
        if (field && !operatorKey) {
            fieldOperators = getFieldOperators(field!) ?? []
            operatorKey = fieldOperators[0]?.value
        }

        if (propertyKey === 'campaigns') {
            nodeValue.value = this.parseAndNodes(unwrappedNode[RuleNodeType.AND])

            return nodeValue
        } else if (propertyKey === 'preferences') {
            nodeValue.value = this.parseAndNodes(unwrappedNode[RuleNodeType.AND])

            return nodeValue
        } else {
            if (arrayContains(validOperatorKeys, operatorKey)) {
                nodeValue.operator = operatorKey
                nodeValue.value = unwrappedNode[operatorKey]

                return nodeValue
            }
        }
    }

    protected rebuildDocumentFromRules(rules: any[], removePartials: boolean = false): any {
        const doc = {}

        const value = this.rebuildAndNodes(rules, removePartials)
        if (!removePartials || value.length > 0) {
            // Root node is always a single wrapper AND
            doc[RuleNodeType.AND] = value
        }

        return doc
    }

    protected rebuildAndNodes(rules: any[], removePartials: boolean = false): any[] {
        const nodes: any[] = []

        for (const rule of rules) {
            const value = this.rebuildOrNode(rule.rules, removePartials)
            if (!removePartials || value.length > 0) {
                const node = {
                    [RuleNodeType.OR]: value,
                }

                nodes.push(node)
            }
        }

        return nodes
    }

    protected rebuildOrNode(rules: any[], removePartials: boolean = false): any[] {
        let nodes: any[] = []

        for (const rule of rules) {
            if (rule.conditionType === RuleNodeType.AND) {
                nodes = this.rebuildAndNodes(rule.rules, removePartials)
            } else if (rule.conditionType === RuleNodeType.OR) {
                const ruleDoc = rule.rule

                let node: any = {
                    [ruleDoc.property]: {
                        [ruleDoc.operator]: ruleDoc.value,
                    },
                }

                if (ruleDoc.property === 'campaigns')
                    node[ruleDoc.property] = this.rebuildCampaignNode(ruleDoc.value, removePartials)

                if (ruleDoc.property === 'preferences')
                    node[ruleDoc.property] = this.rebuildSubscriberPreferenceNode(ruleDoc.value, removePartials)

                if (!removePartials || (ruleDoc.value !== undefined && ruleDoc.value !== null)) {
                    if (ruleDoc.meta !== undefined && Object.keys(ruleDoc.meta).length > 0)
                        node[ruleDoc.property].meta = ruleDoc.meta

                    try {
                        const field = this.props.fields.find((f) => f.property === ruleDoc.builderProperty)
                        if (field) {
                            if (field.ruleTransformOut) {
                                node = field.ruleTransformOut(node, ruleDoc, field)
                            }
                        }
                    } catch (_err) {}

                    nodes.push(node)
                }
            }
        }

        return nodes
    }

    protected rebuildCampaignNode(value: IRuleGroup[], removePartials: boolean = false): any {
        const idGroup = value.find((g) => !!g.rules.find((r) => r.rule.property === 'id'))
        const nodeType = !!idGroup ? idGroup.conditionType : RuleNodeType.AND

        return {
            [nodeType]: this.rebuildAndNodes(value, removePartials),
        }
    }

    protected rebuildSubscriberPreferenceNode(value: IRuleGroup[], removePartials: boolean = false): any {
        return {
            [RuleNodeType.AND]: this.rebuildAndNodes(value, removePartials),
        }
    }

    protected stripUiCodesFromGroupRules(group: IRuleGroup, returnCopy: boolean = false): IRuleGroup {
        const cleanGroup = returnCopy ? clone(group) : group

        if (!!cleanGroup.rules) {
            cleanGroup.rules.forEach((rule, idx) => {
                cleanGroup[idx] = this.stripUiCodesFromRule(rule)
            })
        }

        return cleanGroup
    }

    protected stripUiCodesFromRule(rule: IRule, returnCopy: boolean = false): IRule {
        const cleanRule = returnCopy ? clone(rule) : rule

        if (!!cleanRule.rule && !!cleanRule.rule.meta) {
            for (const enumKey of Object.keys(RuleUiCode)) {
                const codeKey = RuleUiCode[enumKey]

                if (codeKey in cleanRule.rule.meta) {
                    delete cleanRule.rule.meta[codeKey]
                }
            }

            if (Object.keys(cleanRule.rule.meta).length === 0) {
                delete cleanRule.rule.meta
            }
        }

        return cleanRule
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.props.disabled) classNames.push('disabled')
        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
