import React, { ReactNode } from 'react'
import { Well } from '../well/well'
import { Status } from './status'
import { StatusName } from '@pushly/aquarium-utils/lib/enums'
import * as numeral from 'numeral'
import './styles/stats-well.scss'
import { numberWithCommas, numberWithPercent } from '../../_utils/utils'

type StatsWellProps = {
    loading: boolean
    status: {
        id: number
        name: StatusName
    }
    impressions?: number
    clicks?: number
    ctrDecimal?: number
    actionStats?: ActionStats[]
    custom?: () => ReactNode
}

type ActionStats = {
    ordinal: 0 | 1
    clicks: number
}

export const StatsWell = (props: StatsWellProps) => {
    const { loading, status, impressions, clicks, ctrDecimal, actionStats, custom } = props
    const actions = actionStats ?? []
    const hasActions = actions.length > 0

    // -------------------- Numeral stat rendering -------------------- \\
    const renderStat = (stat: number | undefined, format: 'number' | 'percentage') => {
        if (!stat && stat !== 0) {
            return '--'
        }

        return numeral(stat).format(format === 'number' ? '0,0' : '0.00%')
    }

    // -------------------- Action rendering -------------------- \\
    actions.sort((a, b) => (a.ordinal < b.ordinal ? 1 : a.ordinal < b.ordinal ? -1 : 0))

    const renderActionGenStats = (action: any, actionIdx: number) => {
        let actionClicks = action.clicks
        let ctr

        if (impressions) {
            ctr = actionClicks / impressions
        }

        return (
            <Well ghost={true} showHeader={false} showFooter={false}>
                <div className="chart-title">
                    <span>{action.ordinal === 0 ? 'Primary' : 'Secondary'} Clicks</span>
                </div>
                <div className="stat stat-clicks">
                    {actionClicks === undefined ? '--' : numberWithCommas(actionClicks)}
                    <div className="stat stat-ctr sub-stat">({!ctr ? '--' : numberWithPercent(ctr)})</div>
                </div>
            </Well>
        )
    }

    return (
        <Well className="thin gen-stats-well nested" loading={loading} showHeader={false} showFooter={false}>
            <Well ghost={true} showHeader={false} showFooter={false}>
                <div className="chart-title">Status</div>
                <div className="stat stat-status">
                    <Status statusText={status.name} />
                </div>
            </Well>
            <Well ghost={true} showHeader={false} showFooter={false}>
                <div className="chart-title">Impressions</div>
                <div className="stat stat-impressions">{renderStat(impressions, 'number')}</div>
            </Well>
            <Well ghost={true} showHeader={false} showFooter={false}>
                <div className="chart-title">{hasActions && 'Total '}Clicks (CTR)</div>
                <div className="stat stat-clicks">
                    {renderStat(clicks, 'number')}
                    <div className="stat stat-ctr sub-stat">({renderStat(ctrDecimal, 'percentage')})</div>
                </div>
            </Well>
            <>{custom && custom()}</>
            {hasActions && (
                <>
                    {actions.map((action, idx) => (
                        <React.Fragment key={idx}>{renderActionGenStats(action, idx)}</React.Fragment>
                    ))}
                </>
            )}
        </Well>
    )
}
