import { StatusType } from '../../enums/status-type'
import { titleCase } from '../../_utils/utils'
import * as React from 'react'
import { StatusName } from '@pushly/aquarium-utils/lib/enums'

export const Status = (props: { statusText: StatusName }) => {
    let statusText = props.statusText
    let statusId
    const statusLookup = StatusType[statusText]
    if (statusLookup) {
        statusId = statusLookup.id
    }

    // Delivered
    let color: string = 'green'

    switch (statusId) {
        case StatusType.SCHEDULED.id:
        case StatusType.SCHEDULING.id:
            statusText = StatusName.SCHEDULED
            color = 'purple'
            break
        case StatusType.QUEUED.id:
        case StatusType.DELIVERING.id:
            color = 'orange'
            break
        case StatusType.FAILED.id:
            color = 'red'
            break
        case StatusType.PAUSED.id:
            color = 'gray'
            break
        case -1:
            color = 'volcano'
            break
    }

    return <div className={`color-${color}`}>{titleCase(statusText)}</div>
}
