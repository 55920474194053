import { sidebarModulePathRootMap } from './constants'

/**
 * Parses the module path root using a given location.pathname
 *
 * eg:
 *  Path resolution:
 *      /domains/12345/notifications/56789/details
 *      -> domains|notifications|details
 *      -> notifications|details
 *      -> notifications
 *
 *      /domains/12345
 *      -> domains
 *
 *  Map resolution: (modulePathRootMap)
 *      notifications -> { notifications: ['notifications'] } -> 'notifications'
 *      domains -> { settings: ['domains', 'organizations'] } -> 'settings'
 */
export const getSidebarPathModule = (path: string): string | undefined => {
    const mod =
        path
            .trim()
            .toLowerCase()
            .replace(/(^\/+)|(\/+$)/g, '')
            .replace(/\/[\d]+/g, '')
            .replace(/\/+/g, '|')
            .replace(/^(domains|organizations)\|/, '')
            .split('|')[0] || 'root'

    return mod in sidebarModulePathRootMap
        ? mod
        : Object.keys(sidebarModulePathRootMap).find((key) => sidebarModulePathRootMap[key].includes(mod))
}
