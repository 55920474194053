import * as React from 'react'
import { BetterComponent } from '../../../components/better-component/better-component'
import { BetterSelect } from '../../../components/better-select/better-select'
import { IFilterBuilderProps } from '../interfaces/filter-builder'
import { getClassNames } from '../../../_utils/classnames'
import './style/app-message-id.filter-builder.scss'
import { AppMessageSearchBar } from '../../../components/app-message-search-bar/app-message-search-bar'
import { AppService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import clone from 'clone'

export class AppMessageIdFilterBuilder extends BetterComponent<IFilterBuilderProps, any> {
    protected appService: AppService

    constructor(props: IFilterBuilderProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render(): React.ReactNode {
        return (
            <div className={getClassNames('filter', 'amid-filter')}>
                <div className={getClassNames('filter-wrapper')}>
                    <div className={getClassNames('filter-display')}>
                        <span className="filter-op">{this.currentOperatorDisplay}</span>
                    </div>
                    <div className={getClassNames('filter-editor')}>
                        <div className="filter-op">
                            <BetterSelect
                                size="small"
                                disableSearch={true}
                                dropdownClassName="filter-op-dropdown"
                                options={[
                                    { value: 'IN', label: 'is' },
                                    { value: 'NOT_IN', label: 'is not' },
                                ]}
                                defaultValue={['IN']}
                                value={this.currentValue.operator}
                                onChange={(operator: string) => {
                                    this.handleChange({ operator })
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-val">
                        <AppMessageSearchBar
                            className="app-message-search"
                            mode={this.props.mode === 'display' ? 'display' : 'search'}
                            value={!this.currentValue.value ? undefined : this.currentValue.value.toString()}
                            placeholder="Search ID or Name"
                            onChange={this.handleAppMessageChange}
                            getDropdownContainer={this.appService.getAppContainer}
                        />
                    </div>
                </div>
            </div>
        )
    }

    protected get currentValue(): any {
        const propsValue: any = this.props.value || {}
        return clone({
            field: 'APP_MESSAGE_ID',
            operator: propsValue.operator || 'IN',
            value: propsValue.value,
        })
    }

    protected get currentOperatorDisplay(): any {
        return this.currentValue.operator === 'IN' ? 'is' : 'is not'
    }

    protected handleAppMessageChange = (appMsgId: number) => {
        this.handleChange({ value: !appMsgId ? undefined : [appMsgId] })
    }

    protected handleChange(config: any): any {
        if (config) {
            this.props.onChange({
                ...this.props.value,
                ...config,
                field: 'APP_MESSAGE_ID',
            })
        } else {
            this.props.onChange()
        }
    }
}
