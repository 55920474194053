import React, { useCallback, useEffect, useState } from 'react'
import { AppMessageDataRecord, AppMessageDataViewProps } from './types'
import { StatusType } from '../../enums/status-type'
import { useDataViewReducer } from '@pushly/aqe/lib/components/data-view/reducer'
import { DataView } from '@pushly/aqe/lib/components/data-view/data-view'
import { DataViewContext } from '@pushly/aqe/lib/components/data-view/context'
import { DataSourceResponse, DataViewProps } from '@pushly/aqe/lib/components/data-view/types'
import { Alert, Modal, Table, Tooltip } from 'antd'
import { ImpressionsColumnView } from '../impressions-column-view/impressions-column-view'
import { ClicksColumnView } from '../clicks-column-view/clicks-column-view'
import { CtrColumnView } from '../ctr-column-view/ctr-column-view'
import { AppMessageListType } from './enums'
import { stripUndefined } from '../../_utils/strip-undefined'
import { ApiVersion } from '../../enums/api-version.enum'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppMessageScheduleService } from '../../services/app-message-schedule.service'
import Icon from '@ant-design/icons'
import AppMessageFilled from '../icons/app-message-filled'
import classnames from 'classnames'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import * as clone from 'clone'
import { arrayUnique } from '../../_utils/utils'
import { isDeliveryChannelEnabled } from '../../_utils/domain'
import { DomainDto } from '../../dtos/domain'
import deepEqual from 'react-fast-compare'
import titleCase from 'title-case'
import { AppMessageRunDatesColumnView } from './app-message-run-dates-column-view'
import { AppMessageActionColumnView } from './app-message-action-column-view'
import { AppMessageDisplayColumnView } from './app-message-display-column-view'
import { AppMessageTemplateService } from '../../services/app-message-template.service'
import { AppMessageTemplateColumnView } from './app-message-template-column-view'
import './styles/app-message-data-view.scss'
import { AppService, DomainService } from '../../services'
import { InsightsService } from '../../services/insights'
import { AppMessageScheduleViewable } from '../app-message-builder/model-extensions'
import { AppState } from '../../stores/app'

interface IChannelFilterOptions {
    value: DeliveryChannel
    label: string
}

interface IAppMessageTableData<T> {
    data: T
    stats: any
}

const STATUS_OPTIONS = [
    { value: StatusType.SCHEDULED.name, label: titleCase(StatusType.SCHEDULED.name) },
    { value: StatusType.ACTIVE.name, label: titleCase(StatusType.ACTIVE.name) },
    { value: StatusType.PAUSED.name, label: titleCase(StatusType.PAUSED.name) },
    { value: StatusType.COMPLETED.name, label: titleCase(StatusType.COMPLETED.name) },
    { value: StatusType.CANCELLED.name, label: titleCase(StatusType.CANCELLED.name) },
]

export const AppMessageDataView = (props: AppMessageDataViewProps) => {
    const defaultStatusOptions = STATUS_OPTIONS.map((o) => o.value)

    const isAppMessageDataView = props.type === AppMessageListType.APP_MESSAGE
    const isTemplateDataView = props.type === AppMessageListType.TEMPLATE

    const appSvc = useService(AppService)
    const domainService = useService(DomainService)
    const appMessageScheduleSvc = useService(AppMessageScheduleService)
    const appMessageTemplateSvc = useService(AppMessageTemplateService)
    const insightsSvc = useService(InsightsService)

    const [context, dispatch, requestDataSourceReload] = useDataViewReducer<AppMessageDataRecord>({
        id: `app-messages-${props.type}`,
        defaultFilters: {
            status: defaultStatusOptions,
        },
        defaultSorting: {
            columnKey: 'scheduleDate',
            order: 'descend',
        },
    })

    const [domain, setDomain]: [DomainDto | undefined, React.Dispatch<React.SetStateAction<DomainDto | undefined>>] =
        useState()
    const [loadingDomain, setLoadingDomain] = useState(false)

    const [showArchiveDialog, setShowArchiveDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState<AppMessageScheduleViewable | null>(null)

    const buildChannelFilterOptions = useCallback((): IChannelFilterOptions[] => {
        const channelFilters: IChannelFilterOptions[] = []

        if (context.levelResource) {
            const iOSChannel = DeliveryChannel.NATIVE_IOS
            const androidChannel = DeliveryChannel.NATIVE_ANDROID

            if (domain) {
                if (isDeliveryChannelEnabled(domain, iOSChannel)) {
                    channelFilters.push({ value: iOSChannel, label: DeliveryChannel.getShortName(iOSChannel) })
                }

                if (isDeliveryChannelEnabled(domain, androidChannel)) {
                    channelFilters.push({ value: androidChannel, label: DeliveryChannel.getShortName(androidChannel) })
                }
            }
        }

        return channelFilters
    }, [context.instanceId, context.levelResource?.id, props.type])

    const [channelFilterOptions, setChannelFilterOptions] = useState(buildChannelFilterOptions())

    const fetchDomain = async () => {
        const { ok, data } = await domainService.fetchById(props.domainId, {
            showLoadingScreen: false,
            cancellationKey: 'amdvd-fetch',
        })

        if (ok) {
            setDomain(data)
        }
    }

    useEffect(() => {
        if (!domain) {
            fetchDomain()
        }
    }, [props.domainId])

    useEffect(() => {
        if (context.levelResource) {
            const nextOptions = buildChannelFilterOptions()
            if (!deepEqual(nextOptions, channelFilterOptions)) {
                setChannelFilterOptions(nextOptions)
            }
        }
    }, [context.instanceId, context.levelResource?.id, loadingDomain])

    const fetchAppMessages: DataViewProps<AppMessageDataRecord>['dataSourceLoader'] = async (filters, tableState) => {
        const channelFilters = Array.from(filters.channels ?? [])

        let statusFilters = Array.from(filters.status?.length ? filters.status : defaultStatusOptions)
        if (isTemplateDataView) {
            // Templates do not use the status filter
            statusFilters = []
        }

        const apiOptions = {
            showLoadingScreen: false,
            cancellationKey: 'app-message-list.fetch',
            query: stripUndefined({
                page: tableState.pagination.current,
                limit: tableState.pagination.pageSize,
                include_segments: 1,
                search: filters.search ?? undefined,
                channels: channelFilters.length ? channelFilters.join(',') : undefined,
                status: statusFilters.length ? statusFilters.join(',') : undefined,
            }),
            version: ApiVersion.V4,
        }

        const res = await appMessageScheduleSvc.fetchAll(props.domainId, apiOptions)
        let dataViewResponse: DataSourceResponse<AppMessageDataRecord<AppMessageScheduleViewable>> = {
            meta: {},
            cancelled: false,
            ...res,
            data: [],
        }

        if (!res.cancelled) {
            if (res.ok && res.data) {
                res.data.forEach((datum) => {
                    dataViewResponse.data.push({
                        data: datum,
                        stats: {},
                    })
                })

                if (dataViewResponse.data.length) {
                    dataViewResponse.data = await fetchStats(dataViewResponse.data)
                }
            } else if (res.error) {
                console.warn(res.error)
            }
        }

        return dataViewResponse
    }

    const fetchAppMessageTemplates: DataViewProps<AppMessageDataRecord>['dataSourceLoader'] = async (
        filters,
        tableState,
    ) => {
        const channelFilters = Array.from(filters.channels ?? [])

        let statusFilters = Array.from(filters.status?.length ? filters.status : defaultStatusOptions)
        if (isTemplateDataView) {
            // Templates do not use the status filter
            statusFilters = []
        }

        const apiOptions = {
            showLoadingScreen: false,
            cancellationKey: 'app-message-list.fetch',
            query: stripUndefined({
                page: tableState.pagination.current,
                limit: tableState.pagination.pageSize,
                include_segments: 1,
                search: filters.search ?? undefined,
                channels: channelFilters.length > 0 ? channelFilters.join(',') : undefined,
                is_shared: 1,
            }),
            version: ApiVersion.V4,
        }

        const res = await appMessageTemplateSvc.fetchAllByDomainId(props.domainId, apiOptions)
        let dataViewResponse: DataSourceResponse<AppMessageDataRecord> = {
            ...res,
            data: [],
        }

        if (!res.cancelled) {
            if (res.ok && res.data) {
                res.data.forEach((datum) => {
                    dataViewResponse.data.push({
                        data: datum,
                        stats: {},
                    })
                })
            } else if (res.error) {
                console.warn(res.error)
            }
        }

        return dataViewResponse
    }

    const fetchStats = async (
        dataSource: AppMessageDataRecord<AppMessageScheduleViewable>[],
    ): Promise<AppMessageDataRecord<AppMessageScheduleViewable>[]> => {
        const dataSourceWithStats = clone(dataSource)

        const domainIds = [props.domainId]
        const appMessageIdFilterField = 'app_message.id'
        const appMessageIds: number[] = arrayUnique(
            dataSource.map((d: IAppMessageTableData<AppMessageScheduleViewable>) => {
                return d.data.message.id
            }),
        )

        const breakdowns: string[] = ['app_message']
        const appMessageFields: string[] = [appMessageIdFilterField]

        const insightsPkg: any = {
            action_attribution: 'event_date',
            entity: 'app_messages',
            date_preset: 'lifetime',
            date_increment: 'lifetime',
            breakdowns,
            fields: [...appMessageFields, 'impressions', 'clicks', 'ctr_decimal'],
            filters: [
                {
                    field: 'domain.id',
                    operator: 'in',
                    value: domainIds,
                },
                {
                    field: appMessageIdFilterField,
                    operator: 'in',
                    value: appMessageIds,
                },
            ],
        }

        const stats = await insightsSvc.fetch(insightsPkg, false, 'aml.stats')

        if (stats?.find) {
            dataSourceWithStats.forEach((datum: AppMessageDataRecord<AppMessageScheduleViewable>) => {
                const stat = stats.find((s: any) => String(s.app_message.id) === String(datum.data.message.id))

                if (stat) {
                    datum.stats.impressions = stat.impressions
                    datum.stats.clicks = stat.clicks
                    datum.stats.ctr_decimal = stat.ctr_decimal
                }
            })
        }

        return dataSourceWithStats
    }

    const handleArchiveClick = useCallback((item) => {
        setShowArchiveDialog(true)
        setSelectedItem(item.props.data)
    }, [])

    const handleArchive = useCallback(async () => {
        handleDismissArchiveDialog()
        if (selectedItem) {
            const { id } = selectedItem

            if (isAppMessageDataView) {
                await appMessageScheduleSvc.archiveAppMessageSchedule(props.domainId, id!)
            } else {
                await appMessageTemplateSvc.archive(props.domainId, id!)
            }

            requestDataSourceReload()
        }
    }, [selectedItem])

    const handleDismissArchiveDialog = useCallback(() => {
        setShowArchiveDialog(false)
        setSelectedItem(null)
    }, [])

    const handleSendIntegrationLinkClick = useCallback(() => {
        const historyState = {
            containerId: 'send-integrations',
            returnTo: window.location.pathname,
        }

        appSvc.routeWithinDomain('#settings', false, historyState)
    }, [])

    return (
        <>
            {props.nativeDisabled && (
                <Alert
                    className="app-message-alert"
                    type="warning"
                    showIcon={true}
                    message=""
                    description={
                        <span>
                            At least one of the Native Apple or Android&nbsp;
                            <a onClick={handleSendIntegrationLinkClick}>Send Integrations</a> must be configured and
                            enabled to create App Messages.
                        </span>
                    }
                />
            )}
            <DataViewContext.Provider value={{ state: context, dispatch }}>
                <DataView<AppMessageDataRecord>
                    dataSourceLoader={isAppMessageDataView ? fetchAppMessages : fetchAppMessageTemplates}
                    hideAutoRefreshOptions={true}
                    filters={[
                        {
                            key: 'search',
                            type: 'search',
                            componentProps: {
                                className: classnames('app-message-list-search'),
                                placeholder: 'Search by ID or Name',
                            },
                        },
                        {
                            key: 'channels',
                            type: 'select',
                            sticky: { agnostic: true },
                            disabled: channelFilterOptions.length <= 1,
                            componentProps: {
                                className: 'channel-filter-select',
                                prefix: 'Channel',
                                placeholder:
                                    channelFilterOptions.length === 1
                                        ? channelFilterOptions[0].label
                                        : 'Select Channels',
                                options: channelFilterOptions,
                                selectAllLabel: `All Channels`,
                                disableSearch: true,
                                closeOnEscape: true,
                                maxDisplayCount: 2,
                                maxDisplayFormatter: (options) => {
                                    const multi = options.length > 1
                                    return (
                                        <Tooltip title={options.map((o) => o.label).join(', ')}>
                                            {options.length} {multi ? 'Channels' : 'Channel'}
                                        </Tooltip>
                                    )
                                },
                            },
                        },
                        {
                            key: 'status',
                            type: 'select',
                            condition: () => isAppMessageDataView,
                            componentProps: {
                                className: 'status-filter-select',
                                prefix: 'Status',
                                placeholder: 'Select Statuses',
                                options: STATUS_OPTIONS,
                                selectAllLabel: 'All Statuses',
                                disableSearch: true,
                                closeOnEscape: true,
                                maxDisplayCount: 2,
                                maxDisplayFormatter: (options) => {
                                    const multi = options.length > 1
                                    return (
                                        <Tooltip title={options.map((o) => o.label).join(', ')}>
                                            {options.length} {multi ? 'Statuses' : 'Status'}
                                        </Tooltip>
                                    )
                                },
                            },
                        },
                    ]}
                    table={{
                        rowKey: (row) => row.data.id!,
                        noData: isAppMessageDataView
                            ? {
                                  text: 'No App Messages',
                                  icon: <Icon component={AppMessageFilled} />,
                              }
                            : {
                                  text: 'No Templates',
                                  icon: <Icon component={AppMessageFilled} />,
                              },
                    }}
                >
                    {isAppMessageDataView && (
                        <Table.Column
                            key="id"
                            className="app-message"
                            dataIndex={['data', 'id']}
                            title="App Message"
                            render={(_, { data }) => <AppMessageDisplayColumnView type={props.type} data={data} />}
                        />
                    )}

                    {isTemplateDataView && (
                        <Table.Column
                            key="id"
                            className="template"
                            dataIndex={['data', 'id']}
                            title="Template"
                            render={(_, { data }) => <AppMessageTemplateColumnView type={props.type} data={data} />}
                        />
                    )}

                    {isAppMessageDataView && (
                        <Table.Column<AppMessageDataRecord<AppMessageScheduleViewable>>
                            key="run-dates"
                            dataIndex={['data', 'runDateStartUtc']}
                            className="run-dates"
                            title="Run Dates"
                            align="center"
                            render={(_, { data }) => (
                                <AppMessageRunDatesColumnView type={props.type} data={data} domain={domain} />
                            )}
                        />
                    )}

                    {isAppMessageDataView && (
                        <Table.Column<AppMessageDataRecord>
                            key="impressions"
                            className="total-impressions"
                            dataIndex={['stats', 'impressions']}
                            title="Impressions"
                            align="center"
                            render={(imps: number, { data }) => {
                                return <ImpressionsColumnView impressions={imps} />
                            }}
                        />
                    )}

                    {isAppMessageDataView && (
                        <Table.Column<AppMessageDataRecord>
                            key="clicks"
                            dataIndex={['stats', 'clicks']}
                            className="total-clicks"
                            title="Clicks"
                            align="center"
                            render={(clicks: number, { data }) => {
                                return <ClicksColumnView clicks={clicks} />
                            }}
                        />
                    )}

                    {isAppMessageDataView && (
                        <Table.Column<AppMessageDataRecord>
                            key="ctr"
                            className="total-clicks"
                            title="CTR"
                            align="center"
                            render={(_, row) => {
                                return <CtrColumnView impressions={row.stats.impressions} clicks={row.stats.clicks} />
                            }}
                        />
                    )}

                    <Table.Column<AppMessageDataRecord>
                        key="actions"
                        className={isAppMessageDataView ? 'app-message-actions' : 'template-actions'}
                        title="Actions"
                        align="right"
                        render={(_, { data }) => (
                            <AppMessageActionColumnView
                                type={props.type}
                                data={data}
                                onArchiveClick={handleArchiveClick}
                                nativeDisabled={props.nativeDisabled}
                            />
                        )}
                    />
                </DataView>

                <Modal
                    visible={showArchiveDialog}
                    destroyOnClose={true}
                    className="app-message-list-archive-dialog"
                    title="Confirm App Message Archival"
                    okText="Archive"
                    onOk={handleArchive}
                    cancelText="Cancel"
                    onCancel={handleDismissArchiveDialog}
                >
                    <div>
                        {isAppMessageDataView ? (
                            <p>
                                This app message will no longer be shown on the App Message List page. If this app
                                message has accrued any stats it will still display in Insights reports.
                            </p>
                        ) : (
                            <p>This template draft will no longer be shown on the Template page.</p>
                        )}
                    </div>
                </Modal>
            </DataViewContext.Provider>
        </>
    )
}
