import React from 'react'

const AppMessageFilled = React.memo((props: { className?: string }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
        viewBox="0 0 1024 1024"
        data-icon="app-message"
        height="1em"
        width="1em"
        fill="currentColor"
        aria-hidden="true"
    >
        <path
            className="st2"
            d="M829.02,0H194.98C157.99,0,128,29.99,128,66.98v890.04c0,36.99,29.99,66.98,66.98,66.98h634.04
		c36.99,0,66.98-29.99,66.98-66.98V66.98C896,29.99,866.01,0,829.02,0z M832,448v451.95c0,33.16-26.88,60.05-60.05,60.05H252.05
		c-33.16,0-60.05-26.88-60.05-60.05V448V124.05C192,90.88,218.88,64,252.05,64h519.91C805.12,64,832,90.88,832,124.05V448z"
        />
        <path
            className="st4"
            d="M771.95,64H252.05C218.88,64,192,90.88,192,124.05V448h640V124.05C832,90.88,805.12,64,771.95,64z"
            style={{ opacity: 0.4 }}
        />
        <path
            className="st2"
            d="M636.92,756.02h-89.83H387.08c-24.26,0-43.99,19.73-43.99,43.99S362.83,844,387.08,844h160.01h89.83
		c24.26,0,43.99-19.73,43.99-43.99S661.17,756.02,636.92,756.02z"
        />
        <path
            className="st2"
            d="M736.01,564H294.17c-24.26,0-43.99,19.74-43.99,44s19.73,44,43.99,44h441.84c24.26,0,43.99-19.74,43.99-44
		S760.27,564,736.01,564z"
        />
    </svg>
))

export default AppMessageFilled
