import {
    AppMessageTemplateButtonConfig,
    MutableAppMessageTemplateButtonConfig,
} from '@pushly/models/lib/structs/app-messages/app-message-template-button-config'
import * as React from 'react'
import { AppMessageCtaBuilder } from './app-message-cta-builder'
import { MutableAppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'
import { CtaButtonType, CtaGroupTuple } from '../types'

type AppMessageCtaBuilderProps = {
    value: MutableAppMessageUiTemplate
    onChange: (change: Partial<AppMessageTemplateButtonConfig>, action: CtaButtonType) => void
    disabled: boolean
}

export const AppMessageCtasBuilder = (props: AppMessageCtaBuilderProps) => {
    const { value, onChange } = props

    const ctaPrimary = value.ctaPrimary ?? new MutableAppMessageTemplateButtonConfig()
    const ctaSecondary = value.ctaSecondary ?? new MutableAppMessageTemplateButtonConfig()

    const ctas: CtaGroupTuple[] = [
        [CtaButtonType.PRIMARY, ctaPrimary],
        [CtaButtonType.SECONDARY, ctaSecondary],
    ]

    return (
        <div className="app-message-ctas">
            {ctas.map((cta, idx) => (
                <AppMessageCtaBuilder
                    key={`cta-${idx}`}
                    type={idx > 0 ? CtaButtonType.SECONDARY : CtaButtonType.PRIMARY}
                    value={cta}
                    rootCtas={ctas}
                    onChange={onChange}
                    disabled={props.disabled}
                    templateStyle={value.style}
                />
            ))}
        </div>
    )
}
