import React from 'react'
import './color-picker-popover.scss'
import { Popover, Switch } from 'antd'
import { CompactPicker, SketchPicker } from 'react-color'
import { preventBubbling } from '@pushly/aqe/lib/utils/prevent'
import { getClassNames } from '../../_utils/classnames'

type Color = string | null | undefined

interface IColorPickerPopoverProps {
    className?: string
    defaultColor?: Color
    color: Color
    onChange: (color: Color) => void
    content?: React.ReactNode
    disabled?: boolean
    picker?: 'sketch' | 'compact'
}

const ColorPickerPopover = (props: IColorPickerPopoverProps) => {
    // @ts-ignore
    const popoverRef = React.createRef<Popover>()
    let { picker } = props

    picker ??= 'compact'

    return (
        <Popover
            ref={popoverRef}
            className={getClassNames(null, props.className, 'color-picker-popover-trigger')}
            overlayClassName={getClassNames(null, 'color-picker-popover', {
                disabled: props.disabled,
            })}
            trigger="click"
            content={
                picker === 'sketch' ? (
                    <SketchPicker
                        disableAlpha={true}
                        presetColors={[]}
                        color={props.color ?? '#FFF'}
                        onChangeComplete={(res) => {
                            const hex = res.hex?.trim()
                            props.onChange(!!hex ? hex : null)
                        }}
                    />
                ) : (
                    <CompactPicker
                        color={props.color ?? '#FFF'}
                        onChangeComplete={(res) => {
                            const hex = res.hex?.trim()
                            props.onChange(!!hex ? hex : null)
                        }}
                    />
                )
            }
        >
            {props.content ? (
                props.content
            ) : (
                <div
                    className="color-picker-popover-preview"
                    style={{
                        backgroundColor: !props.color ? '#FFF' : props.color,
                    }}
                >
                    <div
                        className="meta"
                        onClick={(ev) => {
                            const isVisible = (popoverRef.current as any)?.tooltip?.state?.visible ?? false

                            if (!isVisible && !(ev.target as HTMLElement).classList.contains('ant-switch')) {
                                preventBubbling(ev)
                            }
                        }}
                    >
                        <span className="toggle">
                            <Switch
                                size="small"
                                checked={!!props.color}
                                onChange={(checked) => {
                                    props.onChange(checked ? props.defaultColor ?? '#FFF' : null)
                                }}
                                onClick={(checked, ev) => {
                                    const isVisible = (popoverRef.current as any)?.tooltip?.state?.visible ?? false

                                    if ((!checked && !isVisible) || (checked && isVisible)) {
                                        preventBubbling(ev)
                                    }
                                }}
                            />
                        </span>
                        <span className="color-code">{props.color ?? 'N/A'}</span>
                    </div>
                </div>
            )}
        </Popover>
    )
}

export default ColorPickerPopover
