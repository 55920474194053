/**
 * Map of module menu item component "key"s
 * to all associated UI path roots.
 *
 * The Domain root path resolves to the Settings module
 * so the association would be
 * {
 *   settings: [
 *     'domain'
 *   ],
 * }
 */
export const sidebarModulePathRootMap: Record<string, string[]> = {
    dashboard: ['root', 'dashboard'],
    notifications: ['notifications'],
    appMessages: ['app-messages'],
    campaigns: ['campaigns'],
    segments: ['segments'],
    prompts: ['prompts'],
    insights: ['insights'],
    settings: ['domains', 'organizations', 'settings'],
}
