import * as React from 'react'
import { useEffect, useState } from 'react'
import { getPathEntityId } from '../../../_utils/get-path-entity-id'
import { DomainDto } from '../../../dtos/domain'
import { AppMessageViewable } from '../../../components/app-message-builder/model-extensions'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppMessageService } from '../../../services/app-message.service'
import { DomainService } from '../../../services'
import SimpleTabbedView from '../../../components/simple-tabbed-view/simple-tabbed-view'
import { getClassNames } from '../../../_utils/classnames'
import { DetailsViewPageHeader } from '../../../components/details-view/details-view-page-header'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { WebBadge } from '../../../components/badges/web-badge'
import { NativeIosBadge } from '../../../components/badges/native-ios-badge'
import { NativeAndroidBadge } from '../../../components/badges/native-android-badge'
import { generateShortID } from '../../../components/campaign-builder/helpers/uid'
import { DeliveryDateRangeSlug } from '../../../components/details-view/delivery-date-range-slug'
import { AppMessageDisplayConditionSlug } from './app-message-display-condition-slug'
import { AudienceDetailsSlug } from '../../../components/details-view/audience-details-slug'
import { AppMessageDetails } from './app-message-details'
import './styles/app-message-details.scss'
import { AppMessageBehaviorSlugs } from './app-message-behavior-slugs'

export const AppMessageDetailsView = () => {
    const pathDomainId = getPathEntityId('domain')
    const appMessageId = getPathEntityId('app-message')

    const [domain, setDomain] = useState<DomainDto>()
    const [appMessage, setAppMessage] = useState<AppMessageViewable>()
    const [loading, setLoading] = useState<boolean>(true)

    const appMsgSvc = useService(AppMessageService)
    const domainSvc = useService(DomainService)

    const appMessageDetailsTabs = [
        {
            tabClassName: 'app-message-list-tab',
            component: SimpleTabbedView.wrapComponentTab(AppMessageDetails, {
                tabName: null,
                tabLabel: 'Details',
            }),
            props: () => {
                return {
                    appMessage,
                    domain,
                }
            },
        },
    ]

    useEffect(() => {
        const fetchDependencies = async () => {
            setLoading(true)
            const { ok, data } = await domainSvc.fetchById(pathDomainId!)

            if (ok && data) {
                setDomain(data)
                const { ok: okay, data: appMsg } = await appMsgSvc.fetchAppMessageById(
                    AppMessageViewable,
                    data.id,
                    appMessageId!,
                    {
                        showLoadingScreen: true,
                        query: { include_segments: 1, include_schedules: 1 },
                    },
                )

                if (okay && appMsg) {
                    setAppMessage(appMsg)
                }
            }

            setLoading(false)
        }

        fetchDependencies()
    }, [pathDomainId, appMessageId])

    return (
        <div className={getClassNames('app-message-details')}>
            {!loading && (
                <>
                    <DetailsViewPageHeader
                        className="details-header"
                        title={appMessage!.name}
                        resource={appMessage!}
                        buildSubOptions={() => {
                            const options: any = []
                            const channels = appMessage!.template!.channels
                            channels.includes(DeliveryChannel.WEB) &&
                                options.push(
                                    <span key={DeliveryChannel.WEB} className="option">
                                        <WebBadge />
                                    </span>,
                                )
                            channels.includes(DeliveryChannel.NATIVE_IOS) &&
                                options.push(
                                    <span key={DeliveryChannel.NATIVE_IOS} className="option">
                                        <NativeIosBadge />
                                    </span>,
                                )
                            channels.includes(DeliveryChannel.NATIVE_ANDROID) &&
                                options.push(
                                    <span key={DeliveryChannel.NATIVE_ANDROID} className="option">
                                        <NativeAndroidBadge />
                                    </span>,
                                )

                            if (options.length > 0) options.unshift(<span key={generateShortID()}>|</span>)
                            return options
                        }}
                        addOnAfter={() => {
                            const schedule = appMessage!.schedules![0]

                            return (
                                <>
                                    <DeliveryDateRangeSlug
                                        start={schedule.runDateStartUtc}
                                        end={schedule.runDateEndUtc}
                                        timeZone={schedule.runDateTimeZone}
                                        window={schedule.runDateWindow}
                                    />
                                    <AppMessageDisplayConditionSlug conditions={schedule.conditions!} />
                                    <AppMessageBehaviorSlugs
                                        behavior={schedule.behavior!}
                                        priority={appMessage?.priority!}
                                    />
                                    <AudienceDetailsSlug
                                        segments={schedule.audience.segments}
                                        excludedSegments={schedule.audience.excludedSegments}
                                    />
                                </>
                            )
                        }}
                    />
                    <SimpleTabbedView tabs={appMessageDetailsTabs} animated={false} pathStyle="slash" />
                </>
            )}
        </div>
    )
}
