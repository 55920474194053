import { Expose, Type } from 'class-transformer'
import { IsArray, IsEnum, IsInt, IsObject, IsOptional, IsPositive, ValidateIf, ValidateNested } from 'class-validator'
import { AbstractAppMessageSchedule } from '@pushly/models/lib/structs/app-messages/abstract-app-message-schedule'
import { AbstractAppMessage, AppMessageSchedule } from '@pushly/models/lib/structs'
import { AbstractStruct, MutableStruct } from '@pushly/models/lib/structs/abstract-struct'
import { Mutable } from '@pushly/models/lib/types/mutable'
import { IsNotEmptyString } from '@pushly/models/lib/class-validators/is-not-empty-string'
import { AppMessage } from '@pushly/models/lib/structs/app-messages/app-message'
import { StatusType } from '@pushly/models/lib/enums'
import { AppMessageScheduleWithFullMessage } from '@pushly/models/lib/structs/app-messages/app-message-schedule-with-full-message'

export class AppMessageBuilderSchedulePayload extends AbstractAppMessageSchedule {}
export const MutableAppMessageBuilderSchedulePayload: MutableStruct<AppMessageBuilderSchedulePayload> =
    AppMessageBuilderSchedulePayload
export type MutableAppMessageBuilderSchedulePayload = Mutable<AppMessageBuilderSchedulePayload>

/** Schedules will not have message IDObject or Model during builder flow
 * @Buildable - used in Builder/Editor components for preparation to POST/PATCH/PUT API Call
 */
export class AppMessageBuilderPayload extends AbstractAppMessage {
    @Expose()
    @ValidateNested({ each: true })
    @Type(() => AppMessageBuilderSchedulePayload)
    @IsArray()
    public readonly schedules: ReadonlyArray<AppMessageBuilderSchedulePayload>
}

/**
 * @Viewable - denotes a type of Readonly Struct used in component views rather than builder/editors
 */
export class AppMessageScheduleViewable extends AppMessageScheduleWithFullMessage {
    @Expose()
    @IsEnum(StatusType)
    public readonly computedStatusId!: StatusType

    @Expose()
    @IsNotEmptyString()
    @ValidateIf((schedule) => schedule.computedStatusId !== undefined)
    public readonly computedStatus!: string
}

// @ts-ignore -- ReadonlyArray conflict
export const MutableAppMessageBuilderPayload: MutableStruct<AppMessageBuilderPayload> = AppMessageBuilderPayload
export type MutableAppMessageBuilderPayload = Mutable<AppMessageBuilderPayload>

export class ExposeUserObject extends AbstractStruct {
    @Expose()
    @IsPositive()
    public readonly id!: number

    @Expose()
    @IsNotEmptyString()
    public readonly name!: string
}

export const MutableOwnershipObject: MutableStruct<ExposeUserObject> = ExposeUserObject
export type MutableExposeUserObject = Mutable<ExposeUserObject>

class AppMessageScheduleWithComputedStatus extends AppMessageSchedule {
    @Expose()
    @IsEnum(StatusType)
    public readonly computedStatusId!: StatusType

    @Expose()
    @IsNotEmptyString()
    @ValidateIf((schedule) => schedule.computedStatusId !== undefined)
    public readonly computedStatus!: string
}

export class AppMessageViewable extends AbstractAppMessage {
    @Expose()
    @ValidateNested()
    @Type((_) => ExposeUserObject)
    @IsObject()
    public readonly creator!: ExposeUserObject

    @Expose()
    @ValidateNested()
    @Type((_) => ExposeUserObject)
    @IsObject()
    public readonly updater!: ExposeUserObject

    @Expose()
    @ValidateNested()
    @Type((_) => AppMessageScheduleWithComputedStatus)
    public schedules: AppMessageScheduleWithComputedStatus[]
}
