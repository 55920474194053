import * as React from 'react'
import { BetterComponent } from '../../../components/better-component/better-component'
import { AppState } from '../../../stores/app'
import { AccountService, AppService, CampaignV2Service, DomainService, NotificationService } from '../../../services'
import { Container } from 'typescript-ioc'
import clone from 'clone'
import { CampaignNotificationsComparisonContext } from './campaign-notifications-comparison-context'
import { Well, NotificationSummaryCard } from '@pushly/aqe/lib/components'
import classnames from 'classnames'
import { CampaignNotificationsListHeader } from './campaign-notifications-list-header'
import {
    CampaignNotificationCompTabProps,
    CampaignNotificationCompTabState,
    ICampaignNotificationTableData,
    IChallengerNotificationData,
} from './interfaces'

import './campaign-notifications.scss'
import { Card, Table, Tooltip } from 'antd'
import { AntdTableEmptyPlaceholder } from '../../../components/aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { LoadingOutlined, WifiOutlined } from '@ant-design/icons'
import { TableRowEntityDisplay } from '../../../components/table-row-entity-display/table-row-entity-display'
import { numberWithPercent } from '../../../_utils/utils'
import { InsightsService } from '../../../services/insights'
import { ImpressionsColumnView } from '../../../components/impressions-column-view/impressions-column-view'
import { ClicksColumnView } from '../../../components/clicks-column-view/clicks-column-view'
import { onDomainIdChange } from '../../../_utils/domain'
import { onAccountIdChange } from '../../../_utils/account'
import { stripUndefined } from '../../../_utils/strip-undefined'
import { extractSummaryNotification } from '../../../_utils/notifications'
import { ApiVersion } from '../../../enums/api-version.enum'

type ChallengerNotificationData = IChallengerNotificationData // OrgNotificationModel | NotificationDto;
type TableData = ICampaignNotificationTableData<ChallengerNotificationData> // | ICampaignNotificationTableData<any>;
type IterableTableData = ICampaignNotificationTableData<ChallengerNotificationData>[] // (ICampaignNotificationTableData<OrgNotificationModel> | ICampaignNotificationTableData<NotificationDto>)[];

type Props = CampaignNotificationCompTabProps & {}

type State = CampaignNotificationCompTabState & {
    showCompareValues?: boolean
    compareValues?: any[]
    compareLoading?: boolean
    actionSteps?: any
    showPreview?: boolean
    preview?: any
    drawerKey?: string
}

export class CampaignNotificationsComparisonsTab extends BetterComponent<Props, State> {
    protected static tabName = 'notifications'
    protected static tabLabel = 'Notifications'

    private appState: AppState
    private appService: AppService
    private domainService: DomainService
    private orgService: AccountService
    private notifSvc: NotificationService
    private campaignService: CampaignV2Service

    private insightsService: InsightsService
    private disposeObservers: any[] = []

    public constructor(props: Props) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)
        this.orgService = Container.get(AccountService)
        this.notifSvc = Container.get(NotificationService)
        this.insightsService = Container.get(InsightsService)
        this.campaignService = Container.get(CampaignV2Service)

        this.state = {
            level: this.props.level,
            levelDependenciesLoaded: false,
            org: null!, // loaded on mount,
            domain: null!, // loaded on mount

            notifs: [],
            dataSourceLoaded: false,
            dataSource: [],

            sortedInfo: {
                columnKey: 'id',
                order: 'ascend',
            },

            selectedNotifications: [],
            filters: {},

            refreshing: false,
            refreshEnabled: true,
        }
    }

    public async componentDidMount() {
        let entityIdChangeHandler = this.props.level === 'domain' ? onDomainIdChange : onAccountIdChange
        this.disposeObservers.push(
            entityIdChangeHandler(this.appState, async () => {
                await this.fetchOrganizationalDependencies()
                this.resetList()
            }),
        )

        await this.fetchOrganizationalDependencies()
        await this.fetchNotifications()
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.unmounting = true
        this.disposeObservers.forEach((fn) => fn())
    }

    public render() {
        const {
            levelDependenciesLoaded,
            dataSourceLoaded,
            refreshing,
            // selectedNotifications,
        } = this.state

        const showTableLoadingAnimation = !levelDependenciesLoaded || (!dataSourceLoaded && !refreshing)

        const currDataSource: IterableTableData = this.state.dataSource

        const visibleIds = currDataSource?.map(this.getRowKey)
        const selectedVisibleIds = this.state.selectedNotifications.filter((id) => visibleIds.includes(id))

        const compareValues = this.state.compareValues ?? []
        const compareLoading = this.state.compareLoading ?? false

        return (
            <div className="campaign-notifications-comparison-tab">
                <CampaignNotificationsComparisonContext.Provider
                    value={{
                        ...this.state,
                        onRefreshClick: this.handleRefreshClick,
                        onRefreshEnabledChange: this.handleRefreshEnabledChange,
                        onCompareClick: this.handleCompareClick,
                    }}
                >
                    <Well
                        className={classnames('campaign-notifs-comparisons-list', 'table-well', 'nested')}
                        header={
                            <CampaignNotificationsListHeader
                                title="Campaign Notifications"
                                filterSize="middle"
                                hideRefresh={false}
                                refreshing={this.state.refreshing}
                            />
                        }
                        hideFooter={true}
                    >
                        <div className={classnames('campaign-notifs-content')}>
                            <Table
                                className={classnames('campaign-notifs-list-table')}
                                rowClassName={(r) => {
                                    let rowClassName = 'campaign-notifs-list-row'
                                    if (this.state.selectedNotifications.length === 3) {
                                        if (!this.state.selectedNotifications.includes(this.getRowKey(r))) {
                                            rowClassName = rowClassName + ' disabled'
                                        }
                                    }
                                    return rowClassName
                                }}
                                size="small"
                                sticky={true}
                                dataSource={currDataSource}
                                bordered={true}
                                rowKey={this.getRowKey}
                                pagination={false}
                                rowSelection={
                                    currDataSource.length > 1
                                        ? {
                                              selections: selectedVisibleIds,
                                              selectedRowKeys: selectedVisibleIds,
                                              hideSelectAll: true,
                                              getCheckboxProps: (r: ICampaignNotificationTableData<any>) => {
                                                  if (this.state.selectedNotifications.length === 3) {
                                                      const rowIdx = this.state.selectedNotifications.findIndex(
                                                          (v) => v === this.getRowKey(r),
                                                      )
                                                      return { disabled: rowIdx === -1, className: 'row-disabled' }
                                                  } else return {}
                                              },
                                              onSelect: (r: ICampaignNotificationTableData<any>) =>
                                                  this.handleRowClick()(this.getRowKey(r)),
                                          }
                                        : undefined
                                }
                                onRow={(r) => {
                                    const key = this.getRowKey(r)
                                    return {
                                        onClick: (ev) => this.handleRowClick(ev)(key),
                                    }
                                }}
                                locale={{
                                    emptyText: (
                                        <AntdTableEmptyPlaceholder text="No Notifications" icon={<WifiOutlined />} />
                                    ),
                                }}
                                loading={
                                    showTableLoadingAnimation && {
                                        indicator: <LoadingOutlined spin={true} />,
                                    }
                                }
                            >
                                <Table.Column
                                    key="id"
                                    defaultSortOrder="ascend"
                                    className="title"
                                    dataIndex={['data']}
                                    title="Title"
                                    width={400}
                                    render={this.renderNotificationDisplay}
                                />
                                <Table.Column
                                    key="body"
                                    className="body"
                                    dataIndex={['data']}
                                    title="Body"
                                    width={300}
                                    align="left"
                                    render={this.renderBodyDisplay}
                                />
                                <Table.Column
                                    key="impressions"
                                    className="total-impressions"
                                    dataIndex={['stats', 'impressions']}
                                    title="Impressions"
                                    sorter={(aData, bData) => this.sortStats(aData, bData, 'impressions')}
                                    align="center"
                                    width={120}
                                    render={(imps: number, { data }: TableData) => {
                                        return <ImpressionsColumnView impressions={imps} />
                                    }}
                                />
                                <Table.Column
                                    key="clicks"
                                    className="total-clicks"
                                    dataIndex={['stats', 'clicks']}
                                    title="Clicks"
                                    align="center"
                                    width={120}
                                    sorter={(aData, bData) => this.sortStats(aData, bData, 'clicks')}
                                    render={(clicks: number, { data }: TableData) => {
                                        return <ClicksColumnView clicks={clicks} />
                                    }}
                                />
                                <Table.Column
                                    key="ctr"
                                    className="ctr"
                                    title="CTR"
                                    align="center"
                                    dataIndex={['stats', 'ctr']}
                                    width={120}
                                    sorter={(aData, bData) => this.sortStats(aData, bData, 'ctr')}
                                    render={(ctr: number, { data }: TableData) => {
                                        return <div>{numberWithPercent(ctr)}</div>
                                    }}
                                />
                            </Table>
                        </div>
                    </Well>

                    {this.state.showCompareValues && (
                        <div className="campaign-notifs-compare-previews">
                            {compareLoading ? (
                                <div className="campaign-notifs-compare-previews loading">
                                    {this.state.selectedNotifications.map((v) => (
                                        <Card key={v} className="compare-value-card" />
                                    ))}
                                </div>
                            ) : (
                                compareValues.map((v) => {
                                    return (
                                        <NotificationSummaryCard
                                            key={v.notification.id}
                                            mode="dark"
                                            compactPreview={true}
                                            loading={false}
                                            notification={extractSummaryNotification(v.notification)}
                                            stats={v.stats}
                                            showScheduleDetails={false}
                                            hideStatus={true}
                                            isCampaignNotif={true}
                                            domain={this.appState.currentDomain!}
                                            hideFooter={true}
                                        />
                                    )
                                })
                            )}
                        </div>
                    )}
                </CampaignNotificationsComparisonContext.Provider>
            </div>
        )
    }

    protected async fetchNotifications() {
        // start loading
        await this.setState({
            dataSourceLoaded: false,
        })

        const state: any = {
            ...this.state,
            refreshing: false,
            dataSourceLoaded: true,
        }

        const insightsPackage: any = {
            date_preset: 'lifetime',
            entity: 'notifications',
            date_increment: 'lifetime',
            breakdowns: ['notification'],
            fields: [
                'campaign.id',
                'notification.id',
                'notification.title',
                'notification.body',
                'impressions',
                'clicks',
                'ctr_decimal',
                'notification.source',
            ],
            filters: [
                {
                    field: 'domain.id',
                    operator: 'in',
                    value: [this.appState.currentDomain!.id],
                },
                {
                    field: 'campaign.id',
                    operator: 'IN',
                    value: [this.props.campaign.id],
                },
            ],
        }

        if (this.props.level === 'domain') {
            insightsPackage.action_attribution = 'send_time'
        }

        const stats = await this.insightsService.fetch(insightsPackage, false, 'cnpl.stats', this.props.level === 'org')

        state.dataSource = stats.map((stat) => ({
            data: stat.notification,
            stats: {
                impressions: stat.impressions,
                clicks: stat.clicks,
                ctr: stat.ctr_decimal,
            },
        }))

        await this.setState(state)
    }

    protected async fetchOrganizationalDependencies() {
        let state: any = { levelDependenciesLoaded: false }
        await this.setState({ ...state })

        if (this.props.level === 'domain') {
            const { ok, data } = await this.domainService.fetchById(this.props.domainId)
            if (ok && data) {
                state.domain = data
            }

            state.level = 'domain'
        } else {
            state.org = await this.orgService.fetchById(this.props.orgId)
        }

        state.levelDependenciesLoaded = true
        await this.setState(state)
    }

    protected async resetList() {
        await this.setState({
            refreshing: true,
            selectedNotifications: [],
            showCompareValues: false,
        })

        await this.fetchNotifications()
    }

    protected getRowKey = (row: TableData) => {
        return row.data.id
    }

    protected sortStats({ stats: a }, { stats: b }, stat: string) {
        const aStat = a[stat]
        const bStat = b[stat]

        return aStat > bStat ? 1 : bStat > aStat ? -1 : 0
    }

    protected handleRowClick = (ev?: any) => (key) => {
        let currSelection = [...this.state.selectedNotifications]
        if (currSelection.includes(key)) {
            currSelection = currSelection.filter((id) => id !== key)
        } else {
            currSelection.push(key)
        }

        if (currSelection.length > 3) {
            return
        }

        this.setState({ selectedNotifications: currSelection })
    }

    protected handleRefreshClick = async (_ev: React.MouseEvent<HTMLElement>): Promise<void> => {
        await this.resetList()
    }

    protected handleRefreshEnabledChange = async (refreshEnabled: boolean) => {
        await this.setState({ refreshEnabled })
    }

    protected handleCompareClick = async () => {
        await this.setState({ showCompareValues: true, compareLoading: true })

        let compareValues: TableData[] = []
        const { filters } = this.state

        const compareIds = this.state.selectedNotifications
        const data = clone(this.state.dataSource)

        compareValues = data.filter((datum) => {
            const match = compareIds.includes(this.getRowKey(datum))
            return match && datum.data
        })

        const baseApiOptions = {
            showLoadingScreen: false,
            query: stripUndefined({
                include_segments: true,
                search: filters.search ?? undefined,
            }),
            version: ApiVersion.V4,
        }

        let resolvers: Array<Promise<any>> = []
        if (Array.isArray(compareValues) && compareValues.length > 0) {
            compareValues.map((val) => {
                const notificationId = val.data.id
                const apiOptions = { ...baseApiOptions, cancellationKey: `campaign-notif-list.fetch-${notificationId}` }

                // fetch notifications
                resolvers.push(
                    this.notifSvc.fetchNotificationById(this.appState.currentDomain!.id, notificationId, apiOptions),
                )
            })
        }

        const notifications = await Promise.all(resolvers)

        compareValues = compareValues.map((val) => {
            const notification = notifications.find((notif) => notif.id === val.data.id)
            return {
                ...val,
                notification,
            }
        })

        this.setState({ compareValues, compareLoading: false })
    }

    protected renderNotificationDisplay = (data: any) => {
        return (
            <div className="notification-display-data">
                <TableRowEntityDisplay title={data.title} />
            </div>
        )
    }

    protected renderBodyDisplay = (data: any): React.ReactNode => {
        const body = data.body
        const BodyWrapper = body ? (_) => <Tooltip title={body}>{_.children}</Tooltip> : (_) => <div>{_.children}</div>
        return (
            <BodyWrapper>
                <div className={!body ? '' : 'no-body-span'}>
                    <span>{body ? body : ''}</span>
                </div>
            </BodyWrapper>
        )
    }
}
