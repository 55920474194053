import * as React from 'react'
import { NotificationTemplateListContext } from './notification-template-list-context'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService } from '../../../services'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { NNTDataTableControlOptions } from './interfaces'
import { getClassNames } from '../../../_utils/classnames'
import { Input } from 'antd'

type FilterKey = keyof NNTDataTableControlOptions['filters']

type NotifTemplateListHeader = {
    title?: string
    filterSize?: SizeType
    filtersAddonBefore?: React.ReactNode
    filtersAddonAfter?: React.ReactNode
    hideFilters?: FilterKey | FilterKey[] | boolean
    filtersDisabled?: FilterKey | FilterKey[] | boolean
}

export const NotificationTemplateListHeader = (props) => {
    const context = React.useContext(NotificationTemplateListContext)

    const appSvc = useService(AppService)
    let liveTypeTimeout: any

    const { data, loading, refreshing, filters, onSearchClick, onFilterChange } = context

    const allFilters: FilterKey[] = ['search']

    let filtersHidden: FilterKey[] = []
    if (props.hideFilters === true) {
        filtersHidden = Array.from(allFilters)
    } else if (props.hideFilters) {
        filtersHidden = Array.isArray(props.hideFilters) ? props.hideFilters : [props.hideFilters]
    }

    let filtersDisabled: FilterKey[] = []
    if (props.filtersDisabled === true) {
        filtersDisabled = Array.from(allFilters)
    } else if (props.filtersDisabled) {
        filtersDisabled = Array.isArray(props.filtersDisabled) ? props.filtersDisabled : [props.filtersDisabled]
    }

    const hideAllFilters = filtersHidden.length === allFilters.length

    const isLoading = loading || refreshing

    return (
        <div className={getClassNames('notification-template-list-header', 'table-well', 'nested')}>
            <div
                className={getClassNames('notification-list-title', {
                    hidden: !props.title,
                })}
            >
                <span>{props.title}</span>
            </div>

            <div
                className={getClassNames('notification-list-filters', {
                    hidden: hideAllFilters,
                })}
            >
                {!filtersHidden.includes('search') && (
                    <Input.Search
                        className={getClassNames('notification-list-search')}
                        size={props.filterSize}
                        placeholder="Search by ID or Template Title"
                        disabled={filtersDisabled.includes('search')}
                        enterButton={true}
                        defaultValue={filters.search}
                        onSearch={onSearchClick}
                        onChange={(ev) => {
                            clearTimeout(liveTypeTimeout)

                            const value = ev.target.value
                            liveTypeTimeout = setTimeout(() => {
                                if (value.length > 2 || value === '') {
                                    onFilterChange('search', value)
                                }
                            }, 320)
                        }}
                    />
                )}
            </div>
        </div>
    )
}
