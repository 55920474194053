import * as React from 'react'
import './emoji-manager.scss'
import 'emoji-picker-element'
import { BetterInput } from '../better-input/better-input'
import { Input, Popover } from 'antd'
import { generateShortID } from '../campaign-builder/helpers/uid'
import { getClassNames } from '../../_utils/classnames'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { MacroManager } from '../macro-manager/macro-manager'
import { useListenerEvent } from '../../hooks/use-listener-event'
import { useRefState } from '../../hooks/use-ref-state'
import { EmojiPopover } from './emoji-popover'

const splitter = new GraphemeSplitter()

function fireNativeInputChangeEvent(el, value) {
    const nativeSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')!.set!
    nativeSetter.call(el, value)

    const fauxEvent = new Event('input', { bubbles: true })
    el.dispatchEvent(fauxEvent)
}

/**
 * Returns the actual html input element
 * whether nested or direct
 *
 */
function getInputElement(ref: React.MutableRefObject<any>): HTMLInputElement {
    const hasMacroManager = childHasMacroManager(ref)
    const current = hasMacroManager ? ref.current?.inputComponentRef : ref.current
    const inputConstructor = current?.constructor
    const isHtmlInput = inputConstructor !== Input && inputConstructor !== BetterInput

    return isHtmlInput ? current : current?.input
}

function childHasMacroManager(ref: React.MutableRefObject<any>): boolean {
    return ref.current?.constructor === MacroManager
}

const BASE_CLASSNAME = 'emoji-manager'

export const EmojiManager: React.FunctionComponent<any> = ({ children, hideToggle, disabled }) => {
    const inputRef = React.useRef<any>(undefined)
    const componentId = React.useRef<string>(`em-${generateShortID()}`)
    const [showEmojiOptions, setShowEmojiOptions] = useRefState(false)

    const InputComponent = React.Children.only(children)

    /**
     * Handles focus, keyup, and click events for the
     * inputComponent.
     *
     * Gathers current inputContext and updates state
     *
     */
    const handleInputInteraction = (ev: any) => {
        ev.stopPropagation?.()
        ev.stopImmediatePropagation?.()
        let shouldShowEmojiOptions = false

        try {
            const evType = ev.type
            shouldShowEmojiOptions = evType === 'mousedown' && showEmojiOptions.current
        } catch (err) {
            console.warn('Error computing input state', err)
        }
        setShowEmojiOptions(shouldShowEmojiOptions)
    }
    //
    const handleEmojiSelection = (ev: any, emoji: any) => {
        const element = getInputElement(inputRef)
        if (element) {
            const selectionStart = element.selectionStart ?? 0
            let value = element.value
            value = value || ''
            const splitValue = splitter.splitGraphemes(value)
            let expandedLength = 0
            const insertIdx = splitValue.findIndex((g) => {
                expandedLength += g.length
                return expandedLength >= selectionStart
            })

            splitValue.splice(
                insertIdx === 0 && insertIdx === selectionStart ? insertIdx : insertIdx + 1,
                0,
                emoji.emoji,
            )
            console.debug(value)

            fireNativeInputChangeEvent(element, splitValue.join(''))
            const selection = selectionStart + (emoji.emoji?.length ?? 0)
            element.selectionStart = selection
            element.selectionEnd = selection
            element.focus()
        }
    }

    const inputCallback = React.useCallback(
        (ev: any) => {
            handleInputInteraction(ev)
        },
        [showEmojiOptions.current],
    )

    useListenerEvent('mousedown', inputCallback, getInputElement(inputRef))
    useListenerEvent('keydown', inputCallback, getInputElement(inputRef))

    return (
        <div
            key={componentId.current}
            id={componentId.current}
            className={getClassNames(BASE_CLASSNAME, componentId.current, {
                'overlay-visible': showEmojiOptions.current,
                'hide-toggle': hideToggle,
                disabled,
            })}
        >
            <div className={getClassNames(`${BASE_CLASSNAME}-input-wrapper`)}>
                <InputComponent.type
                    {...InputComponent.props}
                    ref={(el) => {
                        // call initial ref method
                        const { ref } = InputComponent as any
                        if (ref && typeof ref === 'function') {
                            ref(el)
                        }

                        // set internal ref
                        inputRef.current = el
                    }}
                />
                <EmojiPopover componentId={componentId} onChange={handleEmojiSelection} />
            </div>
        </div>
    )
}
