export enum SubjectEntity {
    ORG = 'org',
    DOMAIN = 'domain',
    USER = 'user',
    ORG_NOTIFICATION = 'org_notification',
    NOTIFICATION = 'notification',
    NOTIFICATION_SCHEDULE = 'notification_schedule',
    NOTIFICATION_TEST = 'notification_test',
    CAMPAIGN = 'campaign',
    ORG_SEGMENT = 'org_segment',
    SEGMENT = 'segment',
    PROMPT = 'prompt',
    PROMPT_GROUP = 'prompt_group',
    DOMAIN_INTEGRATION = 'domain_integration',
    FEED = 'feed',
    SAVED_REPORT = 'saved_report',
    CATEGORY_STRATEGY = 'category_strategy',
    APP_MESSAGE = 'app_message',
}
