import React from 'react'
import { TemplateKeywordsField, Well } from '@pushly/aqe/lib/components'
import { Flags } from '@pushly/aquarium-utils/lib/enums/flags'
import { BulbOutlined, GlobalOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Popover, Skeleton, Switch } from 'antd'
import TemplateTitleField from './template-fields/title-field'
import TemplateBodyField from './template-fields/body-field'
import TemplateLandingUrlField from './template-fields/landing-url-field'
import { NotificationContentModel } from '../../../models/notification/notification-content.model'
import TemplateMediaField from './template-fields/media-field'
import { PromiseableProp } from '../../../_utils/promiseable-prop'
import { getClassNames } from '../../../_utils/classnames'
import { DomainDto } from '../../../dtos/domain'
import NotificationActionsBuilder from './notification-actions-builder'
import NotificationExperienceOptions from './notification-experience-options'
import CharacterLimitTooltip from './character-limit-tooltip'
import { NotificationBuilderLevel, NotificationBuilderTheme } from '../enums'
import { useService } from '../../../hooks/use-service'
import { AppState } from '../../../stores/app'
import NotificationFieldSuggestions from '../notification-field-suggestions'
import { CompositeImage, INotificationFieldSuggestions } from '../../../interfaces/notification-field-suggestions'
import { MacroOptionType } from '../../macro-manager/types'
import { NotificationChannelSettings } from './notification-channel-settings'
import { NotificationNativeAndroidContentModel } from '../../../models/notification/notification-native-android-content.model'
import { NotificationNativeIosContentModel } from '../../../models/notification/notification-native-ios-content.model'
import { NotificationDefaultContentModel } from '../../../models/notification/notification-default-content.model'
import { NotificationWebContentModel } from '../../../models/notification/notification-web-content.model'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { SharedContent } from '../../../interfaces/notification-shared-content'

interface INotificationContentBuilder {
    className?: string
    level: NotificationBuilderLevel
    theme: NotificationBuilderTheme
    domain: DomainDto
    loading?: boolean
    title?: string
    value: NotificationContentModel
    channels: DeliveryChannel[]
    onChange: (value: NotificationContentModel) => void
    onClear: () => void
    disabled?: boolean
    experienceDisabled?: boolean
    keywordOptions?: PromiseableProp<any>
    customMacroOptions?: PromiseableProp<any>
    hideMacroToggle?: boolean
    hideTtlSettings?: boolean
}

type ContentUpdate =
    | NotificationDefaultContentModel
    | NotificationWebContentModel
    | NotificationNativeIosContentModel
    | NotificationNativeAndroidContentModel

const SkeletonWrapper = (props: { loading; children }) => (
    <Skeleton loading={props.loading} active={true} avatar={false} title={false}>
        {props.children}
    </Skeleton>
)

const NotificationContentBuilder = (props: INotificationContentBuilder) => {
    const {
        className,
        level,
        domain,
        loading,
        value,
        channels,
        onChange,
        onClear,
        hideMacroToggle,
        hideTtlSettings,
        disabled,
        experienceDisabled,
    } = props

    const appState = useService<AppState>(AppState)
    const [urlToParse, setUrlToParse] = React.useState<string | null>(null)
    const [suggestedImageComposite, setSuggestedImageComposite] = React.useState<CompositeImage | undefined>(undefined)

    const isOrgLevel = level === NotificationBuilderLevel.ORG
    const isDomainLevel = level === NotificationBuilderLevel.DOMAIN
    const isCampaignLevel = level === NotificationBuilderLevel.CAMPAIGN
    const levelClassName = isCampaignLevel ? 'campaign' : isOrgLevel ? 'org' : 'domain'

    const landingUrlMacroTypes = isCampaignLevel
        ? ['domain', 'device', 'location', 'ecomm', 'notification', 'custom']
        : ['domain', 'device', 'location', 'notification', 'custom']

    const messageFieldMacroTypes = isCampaignLevel ? ['location', 'ecomm', 'custom'] : ['location', 'custom']

    const workingModel = value.getDefaultContent().clone()

    // TODO: consider reworking to handle per channel updates more field specifically
    const handleChange = (update: ContentUpdate, channel?: DeliveryChannel) => {
        const change = value.clone()

        switch (channel) {
            case DeliveryChannel.WEB:
                change.setWebContent(update as NotificationWebContentModel)
                break
            case DeliveryChannel.NATIVE_IOS:
                change.setNativeIosContent(update as NotificationNativeIosContentModel)
                break
            case DeliveryChannel.NATIVE_ANDROID:
                change.setNativeAndroidContent(update as NotificationNativeAndroidContentModel)
                break
            default:
                change.setDefaultContent(update as NotificationDefaultContentModel)
        }

        onChange?.(change)
    }

    type SharedChannelProperty = 'Title' | 'Body' | 'IconUrl' | 'ImageUrl'

    // Form Shared Fields will cascade updates to these fields for every channel enabled OR passed
    // "set" method must have same name for this shared logic to work
    // TODO: Handle Landing URL Updates (native alt url vs landing url per channel transition)
    const setSharedChannelField = <F extends SharedChannelProperty>(
        field: F,
        updateValue: any,
        options: {
            prevValue?: NotificationContentModel
            updateChannels?: DeliveryChannel[]
            waitToCompleteChange?: boolean
        } = {},
    ) => {
        const updateChannels = options.updateChannels
        const workingValue = options.prevValue ? options.prevValue : value.clone()

        const resolvedChannels = [...(updateChannels ?? channels)]
        if (!updateChannels) {
            resolvedChannels.push('default' as DeliveryChannel)
        }

        const setMethod: keyof SharedContent = `set${field}`

        for (const channel of resolvedChannels) {
            let content: SharedContent

            switch (channel) {
                case DeliveryChannel.WEB:
                    content = workingValue.getWebContent().clone()
                    break
                case DeliveryChannel.NATIVE_IOS:
                    content = workingValue.getNativeIosContent().clone()
                    break
                case DeliveryChannel.NATIVE_ANDROID:
                    content = workingValue.getNativeAndroidContent().clone()
                    break
                default:
                    content = workingValue.getDefaultContent().clone()
                    break
            }

            content[setMethod]!(updateValue)
            workingValue.setChannelContent(content)
        }

        if (!options.waitToCompleteChange) {
            onChange?.(workingValue)
        }

        return workingValue
    }

    const domainHasFieldSuggestionsFlag =
        isDomainLevel && (domain.flags?.includes(Flags.domain.FEAT_NOTIF_FIELD_SUGGESTIONS.key) ?? false)
    const domainHasAutoSuggestFields =
        isDomainLevel &&
        (domain.notificationAutoSuggestFields === null || (domain.notificationAutoSuggestFields?.length ?? 0) > 0)
    const showFieldSuggestionsControl = domainHasFieldSuggestionsFlag && domainHasAutoSuggestFields

    const nativeChannelSelected =
        channels.includes(DeliveryChannel.NATIVE_IOS) || channels.includes(DeliveryChannel.NATIVE_ANDROID)
    const showIOSFields =
        (isOrgLevel || isDomainLevel || isCampaignLevel) && channels.includes(DeliveryChannel.NATIVE_IOS)
    const showUseDifferentUrlForNative = channels.includes(DeliveryChannel.WEB) && nativeChannelSelected

    const validMacroOptionTypes: MacroOptionType[] = []
    if (domain.ecommConfig?.itemType) {
        validMacroOptionTypes.push(domain.ecommConfig?.itemType)
    }

    return (
        <>
            <Well
                className={getClassNames('notification-interactions-builder', levelClassName, className)}
                title="Destination"
                mode="ghost"
                hideFooter={true}
                actions={
                    loading ? undefined : (
                        <div className="content-builder-actions">
                            <div className="message-info-toggle">
                                <CharacterLimitTooltip className={getClassNames('notification-message-info-overlay')}>
                                    <a onClick={() => {}}>
                                        <span>Character Limits</span>
                                        <InfoCircleOutlined className="info-icon" />
                                    </a>
                                </CharacterLimitTooltip>
                            </div>
                        </div>
                    )
                }
            >
                <SkeletonWrapper loading={loading}>
                    <TemplateLandingUrlField
                        className={getClassNames(null, 'landing-url-field', {
                            'show-suggestions-toggle': showFieldSuggestionsControl,
                        })}
                        disabled={disabled}
                        value={workingModel.getLandingUrl()}
                        onChange={(v) => {
                            workingModel.setLandingUrl(v)
                            handleChange(workingModel)
                        }}
                        macroTypes={landingUrlMacroTypes}
                        customMacroOptions={props.customMacroOptions}
                        validMacroOptionTypes={validMacroOptionTypes}
                    />

                    {showFieldSuggestionsControl && (
                        <Button
                            className="suggestions-toggle"
                            icon={<BulbOutlined />}
                            disabled={disabled || !workingModel.getLandingUrl()}
                            onClick={() => setUrlToParse(workingModel.getLandingUrl()!)}
                            tabIndex={-1}
                        />
                    )}

                    {showUseDifferentUrlForNative && (
                        <TemplateLandingUrlField
                            disabled={workingModel.getNativeAltUrl() === undefined || disabled}
                            value={workingModel?.getNativeAltUrl()}
                            onChange={(v) => {
                                workingModel.setNativeAltUrl(v)
                                handleChange(workingModel)
                            }}
                            label={
                                <span className="native-alt-url">
                                    <Switch
                                        size="small"
                                        checked={workingModel.getNativeAltUrl() !== undefined}
                                        onChange={(v) => {
                                            if (v) {
                                                workingModel?.setNativeAltUrl(workingModel.getNativeAltUrl() ?? '')
                                            } else {
                                                workingModel?.setNativeAltUrl(undefined)
                                            }
                                            handleChange(workingModel)
                                        }}
                                    />
                                    <span>Use a Different URL for Native App</span>
                                </span>
                            }
                            placeholder="myapp://story/12345"
                            macroTypes={landingUrlMacroTypes}
                            customMacroOptions={props.customMacroOptions}
                            validMacroOptionTypes={validMacroOptionTypes}
                        />
                    )}
                </SkeletonWrapper>
            </Well>

            <Well
                className={getClassNames('notification-design-builder', levelClassName, className)}
                title="Message"
                mode="ghost"
                hideFooter={true}
            >
                <SkeletonWrapper loading={loading}>
                    <TemplateTitleField
                        placeholder="Notification title"
                        disabled={disabled}
                        value={workingModel.getTitle()}
                        onChange={(v) => {
                            workingModel.setTitle(v)
                            setSharedChannelField('Title', v)
                        }}
                        macroTypes={messageFieldMacroTypes}
                        customMacroOptions={props.customMacroOptions}
                        validMacroOptionTypes={validMacroOptionTypes}
                    />
                    <TemplateBodyField
                        placeholder="Notification body"
                        disabled={disabled}
                        value={workingModel.getBody()}
                        onChange={(v) => {
                            workingModel.setBody(v)
                            setSharedChannelField('Body', v || null)
                        }}
                        macroTypes={messageFieldMacroTypes}
                        customMacroOptions={props.customMacroOptions}
                        validMacroOptionTypes={validMacroOptionTypes}
                    />

                    <TemplateMediaField
                        mobileFriendly={props.theme === NotificationBuilderTheme.MOBILE}
                        disabled={disabled}
                        label={
                            <span>
                                <span>Image:</span>
                                <Popover
                                    overlayClassName={getClassNames('notification-builder-popover', 'image-popover')}
                                    content={
                                        <>
                                            <p>
                                                This image will be displayed underneath the title and body of the
                                                notification on Windows and Android. Recommended minimum size is 360x180
                                                with an aspect ratio of 2:1.
                                            </p>
                                        </>
                                    }
                                >
                                    <InfoCircleOutlined className="info-icon" />
                                </Popover>
                            </span>
                        }
                        className="image-upload"
                        domainId={domain.id}
                        defaultDomainMediaUrl={isOrgLevel ? undefined : domain?.defaultIconUrl}
                        accountId={isOrgLevel ? domain.accountId : undefined}
                        defaultCompositeValue={
                            suggestedImageComposite && {
                                url: suggestedImageComposite.url,
                                filename: suggestedImageComposite.filename,
                                providerOptions: {
                                    originalMediaUrl: suggestedImageComposite.originalMediaUrl,
                                    localMediaSource: suggestedImageComposite.src,
                                    imageState: suggestedImageComposite.imageState,
                                },
                            }
                        }
                        value={workingModel.getImageUrl()}
                        onChange={(v) => {
                            setSuggestedImageComposite(undefined)
                            setSharedChannelField('ImageUrl', v ?? null)
                        }}
                        resize={[400, 200]}
                        cropMax={[2048, 1024]}
                        cropRatio={2}
                        quality={isOrgLevel ? 40 : domain.mediaQuality}
                        pjpg={true}
                        forceCrop={true}
                        size="large"
                        hideMacroToggle={hideMacroToggle}
                        macroTag={'item.image'}
                        macroTagLabel="Use item image"
                    />

                    <TemplateMediaField
                        disabled={disabled}
                        label={
                            <>
                                <span>
                                    <span>Icon:</span>
                                    <Popover
                                        overlayClassName={getClassNames('notification-builder-popover', 'icon-popover')}
                                        content={
                                            <>
                                                <p>
                                                    This image will be displayed as a medium-size icon next to the title
                                                    and body of the notification on all platforms. Recommended size is
                                                    512x512 and an aspect ratio of 1:1.
                                                </p>

                                                {isOrgLevel && (
                                                    <p>
                                                        <strong>Domain Default:</strong> When enabled the notification
                                                        will use each Domain's configured default icon when sent.
                                                    </p>
                                                )}

                                                <p>
                                                    <strong>Note:</strong> Changing this icon has no effect for Safari
                                                    notifications. Instead, the subscriber will receive a notification
                                                    with the default icon that was set on your domain when they
                                                    subscribed.
                                                </p>
                                            </>
                                        }
                                    >
                                        <InfoCircleOutlined className="info-icon" />
                                    </Popover>
                                </span>

                                {isOrgLevel && (
                                    <span className="domain-defaults-switch icon-defaults-switch">
                                        <Switch
                                            size="small"
                                            checked={workingModel.getIsUsingDomainDefaultIcon()}
                                            disabled={disabled}
                                            onChange={(v) => {
                                                const update = workingModel.clone()

                                                update.setIsUsingDomainDefaultIcon(v)
                                                update.setIconUrl(null)

                                                handleChange(update)
                                            }}
                                        />
                                        <span>Domain Default</span>
                                    </span>
                                )}
                            </>
                        }
                        className="icon-upload"
                        domainId={domain.id}
                        accountId={isOrgLevel ? domain.accountId : undefined}
                        value={workingModel.getIconUrl()}
                        onChange={(v) => {
                            if (!isOrgLevel) {
                                workingModel.setIsUsingDomainDefaultIcon(false)
                            }
                            setSharedChannelField('IconUrl', v ?? null)
                        }}
                        cropRatio={1}
                        cropDim={[512, 512]}
                        quality={isOrgLevel ? 40 : domain.mediaQuality}
                        forceCrop={true}
                        render={
                            isOrgLevel && workingModel.getIsUsingDomainDefaultIcon()
                                ? () => (
                                      <button
                                          className="sw-file-uploader-btn ant-upload ant-upload-select ant-upload-select-picture-card image-mode icon-upload disabled"
                                          disabled={true}
                                      >
                                          <span className="ant-upload contents" role="button">
                                              <div className="faux-btn">
                                                  <GlobalOutlined />
                                                  <span>Domain Icon</span>
                                              </div>
                                          </span>
                                      </button>
                                  )
                                : undefined
                        }
                    />
                </SkeletonWrapper>
            </Well>

            <Well
                className={getClassNames(
                    'notification-interactions-builder',
                    'buttons-builder',
                    levelClassName,
                    className,
                    {
                        collapsed: !workingModel.getCustomActionsEnabled(),
                    },
                )}
                title={
                    <>
                        <Switch
                            size="small"
                            disabled={disabled}
                            checked={workingModel.getCustomActionsEnabled()}
                            onChange={(enabled) => {
                                const update = workingModel.clone()

                                update.setCustomActionsEnabled(enabled)
                                if (!enabled) {
                                    update.setActions(undefined)
                                }

                                handleChange(update)
                            }}
                        />

                        <span className="title">
                            <span>
                                Customize Buttons <span>{showIOSFields && 'Web & Android Only'}</span>
                            </span>
                            <Popover
                                overlayClassName={getClassNames('notification-builder-popover', 'buttons-popover')}
                                content={
                                    <div>
                                        <p>
                                            By default, all notifications have a “Visit Site” button that, when clicked,
                                            directs the user to the primary landing URL. Enabling this setting allows
                                            you to customize the button behavior, label, & landing URL and add an
                                            optional 2nd button.
                                        </p>
                                        <p>
                                            Learn more about{' '}
                                            <a
                                                href={`${appState.documentationLink}/platform/notifications/action-buttons`}
                                                target="_blank"
                                            >
                                                Custom Buttons
                                            </a>
                                            .
                                        </p>
                                    </div>
                                }
                            >
                                <InfoCircleOutlined className="info-icon" />
                            </Popover>
                        </span>
                    </>
                }
                mode="ghost"
                hideFooter={true}
            >
                <SkeletonWrapper loading={loading}>
                    {workingModel.getCustomActionsEnabled() && (
                        <NotificationActionsBuilder
                            loading={loading}
                            domain={isOrgLevel ? null : domain}
                            customMacroOptions={props.customMacroOptions}
                            validMacroOptionTypes={validMacroOptionTypes}
                            value={workingModel}
                            onChange={(v) => handleChange?.(v)}
                        />
                    )}
                </SkeletonWrapper>
            </Well>

            <Well
                className={getClassNames('notification-tracking-builder', levelClassName, className)}
                title="Tracking"
                mode="ghost"
                hideFooter={true}
            >
                <SkeletonWrapper loading={loading}>
                    <TemplateKeywordsField
                        disabled={disabled}
                        value={workingModel.getKeywords()}
                        onChange={(v) => {
                            workingModel.setKeywords(v)
                            handleChange(workingModel)
                        }}
                        options={props.keywordOptions}
                        max={30}
                        label={
                            <span className="keywords-label">
                                <span>Keywords</span>
                                <Popover
                                    overlayClassName={getClassNames('notification-builder-popover', 'keyword-popover')}
                                    content={
                                        <div>
                                            <p>
                                                Every user who <strong>clicks</strong> on this notification will be
                                                automatically tagged with the provided keywords. You can then build user
                                                segments based on users tagged with specific keywords.
                                            </p>
                                            <p>
                                                You can add multiple keywords by pressing the enter/return key or
                                                entering a comma after each individual keyword.
                                            </p>
                                        </div>
                                    }
                                >
                                    <InfoCircleOutlined className="info-icon" />
                                </Popover>
                            </span>
                        }
                        placeholder={'Notification keywords'}
                        emptyOptionsPlaceholder={'No previously used keywords'}
                    />
                </SkeletonWrapper>
            </Well>

            <NotificationExperienceOptions
                key="variant-experience-options"
                loading={loading}
                title="Behavior"
                domain={domain}
                disabled={disabled || experienceDisabled}
                hideTtlSettings={hideTtlSettings}
                value={workingModel.getExperienceOptions()}
                onChange={(v) => {
                    const update = workingModel.clone()
                    update.setExperienceOptions(v)
                    handleChange(update)
                }}
            />

            {showIOSFields && (
                <NotificationChannelSettings
                    key="notification-channel-settings"
                    loading={loading}
                    title="Channel Settings"
                    domain={domain}
                    disabled={disabled}
                    iosValue={value.clone()?.getNativeIosContent()}
                    androidValue={value.clone()?.getNativeAndroidContent()}
                    onChange={(v) => {
                        const update = value.clone()
                        if (v instanceof NotificationNativeAndroidContentModel) {
                            handleChange(v, DeliveryChannel.NATIVE_ANDROID)
                        }

                        if (v instanceof NotificationNativeIosContentModel) {
                            handleChange(v, DeliveryChannel.NATIVE_IOS)
                        }
                    }}
                    hideNativeIos={!channels.includes(DeliveryChannel.NATIVE_IOS)}
                    hideNativeAndroid={!channels.includes(DeliveryChannel.NATIVE_ANDROID)}
                    hideEnableToggle={true}
                />
            )}

            {showFieldSuggestionsControl && (
                <NotificationFieldSuggestions
                    domain={domain}
                    url={urlToParse}
                    onClose={() => setUrlToParse(null)}
                    onSuggestionsLoaded={(suggestions: INotificationFieldSuggestions) => {
                        const update = workingModel.clone()
                        update.setAutoSuggestedFieldsResults(suggestions)
                        handleChange(update)
                    }}
                    onAccept={async (selections: Partial<INotificationFieldSuggestions>) => {
                        const hasTitle = 'title' in selections && selections.title !== null
                        const hasBody = 'body' in selections && selections.body !== null
                        const hasImage = 'imageUrl' in selections && selections.imageUrl !== null
                        const hasKeywords = 'keywords' in selections && selections.keywords !== null
                        const hasSelections = hasTitle || hasBody || hasImage || hasKeywords

                        if (hasSelections) {
                            let update = value.clone()

                            if (hasTitle) {
                                update = setSharedChannelField('Title', selections.title!, {
                                    prevValue: update,
                                    waitToCompleteChange: true,
                                })
                            }
                            if (hasBody) {
                                update = setSharedChannelField('Body', selections.body!, {
                                    prevValue: update,
                                    waitToCompleteChange: true,
                                })
                            }
                            if (hasImage) {
                                update = setSharedChannelField('ImageUrl', selections.imageUrl!, {
                                    prevValue: update,
                                    waitToCompleteChange: true,
                                })
                                if (selections.compositeImage) {
                                    setSuggestedImageComposite(selections.compositeImage)
                                }
                            }
                            if (hasKeywords) {
                                const compiledKws = Array.from(
                                    new Set([...(workingModel.getKeywords() ?? []), ...(selections.keywords ?? [])]),
                                )
                                update.getDefaultContent().setKeywords(compiledKws)
                            }

                            onChange(update)
                        }

                        setUrlToParse(null)
                    }}
                />
            )}
        </>
    )
}

export default NotificationContentBuilder
