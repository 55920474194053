export enum RefreshTimeout {
    FIVE_SECONDS = 5_000,
    TEN_SECONDS = 10_000,
    FIFTEEN_SECONDS = 15_000,
    THIRTY_SECONDS = 30_000,
    ONE_MINUTE = 60_000,
    FIVE_MINUTES = 300_000,
    TEN_MINUTES = 600_000,
    FIFTEEN_MINUTES = 900_000,
}

const refreshTimeoutInfoMap = {
    [RefreshTimeout.FIVE_SECONDS]: { label: '5s', seconds: 5 },
    [RefreshTimeout.TEN_SECONDS]: { label: '10s', seconds: 10 },
    [RefreshTimeout.FIFTEEN_SECONDS]: { label: '15s', seconds: 15 },
    [RefreshTimeout.THIRTY_SECONDS]: { label: '30s', seconds: 30 },
    [RefreshTimeout.ONE_MINUTE]: { label: '1m', seconds: 60 },
    [RefreshTimeout.FIVE_MINUTES]: { label: '5m', seconds: 300 },
    [RefreshTimeout.TEN_MINUTES]: { label: '10m', seconds: 600 },
    [RefreshTimeout.FIFTEEN_MINUTES]: { label: '15m', seconds: 900 },
}

// tslint:disable-next-line:no-namespace
export namespace RefreshTimeout {
    export function getKeys(): string[] {
        return Object.keys(RefreshTimeout).filter(
            (ch) => typeof RefreshTimeout[ch as keyof RefreshTimeout] === 'string',
        )
    }

    export function getAll(): RefreshTimeout[] {
        const timeouts = RefreshTimeout.getKeys().map((key) => RefreshTimeout[key]) as RefreshTimeout[]
        timeouts.sort()
        return timeouts
    }

    export function getLabel(value: RefreshTimeout): string {
        return refreshTimeoutInfoMap[value].label
    }
}
