import { MacroInputTrigger, MacroOption, MacroOptionGroup, MacroOptions, MacroOptionType, MacroType } from './types'
import { InputContext } from '../custom-quill-editor/types'
import { escapeRegExpString as esc } from '../../_utils/regexp'
import { PromiseableProp } from '../../_utils/promiseable-prop'
import { BaseMacroOptions } from './constants'
import { isPromise } from '../../_utils/object'
import structuredClone from '@ungap/structured-clone'

export const fireNativeInputChangeEvent = (el: HTMLInputElement, value: string) => {
    const nativeSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')!.set!
    nativeSetter.call(el, value)

    const fauxEvent = new Event('input', { bubbles: true })
    el.dispatchEvent(fauxEvent)
}

const getEmptyInputContext = (seq: { start: RegExp; end: RegExp }) => ({
    input: {
        value: '',
        start: '',
        end: '',
    },
    macro: null,
    cursor: {
        cursorPos: 0,
        inTrigger: false,
    },
    seq: {
        start: seq.start.compile(seq.start.source, seq.start.flags),
        end: seq.end.compile(seq.end.source, seq.end.flags),
    },
})

/**
 * Example start sequence: (?<!{){{(?:(?!{|}}).)*$
 * Example end sequence: ^(?:(?<!}|{{).)*}}(?!})
 */
export const getInputContext = (
    value: string | null | undefined,
    cursorPos: number | null,
    trigger: MacroInputTrigger,
): InputContext => {
    const [tStart, tEnd] = trigger.split('.')
    const seq = {
        start: new RegExp(`(?<!${esc(tStart[0])})${esc(tStart)}(?:(?!${esc(tStart[0])}|${esc(tEnd)}).)*$`),
        end: new RegExp(`^(?:(?<!${esc(tEnd[0])}|${esc(tStart)}).)*${esc(tEnd)}(?!${esc(tEnd[0])})`),
    }

    if (value === null || value === undefined) {
        return getEmptyInputContext(seq)
    }

    value = value.replace(/\n$/, '') // rm possible auto-appended new line
    cursorPos ??= 0

    const [vStart, vEnd] = [value.slice(0, cursorPos), value.slice(cursorPos)]

    let tStartPos: number | null = null
    const startMatch = vStart.match(seq.start)
    if (startMatch) {
        tStartPos = startMatch.index!
    }

    let tEndPos: number | null = null
    const endMatch = vEnd.match(seq.end)
    if (endMatch) {
        tEndPos = vStart.length + endMatch[0].indexOf(tEnd)
    }

    const macroConfig =
        tStartPos === null
            ? null
            : {
                  startTrigger: tStart,
                  startPos: tStartPos,
                  endTrigger: tEnd,
                  endPos: tEndPos,
                  value: value
                      .slice(tStartPos, tEndPos ?? undefined)
                      .replace(tStart, '')
                      .replace(tEnd, ''),
              }

    return {
        input: {
            value,
            start: vStart,
            end: vEnd,
        },
        macro: macroConfig,
        cursor: {
            cursorPos,
            inTrigger:
                !!macroConfig &&
                tStartPos !== null &&
                cursorPos >= tStartPos &&
                (tEndPos === null || cursorPos <= tEndPos),
        },
        seq: {
            start: seq.start.compile(seq.start.source, seq.start.flags),
            end: seq.end.compile(seq.end.source, seq.end.flags),
        },
    }
}

type GetMacroOptionsProps = {
    types?: MacroType[]
    options?: PromiseableProp<MacroOptions>
    customOptions?: PromiseableProp<MacroOption[]>
    validOptionTypes?: MacroOptionType[]
}

export const getMacroOptions = ({
    types,
    options,
    customOptions,
    validOptionTypes,
}: GetMacroOptionsProps): MacroOptionGroup[] => {
    let macroOptions = structuredClone(BaseMacroOptions)

    if (typeof options === 'object' && !isPromise(options)) {
        macroOptions = options as any
    }

    if (Array.isArray(customOptions)) {
        macroOptions.custom = {
            label: 'Custom',
            options: customOptions,
        }
    }

    const visibleTypes = types ?? ['domain', 'device', 'location', 'ecomm', 'custom']
    const macroGroups: { label: string; options: MacroOption[] }[] = []
    for (const type of visibleTypes) {
        const typeConfig = macroOptions[type]
        if (!typeConfig) {
            continue
        }

        const group: MacroOptionGroup = {
            label: typeConfig.label,
            options: [],
        }

        for (const opt of typeConfig.options) {
            if (
                validOptionTypes &&
                validOptionTypes.length > 0 &&
                opt.option_types &&
                !opt.option_types.some((ot) => validOptionTypes.includes(ot))
            ) {
                // if valid option types is provided do not return any option that does not have one of those types
                continue
            }

            group.options.push(opt)
        }

        if (group.options.length > 0) {
            macroGroups.push(group)
        }
    }

    return macroGroups
}
