import * as React from 'react'
import './input-page-visited.scss'
import { IRuleBuilderField } from './rule-builder'
import { BetterComponent } from '../better-component/better-component'
import { ChainSelect, IChainOption } from '../chain-select/chain-select-component'
import autobind from 'autobind-decorator'
import { Tooltip, Select, InputNumber as AntInputNumber, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { NoTranslate } from '../no-translate/no-translate'
import { IRuleValidationResponse } from '../rule-builder-v2/interfaces/rule-validation-response'

type PageVisitedScope = '7d' | '14d' | '30d' | '60d'

interface IPageVisitedProps {
    mode: 'edit' | 'display'
    field: IRuleBuilderField
    value: any
    operator?: string
    onChange: any
}

interface IPageVisitedState {
    field: string
    value: number
    operator: string
    meta: any
}

const pageVisitedOptions: IChainOption[] = [
    {
        display: 'within the last',
        value: 'prev',
        options: [
            {
                display: '7 Days',
                value: '7d',
            },
            {
                display: '14 Days',
                value: '14d',
            },
            {
                display: '30 Days',
                value: '30d',
            },
            {
                display: '60 Days',
                value: '60d',
            },
            {
                display: '90 Days',
                value: '90d',
            },
        ],
    },
]

const operatorOptions = [
    {
        display: 'at least',
        value: 'gte',
    },
    {
        display: 'exactly',
        value: 'eq',
    },
    {
        display: 'at most',
        value: 'lte',
    },
]

export class InputPageVisited extends BetterComponent<IPageVisitedProps, IPageVisitedState> {
    private inputRef: any

    public constructor(props: IPageVisitedProps) {
        super(props)
    }

    public async UNSAFE_componentWillReceiveProps(prevProps: any) {
        if (this.props.mode === 'display') {
            if (
                prevProps.value.value !== this.state.value ||
                prevProps.operator !== this.state.operator ||
                prevProps.value.meta.scope !== this.state.meta.scope ||
                prevProps.value.meta.expression !== this.state.meta.expression
            ) {
                await this.initializeStateWithProps()
            }
        }
    }

    public async UNSAFE_componentWillMount() {
        await this.initializeStateWithProps()
    }

    public componentDidMount(): void {
        this.autofocus()
    }

    public render() {
        return this.props.mode === 'edit' ? (
            <div className="input-page-visited">
                <div className="g">
                    <div className="g-label">containing</div>

                    <Input
                        ref={(el) => (this.inputRef = el)}
                        onChange={(element) => this.onExpressionChange(element.target.value.valueOf())}
                        value={this.state.meta.expression}
                    />
                </div>

                <div className="g">
                    <div className="g-label">was visited</div>

                    <Select className="operator-select" onChange={this.onOperatorChange} value={this.state.operator}>
                        {this.renderOperatorOptions(operatorOptions)}
                    </Select>

                    <AntInputNumber
                        onChange={this.onValueChange}
                        value={this.state.value}
                        min={0}
                        max={999}
                        type="number"
                    />

                    <div>time(s)</div>
                </div>

                <div className="g">
                    <ChainSelect
                        mode={this.props.mode}
                        value={this.state.meta.scope}
                        options={pageVisitedOptions}
                        onChange={this.onScopeChange}
                    />

                    <Tooltip title="Time frame does not include page visits from the current day.">
                        <span className="info-icon">
                            <InfoCircleOutlined />
                        </span>
                    </Tooltip>
                </div>
            </div>
        ) : (
            <div className="input-notification-clicks">
                <span className="highlight">containing</span>
                {'\u00A0' /* nbps */}
                <span>
                    “<NoTranslate>{this.state.meta.expression}</NoTranslate>”
                </span>
                {'\u00A0' /* nbps */}
                <span className="highlight">was visited {this.getOperatorDisplay()}</span>
                {'\u00A0' /* nbps */}
                <span>{this.state.value} time(s)</span>
                {'\u00A0' /* nbps */}
                <span className="highlight">
                    <ChainSelect
                        mode={this.props.mode}
                        value={this.state.meta.scope}
                        options={pageVisitedOptions}
                        onChange={this.onScopeChange}
                    />
                </span>

                <Tooltip title="Time frame does not include page visits from the current day.">
                    <span className="info-icon">
                        <InfoCircleOutlined />
                    </span>
                </Tooltip>
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        let res: IRuleValidationResponse = { ok: true }

        if (this.state.meta.expression === undefined || this.state.meta.expression === null) {
            res.ok = false
            res.error = 'Please provide a valid page or screen URL.'
        }

        return res
    }

    public renderOperatorOptions(options: any[]): React.ReactFragment[] {
        return options.map((o) => (
            <Select.Option key={o.value} value={o.value}>
                {o.display}
            </Select.Option>
        ))
    }

    private async initializeStateWithProps() {
        const propsValue = this.props.value ? this.props.value : ({} as any)

        await this.setState({
            operator: this.props.operator || 'gte',
            value: propsValue.value !== undefined ? propsValue.value : 1,
            meta: propsValue.meta || {},
        })
    }

    private getOperatorDisplay(): string {
        if (this.state.operator) {
            return operatorOptions.filter((o) => o.value === this.state.operator)[0].display
        }
        return ''
    }

    private autofocus(): void {
        if (!!this.inputRef) {
            this.inputRef.focus()
        }
    }

    @autobind
    private async onOperatorChange(operator: string) {
        await this.setState({
            operator,
        })

        this.emitChangeEvent()
    }

    @autobind
    private async onExpressionChange(value: string) {
        await this.setState({
            meta: {
                scope: this.state.meta.scope,
                expression: value,
            },
        })

        this.emitChangeEvent()
    }

    @autobind
    private async onScopeChange(value: PageVisitedScope) {
        await this.setState({
            meta: {
                scope: value,
                expression: this.state.meta.expression,
            },
        })

        this.emitChangeEvent()
    }

    @autobind
    private async onValueChange(value: any) {
        await this.setState({
            value,
        })

        this.emitChangeEvent()
    }

    private emitChangeEvent() {
        this.props.onChange({
            field: this.props.field.property,
            operator: this.state.operator,
            value: this.state.value,
            meta: this.state.meta,
        })
    }
}
