import { observable } from 'mobx'
import { Container } from 'typescript-ioc/es5'
import { Ability, AbilityBuilder } from '@casl/ability'
import { AccessRoleId } from '../enums/access-role.enum'
import { AbilityAction } from '../enums/ability-action.enum'
import { SubjectEntity } from '../enums/ability-entity.enum'
import { AppAbility, asCaslSubject } from '../stores/app-ability'
import { UserDto } from '../dtos/user'
import { AppService } from './app'
import PermissionsService, { IPermissionsChangeSet } from './permissions.service'
import AccountUserModel, { ICachedAccountUserRecord } from '../models/access-control/account-user.model'
import DomainUserModel, { ICachedDomainUserRecord } from '../models/access-control/domain-user.model'
import { IAccessPolicy } from 'interfaces/access-policy'
import { mergePolicyConditions, parseAccessPolicyConditions } from '../_utils/access-utils'
import * as randomstring from 'randomstring'
import * as deepEqual from 'react-fast-compare'
import { objectFromEntries } from '../_utils/object'
import { arrayUnique } from '../_utils/utils'
import { flatMap } from '../_utils/array'

type AccessPolicyWithUserRoleIds = IAccessPolicy & { userRoleIds: number[] }

function getSimpleObjectHash(obj: any) {
    return btoa(JSON.stringify(obj))
}

const allPolicySubjects = [
    'user',
    'account',
    'account_user',
    'account_notification',
    'account_segment',
    'domain',
    'domain_user',
    'domain_notification',
    'domain_app_message',
    'domain_campaign',
    'domain_segment',
    'domain_prompt',
    'domain_integration',
    'domain_feed',
    'domain_saved_report',
] as const

const subjectMap: { [subject: string]: SubjectEntity } = {
    user: SubjectEntity.USER,
    account: SubjectEntity.ORG,
    account_user: SubjectEntity.USER,
    account_notification: SubjectEntity.ORG_NOTIFICATION,
    account_segment: SubjectEntity.ORG_SEGMENT,
    domain: SubjectEntity.DOMAIN,
    domain_user: SubjectEntity.USER,
    domain_notification: SubjectEntity.NOTIFICATION,
    domain_app_message: SubjectEntity.APP_MESSAGE,
    domain_notification_test: SubjectEntity.NOTIFICATION_TEST,
    domain_campaign: SubjectEntity.CAMPAIGN,
    domain_segment: SubjectEntity.SEGMENT,
    domain_prompt: SubjectEntity.PROMPT,
    domain_prompt_group: SubjectEntity.PROMPT_GROUP,
    domain_integration: SubjectEntity.DOMAIN_INTEGRATION,
    domain_feed: SubjectEntity.FEED,
    domain_saved_report: SubjectEntity.SAVED_REPORT, // shared saved report
}

// additional tied subjects for the given application
const subjectExtensions: { [subject in keyof typeof subjectMap | string]?: string[] } = {
    domain_prompt: ['domain_prompt_group'],
    domain_notification: ['domain_notification_test'],
}

export const noAccessUserRoles = [AccessRoleId.EXTERNAL_NO_ACCESS, AccessRoleId.INTERNAL_NO_ACCESS]

interface IPolicyBuilderConfig {
    subjectMap: { [subject in (typeof allPolicySubjects)[number] | string]: any }
    subjectExtensions?: { [subject in keyof IPolicyBuilderConfig['subjectMap'] | string]?: any[] }
}

class AccessControlService {
    public static forUser(user: UserDto) {
        return new AccessControlService(user)
    }

    @observable
    public state: string = ''

    @observable
    protected userRoleAccessPolicies: AccessPolicyWithUserRoleIds[] = []
    // utilityMaps
    protected userRoleAccessPolicyIdMap: { [policyId: number]: AccessPolicyWithUserRoleIds } = {}
    protected userRoleAccessPolicyRoleIdSubjectMap: {
        [userRoleId: number]: { [subject: string]: AccessPolicyWithUserRoleIds[] }
    } = {}

    @observable
    protected ability: Ability

    @observable
    protected accounts: { [accountId: number]: AccountUserModel } = {}
    @observable
    protected domains: { [domainId: number]: DomainUserModel } = {}

    @observable
    protected dirtyAccounts: { [accountId: number]: AccountUserModel } = {}
    @observable
    protected dirtyDomains: { [domainId: number]: DomainUserModel } = {}

    /**
     * changeState is a string key containing
     * the following colon dilemeted format:
     *   dirty accounts: `a-${account_id}-${role_id}`
     *   dirty domains: `d-${domain_id}-${role_id}`
     *
     * the format ensures any role changes can be externally observed
     */
    @observable
    protected changeState: string = ''

    protected readonly user: UserDto
    protected readonly appSvc: AppService
    protected readonly permissionsSvc: PermissionsService

    protected constructor(user: UserDto) {
        this.user = user
        this.appSvc = Container.get(AppService)
        this.permissionsSvc = Container.get(PermissionsService)
    }

    protected get subjectPolicyConfig() {
        return {
            subjectMap,
            subjectExtensions,
        }
    }

    public get noAccessRoleId() {
        return this.user.isInternalUser ? AccessRoleId.INTERNAL_NO_ACCESS : AccessRoleId.EXTERNAL_NO_ACCESS
    }

    public isInternalEditorUserScopeForDomain(id: number) {
        const domainEntity = this.appSvc.getDomainDto(id)

        return (
            this.user.isInternalUser &&
            this.domains[id] &&
            this.ability.can(AbilityAction.UPDATE, asCaslSubject(SubjectEntity.DOMAIN, domainEntity))
        )
    }

    public get hasMultiAccountAccess() {
        const auIds = Object.values(this.domains)
            .filter((record) => record.getId() !== this.noAccessRoleId)
            .map((record) => record.getAccountUserId())

        return new Set(auIds).size > 0
    }

    public get hasMultiDomainAccess() {
        return Object.values(this.domains).filter((record) => record.getId() !== this.noAccessRoleId).length > 0
    }

    /**
     * boolean proxy to the observable changeState
     *
     * checking dirty records length doesn't pick up
     * internal role id or name changes
     */
    public get hasChanges() {
        return !!this.changeState
    }

    public get domainIdsWhereIsAdmin() {
        const ids: number[] = []

        for (const record of Object.values(this.domains)) {
            const domainEntity = this.appSvc.getDomainDto(record.getDomainId())

            if (domainEntity && this.ability.can(AbilityAction.UPDATE, domainEntity)) {
                ids.push(record.getDomainId())
            }
        }

        return ids
    }

    /**
     * returns a full clone of current records
     * defaults to including dirty versions
     *
     * @param clean boolean - returns last clean state of records
     */
    public getCachedUserRecords(clean: boolean = false) {
        const cacheClone = new Map<number, AccountUserModel>()

        const accountsRecords = Object.keys(this.accounts).length ? this.accounts : this.dirtyAccounts

        for (const acctRecord of Object.values(accountsRecords)) {
            let acctClone = acctRecord.clone()

            const dirtyAcctRecord = this.dirtyAccounts[acctRecord.getAccountId()]
            if (!clean && dirtyAcctRecord) {
                acctClone = dirtyAcctRecord.clone()
            }

            cacheClone.set(acctRecord.getAccountId(), acctClone)

            let domainRecords = Array.from(acctRecord.getDomainRecords().values())
            if (!domainRecords.length) {
                domainRecords = Object.values(this.dirtyDomains).filter(
                    (d) => d.getAccountUserId() && d.getAccountUserId() === acctClone.getId(),
                )
            }

            for (const domainRecord of domainRecords) {
                let domainClone = domainRecord.clone()

                const dirtyDomainRecord = this.dirtyDomains[domainRecord.getDomainId()]
                if (!clean && dirtyDomainRecord) {
                    domainClone = dirtyDomainRecord.clone()
                }

                acctClone.setDomainRecord(domainRecord.getDomainId(), domainClone)
            }
        }

        return cacheClone
    }

    /**
     * cached account record getter
     * defaults to dirty state if present
     *
     * @param id number - account id
     * @param clean boolean - ensure last dirty/clean state returned
     */
    public getAccountRecord(id: number, clean: boolean = false) {
        if (!clean && this.dirtyAccounts[id]) {
            return this.dirtyAccounts[id]
        }

        return this.accounts[id]
    }

    /**
     * cached domain record getter
     * defaults to dirty state if present
     *
     * @param id number - domain id
     * @param clean boolean - ensure last dirty/clean state returned
     */
    public getDomainRecord(id: number, clean: boolean = false) {
        if (!clean && this.dirtyDomains[id]) {
            return this.dirtyDomains[id]
        }

        return this.domains[id]
    }

    public async revertUnsubmittedDomainRecords() {
        this.dirtyDomains = {}
    }

    public async updateCachedRecords(
        acctId: number,
        nextAcctRecord: AccountUserModel,
        changedDomainRecords?: DomainUserModel[],
    ) {
        let dirtyAcctRecord = nextAcctRecord
        const cleanAcctRecord = this.accounts[acctId]

        // process acct
        let acctChangesFound = !cleanAcctRecord || !cleanAcctRecord.matches(nextAcctRecord)
        if (acctChangesFound) {
            this.dirtyAccounts[acctId] = dirtyAcctRecord.clone()
        } else if (this.dirtyAccounts[acctId]) {
            delete this.dirtyAccounts[acctId]
        }

        // process domain
        if (changedDomainRecords && changedDomainRecords?.length > 0) {
            const domainIds = changedDomainRecords.map((d) => d.getDomainId())
            const cleanDomainRecords = domainIds.map((id) => this.domains[id])
            let domainChangesFound = !deepEqual(cleanDomainRecords, changedDomainRecords)

            if (domainChangesFound) {
                changedDomainRecords.forEach((d) => {
                    if (d !== this.dirtyDomains[d.getDomainId()]) {
                        this.dirtyDomains[d.getDomainId()] = d
                    }
                })
            } else {
                domainIds.forEach((domainId) => delete this.dirtyDomains[domainId])
            }
        }

        this.refreshChangeState()
    }

    public async updateCachedRecord(
        acctId: number,
        nextAcctRecord: AccountUserModel,
        nextDomainRecord?: DomainUserModel,
    ) {
        let dirtyAcctRecord = nextAcctRecord
        const cleanAcctRecord = this.accounts[acctId]

        // process acct
        let acctChangesFound = !cleanAcctRecord || !cleanAcctRecord.matches(nextAcctRecord)
        if (acctChangesFound) {
            this.dirtyAccounts[acctId] = dirtyAcctRecord.clone()
        } else if (this.dirtyAccounts[acctId]) {
            delete this.dirtyAccounts[acctId]
        }

        // process domain
        if (nextDomainRecord) {
            const domainId = nextDomainRecord.getDomainId()
            const nextDomainRole = nextDomainRecord.getRoleId()
            const cleanDomainRecord = this.domains[domainId]

            let domainChangesFound =
                (!cleanDomainRecord && !noAccessUserRoles.includes(nextDomainRole)) ||
                (cleanDomainRecord && !cleanDomainRecord.matches(nextDomainRecord))

            if (domainChangesFound) {
                this.dirtyDomains[domainId] = nextDomainRecord
            } else if (this.dirtyDomains[domainId]) {
                delete this.dirtyDomains[domainId]
            }
        }

        this.refreshChangeState()
    }

    public async persistChanges() {
        const acctChangeRecords: IPermissionsChangeSet<AccountUserModel>[] = []
        const domainChangeRecords: IPermissionsChangeSet<DomainUserModel>[] = []

        // FIFO - using shift
        const dirtyRecords = [...Object.values(this.dirtyAccounts), ...Object.values(this.dirtyDomains)]

        let next = dirtyRecords.shift()
        while (next) {
            const dirtyRecord = next
            next = dirtyRecords.shift()

            if (dirtyRecord instanceof AccountUserModel) {
                const cleanRecord = this.accounts[dirtyRecord.getAccountId()]
                if (!cleanRecord || !cleanRecord.matches(dirtyRecord)) {
                    acctChangeRecords.push({ dirty: dirtyRecord, clean: cleanRecord })
                    this.accounts[dirtyRecord.getAccountId()] = dirtyRecord
                }
            } else {
                const cleanRecord = this.domains[dirtyRecord.getDomainId()]
                if (!cleanRecord || !cleanRecord.matches(dirtyRecord)) {
                    domainChangeRecords.push({ dirty: dirtyRecord, clean: cleanRecord })
                    this.domains[dirtyRecord.getDomainId()] = dirtyRecord
                }
            }
        }

        if (acctChangeRecords.length) {
            try {
                await this.permissionsSvc.updateAccountUserPermissions(this.user.id, acctChangeRecords)
                for (const changeSet of acctChangeRecords) {
                    delete this.dirtyAccounts[changeSet.dirty.getAccountId()]
                }
            } catch (err) {
                if (process.env.IS_LOCAL) {
                    console.debug('persist_account_permissions_error', err)
                }
            }
        }

        if (domainChangeRecords.length) {
            try {
                await this.permissionsSvc.updateDomainUserPermissions(this.user.id, domainChangeRecords)
                for (const changeSet of domainChangeRecords) {
                    delete this.dirtyDomains[changeSet.dirty.getDomainId()]
                }
            } catch (err) {
                if (process.env.IS_LOCAL) {
                    console.debug('persist_domain_permissions_error', err)
                }
            }
        }

        this.refreshChangeState()
        await this.getAbilities(true)
    }

    public async getAbilities(refresh: boolean = false): Promise<AppAbility> {
        if (refresh || !this.ability) {
            const builder = new AbilityBuilder(Ability)
            const user = this.user
            const config = this.subjectPolicyConfig

            await this.getUserRecords(refresh)
            const userRoleAccessPolicies = await this.getUserRoleAccessPolices(refresh)

            const userSubjectPolicies = userRoleAccessPolicies.filter((policy) => {
                return policy.subjects[0] === 'all' || policy.subjects.includes('user')
            })

            const appliedPolicySignatureMap: { [id: string]: string } = {}

            for (const policy of userSubjectPolicies) {
                this.applyPolicy(builder, policy, ['user'], { user }, config, appliedPolicySignatureMap)
            }

            builder.cannot(
                AbilityAction.DESTROY, // odd bug: "... is not assignable to type any"
                config.subjectMap.user,
                parseAccessPolicyConditions({ id: '$ctx.user.id' }, { user }),
            )

            const accountSubjects = allPolicySubjects.filter((subject) => /^account/.test(subject))
            const domainSubjects = allPolicySubjects.filter((subject) => /^domain/.test(subject))

            const computedAbilityMap: any = {}
            const recordsToProcess: (DomainUserModel | AccountUserModel)[] = [...Object.values(this.accounts)]
            let next = recordsToProcess.pop()

            while (next) {
                const record = next

                // ensure child domains records added for account record
                if ('getAccountId' in record) {
                    const domainRecords = Array.from(record.getDomainRecords().values())
                    if (domainRecords.length) {
                        recordsToProcess.unshift(...domainRecords)
                    }
                }

                next = recordsToProcess.pop()

                const recordRole = record.getRoleId()
                if (!user || noAccessUserRoles.includes(recordRole)) {
                    continue
                }

                const recordApplicableSubjects: string[] =
                    'getAccountId' in record ? [...accountSubjects] : [...domainSubjects]

                const recordPolicies = this.getUserRoleAccessPoliciesByRoleIdAndSubject(
                    recordRole,
                    recordApplicableSubjects,
                )

                if (!recordPolicies.length) {
                    continue
                }

                // ensure deny effects processed last
                recordPolicies.sort((aP, bP) => (aP.effect > bP.effect ? 1 : aP.effect < bP.effect ? -1 : 0))

                const context = { record, user }

                for (const policy of recordPolicies) {
                    const computedKey = getSimpleObjectHash({ subjects: recordApplicableSubjects, policy })

                    if (!computedAbilityMap[computedKey]) {
                        computedAbilityMap[computedKey] = {
                            actions: policy.actions,
                            subjects: [],
                            conditions: parseAccessPolicyConditions(policy.conditions, context),
                            inverted: policy.effect === 'deny',
                        }

                        let validSubjects: string[]
                        if (policy.subjects[0] === 'all') {
                            validSubjects = recordApplicableSubjects
                        } else {
                            validSubjects = policy.subjects.filter((subject) =>
                                recordApplicableSubjects.includes(subject),
                            )
                        }

                        const computedSubjects: any[] = []
                        validSubjects.forEach((subject) => {
                            computedSubjects.push(config.subjectMap[subject])

                            const extensions = config.subjectExtensions[subject] ?? []
                            if (extensions.length) {
                                computedSubjects.push(...extensions.map((s) => config.subjectMap[s]))
                            }
                        })

                        computedAbilityMap[computedKey].subjects = computedSubjects
                    } else {
                        computedAbilityMap[computedKey].conditions = mergePolicyConditions(
                            computedAbilityMap[computedKey].conditions,
                            parseAccessPolicyConditions(policy.conditions, context),
                        )
                    }
                }
            }

            for (const key of Object.keys(computedAbilityMap)) {
                const ability = computedAbilityMap[key]
                if (ability.subjects.length) {
                    if (!ability.inverted) {
                        builder.can(ability.actions, ability.subjects, ability.conditions)
                    } else {
                        builder.cannot(ability.actions, ability.subjects, ability.conditions)
                    }
                }
            }

            this.ability = builder.build({
                detectSubjectType: (sub) => sub.__caslSubjectType__,
            })

            // allow observables to update
            this.state = randomstring.generate()
        }

        return this.ability as AppAbility
    }

    public async getUserRecords(refresh: boolean = false) {
        if (refresh || !Object.keys(this.accounts).length) {
            const res = await this.permissionsSvc.loadUserPermissions(this.user.id)
            if (!res.ok) {
                return this.getCachedUserRecords()
            }

            const raw = res.data ?? {
                accounts: [],
                domains: [],
            }
            const recordsToProcess: Array<ICachedAccountUserRecord | ICachedDomainUserRecord> = Array.from(raw.accounts)

            // mapped using record.id - prevents need for accounts.find(...) query in while loop
            const accountUsersMap: { [id: number]: AccountUserModel } = {}
            // mapped using record.account_user_id - prevents need for domains.filter(...) query in while loop
            const accountDomainsMap = objectFromEntries(
                raw.accounts.map((account) => [
                    account.id,
                    raw.domains.filter((domain) => domain.accountUserId === account.id),
                ]),
            )

            // mapped using record.account_id
            const accounts: { [id: number]: AccountUserModel } = {}
            // mapped using record.domain_id
            const domains: { [id: number]: DomainUserModel } = {}

            let next = recordsToProcess.pop()
            while (next) {
                const record = next

                if ('accountId' in record) {
                    const model = AccountUserModel.fromCached(record)
                    accountUsersMap[model.getId()] = model
                    accounts[model.getAccountId()] = model

                    // add acct domains into processing arr
                    if (accountDomainsMap[model.getId()]?.length) {
                        recordsToProcess.unshift(...accountDomainsMap[model.getId()])
                    }
                } else if ('domainId' in record) {
                    const parentRecord = accountUsersMap[record.accountUserId]
                    if (!parentRecord) {
                        console.warn(`Orphaned permission record found for ${record.id}`)
                    } else {
                        const model = DomainUserModel.fromCached(record)
                        parentRecord.setDomainRecord(record.domainId, model)
                        domains[record.domainId] = model
                    }
                }

                next = recordsToProcess.pop()
            }

            this.accounts = accounts
            this.domains = domains
        }

        return this.getCachedUserRecords()
    }

    public getDirtyRecords() {
        return {
            accounts: this.dirtyAccounts,
            domains: this.dirtyDomains,
        }
    }

    protected async getUserRoleAccessPolices(refresh: boolean = false) {
        let userRoleAccessPolicies = Array.from(this.userRoleAccessPolicies)

        if (refresh || !this.userRoleAccessPolicies.length) {
            const res = await this.permissionsSvc.fetchUserRoleAccessPolicies(this.user.id)
            if (!res.ok) {
                return userRoleAccessPolicies
            }

            userRoleAccessPolicies = res.data ?? []
            this.userRoleAccessPolicies = userRoleAccessPolicies

            this.userRoleAccessPolicyIdMap = objectFromEntries(
                userRoleAccessPolicies.map((policy) => [policy.id, policy]),
            )

            const uniqueUserRoleIds: number[] = arrayUnique(
                flatMap(userRoleAccessPolicies, (policy) => policy.userRoleIds),
            )
            const userRoleAccessPolicyRoleIdMap = objectFromEntries(
                uniqueUserRoleIds.map((userRoleId) => [
                    userRoleId,
                    userRoleAccessPolicies.filter((policy) => policy.userRoleIds.includes(userRoleId)),
                ]),
            )

            this.userRoleAccessPolicyRoleIdSubjectMap = objectFromEntries(
                uniqueUserRoleIds.map((userRoleId) => [
                    userRoleId,
                    objectFromEntries(
                        flatMap(userRoleAccessPolicyRoleIdMap[userRoleId], (policy) =>
                            policy.subjects.map((subject) => [
                                subject,
                                userRoleAccessPolicyRoleIdMap[userRoleId].filter((p) => p.subjects.includes(subject)),
                            ]),
                        ),
                    ),
                ]),
            )

            // allow observables to update
            this.state = randomstring.generate()
        }

        return userRoleAccessPolicies
    }

    protected getUserRoleAccessPoliciesByRoleIdAndSubject(
        userRoleId: number,
        subjects: string | string[],
    ): AccessPolicyWithUserRoleIds[] {
        const source = this.userRoleAccessPolicyRoleIdSubjectMap[userRoleId] ?? {}
        let policyIds = new Set((source.all ?? []).map((policy) => policy.id))

        subjects = Array.isArray(subjects) ? subjects : [subjects]
        for (const subject of subjects) {
            if (subject === 'all') {
                continue
            }

            const subjectPolicies = source[subject] ?? []
            if (!subjectPolicies.length) {
                continue
            }

            subjectPolicies.forEach((policy) => policyIds.add(policy.id))
        }

        return [...policyIds].map((id) => this.userRoleAccessPolicyIdMap[id])
    }

    protected applyPolicy(
        builder: AbilityBuilder<any>,
        policy: AccessPolicyWithUserRoleIds,
        subjects: string[],
        context: { user: any; record?: AccountUserModel | DomainUserModel },
        config: IPolicyBuilderConfig,
        appliedPolicySignatureMap: { [id: string]: string },
    ) {
        let validSubjects: string[]
        if (policy.subjects[0] === 'all') {
            validSubjects = subjects
        } else {
            validSubjects = policy.subjects.filter((subject) => subjects.includes(subject))
        }

        if (validSubjects.length) {
            try {
                const conditions = parseAccessPolicyConditions(policy.conditions, context)

                for (const subject of validSubjects) {
                    if (config.subjectExtensions?.[subject]?.length) {
                        validSubjects.push(...config.subjectExtensions[subject]!)
                    }

                    for (const action of policy.actions) {
                        const inverted = policy.effect === 'deny'
                        const mappedSubject = config.subjectMap[subject]

                        if (mappedSubject) {
                            const signature = getSimpleObjectHash({ mappedSubject, action, inverted, conditions })
                            if (appliedPolicySignatureMap[signature]) {
                                continue
                            }
                            appliedPolicySignatureMap[signature] = signature

                            if (!inverted) {
                                builder.can(action, mappedSubject, conditions)
                            } else {
                                builder.cannot(action, mappedSubject, conditions)
                            }
                        }
                    }
                }
            } catch (err) {
                console.error(err, context, policy)
            }
        }
    }

    protected refreshChangeState() {
        let keys: string[] = []

        if (Object.keys(this.dirtyAccounts).length) {
            for (const acct of Object.values(this.dirtyAccounts)) {
                keys.push(`a-${acct.getAccountId()}-${acct.getRoleId()}`)
            }
        }
        if (Object.keys(this.dirtyDomains).length) {
            for (const domain of Object.values(this.dirtyDomains)) {
                keys.push(`d-${domain.getDomainId()}-${domain.getRoleId()}`)
            }
        }

        this.changeState = keys.join(':')
    }
}

export default AccessControlService
