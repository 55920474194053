import * as React from 'react'
import * as changeCase from 'change-case'
import { Cell as FDCell } from 'fixed-data-table-2'
import { arrayUnique, numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import moment from 'moment-timezone'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { BASE_TIME_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../constants'
import titleCase from 'title-case'
import { NoTranslate } from '../../../components/no-translate/no-translate'
import { AppState } from '../../../stores/app'

export const APP_MESSAGE_TABLE_COLUMN_DEFINITIONS = {
    ['DATE']: {
        selectable: false,
        className: 'r-record-date',
        width: 140,
        columnKey: 'event_date',
        title: (reportConfig: any, colConfig: any) => {
            return (
                <>
                    <span>Event Date</span>
                    <Tooltip title={<>The date the app message event took place in the domain's time zone.</>}>
                        <InfoCircleOutlined className="info-icon" />
                    </Tooltip>
                </>
            )
        },
        align: 'center',
        sortable: true,
        render(data: any, dataSet: any[], config: any) {
            const columnKey = 'event_date'

            let value = moment(data[columnKey]).format(SHORT_DATE_FORMAT)

            if (config.dateIncrement === 'MONTH') {
                value = moment(data[columnKey]).format('MMM, YYYY')
            } else if (config.dateIncrement === 'YEAR') {
                value = moment(data[columnKey]).format('YYYY')
            }

            return value
        },
    },
    ['DOMAIN.ID']: {
        className: 'r-domain-id',
        width: 120,
        columnKey: 'domain.id',
        title: 'Domain ID',
        sortable: true,
        sortType: 'number',
    },
    ['APP_MESSAGE.ID']: {
        className: 'r-app-message-id',
        width: 120,
        columnKey: 'app_message.id',
        title: 'App Message ID',
        sortable: true,
        sortType: 'number',
    },
    ['APP_MESSAGE.NAME']: {
        className: 'r-app-message-name',
        width: 240,
        columnKey: 'app_message.name',
        title: 'App Message Name',
        sortable: true,
        sortType: 'string',
        render(data: any, dataSet: any[], config: any, appState: AppState) {
            const appMessageId = data.app_message.id

            return (
                <a href={`/domains/${appState.currentDomain!.id}/app-messages/${appMessageId}/summary`} target="_blank">
                    <NoTranslate>{data.app_message.name}</NoTranslate>
                </a>
            )
        },
    },
    ['APP_MESSAGE.STYLE']: {
        className: 'r-app-message-style',
        width: 120,
        columnKey: 'app_message.style',
        title: 'Style',
        sortable: true,
        sortType: 'string',
        render: (data: any) => titleCase(data.app_message.style),
    },
    ['APP_MESSAGE.RUN_DATE_START']: {
        selectable: false,
        className: 'r-run-date',
        width: 140,
        columnKey: 'app_message.run_date_start',
        title: 'Run Date Start',
        align: 'center',
        sortable: true,
        sort(a: any, b: any) {
            const aRecord = a.app_message
            const bRecord = b.app_message

            const aValue = moment(aRecord.run_date_start)
            const bValue = moment(bRecord.run_date_start)

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        },
        render(data: any, dataSet: any[], appState: AppState) {
            const timestamp = moment.tz(data.app_message.run_date_start, data.app_message.run_date_time_zone)
            const timeDisplay = timestamp.format(BASE_TIME_ONLY_FORMAT)

            return (
                <>
                    <div className="r-run-date-date">{timestamp.format(SHORT_DATE_FORMAT)}</div>
                    <div className="r-run-date-time">{timeDisplay}</div>
                </>
            )
        },
    },
    ['APP_MESSAGE.RUN_DATE_END']: {
        selectable: false,
        className: 'r-run-date',
        width: 140,
        columnKey: 'app_message.run_date_end',
        title: 'Run Date End',
        align: 'center',
        sortable: true,
        sort(a: any, b: any) {
            const aRecord = a.app_message
            const bRecord = b.app_message

            const aValue = moment(aRecord.run_date_end)
            const bValue = moment(bRecord.run_date_end)

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        },
        render(data: any, dataSet: any[], appState: AppState) {
            const timestamp = moment.tz(data.app_message.run_date_end, data.app_message.run_date_time_zone)
            const timeDisplay = timestamp.format(BASE_TIME_ONLY_FORMAT)

            return (
                <>
                    <div className="r-run-date-date">
                        {data.app_message.run_date_end && timestamp.format(SHORT_DATE_FORMAT)}
                    </div>
                    <div className="r-run-date-time">{data.app_message.run_date_end && timeDisplay}</div>
                </>
            )
        },
    },
    ['APP_MESSAGE.RUN_DATE_TIME_ZONE']: {
        selectable: false,
        className: 'r-app-message-run-date-time-zone',
        width: 140,
        columnKey: 'app_message.run_date_time_zone',
        title: 'Run Date Time Zone',
        align: 'center',
        sortable: true,
        sortType: 'string',
    },
    ['APP_MESSAGE.AUDIENCES']: [
        {
            selectable: false,
            className: 'r-audiences',
            width: 240,
            columnKey: 'app_message.audiences.targeted',
            title: 'Audiences Targeted',
            fixed: false,
            sortable: true,
            sort(a: any, b: any) {
                const aRecord = a.app_message.audiences.targeted || []
                const bRecord = b.app_message.audiences.targeted || []
                const extractor = (aud: any) => {
                    let label = 'All Subscribers'

                    if (aud.type && aud.type === 'subscriber_id') {
                        label = 'Subscriber IDs'
                    } else if (aud.label) {
                        label = aud.label.toUpperCase() === 'ALL' ? 'All Subscribers' : aud.label
                    }

                    return label
                }

                const aValue = arrayUnique(aRecord.map(extractor)).join(', ')
                const bValue = arrayUnique(bRecord.map(extractor)).join(', ')

                return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
            },
            render(data: any, dataSet: any[]) {
                const audiences = data.app_message.audiences.targeted || []
                const audiencesNames = arrayUnique(
                    audiences.map((aud: any) => {
                        let label = 'All Subscribers'

                        if (aud.type && aud.type === 'subscriber_id') {
                            label = 'Subscriber IDs'
                        } else if (aud.label) {
                            label = aud.label.toUpperCase() === 'ALL' ? 'All Subscribers' : aud.label
                        }

                        return label
                    }),
                ).join(', ')

                if (audiencesNames.length > 36 || audiences.length > 1) {
                    return (
                        <div>
                            <Tooltip
                                overlayClassName="report-col-value-overlay"
                                trigger="hover"
                                title={audiences.map((aud: any) => (
                                    <div key={aud.label}>
                                        <NoTranslate>{aud.label}</NoTranslate>
                                    </div>
                                ))}
                            >
                                <span className="has-hover-info">{audiences.length} Audiences</span>
                            </Tooltip>
                        </div>
                    )
                }

                return audiencesNames
            },
        },
        {
            selectable: false,
            className: 'r-audiences',
            width: 240,
            columnKey: 'app_message.audiences.excluded',
            title: 'Audiences Excluded',
            fixed: false,
            sortable: true,
            sort(a: any, b: any) {
                const aRecord = a.app_message.audiences.excluded || []
                const bRecord = b.app_message.audiences.excluded || []
                const extractor = (aud: any) => aud.label

                const aValue = arrayUnique(aRecord.map(extractor)).join(', ')
                const bValue = arrayUnique(bRecord.map(extractor)).join(', ')

                return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
            },
            render(data: any, dataSet: any[]) {
                const audiences = data.app_message.audiences.excluded || []

                const defaultValue = { label: 'No Exclusions' }
                if (audiences.length === 0) {
                    audiences.push(defaultValue)
                }

                const audiencesNames = audiences.map((aud: any) => aud.label).join(', ')

                if (audiencesNames.length > 36 || audiences.length > 1) {
                    return (
                        <div>
                            <Tooltip
                                overlayClassName="report-col-value-overlay"
                                trigger="hover"
                                title={audiences.map((aud: any) => (
                                    <div key={aud.label}>
                                        <NoTranslate>{aud.label}</NoTranslate>
                                    </div>
                                ))}
                            >
                                <span className="has-hover-info">{audiences.length} Audiences</span>
                            </Tooltip>
                        </div>
                    )
                }

                return audiencesNames
            },
        },
    ],
    ['PLACEMENT']: {
        className: 'r-placement',
        width: 140,
        columnKey: 'placement',
        title: 'Device Type',
        sortable: true,
        render(data: any) {
            return changeCase.title(data.placement)
        },
    },
    ['CHANNEL']: {
        align: 'left',
        selectable: false,
        classname: 'r-channel',
        width: 120,
        columnKey: 'ch',
        title: 'Delivery Channel',
        sortable: false,
        render: (data: any) => DeliveryChannel.getShortName(data.channel) ?? 'Unknown',
    },
    ['IMPRESSIONS']: {
        className: 'r-impressions',
        width: 120,
        columnKey: 'impressions',
        title: 'Impressions',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.impressions)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['CLICKS']: {
        className: 'r-clicks',
        width: 120,
        columnKey: 'clicks',
        title: 'Clicks',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.clicks)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.clicks).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['CTR_DECIMAL']: {
        selectable: false,
        className: 'r-ctr',
        width: 120,
        columnKey: 'ctr_decimal',
        requiredColumnKeys: ['clicks', 'impressions'],
        title: 'Total CTR',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.impressions ? '--' : numberWithPercent(data.ctr_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggImps = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)
            const aggClicks = dataSet.map((s) => s.clicks).reduce((n: number, v: number) => n + (v || 0), 0)

            const avgCTR = !aggImps ? '--' : numberWithPercent(aggClicks / aggImps)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{avgCTR}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
}
