import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { ModalFuncProps } from 'antd/lib/modal'
import { ButtonProps } from 'antd/lib/button'
import titleCase from 'title-case'

export async function validateFields(form: WrappedFormUtils<any>, excludeFields: string[] = []): Promise<any> {
    const fieldNames = Object.keys(form.getFieldsValue()).filter((f) => excludeFields.indexOf(f) === -1)

    return new Promise((resolve, reject) => {
        form.validateFields(fieldNames, { force: true }, (errors: any, values: any) => {
            if (errors) {
                reject(errors)
            } else {
                resolve(values)
            }
        })
    })
}

interface IModalInstance {
    destroy: () => void
    update: (newConfig: ModalFuncProps) => void
}

export function setModalOkButtonProps(modal: IModalInstance, props: ButtonProps) {
    modal.update({
        okButtonProps: props,
    })
}

export function setModalButtonLoading(modal: IModalInstance, loading?: boolean) {
    setModalOkButtonProps(modal, {
        loading: loading !== false,
    })
}

type TSEnum = { [k: string]: string | number }
type ValueOf<T> = T[keyof T]

/**
 *
 * @param enumValue
 * @param filterCheck - whether the values are string or keys
 */
export function getEnumKeys(enumValue: TSEnum, filterCheck: 'string' | 'number' = 'string') {
    return Object.keys(enumValue).filter((key) => {
        return typeof enumValue[key] === filterCheck
    })
}

export function enumAsSelectOptions<T extends TSEnum, V extends ValueOf<T>>(
    enumValue: T,
    filterCheck: 'string' | 'number' = 'string',
): { value: V; label: string }[] {
    return getEnumKeys(enumValue, filterCheck).map((v) => ({ value: enumValue[v], label: titleCase(v) })) as {
        value: V
        label: string
    }[]
}
