import React from 'react'
import { NotificationListState } from './interfaces'
import { RefreshTimeout } from '../../enums/refresh-timeout'

export type NotificationListContext = NotificationListState & {
    onSearchClick(value: string): Promise<void>
    onFilterChange<
        FilterKey extends keyof NotificationListState['filters'],
        FilterValue extends NotificationListState['filters'][FilterKey],
    >(
        key: FilterKey,
        value: FilterValue,
    ): Promise<void>
    onRefreshClick(ev: React.MouseEvent<HTMLElement>): Promise<void>
    onRefreshEnabledChange(enabled: boolean): void
    onRefreshTimeoutChange(timeout: RefreshTimeout): void
}

export const NotificationListContext = React.createContext<NotificationListContext>({} as NotificationListContext)
