import * as React from 'react'
import './user-list.scss'
import * as deepEqual from 'fast-deep-equal'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Skeleton, Table, Tooltip } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { DomainDto } from '../../dtos/domain'
import { StatusType } from '../../enums/status-type'
import { UserDto } from '../../dtos/user'
import { UserType } from '../../enums/user-type'
import { AppState } from '../../stores/app'
import { AppService, DomainService, UserService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { getClassNames } from '../../_utils/classnames'
import { Button, Modal, Well } from '@pushly/aqe/lib/components'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT, DEMO } from '../../constants'
import { UserAccessWell } from '../user-data-form/user-access-well'
import { validateFields } from '../../_utils/antd'
import { simpleFormErrorNotification } from '../../_utils/utils'
import { CompoundTable, CompoundTableHeader } from '../aqe/compound-table/compound-table'
import { AntdTableEmptyPlaceholder } from '../aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { StatusBadge } from '../badges/status-badge'
import { IServiceApiResponse } from '../../interfaces/service-api-response'
import { AqeSelect } from '../aqe-select/select'
import { AccessRoleId } from '../../enums/access-role.enum'
import AccountUserModel from '../../models/access-control/account-user.model'
import DomainUserModel from '../../models/access-control/domain-user.model'
import { AccessRoleMap } from '../../models/access-role'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { asCaslSubject } from '../../stores/app-ability'
import { noAccessUserRoles } from '../../services/access-control.service'
import { getTablePaginationConfig } from '../segment-list/helpers'
import SwSimpleLinkPagination from '../sw-simple-link-pagination/sw-simple-link-pagination'
import { IApiResponsePaginationLinks } from '../../interfaces/api-response-pagination-links'

interface IAddUserPrompt extends FormComponentProps {
    accountId?: number
    domainId?: number
    onOk: (domainDto: Partial<UserDto> & { permissions?: AccountUserModel[] }) => any
    onCancel: () => any
    visible: boolean
}

interface IAddUserPromptState {}

export const AddUserPrompt = Form.create<IAddUserPrompt>()(
    class extends React.Component<IAddUserPrompt, IAddUserPromptState> {
        public state: IAddUserPromptState = {}

        protected appState: AppState
        protected appService: AppService
        protected userService: UserService
        protected domainService: DomainService

        public constructor(props: IAddUserPrompt) {
            super(props)

            this.appState = Container.get(AppState)
            this.appService = Container.get(AppService)
            this.userService = Container.get(UserService)
        }

        public render() {
            return (
                <Modal
                    className={getClassNames('add-user-prompt')}
                    title="Add User"
                    visible={this.props.visible}
                    onOk={this.handleOk}
                    okText="Submit"
                    onCancel={this.handleCancel}
                >
                    <Skeleton loading={false} active={true} title={false}>
                        <Well className="nested" mode="ghost" hideHeader={true} hideFooter={true}>
                            <Form {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT} className="hide-inline-errors">
                                <Well className="nested" mode="ghost" hideHeader={true} hideFooter={true}>
                                    <Form.Item label="Email Address">
                                        {this.props.form.getFieldDecorator('email', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Email address is required.',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'A valid email address is required.',
                                                },
                                            ],
                                        })(<Input size="small" autoComplete="off" />)}
                                    </Form.Item>
                                </Well>

                                {this.props.form.getFieldDecorator('permissions', {
                                    initialValue: this.defaultPermissions,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Initial user permissions are required.',
                                        },
                                    ],
                                })(
                                    <UserAccessWell
                                        mode="add-user"
                                        accountId={this.props.accountId}
                                        domainId={this.props.domainId}
                                        singleDomain={!!this.props.domainId}
                                    />,
                                )}
                            </Form>
                        </Well>
                    </Skeleton>
                </Modal>
            )
        }

        // pufferfish is currently locked to external creation
        protected get defaultPermissions(): any {
            const perms: any = {
                accounts: [],
                domains: [],
            }
            const defaultAcctRole = AccessRoleMap.get(AccessRoleId.EXTERNAL_NO_ACCESS)!
            const defaultDomainRole = AccessRoleMap.get(AccessRoleId.EXTERNAL_USER)!

            if (this.props.accountId) {
                perms.accounts.push(
                    new AccountUserModel({
                        accountId: this.props.accountId,
                        roleId: defaultAcctRole.id,
                        flags: [],
                        roleName: defaultAcctRole.name,
                    }),
                )
            }

            if (this.props.domainId) {
                perms.domains.push(
                    new DomainUserModel({
                        domainId: this.props.domainId,
                        roleId: defaultDomainRole.id,
                        flags: [],
                        roleName: defaultDomainRole.name,
                    }),
                )
            }

            return perms
        }

        protected handleOk = async () => {
            const formValues = this.props.form.getFieldsValue()

            try {
                await validateFields(this.props.form)

                const dto: Partial<UserDto> & { permissions?: AccountUserModel[] } = {}
                dto.userType = UserType.EXTERNAL // pufferfish is currently locked to single user type
                dto.email = formValues.email

                const permissions: AccountUserModel[] = []
                if (formValues.permissions) {
                    const account = Object.values<AccountUserModel>(formValues.permissions.accounts ?? {}).find(
                        (a: AccountUserModel) => a.getAccountId() === this.props.accountId,
                    )

                    if (account) {
                        const allAccountDomainIds =
                            this.appState.currentUserDomains
                                ?.filter((d) => d.accountId === this.props.accountId)
                                .map((d) => d.id) ?? []

                        const domains = Object.values<DomainUserModel>(formValues.permissions.domains ?? {}).filter(
                            (d: DomainUserModel) =>
                                allAccountDomainIds.includes(d.getDomainId()) &&
                                !noAccessUserRoles.includes(d.getRoleId()),
                        )

                        if (domains.length) {
                            account.setDomainRecords(domains)
                            permissions.push(account)
                        } else {
                            throw {
                                permissions: {
                                    errors: [
                                        { message: 'At least one domain must have a minimum of Read-Only access.' },
                                    ],
                                },
                            }
                        }
                    }
                }

                dto.permissions = permissions

                this.props.onOk(dto)
            } catch (err) {
                simpleFormErrorNotification(err)
            }
        }

        protected handleCancel = () => {
            this.props.onCancel()
        }
    },
)

interface IUserList {
    options: {
        accountId?: number
        domainId?: number
    }
}

interface IUserListState {
    loading: boolean
    users: UserDto[]
    usersMeta: any
    page: number
    pageSize: number
    query?: string
    typeFilter: string[]
    statusFilter: string[]
    paginationLinks?: IApiResponsePaginationLinks

    filteredDomain?: DomainDto

    showAddUserPrompt: boolean
}

export class UserList extends React.Component<IUserList, IUserListState> {
    protected appState: AppState
    protected appService: AppService
    protected userService: UserService
    protected domainService: DomainService

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.userService = Container.get(UserService)
        this.domainService = Container.get(DomainService)

        const defaultTypeFilter = this.appState.currentUser?.isInternalUser ? [UserType.EXTERNAL] : []

        this.state = {
            loading: true,
            users: [],
            usersMeta: {},
            page: 1,
            pageSize: 15,
            showAddUserPrompt: false,
            typeFilter: defaultTypeFilter,
            statusFilter: [StatusType.ACTIVE.name, StatusType.INVITED.name, StatusType.PASSWORD_RESET.name],
        }
    }

    public componentDidMount(): void {
        if (this.props.options.domainId) {
            this.fetchDomain()
        }

        this.fetchUsers()
    }

    public render() {
        const isAddUserDisabled = !this.isCurrentUserDomainManagerForUsers()

        return (
            <>
                <Well className={getClassNames('user-list', 'type-2')} hideHeader={true} hideFooter={true}>
                    <CompoundTable>
                        <CompoundTableHeader
                            left={
                                <>
                                    <Input.Search
                                        className="user-search"
                                        placeholder="Search ..."
                                        onSearch={this.searchUsers}
                                    />
                                    <AqeSelect
                                        className="status-filter"
                                        mode="multiple"
                                        disableSearch={true}
                                        disableSelectAll={true}
                                        defaultValue={this.state.statusFilter}
                                        options={[
                                            { value: StatusType.ACTIVE.name, label: 'Active' },
                                            { value: StatusType.DISABLED.name, label: 'Disabled' },
                                            { value: StatusType.INVITED.name, label: 'Invited' },
                                            { value: StatusType.PASSWORD_RESET.name, label: 'Password Reset' },
                                        ]}
                                        onClose={this.filterUserStatuses}
                                        maxDisplayCount={2}
                                        maxDisplayFormatter={(options: any[]) => {
                                            const multi = options.length > 1

                                            return (
                                                <Tooltip title={options.map((o) => o.label).join(', ')}>
                                                    {options.length} {multi ? 'Statuses' : 'Status'} Selected
                                                </Tooltip>
                                            )
                                        }}
                                    />
                                </>
                            }
                            right={
                                isAddUserDisabled ? undefined : (
                                    <>
                                        <Button type="primary" onClick={this.showAddUserPrompt}>
                                            <span>Add User</span>
                                        </Button>
                                    </>
                                )
                            }
                        />
                        <Table
                            dataSource={this.state.users}
                            pagination={false}
                            footer={(currentTableData) => (
                                <SwSimpleLinkPagination
                                    currentTableData={currentTableData}
                                    links={this.state.paginationLinks}
                                    onPrev={async (_ev, _link, config) => {
                                        this.setState({ page: config.current! }, () => this.fetchUsers())
                                    }}
                                    onNext={async (_ev, _link, config) => {
                                        this.setState({ page: config.current! }, () => this.fetchUsers())
                                    }}
                                />
                            )}
                            rowKey={(record) => record.id.toString()}
                            loading={
                                this.state.loading && {
                                    indicator: <Loading3QuartersOutlined spin={true} />,
                                }
                            }
                            locale={{
                                emptyText: <AntdTableEmptyPlaceholder text="No Users" icon="user" />,
                            }}
                        >
                            <Table.Column className="id" title="ID" dataIndex="id" />
                            <Table.Column className="user" title="User" render={this.renderUserColumn} />
                            {!!this.props.options.domainId && (
                                <Table.Column className="permissions" title="Role" render={this.renderUserRole} />
                            )}
                            <Table.Column className="status" title="Status" render={this.renderStatusColumn} />
                            <Table.Column className="actions" title="Actions" render={this.renderRowActions} />
                        </Table>
                    </CompoundTable>

                    <AddUserPrompt
                        key={`${this.state.showAddUserPrompt ? 'v' : 'h'}-add-user`}
                        visible={this.state.showAddUserPrompt}
                        accountId={this.accountId}
                        domainId={this.domainId}
                        onOk={this.handleUserAdd}
                        onCancel={this.hideAddUserPrompt}
                    />
                </Well>
            </>
        )
    }

    protected get accountId(): number | undefined {
        let accountId = this.props.options.accountId
        if (!accountId && !!this.state.filteredDomain) {
            accountId = this.state.filteredDomain.accountId
        }

        return !accountId ? undefined : parseInt(accountId.toString(), 10)
    }

    protected get domainId(): number | undefined {
        const domainId = this.props.options.domainId
        return !domainId ? undefined : parseInt(domainId.toString(), 10)
    }

    protected get paginationConfig(): any {
        return getTablePaginationConfig({
            pageSize: this.state.pageSize,
            current: this.state.page,
            onChange: async (page: number) => {
                await this.setState({ page })
                await this.fetchUsers()
            },
        })
    }

    // external platform only allows management of external users: userTypeId = 2
    protected isCurrentUserDomainManagerForUsers(): boolean {
        let can = false
        if (this.props.options.domainId) {
            can = this.appState.abilityStore.can(
                AbilityAction.UPDATE,
                asCaslSubject(SubjectEntity.USER, { domainId: this.props.options.domainId, userTypeId: 2 }),
            )
        } else if (this.props.options.accountId) {
            can = this.appState.abilityStore.can(
                AbilityAction.UPDATE,
                asCaslSubject(SubjectEntity.USER, { accountId: this.props.options.accountId, userTypeId: 2 }),
            )
        }

        return can
    }

    protected renderUserColumn(_: string, user: UserDto): React.ReactNode {
        const name: string = user.name || user.email

        return <TableRowEntityDisplay title={name} status={user.email} />
    }

    protected renderUserRole = (_: string, user: UserDto & { domainPermissions: any }): React.ReactNode => {
        let text: any = 'No Access'

        if (user.domainPermissions?.userRoleId) {
            const role = AccessRoleMap.get(user.domainPermissions.userRoleId)
            if (role) {
                text = role.label
            }
        }

        return text
    }

    protected renderStatusColumn = (_: string, user: UserDto): React.ReactNode => {
        return <StatusBadge type="user" status={user.status} expanded={true} />
    }

    protected renderRowActions = (_: any, user: UserDto): React.ReactNode => {
        const userDisabled = user.statusId === StatusType.DISABLED.id
        const showReInvite = !!this.accountId && user.statusId === StatusType.INVITED.id
        const showResetPassword =
            !!this.accountId &&
            !userDisabled &&
            user.statusId === StatusType.ACTIVE.id &&
            user.isDbUser &&
            !user.isApiUser

        const currentUser = this.appState.currentUser!
        const rowUserIsSelf = currentUser.id === user.id
        const currentUserIsDomainAdmin = this.isCurrentUserDomainManagerForUsers()

        const showDisableUser = currentUserIsDomainAdmin && !rowUserIsSelf

        const actions: any[] = []

        if (userDisabled) {
            actions.push({
                text: 'Enable',
                icon: 'eye',
                onClick: this.handleEnable,
                confirm: {
                    okText: 'Enable',
                    title: 'Enable User',
                    iconType: 'question-circle',
                    content: <>Are you sure you want to enable this user?</>,
                },
                user,
            })
        } else {
            if (showDisableUser) {
                actions.push({
                    text: 'Disable',
                    icon: 'eye',
                    onClick: this.handleDisable,
                    confirm: {
                        okText: 'Disable',
                        title: 'Disable User',
                        iconType: 'warning',
                        content: <>Are you sure you want to disable this user?</>,
                    },
                    user,
                })
            }
            if (showReInvite) {
                actions.push({
                    text: 'Re-Invite',
                    icon: 'mail',
                    onClick: this.handleReInvite,
                    user,
                })
            }
            if (showResetPassword) {
                actions.push({
                    text: 'Password Reset',
                    icon: 'lock',
                    onClick: this.handleResetPassword,
                    user,
                })
            }
        }

        return (
            <Button
                size="small"
                altHref={this.buildUserUrl(user)}
                onClick={() => this.jumpToUser(user)}
                actions={actions.length > 0 ? actions : undefined}
            >
                <span>View</span>
            </Button>
        )
    }

    protected buildUserUrl(user: UserDto): string {
        const url = `/users/${user.id}`
        const qsParts: string[] = []

        if (this.accountId) {
            qsParts.push(`account_id=${this.accountId}`)
        }
        if (this.domainId) {
            qsParts.push(`domain_id=${this.domainId}`)
        }

        return `${url}?${qsParts.join('&')}`
    }

    protected jumpToUser(user: UserDto): void {
        this.appService.route(this.buildUserUrl(user))
    }

    protected showAddUserPrompt = async (): Promise<void> => {
        return this.setState({ showAddUserPrompt: true })
    }

    protected hideAddUserPrompt = async (): Promise<void> => {
        return this.setState({ showAddUserPrompt: false })
    }

    protected handleUserAdd = async (
        userDto: Partial<UserDto> & { permissions?: AccountUserModel[] },
    ): Promise<void> => {
        let accounts: any[] = []

        if (Array.isArray(userDto.permissions) && userDto.permissions[0]) {
            const source = userDto.permissions[0]
            const accountRecord = {
                accountId: source.getAccountId(),
                roleId: source.getRoleId(),
                flags: source.getFlags(),
                domains: {},
            }

            for (const domain of Array.from(source.getDomainRecords().values())) {
                accountRecord.domains[domain.getDomainId()] = {
                    roleId: domain.getRoleId(),
                    flags: domain.getFlags(),
                }
            }

            accounts.push(accountRecord)
        }

        const { ok, data: user } = await this.userService.add(
            { ...userDto, accounts },
            {
                showLoadingScreen: true,
            },
        )

        if (ok) {
            this.jumpToUser(user)
        }
    }

    protected handleDisable = async (item: any) => {
        const { user } = item.props

        await this.userService.updateById(
            user.id,
            {
                status: 'DISABLED',
            },
            {
                showLoadingScreen: true,
            },
        )

        return this.fetchUsers()
    }

    protected handleEnable = async (item: any) => {
        const { user } = item.props

        await this.userService.updateById(
            user.id,
            {
                status: 'ACTIVE',
            },
            {
                showLoadingScreen: true,
            },
        )

        return this.fetchUsers()
    }

    protected handleReInvite = async (item: any): Promise<void> => {
        const { user } = item.props

        await this.userService.triggerInviteForUserId(user.id, this.accountId!, {
            showLoadingScreen: true,
        })

        return this.fetchUsers()
    }

    protected handleResetPassword = async (item: any): Promise<void> => {
        const { user } = item.props

        await this.userService.triggerPasswordResetForUserId(user.id, this.accountId!, {
            showLoadingScreen: true,
        })

        return this.fetchUsers()
    }

    protected filterUserTypes = async (typeFilter: string[]) => {
        if (!deepEqual(typeFilter, this.state.typeFilter)) {
            typeFilter = typeFilter || []
            await this.setState({ typeFilter })

            return this.fetchUsers()
        }
    }

    protected filterUserStatuses = async (statusFilter: string[]) => {
        if (!deepEqual(statusFilter, this.state.statusFilter)) {
            statusFilter = statusFilter || []
            await this.setState({ statusFilter })

            return this.fetchUsers()
        }
    }

    protected searchUsers = async (
        value: string,
        ev: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
    ): Promise<void> => {
        let query: string | undefined = value.trim()

        if (value.length < 2) {
            query = undefined
        }

        await this.setState({ query })

        return this.fetchUsers()
    }

    protected async fetchUsers(): Promise<void> {
        const update: any = { loading: true }
        await this.setState(update)

        update.loading = false
        update.users = []

        let response: IServiceApiResponse<any[]> = { ok: false, data: [] }
        const options = {
            showLoadingScreen: false,
            query: {
                page: this.state.page,
                limit: this.state.pageSize,
                search: this.state.query,
                type: this.state.typeFilter.join(','),
                status: this.state.statusFilter.join(','),
            },
        }

        if (this.domainId) {
            response = await this.userService.fetchAllDomainUsers(this.domainId, options)
        } else if (this.accountId) {
            response = await this.userService.fetchAllAccountUsers(this.accountId, options)
        }

        if (response && response.ok) {
            update.users = response.data
            update.paginationLinks = response.meta.links ?? update.paginationLinks
            update.usersMeta = response.meta
        }

        return this.setState(update)
    }

    protected async fetchDomain() {
        const { ok, data: domain } = await this.domainService.fetchById(this.props.options.domainId!, {
            showLoadingScreen: false,
            cancellationKey: 'duld-fetch',
        })

        if (ok) {
            this.setState({ filteredDomain: domain })
        }
    }
}
